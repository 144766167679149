import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import './style.less';

import CustomButton from 'components/CustomButton';
import FileItem from 'components/FileItem'

import Description from 'components/Description';
import Avatar from 'components/Avatar';
import CustomModal from 'components/CustomModal';
import Answer from 'components/Answer';
import T from 'components/T';
import A from 'components/A';

import { canUseResponseDataByKey } from 'utils/dataRequests';
import { formatLong } from 'utils/date';

import {
  Empty,
  Row,
  Col,
  Divider,
} from 'antd';

const DEFAULT_AVATAR_PROPS = {
  icon: 'user',
};

const MODAL_DATA_ANSWER_MODE = {
  external: 'external',
};

const getAvatarProps = (avatar) => {
  if(avatar) {
    return { src: avatar };
  }
  return DEFAULT_AVATAR_PROPS;
};

const ModalDataAnswer = ({
  intl,
  mode = 'suborg',
  shown,
  showUser = true,
  kpi_name,
  org_name,
  period,
  name,
  avatar,
  email,
  updated_at,
  schema,
  schemaLabels,
  value,
  attachments = [],
  loading,
  error,
  onClose,
  showUseButtons,
  onUseValue,
  onUseAttachment,
  comment,
  config,
  applies,
  not_apply_comment = '',
  request_id,
  request_data_in_use,
  request_status,
  handleUseDataRequests,
  value_source,
  tableDimensions,
}) => {
  const supportNotApply = typeof applies === 'boolean';

  const [submitting, setSubmitting] = useState(false);

  const handleUseValue = useCallback(() => {
    setSubmitting('value');
    if (mode === MODAL_DATA_ANSWER_MODE.external) {
      handleUseDataRequests([request_id], true, false);
    } else {
      onUseValue({ value, comment });
    }
  }, [
    mode,
    handleUseDataRequests,
    onUseValue,
    value,
    comment,
    request_id
  ]);

  const handleUseAttachment = useCallback((attachment) => {
    onUseAttachment(attachment);
  }, [
    onUseAttachment,
  ]);

  const handleUseAllAttachments = useCallback(() => {
    setSubmitting('attachment');
    if (mode === MODAL_DATA_ANSWER_MODE.external) {
      handleUseDataRequests([request_id], false, true);
    } else {
      attachments.forEach(onUseAttachment);
    }
  }, [
    mode,
    handleUseDataRequests,
    attachments,
    onUseAttachment,
    request_id
  ]);

  const handleUseBoth = useCallback(() => {
    setSubmitting('both');
    if (mode === MODAL_DATA_ANSWER_MODE.external) {
      handleUseDataRequests([request_id], true, true);
    } else {
      onUseValue({ value, comment });
      attachments.forEach(onUseAttachment);
    }
  }, [
    mode,
    handleUseDataRequests,
    onUseValue,
    value,
    comment,
    attachments, 
    onUseAttachment,
    request_id
  ]);

  useEffect(() => {
    if(submitting && !loading && !error) {
      setSubmitting(false);
      onClose();
    }
  }, [
    submitting,
    loading,
    error,
    onClose,
  ]);

  return (
    <CustomModal
      className="ModalDataAnswer"
      shown={shown}
      width="auto"
      title={
        <div className="ModalDataAnswer__header">
          <div className="ModalDataAnswer__header-title">
            { kpi_name }
          </div>
          <div className="ModalDataAnswer__header-subtitle">
            <T
              modal_data_answer_org_and_period={{
                org_name,
                period,
              }}
            />
          </div>
        </div>
      }
      onCancel={onClose}
      footer={
        <div className="ModalDataAnswer__footer">
          <div className="ModalDataAnswer__footer-usebuttons">
            {
              !showUseButtons || (!value && attachments.length === 0) || (supportNotApply && !applies) ? null :
              <React.Fragment>
                {
                  attachments.length === 0 || (mode === MODAL_DATA_ANSWER_MODE.external && value_source !== "manual")
                    || (request_id && !canUseResponseDataByKey('attachments', request_status, request_data_in_use))
                    ? null :
                  <CustomButton
                    disabled={loading}
                    loading={loading === 'attachment'}
                    onClick={handleUseAllAttachments}
                  >
                    { intl.formatMessage({ id: "data_answer_card_use_attachment"}) }
                  </CustomButton>
                }
                {
                  !value || (mode === MODAL_DATA_ANSWER_MODE.external && value_source !== "manual")
                    || (request_id && !canUseResponseDataByKey('value', request_status, request_data_in_use))
                  ? null :
                  <CustomButton
                    disabled={loading}
                    loading={loading === 'value'}
                    onClick={handleUseValue}
                  >
                    { intl.formatMessage({ id: "data_answer_card_use_data"}) }
                  </CustomButton>
                }
                {
                  attachments.length === 0 || !value || (mode === MODAL_DATA_ANSWER_MODE.external && value_source !== "manual")
                  || (request_id && (!canUseResponseDataByKey('value', request_status, request_data_in_use)
                  || !canUseResponseDataByKey('attachments', request_status, request_data_in_use)))
                  ? null :
                  <CustomButton
                    disabled={loading}
                    loading={loading === 'both'}
                    onClick={handleUseBoth}
                  >
                    { intl.formatMessage({ id: "data_answer_card_use_both"}) }
                  </CustomButton>
                }
              </React.Fragment>
            }
          </div>
          <CustomButton
            className="ModalDataAnswer__btn-footer"
            key="back"
            onClick={onClose}
          >
            {intl.formatMessage({ id: "modal_data_answer_close"})}
          </CustomButton>
        </div>
      }
    >
      {
        !showUser
        ? null
        : (
          <React.Fragment>
            <Row type='flex' gutter={13}>
              <Col>
                <Avatar
                  className='ModalDataAnswer__avatar'
                  size={62}
                  {...getAvatarProps(avatar)}
                />
              </Col>
              <Col>
                <div className='ModalDataAnswer__answer-user'>
                  <div>
                    <b>{ name }</b>{` ${intl.formatMessage({ id: `modal_data_answer_name_${mode}`})}`}
                  </div>
                  <div>
                    {
                      !value ? null :
                      <T
                        modal_data_answer_reported_on={{
                          date: formatLong(updated_at, intl),
                        }}
                      />
                    }
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
          </React.Fragment>
        )
      }
      <section
        className="ModalDataAnswer__answer-section"
      >
        {
          (supportNotApply && !applies)
          ? (
            <Empty
              className="ModalDataAnswer__answer-empty-image"
              image={'/images/icon-empty-data.svg'}
              description={intl.formatMessage({ id: "kpi_status_notapplies"}) }
            />
          )
          : !value
          ? (
            <Empty
              className="ModalDataAnswer__answer-empty-image"
              image={'/images/icon-empty-data.svg'}
              description={intl.formatMessage({ id: "kpi_detail_empty"})}
            />
          )
          : (
            <React.Fragment>
              <Answer.Show
                schema={schema}
                schemaLabels={schemaLabels}
                value={value}
                config={config}
                tableDimensions={tableDimensions}
              />
            </React.Fragment>
          )
        }
      </section>
      {
        !supportNotApply || applies || !not_apply_comment
        ? null
        : (
          <React.Fragment>
            <Divider/>
            <section>
              <div className='ModalDataAnswer__comment-header'>
                { intl.formatMessage({ id: "reason"}) }
              </div>
              <Description
                description={ not_apply_comment }
              />
            </section>
          </React.Fragment>
        )
      }
      {
        !comment || (supportNotApply && !applies)
        ? null
        : (
          <React.Fragment>
            <Divider/>
            <section>
              <div className='ModalDataAnswer__comment-header'>
                { intl.formatMessage({ id: "comment"}) }
              </div>
              <Description
                description={ comment }
              />
            </section>
          </React.Fragment>
        )
      }
      {
        !attachments || attachments.length === 0
        ? null
        : (
          <React.Fragment>
            <Divider/>
            <section>
              <Row
                type="flex"
                gutter={[15, 15]}
                className="ModalDataAnswer__files"
              >
                {
                  attachments.map(
                    attachment => (
                      <Col
                        key={attachment.url}
                      >
                        <FileItem {...attachment} >
                          {mode === MODAL_DATA_ANSWER_MODE.external ? null : (
                            <>
                              {' '}-{' '}
                              <A onClick={() => handleUseAttachment(attachment)}>
                                { intl.formatMessage({ id: "fileitem_usethis"}) }
                              </A>
                            </>
                          )}
                        </FileItem>
                      </Col>
                    )
                  )
                }
              </Row>
            </section>
          </React.Fragment>
        )
      }
    </CustomModal>
  );
};

export default injectIntl(ModalDataAnswer);
