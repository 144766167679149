import React, { useEffect, useMemo,useState } from 'react';
import { injectIntl } from 'react-intl';

import Description from 'components/Description'
import Variance from 'components/Variance'
import AnswerFooter from 'components/AnswerFooter';
import { useAvailableUnits } from 'utils/useAvailableUnits';
import { formatNumberWithIntl } from 'utils/formatNumber';
import { getNumberFormatOptions } from 'utils/kpi';

import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';

// import Answer from 'components/Answer';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTargets from 'components/DataTargets';

import {
  Empty,
  Row,
  Col,
  Divider,
} from 'antd';
import { useMetricUnits } from 'utils/useMetricUnits';

const DEFAULT_INITIAL_VALUE = {
  value: null,
  unit: 'number',
};

// NOTICE: This must be the same as in src/components/TableAnswer/converters.js
const PERCENTAGE_SLUG = '__percentage';
const NO_VALUE = '-';
const QUALITATIVE_SCHEMA_TYPE= 'qualitative';


const Component = injectIntl(({
  intl,
  schema,
  config,
  previous_value,
  value: kpiValue,
}) => {
  const availableUnits = useAvailableUnits(schema);
  const show_kpi_variation = config?.show_kpi_variation;

  const {
    unit = (availableUnits[0] || {}).slug, // TODO: Show unit better!!!
    value = DEFAULT_INITIAL_VALUE.value,
  } = kpiValue || {};

  const unitText = useMemo(() => {
    const unitObject = availableUnits.find(({ slug }) => slug === unit)

    if (schema.metricSlug === 'currency' && (!kpiValue || !kpiValue.unit)) {
      return ''
    }

    return unitObject
      ? unitObject.symbol
      : '';
  }, [
    unit,
    schema,
    kpiValue,
    availableUnits,
  ]);

  return (
     <>
      <Row
        type="flex"
        align="middle"
        gutter={25}
      >
        <Col>
          <div className="KpiDetail__answer-quantitative-show">
            {
              typeof value === 'undefined' || value === null
              ? NO_VALUE
              : formatNumberWithIntl(intl)(
                value,
                getNumberFormatOptions(schema, config)
              )
            }
          </div>
        </Col>
        <Col>
          { unitText }
        </Col>
      </Row>
      {show_kpi_variation &&
      <Row>
        <Col>
          <Variance
            intl={intl}
            kpiValue={kpiValue}
            previousKpiValue={previous_value}
            availableUnits={availableUnits}
          />
        </Col>
      </Row>
      }
    </>
  );
});

const TableComponent = injectIntl(({
  intl,
  schema,
  config,
  value: kpiValue,
  showPercentage = false,
}) => {
  const schemaAvailableUnits = useAvailableUnits(schema);
  const { baseUnits } = useMetricUnits(schema);
  const availableUnits = useMemo(() => {

    // In heterogeneous table, data comes in base unit, which maybe does not exist into the availableUnits
    // In order to show the table without base units, we add the base unit to the availableUnits

    if (schemaAvailableUnits.find(_unit => _unit.is_base)) return schemaAvailableUnits;
    return [...schemaAvailableUnits, baseUnits ];

  }, [ schemaAvailableUnits, baseUnits ]);

  const {
    unit = (schemaAvailableUnits[0] || {}).slug,
    value = DEFAULT_INITIAL_VALUE.value,
    previous_value,
    target_value = null,
    [PERCENTAGE_SLUG]: percentage,
  } = kpiValue || {};

  const unitText = useMemo(() => {
    const unitObject = availableUnits.find(({ slug }) => slug === unit);
    if (schema.metricSlug === 'currency' && !kpiValue?.unit) {
      return ''
    }

    return unitObject
      ? unitObject.symbol
      : '';
  }, [
    unit,
    schema,
    kpiValue,
    availableUnits,
  ]);

  const show_kpi_variation = config?.show_kpi_variation;
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();


  const ifTargetValue = featureList && featureList.has('targets') && target_value !== null;
  return (
    <div className={`KpiDetail__answer-quantitative-row${ifTargetValue? '-target':''}`} >
      <Row
        type="flex"
        align={ifTargetValue? 'top': 'middle'}
        gutter={ifTargetValue? 0 : 10}
        justify={ifTargetValue? 'space-between': 'start'}
        style={{flexGrow:1}}
      >
        <Col>
          <span>
            {
              typeof value === 'undefined' || value === null
              ? NO_VALUE
              : formatNumberWithIntl(intl)(
                value,
                getNumberFormatOptions(schema, config),
              )
            }
            {
              unitText
              ? ` ${unitText}`
              : ''
            }
          </span>
        </Col>
        {
          !showPercentage || typeof value === 'undefined' || value === null || typeof percentage === 'undefined'
          ? null
          : (
            <Col>
              <span
                className="KpiDetail__answer-quantitative-percentage"
              >
                {
                  formatNumberWithIntl(intl)(
                    percentage,
                    {
                      style: 'percent',
                      maximumFractionDigits: 1,
                    }
                  )
                }
              </span>
            </Col>
          )
        }
        { show_kpi_variation &&
          <Col>
            <Variance
              intl={intl}
              kpiValue={kpiValue}
              previousKpiValue={previous_value}
              availableUnits={availableUnits}
            />
          </Col>
        }
        { ifTargetValue && 
          <Col className='DataTarget'>
            <DataTargets.QuantitativeTableShow 
              target={target_value}
              kpi_value={kpiValue}
              schema={schema}
              config={config}
            />
            </Col>

        }
      </Row>
    </div>
  );
});

const SummaryComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  config,
  value: kpiValue,
  comment
}) => {
  const t = intl.messages;
  const availableUnits = useAvailableUnits(schema);
  const {
    unit = (availableUnits[0] || {}).slug, // TODO: Show unit better!!!
    value = DEFAULT_INITIAL_VALUE.value,
  } = kpiValue || {};

  const unitText = useMemo(() => {
    const unitObject = availableUnits.find(({ slug }) => slug === unit)
    return unitObject ? unitObject.symbol : '';
  }, [
    unit,
    availableUnits,
  ]);

  return (
    typeof value === 'undefined' || value === null
    ? (
      <Empty
        image={'/images/icon-empty-data.svg'}
        description={t.kpi_detail_empty}
      />
    )
    : (
      <>
      <Row
        type="flex"
        align="middle"
        gutter={15}
      >
        <Col>
          <div className="KpiDetail__answer-quantitative-show">
            {
              formatNumberWithIntl(intl)(
                value,
                getNumberFormatOptions(schema, config)
              )
            }
          </div>
        </Col>
        <Col>
          { unitText }
        </Col>
      </Row>
      </>
    )
  );
});

const Show = ({
  intl,
  source,
  source_params,
  schema, // TODO: obtain the units from schema
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  canWrite,
  target,
  onTarget,
  suborganization_data
}) => {
  const {
    suborganization,
    permissions,
  } = useOrganizations();
  const { config = {} } = suborganization;

  const [hasTarget, setHasTarget] = useState(false);
  const t = intl.messages;
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  useEffect(()=>{
    if(featureList.has('targets') && target){
      setHasTarget(true);
    }
  },[target,featureList]);

  const hasTargetPermissions = useMemo(() => {
    return featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <section>
      <section style={{display:"flex",flexDirection:"row"}}>
      <div className="ant-col-6">
        <Component
          schema={schema}
          config={config}
          value={value}
          previous_value={previous_value}
        />
        {
          !comment
          ? null
          : (
            <React.Fragment>
              <Divider/>
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
              />
            </React.Fragment>
          )
        }
        </div>
        <div className="ant-col-6">
        <DataTargets
          schema={schema}
          config={config}
          kpi_value={value}
          target={target}
          justify="start"
        />
        </div>
      </section>
      <Divider/>
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        onTarget={onTarget}
        hasTarget={hasTarget}
        isTargetElegible={hasTargetPermissions}
        suborganization_data={suborganization_data}
      />
    </section>
  );
};

Show.Component = Component;
Show.TableComponent = TableComponent;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

