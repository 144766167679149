import { parse } from 'math-parser';
import { traverse } from 'math-traverse';

import {
  deepGet,
  deepGetStar,
  nestedObjectFilter,
} from '../functional';

import BracesReplacer from '../js/bracesReplacer';

import {
  chunk,
} from './array';

import {
  kebabToCamel,
  //camelToKebab,
} from './string';

import objectMap from './objectMap';

import {
  convertToUnit,
  fixValueUnit,
} from './units';

import { getSequence } from './sequence';

const DEBUG=false;

// Some constants: periods
export const PERIOD_SELF = '__self';
export const PERIOD_PREV = '__prev';
export const PERIOD_NEXT = '__next';
export const PERIOD_MONTH_TO_YEAR       = '__month_to_year';
export const PERIOD_MONTH_TO_SEMESTER   = '__month_to_semester';
export const PERIOD_MONTH_TO_QUARTER    = '__month_to_quarter';
export const PERIOD_QUARTER_TO_YEAR     = '__quarter to year';
export const PERIOD_QUARTER_TO_SEMESTER = '__quarter_to_semester';
export const PERIOD_SEMESTER_TO_YEAR    = '__semester_to_year';
export const DEFAULT_PERIOD = PERIOD_SELF;

// Some constants: organizations
export const ORGANIZATION_NOPARENT = '__noparent';
export const ORGANIZATION_SELF = '__self';
export const ORGANIZATION_PARENT = '__parent';
export const ORGANIZATION_CHILDREN = '__children';
export const ORGANIZATION_SIBLINGS = '__siblings';
export const ORGANIZATION_ROOT = '__root';
export const DEFAULT_ORGANIZATION = ORGANIZATION_SELF;

const ID_FUNC = a => a;

const OPERATIONS_ALLOWING_NULLS = [
  'add',
  'SUM',
  'PRODUCT',
  'MIN',
  'MAX',
  'MINCHOICE',
  'MAXCHOICE',
  'MEAN',
  'MEANNORM',
  'MINMAXNORM',
];

const ALL_OPERATIONS = {
  add: (...args) => args.reduce((a, b) => a+b, 0), // NOTICE: This op allows nulls
  sub: (a, b) => a - b,
  mul: (...args) => args.reduce((a, b) => a*b, 1),
  div: (a, b) => a / b,
  neg: (a) => -a,
  SUM: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return ALL_OPERATIONS.add(...validArgs)
  },
  PRODUCT: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return ALL_OPERATIONS.mul(...args)
  },
  MIN: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return args.reduce((a, b) => Math.min(a,b), Infinity)
  },
  MAX: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return args.reduce((a, b) => Math.max(a,b), -Infinity)
  },
  MINCHOICE: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return args.reduce((a, b) => Math.min(a,b), Infinity)
  },
  MAXCHOICE: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return args.reduce((a, b) => Math.max(a,b), -Infinity)
  },
  MEAN: (...args) => { // NOTICE: This op allows nulls
    const validArgs = args.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validArgs.length === 0) {
      return null;
    }
    return args.reduce((a, b) => a+b, 0) / args.length
  },
  MEANNORM: (val, ...rest) => {
    if(typeof val === 'undefined' || val === null) {
      return val;
    }

    const validRest = rest.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validRest.length === 0) {
      return null;
    }

    const a = 0;
    const b = 1;
    const max = ALL_OPERATIONS.MAX(val, ...validRest);
    const min = ALL_OPERATIONS.MIN(val, ...validRest);
    const mean = ALL_OPERATIONS.MEAN(val, ...validRest);

    if(max === min) {
      // TODO: ???
      return a;
    }

    return a + (((val - mean) * (b - a)) / (max - min));
  },
  MINMAXNORM: (val, ...rest) => {
    if(typeof val === 'undefined' || val === null) {
      return val;
    }

    const validRest = rest.filter(arg => typeof arg !== 'undefined' && arg !== null);
    if(validRest.length === 0) {
      return null;
    }

    const a = 0;
    const b = 1;
    const max = ALL_OPERATIONS.MAX(val, ...validRest);
    const min = ALL_OPERATIONS.MIN(val, ...validRest);

    if(max === min) {
      // TODO: ???
      return a;
    }

    return a + (((val - min) * (b - a)) / (max - min));
  },
  SUMPRODUCT: (size, ...args) => {
    if(args.length % size !== 0) {
      console.log('Called SUMPRODUCT with wrong args', size, args.length);
      return null;
    }
    const chunks = chunk(args, size);
    return ALL_OPERATIONS.SUM(
      ...(
        chunks.map(
          chunk => ALL_OPERATIONS.PRODUCT(...chunk)
        )
      )
    );
  },
  default: (...args) => {
    console.log('Called unknown function with args', args);
    return null;
  },
};

const convertToNumberOnly = (payload) => {
  if(!payload) {
    return payload;
  }

  const {
    value,
    unit,
  } = payload;

  if(typeof value === 'undefined' || value === null || isNaN(value)) {
    return {
      value,
      unit,
    }
  }

  return {
    value: Number(value),
    unit,
  };
};

const getOperation = node => {
  // By default we use implicit functions (e.g. '+' => 'add')
  let funcName = node.op;
  if(typeof node.op === 'object' && node.op.type === 'Identifier') {
    // Parenthesized functions appear like this...
    funcName = node.op.name;
  }

  return ALL_OPERATIONS[funcName] || ALL_OPERATIONS.default;
};

const operationAllowsNull = node => {
  // By default we use implicit functions (e.g. '+' => 'add')
  let funcName = node.op;
  if(typeof node.op === 'object' && node.op.type === 'Identifier') {
    // Parenthesized functions appear like this...
    funcName = node.op.name;
  }

  return OPERATIONS_ALLOWING_NULLS.includes(funcName);
};

const flattenArray = arr => (arr || []).reduce((flat, el) =>
  flat.concat(Array.isArray(el) ? flattenArray(el) : el)
, []);

const isLeaf = (fieldName) => (value, needsValue = true) => {
  if(typeof value !== 'object' || value === null || Array.isArray(value)) {
    return true;
  }

  return (
    (!needsValue || typeof value[fieldName] !== 'undefined')
    &&
    Object.keys(value)
      .filter(key => !([fieldName, 'unit', 'target_value'].includes(key) || key.startsWith('_')))
      .length === 0
  )
};

const getAllLeafs = (fieldName) => (tree) => {
  if(isLeaf(fieldName)(tree)) {
    return tree;
  }

  return Object.keys(tree)
    .filter(key => key !== 'unit' && key !== 'target_value' && !key.startsWith('_'))
    .map(key => tree[key])
    .map(getAllLeafs(fieldName));
};

export const convertPeriodToSubperiods = (
  base,
  big = 'year',
  small = 'month',
  cycle_date = '01-01',
) => {
  const year = Number((base || '').slice(0, 4)); // Unfortunately we may need to do peiod math...
  const firstMonth = Number(cycle_date.slice(0, 2));
  let amount;                      // How many periods
  let firstPeriod = 1;             // What is the first period
  let formatYear = (year) => year; // For anything except months
  let formatPeriod = (period) => (period);
  let subPeriod = 1;               // Like the 1 in '2020-Q1'

  const order = {
    month: 1,
    quarter: 2,
    semester: 3,
    year: 4,
  };

  if(!order[small] || !order[big] || order[big] <= order[small]) {
    throw new Error(`Do not know how to split ${big} to ${small}`);
  }

  switch(small) {
    case 'month':
      amount = 12;
      firstPeriod = firstMonth;
      formatYear = (year, period) => period > 12 ? String(year+1) : String(year);
      formatPeriod = (period) => String(((period - 1) % 12)+1).padStart(2, '0');
      break;
    case 'quarter':
      amount = 4;
      formatPeriod = (period) => `Q${period}`;
      break;
    case 'semester':
      amount = 2;
      formatPeriod = (period) => `S${period}`;
      break;
    default:
      throw new Error(`Do not know how to split to ${small}`);
  }

  switch(big) {
    case 'quarter':
      amount = amount / 4
      subPeriod = Number(((base || '').split('-')[1] || 'Q1').slice(-1)) || 1;
      if(subPeriod > 4) {
        throw new Error(`Wrong quarter ${base}`);
      }
      break;
    case 'semester':
      amount = amount / 2
      subPeriod = Number(((base || '').split('-')[1] || 'S1').slice(-1)) || 1;
      if(subPeriod > 2) {
        throw new Error(`Wrong semester ${base}`);
      }
      break;
    case 'year':
      subPeriod = 1; // Make sure we do not do weird stuff
      break;
    default:
      throw new Error(`Do not know how to split from ${big}`);
  }

  // Adjust the first period
  firstPeriod = firstPeriod + ((subPeriod - 1) * amount);
  return getSequence(amount, firstPeriod).map(period => `${formatYear(year, period)}-${formatPeriod(period)}`)
};

const findValue = (values, context = {}) => {
  // Prepare values for fast access...
  const orgMap = {};
  const kpiMap = {};
  values.forEach(({
    value,
    period,
    slug,
    organization,
    parent_organization,
    //schema,
  }) => {
    orgMap[parent_organization || ORGANIZATION_NOPARENT] = orgMap[parent_organization || ORGANIZATION_NOPARENT] || [];
    orgMap[parent_organization || ORGANIZATION_NOPARENT].push(organization);

    kpiMap[organization] = kpiMap[organization] || {};
    kpiMap[organization][slug] = kpiMap[organization][slug] || {};
    kpiMap[organization][slug][period] = value;
  });

  return (node) => {
    const {
      name,
      address,
      fieldName,
      period,
      organization,
      unit,
      filterRows = {},
    } = node || {};
    let organizations = [];
    switch(organization) {
      case ORGANIZATION_SELF:
        organizations.push(context.organization);
        break;
      case ORGANIZATION_PARENT:
        organizations.push(context.parent_organization);
        break;
      case ORGANIZATION_CHILDREN:
        organizations = organizations.concat(
          orgMap[context.organization] || []
        );
        break;
      case ORGANIZATION_SIBLINGS:
        organizations = organizations.concat(
          (
            orgMap[context.parent_organization] || []
          ).filter(slug => slug !== context.organization)
        );
        break;
      default:
        // We assume this is an organization slug
        organizations.push(organization);
    }

    let periodStrs; // Here we store the result
    switch(period) {
      case PERIOD_SELF:
        periodStrs = [ context.period ];
        break;
      case PERIOD_PREV:
        periodStrs = [ context.prevPeriod ];
        break;
      case PERIOD_NEXT:
        periodStrs = [ context.nextPeriod ];
        break;
      case PERIOD_MONTH_TO_YEAR:
        periodStrs = convertPeriodToSubperiods(context.period, 'year', 'month', context.cycle_date);
        break;
      case PERIOD_MONTH_TO_SEMESTER:
        periodStrs = convertPeriodToSubperiods(context.period, 'semester', 'month', context.cycle_date);
        break;
      case PERIOD_MONTH_TO_QUARTER:
        periodStrs = convertPeriodToSubperiods(context.period, 'quarter', 'month', context.cycle_date);
        break;
      case PERIOD_QUARTER_TO_YEAR:
        periodStrs = convertPeriodToSubperiods(context.period, 'year', 'quarter', context.cycle_date);
        break;
      case PERIOD_QUARTER_TO_SEMESTER:
        periodStrs = convertPeriodToSubperiods(context.period, 'semester', 'quarter', context.cycle_date);
        break;
      case PERIOD_SEMESTER_TO_YEAR:
        periodStrs = convertPeriodToSubperiods(context.period, 'year', 'semester', context.cycle_date);
        break;
      default:
        periodStrs = [ period ]; // This includes both a single fixed period and '*'
    }

    const isTable = address && address.length > 0;

    let result = [];
    // ...and access them
    for(const org_slug of organizations) {
      for(const periodStr of periodStrs) {
        let value = deepGetStar(
          kpiMap,
          [
            org_slug,
            name,
            (periodStr || DEFAULT_PERIOD),
            ...(address || [])
          ]
        );

        // NOTICE: filterRows needs to fetch related addresses
        if(Object.keys(filterRows).length > 0) {
          value = nestedObjectFilter(
            value,
            (_subvalue, subvalueAddress) => {
              let matchesFilter = true;
              for(let [key, expectedVal] of Object.entries(filterRows)) {
                // NOTICE: We fetch the actual column for this heterogeneous table
                const address = [
                  ...subvalueAddress,
                  key,
                ];
                const actualValObject = deepGet(kpiMap, [
                  org_slug,
                  name,
                  (periodStr || DEFAULT_PERIOD),
                  ...address
                ]);
                // WORKAROUND: We do not know what this column is, but we can guess!!!
                const actualVal = actualValObject[
                  ['text', 'value', 'choice', 'boolean'].find(prop => typeof actualValObject[prop] !== 'undefined')
                ];
                matchesFilter = matchesFilter && (expectedVal === actualVal);
                if(!matchesFilter) break; // Optimize a bit
              }
              return matchesFilter;
            },
            isLeaf(fieldName),
          );
        }

        if(typeof value === 'undefined' || value === null) {
          if(
            organization !== ORGANIZATION_SIBLINGS &&
            organization !== ORGANIZATION_CHILDREN
          ) {
            // If this is a value just from a single org, bail out
            result.push(undefined);
          }
          // NOTICE: We break the period loop, not the org one
          continue;
        }

        // Convert units
        const convert = unit
          ? (
            value => {
              // TODO: Maybe move this to the kpi/units helper or something...
              const schema = context.schema || {};
              const metricSlug = schema.metricSlug;
              let convertToBase = ID_FUNC;

              if(metricSlug) {
                const allUnits = (context.units || [])
                  .filter(({ metric_slug }) => metric_slug === metricSlug);
                const baseUnit = ((allUnits || []).find(({ is_base }) => is_base) || (allUnits || [])[0] || {}).slug;
                if(baseUnit) {
                  convertToBase = convertToUnit(baseUnit, context.units)
                }
              }
              return convertToUnit(unit, context.units)(
                convertToBase(
                  fixValueUnit(schema)(value)
                )
              );
            }
          )
          : convertToNumberOnly;

        if(
          isTable &&
          (
            typeof value === 'undefined'
            || value === null
            || (
                 isLeaf(fieldName)(value, false) // NOTICE: We allow leaf values with the field undefined
                 && typeof value === 'object'
                 && (
                   typeof value[fieldName] === 'undefined'
                   || value[fieldName] === null
                 )
               )
          )
        ) {
          result.push(0); // Null in a table means 0
          // NOTICE: We break the period loop, not the org one
          continue;
        }

        if(isLeaf(fieldName)(value)) {
          if(typeof value !== 'object') {
            console.log('WARNING: Standalone value', value);
          }

          result.push(
            typeof value === 'object'
              ? (convert(value) || {})[fieldName]
              : Number(value) // Standalone values cannot be converted
          );
          // NOTICE: We break the period loop, not the org one
          continue;
        }

        result = result.concat(
          flattenArray(
            getAllLeafs(fieldName)(value)
          ).map(value => {
            if(typeof value !== 'object') {
              console.log('WARNING: Standalone leaf value', value);
            }
            return typeof value === 'object'
              ? (convert(value) || {})[fieldName]
              : Number(value) // Standalone values cannot be converted
          })
        );
      }
    }
    return result;
  };
};

const adaptValue = (schema, tableDimensions) => (value) => {
  let result;
  switch((schema || {}).type) {
    case 'choice':
      switch(((schema || {}).options || {}).source) {
        case 'standard':
          result = (
            ((schema || {}).options || {}).standardItems || []
          ).map(({ slug }) => slug ).indexOf(value);
          return result;
        case 'organization':
          result = (
            tableDimensions[((schema || {}).options || {}).by] || []
          ).indexOf(value);
          return result;
        default:
          return value;
      }
    default:
      return value;
  }
};

const unadaptValue = (schema, tableDimensions) => (value) => {
  let result;
  switch((schema || {}).type) {
    case 'choice':
      switch(((schema || {}).options || {}).source) {
        case 'standard':
          result = (
            ((schema || {}).options || {}).standardItems || []
          )[value];
          return typeof result !== 'undefined'
            ? (result || {}).slug
            : value
        case 'organization':
          result = (
            tableDimensions[((schema || {}).options || {}).by] || []
          )[value];
          return typeof result !== 'undefined'
            ? result
            : value
        default:
          return value;
      }
    default:
      return value;
  }
};

export const applyAst = (
  ast,
  values,
  context,
) => {
  if(DEBUG) console.log('Math.traverse', ast, values);
  let stack = [];
  let args = [];
  let operation = () => console.log('Operation not defined');
  let reg;
  let allowsNull;

  const find = findValue(values, context);
  const schema = context.schema || {};
  const tableDimensions = context.tableDimensions || {};
  const adapt = adaptValue(schema, tableDimensions);
  const unadapt = unadaptValue(schema, tableDimensions);

  try {
    traverse(ast, {
      enter: (node) => {
        if(DEBUG) console.log('Enter', node, stack);
        switch(node.type) {
          case 'Apply':
            if(DEBUG) console.log('Push delimiter', node);
            stack.push({
              type: 'delimiter',
              node,
            });
            break;
          case 'Identifier':
          case 'Number':
          default:
            break;
        }
      },
      leave: (node) => {
        if(DEBUG) console.log('Leave', node, stack);
        switch(node.type) {
          case 'Apply':
            operation = getOperation(node);
            allowsNull = operationAllowsNull(node);

            args = [];

            if(DEBUG) console.log('Apply operation stack', operation, allowsNull, stack);

            // NOTICE: When the op allows nulls, we remove all the 'undefined' values until the first delimiter
            if(allowsNull) {
              reg = stack.findIndex(
                el => el !== null && typeof el === 'object' && el.type === 'delimiter'
              );
              stack = stack.filter((el, index) => typeof el !== 'undefined' || reg < 0 || index < reg)
            }

            if(DEBUG) console.log('Apply operation filtered stack', operation, allowsNull, stack);

            reg = stack.pop();
            while(
              typeof reg !== 'undefined' &&
              (allowsNull || reg !== null) && // NOTICE: Some operations DO allow nulls
              (
                typeof reg !== 'object' ||
                (reg || {}).type !== 'delimiter'
              )
            ) {
              args.unshift(reg);
              reg = stack.pop();
            }

            // TODO: check arity with operation.length?
            if(
              typeof reg === 'undefined' ||
              reg === null ||
              reg.node !== node
            ) {
              if(DEBUG) console.log('Inconsistency?', reg, args, node);
              if(!args || args.length === 0) {
                break;
              }
            }
            reg = operation(
              ...(
                args.map(adapt)
              )
            );
            if(reg === null) {
              throw new Error(`Unknown operation or wrong parameters ${JSON.stringify(node.op)} ${JSON.stringify(args)}`);
            }
            if(DEBUG) console.log('Push result', reg);
            stack.push(reg);
            break;
          case 'Identifier':
            reg = find(node);
            if(DEBUG) console.log('Push identifier', ...reg);
            stack.push(...reg);
            break;
          case 'Number':
            reg = Number(node.value);
            if(DEBUG) console.log('Push number', reg);
            stack.push(reg);
            break;
          default:
        }
      },
      path: [],
    })
  } catch(err) {
    if(DEBUG) {
      console.log('Caught error while processing math, ignoring', err);
    }
    return null;
  }

  if(DEBUG) console.log('Finish evaluating', stack);

  // NOTICE: We remove extra delimiters just in case
  stack = stack
    // QUESTION: Why are there sporious 'undefined' or delimiters in the stack???
    .filter(node => typeof node !== 'undefined')
    .filter(node => !(
      typeof node === 'object' && node !== null && node.type === 'delimiter'
    ));

  if(DEBUG) console.log('Before return', stack);
  return stack.length === 1
    ? unadapt(stack[0])
    : null;
};

const __astTestIsIdentifier = (val) => val.type === 'Identifier' && val.subscript;

const __astMapIdentifier = ({
  kpis,
  cache,
  fieldName,
}) => {
  return (val) => {
    const subscript = val.subscript.value;
    const info = (kpis[val.name] || {})[subscript] || {};
    return {
      type: 'Identifier',
      name: cache[val.name],
      address: (info.address || []),
      fieldName, // 'value' or 'choice' depending on schema.type
      period: info.period || DEFAULT_PERIOD,
      organization: info.organization || DEFAULT_ORGANIZATION,
      unit: info.unit,
      filterRows: info.filterRows,
    };
  }
};

export const formulaToAst = (
  formula = '',
  context = {},
) => {
  let kpis = {};
  let cache = {};

  const kpiFormula = formula.replace(
    new BracesReplacer(),
    function(match) {
      // NOTICE: here we abuse the "pure" replace function to emit a side effect
      //         this is ugly but pretty damn convenient
      try {
        const info = JSON.parse(match);
        const kpiSlug = info.kpi || (context || {}).default_kpi_slug;

        if(!kpiSlug) {
          throw new Error('Cannot find null kpi');
        }

        const base = kebabToCamel(kpiSlug);
        cache[base] = kpiSlug;

        const subindex = Object.keys(kpis[base] || {}).length;
        const identifier = `${base}_${subindex}`;
        kpis[base] = kpis[base] || {};
        kpis[base][subindex] = info;
        return identifier;
      } catch(err) {
        console.log('ERROR parsing', match, err);
        return 'x';
      }
    }
  );

  const kpiAst = parse(kpiFormula);

  const fieldName = ((context || {}).schema || {}).type === 'choice'
    ? 'choice'
    : 'value'

  const mapIdentifierFunc = __astMapIdentifier({ kpis, cache, fieldName });

  const finalAst = __astTestIsIdentifier(kpiAst)
  ? mapIdentifierFunc(kpiAst)
  : objectMap(
      kpiAst,
      __astTestIsIdentifier,
      mapIdentifierFunc,
    )

  return finalAst;
};

export const formulaToKpiInfo = (formula = '') => {
  let result = [];

  formula.replace(
    new BracesReplacer(),
    function(match) {
      // NOTICE: here we abuse the "pure" replace function to emit a side effect
      //         this is ugly but pretty damn convenient
      try {
        const info = JSON.parse(match);
        result.push({
          ...info,
          period: info.period || DEFAULT_PERIOD,
          organization: info.organization || DEFAULT_ORGANIZATION,
        });
        return 'x';
      } catch(err) {
        console.log('ERROR parsing', match, err);
        return 'x';
      }
    }
  );

  return result;
};

export const getDefaultChildrenFormulas = (schemaType, slug) => {
  switch(schemaType) {
    case 'quantitative':
      return [
        `SUM({ "kpi": "${slug}", "organization": "__children" })`,
        `MEAN({ "kpi": "${slug}", "organization": "__children" })`,
      ];
    default:
      return [];
  }
};

