import React, { useMemo } from "react";
import { intersectionBy, differenceBy } from "lodash";

import CreateEditCustomKPI from '../CreateEditCustomKPI';
import CategoryTreeParentSelector from "../CategoryTreeParentSelector";
import KpiCategoryIndicatorSearch from "../KpiCategoryIndicatorSearch";
import ConfigureTimeSelector from "../ConfigureTimeSelector";

export default ({
  intl,
  values,
  reportingStructure,
  isCreatingNewKpi,
  handleChange,
  handleNewKpi,
  flatTree,
  kpiValues,
  kpiErrors,
  kpiHandleChange,
  organizationName,
  organization,
  suborganization,
  reporting_structure_kpis,
}) => {

  const kpisAlreadyAdded = useMemo(() => intersectionBy(values.selected_kpis, reporting_structure_kpis, 'slug'), [reporting_structure_kpis, values.selected_kpis])
  const kpisNotAlreadyAdded = useMemo(() => differenceBy(values.selected_kpis, reporting_structure_kpis, 'slug'), [reporting_structure_kpis, values.selected_kpis])

  return useMemo(() => [
    {
      title: intl.formatMessage({ id: "create_or_edit_kpi_search" }),
      content: !isCreatingNewKpi ? (
        <KpiCategoryIndicatorSearch
          selection={values.selected_kpis?.map(c => ({ value: c.uuid }))}
          listItems={flatTree}
          onCreate={handleNewKpi}
          onSelectItem={({ slug, uuid, name, isNew = false, is_custom = false, code, standard }, checked) => {
            const selectedKpis = checked ? [...(values.selected_kpis || []), { slug, uuid, name, isNew, is_custom, code, standard }] : values.selected_kpis?.filter(c => c.uuid !== uuid)
            handleChange('selected_kpis')(selectedKpis)
          }}
          customStandardName={organizationName}
          type="indicator"
        />) : (
        <CreateEditCustomKPI
          intl={intl}
          values={kpiValues}
          handleChange={kpiHandleChange}
          errors={kpiErrors}
          organization={organization}
          suborganization={suborganization}
          isCreate
          schemaDirty={false}
          setSchemaDirty={() => { }} // Since this is KPI creation, we don't need to know if form is dirty
          showFrequency={false}
        />
      ),
      isValid: () => (isCreatingNewKpi || values.selected_kpis?.length > 0),
    },
    {
      title: "Select start dates",
      content: (
        <ConfigureTimeSelector
          handleChange={handleChange}
          organization={organization}
          suborganization={suborganization}
          values={values}
          reporting_structure_kpis={reporting_structure_kpis}
          kpisAlreadyAdded={kpisAlreadyAdded}
          kpisNotAlreadyAdded={kpisNotAlreadyAdded}
        />
      ),
      isValid: () => kpisNotAlreadyAdded.every(kpi => kpi.periodicities?.length && kpi.cycle_date && kpi.year)
    },
    {
      title: intl.formatMessage({ id: "create_or_edit_kpi_parent" }),
      content: <CategoryTreeParentSelector
        reportingStructure={reportingStructure || []}
        onSelectParent={handleChange('parents')}
        selectedParents={values?.parents}
      />,
      isValid: () => values?.parents?.length > 0
    }
  ], [intl, isCreatingNewKpi, values, flatTree, handleNewKpi, organizationName, kpiValues, kpiHandleChange, kpiErrors, organization, suborganization, handleChange, reporting_structure_kpis, kpisAlreadyAdded, kpisNotAlreadyAdded, reportingStructure])
}
