import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import type { EmptyProps } from "./Empty";

/**
 * Animation component imports
 */
import { ReactComponent as SearchNoResultAnimation } from "assets/animations/searchNoResult.svg";
import { ReactComponent as FilterNoResultAnimation } from "assets/animations/filterNoResult.svg";
import { ReactComponent as NoHistoryAnimation } from "assets/animations/noHistory.svg";
import { ReactComponent as noAttachmentsAnimation } from "assets/animations/noAttachments.svg";
import { ReactComponent as noDownloadsAnimation } from "assets/animations/noDownloads.svg";
import { ReactComponent as noRequestAnimation } from "assets/animations/noRequest.svg";
import { ReactComponent as noAccessAnimation } from "assets/animations/noAccess.svg";
import { ReactComponent as landingMobileAnimation } from "assets/animations/landingMobile.svg";
import { ReactComponent as noTreeCreatedAnimation } from "assets/animations/noTreeCreated.svg";
import { ReactComponent as nothingSelectedAnimation } from "assets/animations/nothingSelected.svg";
import { ReactComponent as noDataAddedAnimation } from "assets/animations/noDataAdded.svg";
import { ReactComponent as noDashboardAnimation } from "assets/animations/noDashboard.svg";
import { ReactComponent as noGraphInDashboardAnimation } from "assets/animations/noGraphInDashboard.svg";
import { ReactComponent as noDataOnPeriodsAnimation } from "assets/animations/noDataOnPeriods.svg";

/**
 * Empty States
 */
const noFilterResults = {
  svgAnimation: FilterNoResultAnimation,
  message: <FormattedMessage id="no_filter_results_message" />,
  explanation: <FormattedMessage id="no_filter_results_explanation" />,
};

const noResultsProps = {
  svgAnimation: SearchNoResultAnimation,
  message: <FormattedMessage id="no_search_results_message" />,
  explanation: <FormattedMessage id="no_search_results_explanation" />,
};

const noHistoryProps = {
  svgAnimation: NoHistoryAnimation,
  message: <FormattedMessage id="no_history_message" />,
  explanation: (
    <FormattedMessage
      id="no_history_explanation"
      values={{
        link: (
          <Link className="link" to="/data/manage">
            <FormattedMessage id="no_history_explanation_link" />
          </Link>
        ),
      }}
    />
  ),
};

const noAttachmentsProps = {
  svgAnimation: noAttachmentsAnimation,
  message: <FormattedMessage id="no_attachments_message" />,
  explanation: (
    <FormattedMessage
      id="no_attachments_explanation"
      values={{
        link: (
          <Link className="link" to="/data/fill">
            <FormattedMessage id="no_attachments_explanation_link" />
          </Link>
        ),
      }}
    />
  ),
};

const noAttachmentsKPIProps = {
  svgAnimation: noAttachmentsAnimation,
  message: <FormattedMessage id="no_attachments_kpi_message" />,
  explanation: (
    <FormattedMessage
      id="no_attachments_kpi_explanation"
      values={{
        link: (
          <Link className="link" to="data">
            <FormattedMessage id="no_attachments_kpi_explanation_link" />
          </Link>
        ),
      }}
    />
  ),
};

const noHistoryKPIProps = {
  svgAnimation: NoHistoryAnimation,
  message: <FormattedMessage id="no_history_message" />,
};

const noDownloadsProps = {
  svgAnimation: noDownloadsAnimation,
  message: <FormattedMessage id="no_downloads_message" />,
  explanation: (
    <FormattedMessage
      id="no_downloads_explanation"
      values={{
        link: (
          <Link className="link" to="/data/files">
            <FormattedMessage id="no_downloads_explanation_link" />
          </Link>
        ),
      }}
    />
  ),
};

const noRequestProps = {
  svgAnimation: noRequestAnimation,
  message: <FormattedMessage id="no_request_message" />,
  explanation: (
    <FormattedMessage
      id="no_request_explanation"
      values={{
        link: (
          <Link className="link" to="/data/fill">
            <FormattedMessage id="no_request_explanation_link" />
          </Link>
        ),
      }}
    />
  ),
};

const noRequestThirdProps = {
  svgAnimation: noRequestAnimation,
  message: <FormattedMessage id="no_request_third_message" />,
};

const noAccessProps = {
  svgAnimation: noAccessAnimation,
  message: <FormattedMessage id="no_access_message" />,
};

const landingMobileProps = {
  svgAnimation: landingMobileAnimation,
  message: <FormattedMessage id="landing_mobile_message" />,
  explanation: <FormattedMessage id="landing_mobile_explanation" />,
};

const noTreeCreatedProps = {
  svgAnimation: noTreeCreatedAnimation,
  message: <FormattedMessage id="no_tree_created_message" />,
  explanation: <FormattedMessage id="no_tree_created_explanation" />,
};

const nothingSelectedProps = {
  svgAnimation: nothingSelectedAnimation,
  message: <FormattedMessage id="nothing_selected_message" />,
};

const noDataAddedProps = {
  svgAnimation: noDataAddedAnimation,
  message: <FormattedMessage id="no_data_added_message" />,
};

const noDashboardProps = {
  svgAnimation: noDashboardAnimation,
  message: <FormattedMessage id="no_dashboard_message" />,
};

const noGraphInDashboardProps = {
  svgAnimation: noGraphInDashboardAnimation,
  message: <FormattedMessage id="no_graph_in_dashboard_message" />,
  explanation: <FormattedMessage id="no_graph_in_dashboard_explanation" />,
};

const noDataOnPeriodsProps = {
  svgAnimation: noDataOnPeriodsAnimation,
  message: <FormattedMessage id="no_data_on_periods_message" />,
  explanation: <FormattedMessage id="no_data_on_periods_explanation" />,
};

export const emptyPropsMap = new Map<string, EmptyProps>([
  ["noFilterResults", noFilterResults],
  ["noSearchResults", noResultsProps],
  ["noHistory", noHistoryProps],
  ["noAttachments", noAttachmentsProps],
  ["noAttachmentsKPI", noAttachmentsKPIProps],
  ["noHistoryKPI", noHistoryKPIProps],
  ["noDownloads", noDownloadsProps],
  ["noRequest", noRequestProps],
  ["noRequestThird", noRequestThirdProps],
  ["noAccess", noAccessProps],
  ["landingMobile", landingMobileProps],
  ["noTreeCreated", noTreeCreatedProps],
  ["nothingSelected", nothingSelectedProps],
  ["noDataAdded", noDataAddedProps],
  ["noDashboard", noDashboardProps],
  ["noGraphInDashboard", noGraphInDashboardProps],
  ["noDataOnPeriods", noDataOnPeriodsProps],
]);

export const getEmptyResultsProps = (
  hasSearch: Boolean,
  hasFilter: Boolean
): EmptyProps => {
  if (!hasSearch && hasFilter) {
    return noFilterResults;
  }
  return noResultsProps;
};
