import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { range } from 'lodash'

import UploadButton from 'containers/UploadButton';
import LoaderModal from 'components/LoaderModal';
import AplanetIcon from 'components/AplanetIcon';

import {
  getBulkUploadUrl,
  requestBulkUploadList,
  generateKpiExcel,
  generateSingleNodeExcel,
} from 'actions/api';

import {
  bulkUploadSuccess,
} from 'actions/bulk';

import {
  resetKpiExcel,
} from 'actions/resetData';

import {
  DB_DATE_FORMAT,
  formatDate,
  formatLong,
  SHORT_FORMAT_WITH_YEAR,
} from 'utils/date';

import useOrganizations from 'utils/useOrganizations';
import useAuthorizedRequest from 'utils/useAuthorizedRequest';

import Avatar from 'components/Avatar';

import {
  Row,
  Col,
  Table,
  Card,
  Modal,
  Select,
  Form,
  Button,
} from 'antd';

import {
  UserOutlined,
  FileExcelFilled,
} from '@ant-design/icons';
import { useEventTracking } from 'hooks/useEventTracking';


// TODO: Years should be based on currently applicable periods' range, not hard-coded
const PERIOD_YEARS = (
  range(2017, (new Date()).getFullYear() + 2)
).reverse();

const XLSX_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const Upload = ({
  intl,
}) => {
  const [kpiExcelDownloading, setKpiExcelDownloading] = useState(false);
  const [showFullNodeDownload, setShowFullNodeDownload] = useState(false);
  const [excelDownloadSelectedYear, setExcelDownloadSelectedYear] = useState(PERIOD_YEARS[0]);
  const [intervalId, setIntervalId] = useState();

  const t = intl.messages;
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();

  const kpiExcelDownloadDateRange = useMemo(() => ({
    start_date: `${excelDownloadSelectedYear}-01-01`,
    end_date: `${excelDownloadSelectedYear}-12-31`,
  }), [
    excelDownloadSelectedYear,
  ]);

  const isRootOrg = useMemo(() =>
    suborganization.slug === organization.slug,
    [
      organization,
      suborganization,
    ]
  );

  const {
    can_import_kpis,
    can_export_kpis,
  } = permissions;

  const {
    loading: loadingBulk,
    //error: errorBulk,
    data: bulk,
  } = useSelector(state => state.data_bulk_upload);

  const {
    data: profile,
  } = useSelector(state => state.profile);

  const onSuccess = useCallback((filename) => {
    if (!profile || !suborganization) {
      return;
    }

    dispatch(
      bulkUploadSuccess(
        suborganization.slug,
        filename,
        profile.name,
        profile.email,
        profile.avatar,
      )
    );

    eventTracking.capture('dataFiles.uploadNodeTemplate');

  }, [
    dispatch,
    suborganization,
    profile,
    eventTracking,
  ]);

  useEffect(() => {
    dispatch(
      requestBulkUploadList(
        organization.slug,
        suborganization.slug,
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  const refreshList = useCallback(() => {
    dispatch(
      requestBulkUploadList(
        organization.slug,
        suborganization.slug,
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  const columns = useMemo(() => ([
    {
      title: t.name,
      dataIndex: 'member_name',
      key: 'name',
      render: (name, record) => (
        <React.Fragment>
          <Row type="flex" gutter={10} justify="left" align="middle" style={{ flexWrap: 'nowrap' }}>
            <Col>
              <Avatar
                src={record.member_avatar}
                name={record.member_name}
                shape="circle"
                size={38}
                icon={<UserOutlined />}
              />
            </Col>

            <Col>
              <Row type="flex" gutter={2}>
                <Col className="DataManagement__nobreak">
                  {name}
                </Col>
              </Row>
              <div className="DataManagement__nobreak">
                {record.member_email}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t.file,
      dataIndex: 'params',
      key: 'file',
      render: ({ filename }) => (
        <span className="DataManagement__nobreak">{filename}</span>
      ),
    },
    {
      title: (
        <Row type="flex" align="middle" gutter={20}>
          <Col>
            {intl.formatMessage({ id: 'date' })}
          </Col>

          <Col>
            <Button type="link" onClick={refreshList}>
              <Row type="flex" align="middle" gutter={5} className="DataManagement__importtable_refresh">
                <AplanetIcon name="sync" />
                <Col>
                  {intl.formatMessage({ id: 'datamanagement_datafiles_refresh_status' })}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      ),
      dataIndex: 'updated_at',
      key: 'date',
      render: (updated_at, record) => {
        if (record?.status === 'pending') {
          return (
            <Row type="flex" align="middle" gutter={5}>
              <AplanetIcon name="spinner" className="fa-spin fa-pulse" />
              <Col>
                {intl.formatMessage({ id: 'datamanagement_datafiles_processing' })}
              </Col>
            </Row>
          )
        } else if (record?.status === 'failed') {
          return (
            <span className="DataManagement__importtable__upload_failed">
              {intl.formatMessage({ id: 'datamanagement_datafiles_failed' })}
            </span>
          );
        }
        return <span className="DataManagement__nobreak">{formatLong(updated_at, intl)}</span>
      },
    },
  ]), [
    refreshList,
    intl,
    t,
  ]);

  const {
    loading: loadingKpiExcel,
    processing: processingKpiExcel,
    ready: readyKpiExcel,
    //error: errorKpiExcel,
    data: kpiExcel,
  } = useSelector(state => state.kpi_excel_download);

  const handleFullNodeDownload = useCallback(() => {
    dispatch(
      generateKpiExcel({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        start_date: kpiExcelDownloadDateRange.start_date,
        end_date: kpiExcelDownloadDateRange.end_date,
      })
    );
    setShowFullNodeDownload(false);
    eventTracking.capture('dataFiles.downloadGlobalFile', {
      year: excelDownloadSelectedYear
    });
  }, [
    dispatch,
    organization,
    suborganization,
    kpiExcelDownloadDateRange,
    eventTracking,
    excelDownloadSelectedYear
  ]);

  const getTemplateName = useCallback((fullNode = false) => {
    const date = formatDate(
      new Date(), fullNode ? SHORT_FORMAT_WITH_YEAR : DB_DATE_FORMAT,
    );
    const extension = kpiExcel?.url?.split('.')?.pop();

    if (fullNode) {
      return `${organization.name} ${intl.formatMessage({ id: 'datamanagement_full_data_download_filename' })} ${date}.${extension}`;
    }
    return `${suborganization.name} ${intl.formatMessage({ id: 'datamanagement_upload_node_data_download_name' })} ${date}.${extension}`;
  }, [
    intl,
    kpiExcel,
    organization,
    suborganization,
  ]);


  const handleDownload = useCallback((content) => {
    let anchor = linkRef.current;

    if(!anchor) {
      console.log('ERROR: cannot handle download without an anchor');
      return;
    }

    const windowUrl = window.URL || window.webkitURL;
    const blob = content instanceof Blob
      ? content
      : new Blob(
        [content],
        { type: XLSX_MIME_TYPE }
      );

    const url = windowUrl.createObjectURL(blob);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', getTemplateName(!!kpiExcel.fullNode));
    anchor.click();
    windowUrl.revokeObjectURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kpiExcel,
  ]);

  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);

  const {
    linkRef,
    onClick: downloadFile,
  } = useAuthorizedRequest({
    url: kpiExcel?.url,
    onSuccess: handleDownload,
    onError: handleError,
  });

  const handleSingleNodeDownload = useCallback(() => {
    dispatch(
      generateSingleNodeExcel({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
      })
    );
    eventTracking.capture('dataFiles.downloadNodeTemplate');
  }, [
    dispatch,
    organization,
    suborganization,
    eventTracking,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { dispatch(resetKpiExcel()); setKpiExcelDownloading(false); }, []); // NOTICE: only once

  useEffect(() => {
    if (readyKpiExcel && !kpiExcelDownloading) {
      downloadFile();

      setKpiExcelDownloading(true);
      dispatch(resetKpiExcel());
      setKpiExcelDownloading(false);
    }
  }, [
    dispatch,
    readyKpiExcel,
    downloadFile,
    kpiExcelDownloading,
  ]);

  const showSystemAdminActivity = organization?.general_config?.show_system_admin_activity

  const filteredData = useMemo(() => bulk?.filter(entry => showSystemAdminActivity || entry.member_role !== 'system'), [bulk, showSystemAdminActivity]);

  useEffect(
    () => {
      const hasPendingUpload = !!filteredData?.find(({status}) => status === 'pending');
      if (hasPendingUpload && !intervalId) {
        const id = setInterval(refreshList, 30 * 1000);
        setIntervalId(id);
      } else if (!hasPendingUpload && intervalId) {
        clearInterval(intervalId);
        setIntervalId();
      }
    },
    [
      intervalId,
      filteredData,
      refreshList,
    ]
  );

  useEffect(() => () => {
    if (intervalId) {
      // Clear interval on unmount
      clearInterval(intervalId);
    }
  }, [intervalId]);

  return (
    <div className="DataManagement__upload">
      <Row
        type="flex"
        align="top"
        gutter={[20, 20]}
        className="DataManagement__uploadButtonsWrapper"
      >
        <Col
          span={24}
          className={isRootOrg ? '' : 'DataManagement__download_all_nodes_child'}
        >
          <Card>
            <h3>{intl.formatMessage({ id: 'datamanagement_download_files' })}</h3>
            <Row gutter={[10, 30]}>
              <Col>
                <p>{intl.formatMessage({ id: 'datamanagement_download_all_nodes_file' })}</p>
                <Button
                  className="DataManagement__download-template-button"
                  type="primary"
                  onClick={() => setShowFullNodeDownload(true)}
                >
                  <FileExcelFilled />
                  {intl.formatMessage({ id: 'download_template_full_node' })}
                </Button>
              </Col>

              <Modal
                className="DataManagement__download-template-modal"
                visible={showFullNodeDownload}
                closable={false}
                footer={null}
                title={
                  <>
                    <AplanetIcon name="file-excel" size="15px" /> {intl.formatMessage({ id: 'datamanagement_datafiles_download_file' })}
                  </>
                }
              >
                <p>{intl.formatMessage({ id: 'datamanagement_datafiles_download_full_data_modal_description' })}</p>
                <Form layout="vertical">
                  <Form.Item
                    colon={false}
                    required={true}
                    label={intl.formatMessage({ id: 'datamanagement_datafiles_download_full_data_select_year' })}
                  >
                    <Select
                      optionLabelProp="label"
                      style={{ width: '100%' }}
                      value={excelDownloadSelectedYear}
                      onChange={value => setExcelDownloadSelectedYear(value)}
                    >
                      {PERIOD_YEARS.map(year => (
                        <Select.Option
                          key={year}
                          value={year}
                          label={
                            <>
                              <AplanetIcon name="calendar-day" size="15px" /> {year}
                            </>
                          }
                        >
                          {year}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>

                <Row align="end" gutter={10} className="DataManagement__download-template-modal-footer">
                  <Col>
                    <Button
                      onClick={() => setShowFullNodeDownload(false)}
                    >
                      {intl.formatMessage({ id: 'datamanagement_datafiles_download_full_data_modal_cancel' })}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleFullNodeDownload}
                    >
                      {intl.formatMessage({ id: 'datamanagement_datafiles_download_full_data_modal_download' })}
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card>
            <h3>{intl.formatMessage({ id: 'datamanagement_upload_files' })}</h3>
            <Row gutter={[20, 20]}>
              {can_export_kpis &&
              <Col span={12}>
                <p>{intl.formatMessage({ id: 'datamanagement_download_files_description' })}</p>
                <Button
                  type="primary"
                  className="DataManagement__download-template-button"
                  onClick={handleSingleNodeDownload}
                >
                  <FileExcelFilled />
                  {t.download_template}
                </Button>
              </Col>
              }
              {can_import_kpis &&
              <Col span={12}>
                <p>{intl.formatMessage({ id: 'datamanagement_upload_files_description' })}</p>
                <p className="DataManagement__upload_current_node">{intl.formatMessage({ id: 'datamanagement_current_node' })} {suborganization.name}</p>

                  <UploadButton
                    actionUrl={
                      getBulkUploadUrl(
                        organization.slug,
                        suborganization.slug,
                      )
                    }
                    onSuccess={onSuccess}
                    accept=".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  >
                    {t.datamanagement_upload_xls}
                  </UploadButton>
              </Col>
              }

              <Col span={24}>
                <h3>{intl.formatMessage({ id: 'datamanagement_upload_files_uploaded_files' })}</h3>
                <Row
                  type="flex"
                  justify="center"
                >
                  <Table
                    loading={loadingBulk}
                    className="DataManagement__importtable"
                    columns={columns}
                    dataSource={filteredData}
                    rowClassName={({status}, index) => `${index % 2 ? 'Table-row-odd' : 'Table-row-even'}${status === 'failed' ? ' DataManagement__importtable__row_failed' : ''}`}
                    pagination={false}
                  />
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <LoaderModal
        visible={loadingKpiExcel || processingKpiExcel}
        title={intl.formatMessage({ id: 'datamanagement_upload_download_template_for_whole_tree_loading_title' })}
        message={intl.formatMessage({ id: 'datamanagement_upload_download_template_for_whole_tree_loading' })}
      />
      { /* eslint-disable-next-line */ }
      <a ref={linkRef}></a>
    </div>
  );
}

export default injectIntl(
  withRouter(
    Upload
  )
);
