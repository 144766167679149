import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import LocaleFlag from 'components/LocaleFlag';

import { Loading } from 'tsComponents/emptyStates/Loading';

import useOrganizations from 'utils/useOrganizations';
import {
  syncKpiVariables,
  getKpiVariableStatus,
  syncKpiVariablesToChildOrganizations,
} from 'actions/api';


import CustomButton from 'components/CustomButton';
import {
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Form,
  Alert,
  Tag,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import './style.less';


const Variable = ({
  intl,
  allVariables,
  data: defaultData,
  canDelete,
  canManage,
  editingVariables,
  setEditingVariables,
  status,
  fetchingStatus,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [data, setData] = useState(defaultData);
  const [duplicateInLocales, setDuplicateInLocales] = useState([]);
  const [missingRequiredTranslation, setMissingRequiredTranslation] = useState(false);

  useEffect(
    () => setData(defaultData),
    [defaultData]
  );

  useEffect(
    () => {
      if (data.modified && !editingVariables.includes(data.slug)) {
        setEditingVariables([...editingVariables, data.slug]);
      } else if (!data.modified && editingVariables.includes(data.slug)) {
        setEditingVariables(editingVariables.filter(slug => slug !== data.slug));
      }
    },
    [
      data,
      editingVariables,
      setEditingVariables,
    ]
  );

  const {
    organization,
    suborganization,
  } = useOrganizations();

  useEffect(
    () => {
      if (canDelete) { // This means it's owned variable
        dispatch(
          getKpiVariableStatus(
            organization.slug,
            suborganization.slug,
            defaultData.slug,
          )
        );
      }
    },
    [
      dispatch,
      defaultData,
      organization,
      suborganization,
      canDelete,
    ]
  );

  const getVariableNameInLocale = useCallback(
    (variable, locale) => {
      const variableInCurrentLocale = variable?.[locale];

      if (variableInCurrentLocale?.custom) {
        return variableInCurrentLocale.custom.value;
      }
      return variableInCurrentLocale?.default?.value;
    },
    []
  );

  const getOptionNameInLocale = useCallback(
    (option, locale) => {
      const optionInCurrentLocale = option?.[locale];

      if (optionInCurrentLocale?.custom) {
        return optionInCurrentLocale.custom.value;
      }
      return optionInCurrentLocale?.default?.value;
    },
    []
  );

  const preferredLanguages = useMemo(
    () => {
      const languages = organization?.languages || [organization.language];
      return languages.sort((_, b) => b === suborganization.language ? 1 : -1)
    }, [
      organization,
      suborganization
    ]
  );

  const handleOnChange = useCallback(
    (optionSlug, locale, value) => {
      let variableData = {
        ...data,
        modified: true,
        modifiedVariableName: !optionSlug || data.modifiedVariableName,
      };

      if (optionSlug) {
        variableData.options = {
          ...(variableData.options || {}),
          [optionSlug]: {
            ...(variableData.options?.[optionSlug] || {}),
            [locale]: {
              ...(variableData.options?.[optionSlug]?.[locale] || {}),
              custom: {
                ...(variableData.options?.[optionSlug]?.[locale]?.custom || {}),
                default_variable_id: variableData.options?.[optionSlug]?.[locale]?.default?.id || null,
                locale,
                slug: variableData.slug,
                option_slug: optionSlug,
                organization_id: suborganization.id,
                value,
              },
            },
          }
        }
      } else {
        if (duplicateInLocales.includes(locale)) {
          setDuplicateInLocales(
            duplicateInLocales.filter(_locale => locale !== _locale)
          );
        }
        variableData.variable = {
          ...(variableData.variable || {}),
          [locale]: {
            ...(variableData.variable?.[locale] || {}),
            custom: {
              ...(variableData.variable?.[locale]?.custom || {}),
              default_variable_id: variableData.variable?.[locale]?.default?.id || null,
              locale,
              slug: variableData.slug,
              option_slug: "",
              organization_id: suborganization.id,
              value,
            },
          },
        };
      }
      setData(variableData);
    },
    [
      data,
      suborganization,
      duplicateInLocales,
    ],
  );

  const handleAddNewOption = useCallback(
    () => {
      setTimeout(() => {
        // Scroll to the bottom of the content
        const contentElement = componentRef.current.closest('.ant-tabs-content-holder');
        contentElement.scrollTop = contentElement.scrollHeight;
      }, 0);

      setData({
        ...data,
        options: {
          ...(data.options || {}),
          [`__option-${uniqueId()}`]: {},
        }
      })
    },
    [data],
  );

  const validateAndProcessData = useCallback(
    (variablesToSync, optionsToSync) => {
      let duplicates = [];
      let missingRequiredTranslation = false;
      let processedVariables = [];
      let processedOptions = [];

      if (variablesToSync.length) {
        const variableInOrgLocale = variablesToSync.find(
          ({locale}) => locale === suborganization.language
        );
        const isPreOwnedVariable = variablesToSync.find(
          ({default_variable_id}) => !!default_variable_id
        );

        if (
          (
            isPreOwnedVariable && variableInOrgLocale && !variableInOrgLocale.value
          ) || (
            !isPreOwnedVariable && (!variableInOrgLocale || !variableInOrgLocale.value)
          )
        ) {
          missingRequiredTranslation = true;
        } else {
          processedVariables = [...variablesToSync];

          if (variableInOrgLocale && !variableInOrgLocale.default_variable_id) {
            const existingLocales = variablesToSync.map(({locale}) => locale);

            preferredLanguages.filter(
              language => !existingLocales.includes(language)
            ).forEach(
              language => {
                processedVariables.push({
                  locale: language,
                  option_slug: variableInOrgLocale.option_slug,
                  organization_id: variableInOrgLocale.organization_id,
                  slug: variableInOrgLocale.slug,
                  value: variableInOrgLocale.value,
                });
              }
            )
          }
        }

        variablesToSync.forEach(({locale, value, slug}) => {
          if (
            allVariables.find(
              ({variable, slug: _slug}) => (
                (value || '').trim() === (
                  variable[locale]?.custom?.value
                  || variable[locale]?.default?.value
                  || ''
                ).trim()
              ) && (_slug !== slug)
            )
          ) {
            duplicates.push(locale);
          }
        });
      }

      optionsToSync.forEach(option => {
        if (missingRequiredTranslation) {
          return;
        }

        if (option.status === 'deleted') {
          processedOptions.push(option);
          return;
        }

        const variableInOrgLocale = option.variables.find(
          ({locale}) => locale === suborganization.language
        );
        const isPreOwnedVariable = variablesToSync.find(
          ({default_variable_id}) => !!default_variable_id
        );

        if (
          (
            isPreOwnedVariable && variableInOrgLocale && !variableInOrgLocale.value
          ) || (
            !isPreOwnedVariable && (!variableInOrgLocale || !variableInOrgLocale.value)
          )
        ) {
          missingRequiredTranslation = true;
        } else {
          let processedOption = {...option};

          if (
            variableInOrgLocale
            && !variableInOrgLocale.default_variable_id
          ) {
            const existingLocales = option.variables.map(({locale}) => locale);
            preferredLanguages.filter(
              language => !existingLocales.includes(language)
            ).forEach(
              language => {
                processedOption.variables = [
                  ...processedOption.variables,
                  {
                    locale: language,
                    option_slug: variableInOrgLocale.option_slug,
                    organization_id: variableInOrgLocale.organization_id,
                    slug: variableInOrgLocale.slug,
                    value: variableInOrgLocale.value,
                  }
                ];
              }
            );
          }
          processedOptions.push(processedOption);
        }
      });

      if (duplicates.length) {
        setDuplicateInLocales(duplicates);
      }
      setMissingRequiredTranslation(missingRequiredTranslation);
      return {
        result: !duplicates.length && !missingRequiredTranslation,
        processedVariables,
        processedOptions,
      };
    },
    [
      allVariables,
      suborganization,
      preferredLanguages,
    ]
  );

  const handleSave = useCallback(
    (status = null) => {
      let variablesToSync = [];
      let optionsToSync = [];

      for (const localeVariable of Object.values(data.variable)) {
        if (localeVariable.custom) {
          if (status && !localeVariable.custom.id) {
            continue;
          }

          variablesToSync.push({
            ...localeVariable.custom,
            ...(status ? { status } : {}),
          });
        }
      }

      Object.values(data.options).forEach(option => {
        let optionData = {};

        const { status: optionStatus, ...optionInLocales } = option;

        for (const localeOption of Object.values(optionInLocales)) {
          if (localeOption.default && optionStatus === 'deleted') {
            optionData = {
              ...optionData,
              variables: [
                ...(optionData.variables || []),
                {
                  ...localeOption.default,
                },
              ],
              slug: localeOption.default.option_slug,
              status: optionStatus,
            };
          }
          if (localeOption.custom) {
            if (
              (
                (optionStatus === 'deleted') || status
              )
              && !localeOption.custom.id
            ) {
              continue;
            }

            let slug = optionData.slug;

            if (localeOption.default) {
              slug = localeOption.default.option_slug;
            } else if (localeOption.custom.id) {
              slug = localeOption.custom.option_slug;
            }

            optionData = {
              ...optionData,
              variables: [
                ...(optionData.variables || []),
                {
                  ...localeOption.custom,
                  option_slug: slug,
                },
              ],
              slug,
              status: optionStatus === 'deleted'
                ? 'deleted'
                : status,
            };
          }
        }

        if (optionData.variables) {
          optionsToSync.push(optionData);
        }
      });

      if (variablesToSync.length === 0 && optionsToSync.length === 0) {
        setData({
          ...data,
          modified: false,
          modifiedVariableName: false,
        })
      } else if (status) {
        setDuplicateInLocales([]);
        setMissingRequiredTranslation(false);
        dispatch(
          syncKpiVariables(
            organization.slug,
            suborganization.slug,
            variablesToSync,
            optionsToSync,
            data.modifiedVariableName,
          )
        );
      } else {
        const validationData = validateAndProcessData(
          variablesToSync, optionsToSync
        );

        if (validationData.result) {
          dispatch(
            syncKpiVariables(
              organization.slug,
              suborganization.slug,
              validationData.processedVariables,
              validationData.processedOptions,
              data.modifiedVariableName,
            )
          );
        }
      }
    },
    [
      dispatch,
      data,
      organization,
      suborganization,
      validateAndProcessData,
    ],
  );

  const handleOnDeleteOption = useCallback(
    (option_slug) => {
      setData({
        ...data,
        modified: true,
        options: {
          ...data.options,
          [option_slug]: {
            ...data.options[option_slug],
            status: 'deleted',
          },
        },
      });
    },
    [data]
  );

  const canResetVariable = useMemo(
    () => {
      let canReset = !!Object.values(data.variable)
        .find(localeVariable => !!localeVariable.custom);

      if (!canReset) {
        Object.values(data.options).forEach(option => {
          if (!['deleted', 'deprecated'].includes(option.status) && !canReset) {
            for (const localeOption of Object.values(option)) {
              if (localeOption.custom) {
                canReset = true;
                break;
              }
            }
          }
        });
      }
      return canReset;
    },
    [data]
  );

  const filteredOptions = useMemo(
    () => {
      const locale = suborganization.language;
      return Object.entries(data.options || {})
        .filter(([_, option]) => {
          let disabled = false;
          const localeOptions = {...option};

          for (const localeOption of Object.values(localeOptions)) {
            if (localeOption.custom?.status === 'disabled') {
              disabled = true;
              break;
            }
          }
          return !['deleted', 'deprecated'].includes(option.status) && !disabled;
        })
        .sort(([aSlug, aOption], [bSlug, bOption]) => {
          const [a, b] = [
            aOption[locale]?.custom?.value || aOption[locale]?.default?.value,
            bOption[locale]?.custom?.value || bOption[locale]?.default?.value
          ];
          if(a < b) { return -1; }
          if(a > b) { return 1; }
          return 0;
        });
    }, [
      data,
      suborganization,
    ]
  );

  const handleOnSyncVariableToChildOrganizations = useCallback(
    () => {
      dispatch(
        syncKpiVariablesToChildOrganizations(
          organization.slug,
          suborganization.slug,
          defaultData.slug,
        )
      )
    },
    [
      dispatch,
      organization,
      suborganization,
      defaultData,
    ]
  );

  if (fetchingStatus) {
    return <Loading />;
  }

  return (
    <Row
      className="VariablesConfiguration__variable"
      gutter={[10, 10]}
      ref={componentRef}
    >
      <Row type="flex" gutter={5} className="VariablesConfiguration__variable_actions">
        { canManage && !data.deprecated && !!suborganization.children.length &&
          <Col>
            <Popconfirm
              title={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm'})}
              placement="topLeft"
              onConfirm={handleOnSyncVariableToChildOrganizations}
              okText={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm_no'})}
            >
              <CustomButton
                icon={<SyncOutlined />}
                disabled={data.modified}
              >
                {intl.formatMessage({id: 'variables_configuration_sync_to_all_children'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canManage && !canDelete && canResetVariable &&
          <Col>
            <Popconfirm
              title={intl.formatMessage({id: 'variables_configuration_reset_confirm'})}
              placement="topLeft"
              onConfirm={() => handleSave('deleted')}
              okText={intl.formatMessage({id: 'variables_configuration_reset_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_reset_confirm_no'})}
            >
              <CustomButton danger={true}>
                {intl.formatMessage({id: 'variables_configuration_reset'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canDelete && !status?.is_used &&
          <Col>
            <Tooltip
              title={
                !suborganization.parent_id
                ? null
                : intl.formatMessage({id: 'variables_configuration_delete_variable_from_suborg_tooltip'})
              }
              mouseEnterDelay={1}
            >
              <span>
                <Popconfirm
                  title={intl.formatMessage({id: 'variables_configuration_delete_confirm'})}
                  placement="topLeft"
                  onConfirm={() => handleSave('deleted')}
                  okText={intl.formatMessage({id: 'variables_configuration_delete_confirm_yes'})}
                  cancelText={intl.formatMessage({id: 'variables_configuration_delete_confirm_no'})}
                >
                  <CustomButton danger={true} disabled={!!suborganization.parent_id}>
                    {intl.formatMessage({id: 'variables_configuration_delete'})}
                  </CustomButton>
                </Popconfirm>
              </span>
            </Tooltip>
          </Col>
        }
        { canDelete && status?.is_used && !data.deprecated &&
          <Col>
            <Popconfirm
              title={
                intl.formatMessage(
                  { id: 'variables_configuration_deprecate_confirm' },
                  { numberOfKpis: status.number_of_kpis }
                )
              }
              placement="topLeft"
              onConfirm={() => handleSave('deprecated')}
              okText={intl.formatMessage({id: 'variables_configuration_deprecate_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_deprecate_confirm_no'})}
            >
              <CustomButton danger={true}>
                {intl.formatMessage({id: 'variables_configuration_deprecate'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canManage && !data.deprecated &&
          <Col>
            <CustomButton
              type="primary"
              onClick={() => handleSave()}
              disabled={!data.modified}
            >
              {intl.formatMessage({id: 'variables_configuration_save'})}
            </CustomButton>
          </Col>
        }
      </Row>
      <Col span={24}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Row gutter={10}>
              <Col>
                <h4>{intl.formatMessage({id: 'variables_configuration_name'})}</h4>
              </Col>
              { data.deprecated &&
                <Col>
                  <Tag color="red">
                    {intl.formatMessage({id: 'variables_configuration_deprecated'})}
                  </Tag>
                </Col>
              }
            </Row>
          </Col>
          
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[5, 5]}>
          { !!duplicateInLocales.length &&
            <Col span={24}>
              <Alert
                type="error"
                message={intl.formatMessage({id: 'variables_configuration_duplicate_existing_variable'})}
              />
            </Col>
          }
          { missingRequiredTranslation &&
            <Col span={24}>
              <Alert
                type="error"
                message={intl.formatMessage({id: 'variables_configuration_missing_required_translation'})}
              />
            </Col>
          }
          { preferredLanguages.map(locale =>
            <Col span={8} key={locale}>
              <Tooltip
                title={
                  !suborganization.parent_id
                  ? null
                  : intl.formatMessage({id: 'variables_configuration_edit_variable_from_suborg_tooltip'})
                }
                mouseEnterDelay={1}
              >
                <Form.Item
                  validateStatus={duplicateInLocales.includes(locale) ? 'error' : null}
                  style={{marginBottom: 0}}
                  required={locale === suborganization.language}
                >
                  <Input
                    addonBefore={
                      <LocaleFlag intl={intl} locale={locale} />
                    }
                    value={getVariableNameInLocale(data.variable, locale)}
                    onChange={e => handleOnChange(null, locale, e.target.value)}
                    suffix={locale === suborganization.language ? <span className="VariablesConfiguration__variable__required">*</span> : null}
                    disabled={data.deprecated || suborganization.parent_id || !canManage}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row type="flex" gutter={10} align="middle">
          <Col>
            <h4 className="VariablesConfiguration__variable__options_header">
              {intl.formatMessage({id: 'variables_configuration_values'})}
            </h4>
          </Col>
          { canManage && !data.deprecated &&
            <Col>
              <CustomButton
                className="VariablesConfiguration__variable__add_option_button"
                icon={<PlusOutlined />}
                type="primary"
                onClick={handleAddNewOption}
              />
            </Col>
          }
        </Row>
      </Col>
      { filteredOptions.map(([key, option], index) =>
        <React.Fragment key={key}>
          { index > 0 &&
            <Col span={24}>
              <Divider />
            </Col>
          }
          <Col span={24} className="VariablesConfiguration__variable_option">
            <Row gutter={[5, 5]}>
              { preferredLanguages.map(locale => {
                return (
                  <Col span={8} key={locale}>
                    <Input
                      addonBefore={
                        <LocaleFlag intl={intl} locale={locale} />
                      }
                      value={getOptionNameInLocale(option, locale)}
                      onChange={e => handleOnChange(key, locale, e.target.value)}
                      suffix={locale === suborganization.language ? <span className="VariablesConfiguration__variable__required">*</span> : null}
                      disabled={!canManage || data.deprecated}
                    />
                  </Col>
                );
              })}
              { canManage && !data.deprecated &&
                <Col>
                  <CustomButton
                    className="VariablesConfiguration__variable__delete_option_button"
                    icon={<DeleteOutlined />}
                    danger={true}
                    onClick={() => handleOnDeleteOption(key)}
                  />
                </Col>
              }
            </Row>
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
}

export default injectIntl(Variable);
