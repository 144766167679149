import React from 'react';
import { injectIntl } from 'react-intl';

import Description from 'components/Description'
import AnswerFooter from 'components/AnswerFooter';

import {
  Empty,
  Row,
  //Col,
  Divider,
} from 'antd';

const Component = ({
  schema, // TODO: Consider schema?
  value,
  expanded = false,
}) => {
  const { text = '' } = value || {};
  return (
    <Description
      description={text}
      expanded={expanded}
    />
  );
};

const TableComponent = ({
  // NOTICE: Table components are plain-text only
  schema, // TODO: Consider schema?
  value,
}) => {
  const { text = '' } = value || {};
  return (
    <Description
      description={text}
      className="text"
    />
  );
};

const SummaryComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
}) => {
  const t = intl.messages;
  const { text = '' } = value || {};
  return (
    !text
    ? (
      <Empty
        image={'/images/icon-empty-data.svg'}
        description={t.kpi_detail_empty}
      />
    )
    : (
      <Description.Summary
        summary={text}
      />
    )
  );
});

const Show = ({
  intl,
  source,
  source_params,
  schema,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  canWrite = true,
  expanded = false,
  suborganization_data
}) => {
  const t = intl.messages;

  return (
    <section>
      <Row>
        <Component
          schema={schema}
          value={value}
        />
      </Row>
      <section>
        {
          !comment
          ? null
          : (
            <section>
              <Divider/>
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
                expanded={expanded}
              />
            </section>
          )
        }
      </section>
      <Divider/>
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        suborganization_data={suborganization_data}
      />
    </section>
  );
};

Show.Component = Component;
Show.TableComponent = TableComponent;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

