import React, {
  useState,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { Tooltip } from 'antd';
import AplanetIcon from 'components/AplanetIcon';
import Answer from 'components/Answer';
import { toBetweenDotsDateFormat } from 'utils/date';
import CustomModalType from 'components/CustomModalType';
import DataRequestsInfoModalHeader from './DataRequestsInfoModalHeader';
import { TableDataRequestsCellsContext } from './context';
import { DELETED_USER_SLUG } from 'utils/user';

const DataRequestsValueCell = ({
  intl,
  value,
  lastKpiUpdate,
  requestedToName,
  isResponse,
  valueSource,
}) => {
  const {
    schema,
    schemaLabels,
    suborganizationConfig,
  } = useContext(TableDataRequestsCellsContext);

  const [showModal, setShowModal] = useState(false);

  return (
    <td
      className={`DataRequests__response-value ${isResponse ? '' : 'is-disabled'}`}
    >
      {schema.type === 'table' || schema.type === 'tuple' ? (
        <div className='DataRequests__response-table-wrapper'>
          <AplanetIcon name="Table" onClick={() => setShowModal(true)} title={intl.formatMessage({id: `admin_data_requests_table_value_see_${schema.type}`})} size="18px" />
          <span className='DataRequests__response-see-table' onClick={() => setShowModal(true)}>{intl.formatMessage({id: `admin_data_requests_table_value_see_${schema.type}`})}</span>
          <CustomModalType
            showModal={showModal}
            width={1200}
            onCancel={() => setShowModal(false)}
            title={isResponse
              ? intl.formatMessage({id: `admin_data_requests_${schema.type}_value_response_by`}, {value: requestedToName  === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName })
              : intl.formatMessage({id: `admin_data_requests_${schema.type}_value_filled_by`},  {value: lastKpiUpdate?.name === DELETED_USER_SLUG ? intl.formatMessage({ id: lastKpiUpdate?.name }) : lastKpiUpdate?.name })
            }
            content={showModal ? (
              <>
              <DataRequestsInfoModalHeader />
              <Answer.Show
                schema={schema}
                schemaLabels={schemaLabels}
                value={value}
                config={suborganizationConfig}
              />
            </>
            ) : null}
            hasCloseButton
          />
        </div>
      ) : (
        schema.type === 'qualitative' ? (
        <Tooltip
          overlayClassName="DataRequests__response-value-tooltip"
          title={(
            <ReactMarkdown
              source={value.text}
            />
          )}
        >
          <div>
            <ReactMarkdown
              className='DataRequests__response-value-qualitative'
              source={value.text}
            />
          </div>
        </Tooltip>
        ) : (
          <Answer.Show
            schema={schema}
            schemaLabels={schemaLabels}
            value={value}
          />
        )
      )}
      {isResponse ? null : (
        <span className='DataRequests__response-value-filled'>
          {valueSource === 'manual'
            ? intl.formatMessage({id: `admin_data_requests_table_value_filled`}, {value: lastKpiUpdate?.name === DELETED_USER_SLUG ? intl.formatMessage({ id: lastKpiUpdate?.name }) : lastKpiUpdate?.name, date: toBetweenDotsDateFormat(lastKpiUpdate?.updated_at, intl)})
            : intl.formatMessage({id: `admin_data_requests_value_filled_by_${valueSource}`})}
        </span>
      )}
    </td>
  );
}

DataRequestsValueCell.propTypes = {
  value: PropTypes.object.isRequired,
  lastKpiUpdate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    updated_at:PropTypes.string.isRequired,
  }),
  requestedToName: PropTypes.string.isRequired,
  isResponse: PropTypes.bool,
  valueSource: PropTypes.oneOf(['manual', 'calculated', 'aggregated', 'children']),
};

export default injectIntl(DataRequestsValueCell);