import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Radio, Space } from 'antd';
import { DashboardCardFormContext } from '../..';
import useOrganizations from 'utils/useOrganizations';
import CustomTag from 'components/CustomTag';
import CardFormStepWrapper from '../CardFormStepWrapper';
import { FORM_KEY_SELECTED_NODE_LEVEL } from 'containers/DashboardCardForm/constants';

const SelectNodes = ({ intl }) => {

    const { values, handleChange } = useContext(DashboardCardFormContext);
    const { suborganization } = useOrganizations();

    const childrenOrgApplying =  suborganization.children.filter( subOrg => values.selectedRows.some( kpi => kpi.orgs.includes( subOrg.slug ) ));

  return (
    <CardFormStepWrapper className='DashboardCardForm__nodeSelection'>            
        <div className='DashboardCardForm__stepTitle'>
            { intl.formatMessage({ id: 'dashboard_card_form_select_nodes' }) }
        </div>
        
        <Radio.Group
            value={ values.selectedNodeLevel }
            onChange={ (e) => handleChange(FORM_KEY_SELECTED_NODE_LEVEL)( e.target.value ) }
            buttonStyle="solid" 
        >
            <Space direction="vertical" size={ 10 }>

                <Radio value={ 1 }>
                    { intl.formatMessage({ id: 'dashboard_card_form_select_nodes_mine' }) }: &nbsp;
                    {  suborganization.name }
                </Radio>

                {
                     childrenOrgApplying.length > 0 && 
                    <div>
                        <Radio value={ 2 } disabled={ ['boolean', 'qualitative'].includes(values.selectedRows[0]?.schema?.type) }>
                            { intl.formatMessage({ id: 'dashboard_card_form_select_nodes_childs' }) } 
                        </Radio>
                        <div className='DashboardCardForm__nodeSelection__tags'>
                            {
                                 childrenOrgApplying.map( subOrg => (
                                    <CustomTag
                                        className="tag"
                                        key={ subOrg.id }
                                        data-testid={`tag_${subOrg.id}`}
                                        colorclass="grey"
                                        name={ subOrg.name }
                                        selected={ false }
                                    />
                                ))
                            }
                        </div>
                    </div>
                }
            </Space>
            
        </Radio.Group>
    </CardFormStepWrapper>
  )
}

export default injectIntl(SelectNodes);