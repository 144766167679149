import { loop, Cmd } from 'redux-loop';

const TARGET = 'dashboard';
const CHART_TARGET = 'dashboard_chart';
const CARD_TARGET = 'dashboard_card';
const CARD_VALUES_TARGET = 'dashboard_values';

const isTarget = (target) => [TARGET, CHART_TARGET, CARD_TARGET, CARD_VALUES_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;
const isCardTarget = (target) => target === CARD_TARGET;
const isCardValuesTarget = (target) => target === CARD_VALUES_TARGET;

const initialState = {
  template: null,
  data: null,
  chart: {
    processing: false,
    ready: false,
    error: null,
    endpoint: '',
    ticket: '',
    url: '',
  },
  card: {
    data: null,
    loading: false,
    error: false
  },
  loading: false,
  error: false,
  grid_layout: [],
  shared_dashboard_ids: []
};

const waitForChart = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;

      if (isCardTarget(action.target)){
        return {
          ...state,
          card: {
            ...initialState.card,
            loading: action.method === 'GET',
            error: false,
          }
        };
      }

      if (isCardValuesTarget(action.target)){
        return {
          ...state,
          error: false,
        };
      }

      return {
        ...state,
        chart: initialState.chart,
        card: initialState.card,
        loading: action.method === 'GET',
        //data: null,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      const response = action.response;
      if(isGeneralTarget(response.target)) {
        return {
          ...state,
          template: response.result.template,
          data: response.result.data,
          card: { ...initialState.card },
          loading: false,
          error: false,
          grid_layout: response.result.grid_layout,
          shared_dashboard_ids: response.result.shared_dashboard_ids,
          organization_id: response.result.organization_id,
        };
      }

      if (isCardTarget(response.target)){

        let dashboardData = response.method !== 'GET' ? { ...response.result } : {}; 

        return {
          ...state,
          ...dashboardData,
          card: { 
            ...state.card,
            data: response.method === 'GET' ? response.result.data : null,
            loading: false,
            error: false
          }
        }
      }

      if (isCardValuesTarget(response.target)){

        let dashboardCardValues = { ...response.result.data };

        return {
          ...state,
          data: { ...state.data, [dashboardCardValues.id]: dashboardCardValues },
        }

      }

      // We assume response.target === CHART_TARGET
      const { ticket } = response.result || {};
      if(!ticket) return {
        ...state,
        chart: initialState.chart,
      }

      const questIndex = (action.response.endpoint || '').indexOf('?')
      const search = questIndex >= 0
        ? action.response.endpoint.slice( questIndex )
        : ''

     const base = questIndex >= 0
      ? action.response.endpoint.slice( 0, questIndex )
      : action.response.endpoint

      const endpoint = `${base}/status/${ticket}${search}`;
      return loop({
        ...state,
        chart: {
          ...(action.response.result || initialState.chart),
          processing: true,
          ready: false,
          error: null,
          endpoint,
        },
      }, Cmd.action(waitForChart(endpoint)));

    case 'RESET_DATA':
      if(action.target !== CHART_TARGET) {
        return state;
      }
      return {
        ...state,
        chart: initialState.chart
      };

    case 'ACTIVE_WAIT_COMPLETE':
      if(!state.chart.endpoint || state.chart.endpoint !== action.endpoint) return state;
      return {
        ...state,
        chart: {
          ...state.chart,
          done: true,
          processing: false,
          error: null,
        }
      };
    case 'ACTIVE_WAIT_FAILED':
      if(!state.chart.endpoint || state.chart.endpoint !== action.endpoint) return state;
      return {
        ...state,
        chart: {
          ...state.chart,
          done: false,
          processing: false,
          error: 500,
        }
      };

    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      if(isGeneralTarget(action.request.target)) {
        return {
          ...state,
          template: null,
          data: null,
          loading: false,
          error: true
        };
      }

      if (isCardTarget(action.request.target)){
        return {
          ...state, 
          card: { 
            ...state.card,
            data: null,
            loading: false,
            error: true
          }
        };
      }

      // We assume request.target === CHART_TARGET
      return {
        ...state,
        chart: {
          ...state.chart,
          done: false,
          processing: false,
          error: true,
        }
      }

    default:
      return state;
  }
};

export {
  reducer as dashboard,
};
