import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useIntl } from 'react-intl';
import {
  Divider,
  Row,
  Col,
  Modal,
  Space,
  notification,
} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import {
  getAttachThirdPartyFileUrl,
  submit,
  deleteKpiAttachment,
  uploadKpiThirdPartyAttachmentSuccess,
  attachmentUploadFailed,
  attachmentUploaded,
  initializeAttachmentsUpload,
  startUploadingAttachments,
} from 'actions/thirdParty';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import { useAnswerState } from 'utils/useAnswerState';
import {
  formatDate,
  DATE_ONLY,
} from 'utils/date';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';
import useCustomRequest from 'utils/useCustomRequest';
import { useEventTracking } from 'hooks/useEventTracking';

import { Loading } from 'tsComponents/emptyStates/Loading';
import CustomTextArea from 'components/CustomTextArea';
import CustomButton from 'components/CustomButton'
import OrganizationLogo from 'components/OrganizationLogo';
import EsgLogo from 'components/EsgLogo';
import FileItem from 'components/FileItem'
import Answer from 'components/Answer';
import UploadArea from 'containers/UploadArea';
import ReportingStandardTag from 'components/ReportingStandardTag';
import PeriodLabel from 'components/PeriodLabel';
import EditableComment from 'components/EditableComment';
import { SDGLogoList } from 'components/SDGList'
import A from 'components/A'
import Description from 'components/Description'
import AplanetIcon from 'components/AplanetIcon';

// NOTICE: This component below corresponds to old ../ThirdParty/Kpi.js.
//         Please, it needs refactor when is redesigned in the future.

const Kpi = ({
  token,
  organization,
  suborganization,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();

  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [deletingAttachment, setDeletingAttachment] = useState(false);

  const {
    kpi_request_detail,
    uploadingAttachments,
    attachments: attachmentsUploadStatus,
    loading,
    error,
  } = useSelector(state => state.third_party);

  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(() => dispatch(_refreshAccessToken()), [dispatch]);

  const customRequest = useCustomRequest({
    api_requests,
    refreshAccessToken,
  }, [
    api_requests,
    refreshAccessToken,
  ]);

  const {
    slug,
    config,
    name,
    requesterEmail,
    params,
    type,
    sdgs = [],
    standard_info = {},
    attachments = [],
    schema,
    suggested_at,
    comment: requesterComment = '',
    schemaLabels,
    period,
    period_start,
    period_end,
    guidelines_std,
    guidelines_org,
    suggestion,
    data_request_id,
    tableDimensions,
    request_status,
    organization_slug,
    organization_name,
    parent_organization_slug,
  } = kpi_request_detail || {};

  let initialValue = null;
  let initialComment = null;

  if (config?.third_party_can_see_values) {
    initialValue = suggestion?.value;
    initialComment = suggestion?.comment;
  }

  const {
    values: answerValues,
    update: updateAnswer,
  } = useAnswerState({
    schema,
    tableDimensions,
    initialState: initialValue,
    slug: slug,
    organization: organization_slug || suborganization?.slug,
    organization_parent: parent_organization_slug || suborganization?.parent_slug || null,
    period,
  });

  const [comment, setComment] = useState(initialComment || '');

  const submitResponse = useCallback(
    () => {
      setSubmitting(true);
      dispatch(
        submit({
          token,
          topLevelOrg: organization.slug,
          suborganization_slug: organization_slug,
          data_request_id,
          value: answerValues,
          comment,
          kpi_slug: slug,
          attachments
        })
      );
    },
    [
      dispatch,
      token,
      organization.slug,
      organization_slug,
      data_request_id,
      answerValues,
      comment,
      slug,
      attachments
    ]
  );

  const handleSubmit = useCallback(
    () => {
      setDirty(false);
      if (attachmentsToUpload.length) {
        dispatch(
          initializeAttachmentsUpload(attachmentsToUpload.map(({ uid }) => uid))
        );
      } else {
        submitResponse();
      }
    },
    [
      dispatch,
      attachmentsToUpload,
      submitResponse,
    ]
  );

  const handleUpdateAnswer = useCallback((...args) => {
    setDirty(true);
    updateAnswer(...args);
  }, [
    updateAnswer,
  ]);

  const handleSetComment = useCallback((...args) => {
    setDirty(true);
    setComment(...args);
  }, []);

  const enabledReportSet = useMemo(() => {
    // NOTICE: all the organizations should support 'aplanet' codes
    return new Set((config?.enabled_reports || []).concat('aplanet'));
  }, [
    config,
  ]);

  const documentationUrls = useMemo(() => {
    return Object.keys(standard_info)
      .map(standard => {
        let urls = standard_info[standard].map(({ documentation_url }) => documentation_url);
        return urls.length ? [standard, urls] : null;
      })
      .filter(Boolean)
      .reduce((obj, [key, vals]) => {
        obj[key] = (vals || []).filter(Boolean);
        return obj;
      }, {});
  }, [
    standard_info,
  ]);


  // NOTICE: Below attachments code

  const attachmentUploadUrl = useMemo(
    () => getAttachThirdPartyFileUrl(
      token,
      organization_slug,
      slug,
      period,
      data_request_id,
      organization.slug
    ),
    [
      token,
      organization_slug,
      slug,
      period,
      data_request_id,
      organization.slug
    ]
  );

  useEffect(
    () => {
      if (
        !submitting
        && uploadingAttachments
        && attachmentsUploadStatus.length
        && !attachmentsUploadStatus.some(({ status }) => status === 'uploading')
      ) {
        submitResponse();
        setAttachmentsToUpload(
          attachmentsToUpload.filter(({ uid }) => {
            return attachmentsUploadStatus
              .find(({ id }) => id === uid)?.status === 'failed';
          })
        );
      }
    },
    [
      submitting,
      uploadingAttachments,
      attachmentsUploadStatus,
      attachmentsToUpload,
      submitResponse,
    ]
  );

  // Send attachments failed
  useEffect(
    () => {
      if (
        submitting
        && !loading
        && !error
      ) {
        if (
          attachmentsUploadStatus.some(({ status }) => status === 'failed')
        ) {
          setDirty(false);
          notification.error({
            description: intl.formatMessage({ id: 'third_party_data_request_attachment_upload_failed_notification' }),
          });
        }
        setSubmitting(false);
      }
    },
    [
      intl,
      submitting,
      loading,
      error,
      attachmentsToUpload,
      attachmentsUploadStatus,
    ]
  );

  // End of deleting attachment
  useEffect(
    () => {
      if (
        deletingAttachment
        && !loading
        && !error
      ) {
        setDeletingAttachment(false);
      }
    },
    [
      intl,
      loading,
      error,
      deletingAttachment,
    ]
  );

  // Send response failed
  useEffect(
    () => {
      if (
        (
          submitting
          || deletingAttachment
        )
        && !loading
        && error
      ) {
        if (submitting) {
          setSubmitting(false);
        } else if (deletingAttachment) {
          setDeletingAttachment(false);
        }
        notification.error({
          description: intl.formatMessage({ id: 'third_party_fields_error' }),
        });
      }
    },
    [
      intl,
      submitting,
      loading,
      error,
      deletingAttachment,
    ]
  );

  const onAttachSuccess = useCallback((response, file) => {
    dispatch(attachmentUploaded(file.uid));
    try {
      const attachment = JSON.parse(response);
      dispatch(
        uploadKpiThirdPartyAttachmentSuccess(data_request_id, attachment)
      )
    } catch (err) {
      console.error(err);
    }
  }, [data_request_id, dispatch]);

  const onAttachError = useCallback((_, file) => {
    dispatch(attachmentUploadFailed(file.uid));
  }, [
    dispatch,
  ]);

  // Upload attatchments in local
  useEffect(
    () => {
      if (
        !uploadingAttachments
        && attachmentsUploadStatus.length
        && attachmentsUploadStatus
          .every(({ status }) => status === 'uploading')
      ) {
        dispatch(startUploadingAttachments());
        attachmentsToUpload.forEach(
          attachment => {
            customRequest({
              action: attachmentUploadUrl,
              data: {},
              file: attachment,
              filename: 'file',
              onSuccess: onAttachSuccess,
              onError: onAttachError,
            });
          }
        );
      }
    },
    [
      dispatch,
      uploadingAttachments,
      attachmentsToUpload,
      attachmentsUploadStatus,
      attachmentUploadUrl,
      customRequest,
      onAttachSuccess,
      onAttachError,
    ]
  );
  const handleBeforeUpload = useCallback(
    (file, fileList) => {
      const isLt20M = (f) => f.size / 1024 / 1024 < 20;
      if (!isLt20M(file)) {
        notification.error({
          message: intl.formatMessage({ id: 'datamanagement_datafiles_failed' }),
          description: intl.formatMessage({ id: 'file_size_limit' }),
          icon: <MinusCircleOutlined style={{ color: 'red' }} />,
          className: 'custom-class',
          style: {
            color: 'red',
          }
        })
      } else {
        setDirty(true);
        setAttachmentsToUpload([
          ...attachmentsToUpload,
          ...fileList.filter(isLt20M),
        ]);
      }
      return false;
    },
    [attachmentsToUpload, intl]
  );

  const handleOnRemoveNotUploadedAttachment = useCallback(
    (e, attachment) => {
      e.preventDefault();
      setAttachmentsToUpload(
        attachmentsToUpload.filter(({ uid }) => uid !== attachment.uid)
      );
      eventTracking.capture(
        'dataRequestExternal.responseAttachmentDelete',
        {
          organization_id: organization?.id,
          organization_name: organization?.name,
          organization_slug: organization?.slug,
          suborganization_id: suborganization?.id,
          suborganization_name: suborganization?.name,
          suborganization_slug: suborganization?.slug,
          kpi_slug: slug,
          period,
        }
      );
    },
    [
      attachmentsToUpload,
      eventTracking,
      organization?.id,
      organization?.name,
      organization?.slug,
      period,
      slug,
      suborganization?.id,
      suborganization?.name,
      suborganization?.slug
    ]
  );

  const handleOnDelete = useCallback(
    (e, attachment) => {
      e.preventDefault();
      Modal.confirm({
        title: intl.formatMessage({ id: `datamanagement_delete_attachment_modal_title` }),
        content: intl.formatMessage({ id: `datamanagement_delete_attachment_modal_description` }),
        okText: intl.formatMessage({ id: `datamanagement_delete_attachment_modal_ok` }),
        cancelText: intl.formatMessage({ id: `datamanagement_delete_attachment_modal_cancel` }),
        onOk() {
          setDeletingAttachment(true);
          eventTracking.capture(
            'dataRequestExternal.responseAttachmentDelete',
            {
              organization_id: organization?.id,
              organization_name: organization?.name,
              organization_slug: organization?.slug,
              suborganization_id: suborganization?.id,
              suborganization_name: suborganization?.name,
              suborganization_slug: suborganization?.slug,
              kpi_slug: slug,
              period,
            }
          );
          dispatch(
            deleteKpiAttachment(
              token,
              organization.slug,
              organization_slug,
              slug,
              period,
              attachment.filename,
              data_request_id
            )
          );
        },
      })
    },
    [
      intl,
      eventTracking,
      organization?.id,
      organization?.name,
      organization.slug,
      suborganization?.id,
      suborganization?.name,
      suborganization?.slug,
      slug,
      period,
      dispatch,
      token,
      organization_slug,
      data_request_id
    ]
  );

  const newAttachments = useMemo(
    () => {
      if (uploadingAttachments) {
        return attachmentsToUpload.filter(
          attachment => attachmentsUploadStatus
            .find(({ id }) => id === attachment.uid)?.status !== 'success'
        );
      } else {
        return attachmentsToUpload;
      }
    },
    [
      attachmentsToUpload,
      attachmentsUploadStatus,
      uploadingAttachments,
    ]
  );

  return (
    <section className='ThirdPartyRequestsKpi__kpi'>
      <section className='ThirdPartyRequestsKpi__row ThirdPartyRequestsKpi__header'>
        <section className='ThirdPartyRequestsKpi__row-flexSpaceBetween'>
          <div className="ThirdPartyRequestsKpi__org-container">
            {!suborganization
              ? null
              : (
                <OrganizationLogo
                  className='Nav-logo'
                  {...suborganization}
                  showPoweredBy={config?.show_powered_by
                  }
                />
              )
            }
            <div className="ThirdPartyRequestsKpi__org-name">
              <div>{suborganization.name}</div>
              {suborganization.slug === organization_slug
                ? null
                : (
                  <>
                    <span>/</span>
                    <div>
                      {organization_name}
                    </div>
                  </>
                )
              }
              <PeriodLabel
                className="ThirdPartyRequestsKpi__period"
                forceExpand={config?.expand_period_label}
                period={{
                  label: period,
                  start_date: period_start,
                  end_date: period_end,
                }}
              />
            </div>
          </div>
          <div className="ThirdPartyRequestsKpi__esg-logo">
            <EsgLogo type={type} />
          </div>
        </section>
        <section className='ThirdPartyRequestsKpi__row-flexSpaceBetween'>
          <span className='ThirdPartyRequestsKpi__header-title'>{name}</span>
          {request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use ? null : (
            <section className='ThirdPartyRequestsKpi__header-buttons'>
              <CustomButton
                type='primary'
                disabled={
                  loading || !answerValues || !dirty || request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use
                  || (schema?.type === 'quantitative' && (typeof answerValues.value === 'undefined' || answerValues.value === null))
                  || ((!answerValues || !dirty) && !attachmentsToUpload?.length) || uploadingAttachments
                }
                onClick={handleSubmit}
                loading={uploadingAttachments || submitting}
              >
                {intl.formatMessage({ id: `send` })}
              </CustomButton>
            </section>
          )}
        </section>
        <section className='ThirdPartyRequestsKpi__row-flexSpaceBetween'>
          <section className='ThirdPartyRequestsKpi__header-info'>
            <section>
              <span>{`${intl.formatMessage({ id: `third_party_fields_request_by` })}:`}</span>
              <span>
                {
                  requesterEmail || '-'
                }
              </span>
            </section>
            <section>
              <span>{`${intl.formatMessage({ id: `third_party_fields_request_on` })}:`}</span>
              <span>
                {
                  params?.request?.first_notif_date
                    ? formatDate(params?.request?.first_notif_date, DATE_ONLY, intl)
                    : '-'
                }
              </span>
            </section>
            {
              !suggested_at ? null :
                <section>
                  <span>{`${intl.formatMessage({ id: `third_party_fields_suggested_on` })}:`}</span>
                  <span>
                    {
                      formatDate(suggested_at, DATE_ONLY, intl)
                    }
                  </span>
                </section>
            }

          </section>
          <section className='ThirdPartyRequestsKpi__flex'>
            <section className='ThirdPartyRequestsKpi__sdgs'>
              <span>{intl.formatMessage({ id: `sdg` })}</span>
              <section>
                <SDGLogoList
                  sdgs={sdgs} className='ThirdPartyRequestsKpi__sdg' square={true}
                />
              </section>
            </section>
            {Object.keys(standard_info)
              .filter(standard => enabledReportSet.has(standard))
              .sort((a, b) => a < b)
              .map(standard => {
                const values = (standard_info[standard] || []).filter(({ code }) => !!code);
                return (values.length > 0 &&
                  <section
                    key={standard}
                    className='ThirdPartyRequestsKpi__standards'
                  >
                    <span className='ThirdPartyRequestsKpi__standards-title'>
                      {standard !== "aplanet" ? intl.formatMessage({ id: `reporting_standard_${standard}` }) : ""}
                    </span>
                    {
                      values.map(val => (
                        <ReportingStandardTag
                          key={val.category_id}
                          standard={standard}
                          showTooltip
                          {...val}
                        />
                      ))
                    }
                  </section>
                );
              })
            }
          </section>
        </section>
      </section>
      <section className='ThirdPartyRequestsKpi__row ThirdPartyRequestsKpi__body'>
        {loading ? (<Loading />) : (
          <>
            {request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use ? (
              <div className="ThirdPartyRequestsKpi__alert-wrapper">
                <AplanetIcon className="ThirdPartyRequestsKpi__alert-icon" name="Not apply" size="18px" />
                <span className="ThirdPartyRequestsKpi__alert">{intl.formatMessage({ id: `third_party_requests_edit_alert` })}</span>
              </div>
            ) : null}
            {!requesterComment ? null :
              <React.Fragment>
                <div className="ThirdPartyRequestsKpi__title">{intl.formatMessage({ id: `comment` })}</div>
                <div>
                  {requesterComment}
                </div>
                <Divider />
              </React.Fragment>
            }
            <div className="ThirdPartyRequestsKpi__title">{intl.formatMessage({ id: `answer` })}</div>
            {request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use ? (
              <Answer.Show
                schema={schema}
                schemaLabels={schemaLabels}
                value={answerValues}
                config={config}
              />
            ) : (
              <Answer.Edit
                schema={schema}
                schemaLabels={schemaLabels}
                config={config}
                value={answerValues}
                onChange={handleUpdateAnswer}
                disabled={loading || uploadingAttachments}
                tableDimensions={tableDimensions}
                kpi_slug={slug}
                organization={organization_slug || suborganization?.slug}
                organization_parent={parent_organization_slug || suborganization?.parent_slug || null}
                period={period}
              />
            )}
            <Divider />
            <span className='ThirdPartyRequestsKpi__title'>{intl.formatMessage({ id: `kpi_detail_attachments` })}</span>
            <Row
              type="flex"
              gutter={[15, 15]}
              className="ThirdPartyRequestsKpi__files"
            >
              {attachments.map(
                attachment => (
                  <Col
                    key={attachment.download}
                  >
                    <FileItem
                      {...attachment}
                      showDownload={false}
                    >
                      {request_status !== DATA_REQUEST_STATUS.in_use
                        && request_status !== DATA_REQUEST_STATUS.partially_in_use
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        ? <a
                          href=""
                          className="ThirdPartyRequestsKpi__files__delete"
                          onClick={e => handleOnDelete(e, attachment)}
                        >
                          {intl.formatMessage({ id: `delete` })}
                        </a>
                        : null
                      }
                    </FileItem>
                  </Col>
                )
              )
              }
              {request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use ? null : (
                <>
                  {
                    newAttachments.map(
                      attachment => (
                        <Col
                          key={attachment.uid}
                        >
                          <FileItem
                            filename={attachment.name}
                            size={attachment.size}
                            mimetype={attachment.type}
                            showDownload={false}
                          >
                            <Space>
                              { // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                  href=""
                                  className="ThirdPartyRequestsKpi__files__delete"
                                  onClick={e => handleOnRemoveNotUploadedAttachment(e, attachment)}
                                >
                                  {intl.formatMessage({ id: 'delete' })}
                                </a>
                              }
                              {
                                uploadingAttachments
                                  ? <AplanetIcon
                                    name="rotate"
                                    size="18px"
                                    iconProps={{ spin: true }}
                                  />
                                  : attachmentsUploadStatus.find(({ id }) => id === attachment.uid)?.status === 'failed'
                                    ? <AplanetIcon
                                      name="ban"
                                      size="18px"
                                      title={intl.formatMessage({ id: 'third_party_data_request_attachment_upload_failed' })}
                                      iconProps={{ color: "#D65050" }}
                                    />
                                    : <AplanetIcon
                                      name="cloud-slash"
                                      size="18px"
                                      title={intl.formatMessage({ id: 'third_party_data_request_attachment_not_uploaded' })}
                                    />
                              }
                            </Space>
                          </FileItem>
                        </Col>
                      )
                    )
                  }
                  <Col>
                    <UploadArea
                      className="ThirdPartyRequestsKpi__files_upload"
                      disabled={loading || uploadingAttachments}
                      actionUrl={attachmentUploadUrl}
                      onSuccess={onAttachSuccess}
                      beforeUpload={handleBeforeUpload}
                      fileList={attachmentsToUpload}
                    >
                      {intl.formatMessage({ id: 'third_party_attachment_add' })}
                    </UploadArea>
                  </Col>
                </>
              )}
            </Row>
            <Divider />
            <span className='ThirdPartyRequestsKpi__title'>{intl.formatMessage({ id: `add_comment` })}</span>
            <CustomTextArea
              readOnly={loading || request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use || uploadingAttachments}
              className='ThirdPartyRequestsKpi__box'
              value={comment}
              onChange={handleSetComment}
            />
            {!guidelines_org && !guidelines_std
              ? null
              : (
                <>
                  <Divider />
                  <span className='ThirdPartyRequestsKpi__title'>{intl.formatMessage({ id: `guidelines` })}</span>
                </>
              )
            }
            {(guidelines_std || Object.values(documentationUrls).some(documentationUrl => documentationUrl.length)) &&
              <div
                className="ThirdPartyRequestsKpi__guidelines-title"
              >
                {intl.formatMessage({ id: `guidelines_std` })}
              </div>
            }
            {Object.values(documentationUrls).some(documentationUrl => documentationUrl.length) && (
              Object.keys(documentationUrls)
                .filter(standard => documentationUrls[standard] && documentationUrls[standard].length > 0)
                .map(standard => (
                  <ul
                    key={standard}
                  >
                    {
                      standard === 'aplanet'
                        ? null
                        : (
                          <>
                            {intl.formatMessage({ id: `reporting_standard_${standard}` })}
                            {': '}
                          </>
                        )
                    }
                    {
                      documentationUrls[standard].map((durl, index) => (
                        <li style={{ 'listStyle': 'none' }} key={`documentation_url_${index}`}>
                          <A
                            href={durl}
                            newWindow
                          >
                            {durl}
                          </A>
                        </li>
                      ))
                    }
                  </ul>
                ))
            )
            }
            {guidelines_std &&
              <Description description={guidelines_std} />
            }
            {!guidelines_org
              ? null
              : (
                <EditableComment
                  title={intl.formatMessage({ id: `guidelines_org` })}
                  value={guidelines_org}
                  editable={false}
                  loading={loading}
                  titleClass='ThirdPartyRequestsKpi__guidelines-title'
                />
              )
            }
          </>
        )}
      </section>
    </section>
  );
};

Kpi.propTypes = {
  token: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo_small: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  suborganization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    parent_slug: PropTypes.string,
    logo_small: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

export default withRouter(Kpi);

