import React, { useMemo, useState, useCallback, useRef } from 'react';
import { injectIntl } from 'react-intl';

import { flattenTree, searchTreeWithFullPath } from 'utils/tree';
import Avatar from 'components/Avatar';

import OrganizationTreeSelector from 'components/OrganizationTreeSelector';
import useOutsideClick from 'utils/useOutsideClick';
import useOrganizations from 'utils/useOrganizations';

import {
  Tooltip,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import './style.less';


const MAX_ORG_LABEL_LENGTH = 40;

const OrganizationSelector = ({
  tree,
  current,
  onSelect,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    organizationTree,
  } = useOrganizations();

  const flat_tree = useMemo(() => flattenTree(tree), [ tree ]);

  const handleSelect = useCallback((selectedKey) => {
    setIsOpen(false);
    onSelect(flat_tree[selectedKey]);
  }, [
    onSelect,
    flat_tree
  ]);

  const onClickKeyEsc = useCallback(() => {
    setIsOpen(false);
  }, []); 

  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsOpen(false)
  });
  
  const disableDropdown = useMemo(
    () => !tree || !tree.children.length || disabled,
    [disabled, tree]
  );

  const [currentOrganizationTreeWithParents] = useMemo(
    () => searchTreeWithFullPath([organizationTree], (node) => node.slug === current?.slug),
    [organizationTree, current.slug]
  );
  const organizationsRootToLeaf = useMemo(
    () => Object.values(
      flattenTree(currentOrganizationTreeWithParents)
    ).map(node => node.name),
    [currentOrganizationTreeWithParents]
  );
  const orgLabel = useMemo(
    () => {
      if (organizationsRootToLeaf.length < 3) {
        return organizationsRootToLeaf.join(' >> ');
      }

      const parts = [
        organizationsRootToLeaf[0],
        organizationsRootToLeaf.slice(1, -1).join(' >> '),
        organizationsRootToLeaf.at(-1),
      ];

      const lengthOfRootAndLeaf = parts[0].length + parts[2].length;
      if (lengthOfRootAndLeaf >= MAX_ORG_LABEL_LENGTH) {
        parts[1] = '...';
      } else {
        const left = MAX_ORG_LABEL_LENGTH - lengthOfRootAndLeaf;

        if (left < parts[1].length) {
          parts[1] = parts[1].substring(0, left) + '...';
        }
      }
      return parts.join(' >> ');
    },
    [
      organizationsRootToLeaf,
    ]
  );

  return (
      isOpen ? (
        <div className="OrganizationSelector" ref={ref}>
          <OrganizationTreeSelector
            tree={tree}
            currentSelection={[current.id]}
            onClickKeyEsc={onClickKeyEsc}
            onSelect={handleSelect}
          />
        </div>
      ) : (
        <section onClick={() => !disableDropdown && setIsOpen(true)}>
          <Avatar
            shape="square"
            src={current.logo_small || undefined}
            name={current.name || undefined}
            icon={<UserOutlined />}
            className='OrganizationSelector-avatar'
          />
          <div className='OrganizationSelector-profileName'>
            <Tooltip title={organizationsRootToLeaf.join(' >> ')}>
              { orgLabel }
            </Tooltip>
          </div>
          { !disableDropdown &&
            <img
              className='OrganizationSelector-chevron'
              src='/images/assets/chevron-down.svg'
              alt='chevron-down'
            />
          }
        </section>
      )
  );
}

export default injectIntl(OrganizationSelector);
