import React, {
  useState,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { IntlProvider } from 'react-intl-redux';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';

import { history } from './store';
import { saveState, loadState } from 'store/persistence';
import { hydrateStore } from 'actions/store';

import { ExternalRoot } from 'containers/Root';
import BackErrorNotification from 'components/BackErrorNotification';
import WebSocketProvider from 'components/WebsocketContext';
import config from 'config';
import {
  patchStateWithTopLevelOrg,
} from 'utils/topLevelOrgInUrl';
import {
  fetchConfigData,
} from 'actions/thirdParty';
import { queryStringToObject } from 'utils/queryParameters';

const ExternalApp = () =>{ 
  const token = window?.location?.pathname?.split('/')[2];

  const dispatch = useDispatch();
  const [, contextHolder] = notification.useNotification();

  const [hydrated, setHydrated] = useState(false);

  const {
    topLevelOrg = null,
    profile,
    selector_orgs,
  } = useSelector(state => state.third_party);

  const topOrganizationSlugParam = useMemo(() => {
    const queryStringObject = queryStringToObject(window?.location?.search);
    return queryStringObject?.toplevelorg || topLevelOrg;
  }, [topLevelOrg]);

  // Save state upon auth/org change
  useEffect(() => {
    saveState({
      organization_slug: topOrganizationSlugParam,
    });
  }, [topOrganizationSlugParam]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(state => patchStateWithTopLevelOrg(state))
      .then(state => dispatch( hydrateStore(state) ))
      .then(() => setHydrated(true));
  }, [ dispatch ]);

  useEffect(() => {
    if (!profile && !selector_orgs) {
      dispatch(fetchConfigData(token, topOrganizationSlugParam));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    token,
    topOrganizationSlugParam
  ]);

  // Append Usetiful script and Segment users by language
  useEffect(() => {
    if ( config.USETIFUL_TOKEN && profile?.language ){
    const headHtml = document.getElementsByTagName('head')[0];
    const usetifulScript = document.createElement('script');

      window.usetifulTags = {
        lang: String(profile?.language || null)
      };
    usetifulScript.setAttribute('id','usetifulScript');
    usetifulScript.src = "https://www.usetiful.com/dist/usetiful.js";
    usetifulScript.dataset.token = config.USETIFUL_TOKEN;
    usetifulScript.async = true;
    
    headHtml.appendChild(usetifulScript);
    return () => {
      headHtml.removeChild(usetifulScript);
    }
  }
  }, [
    profile?.language
  ]);

  if (
    !token
      || !topOrganizationSlugParam
      || !profile?.language
      || !selector_orgs
      || !hydrated
  ) {
    return null;
  }

  return (
    <WebSocketProvider> 
      <IntlProvider>
        <>
          {contextHolder}
          <BackErrorNotification /> 
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={ExternalRoot} />
            </Switch>
          </ConnectedRouter>
        </>
      </IntlProvider>
    </WebSocketProvider>
  ); 
};

export default ExternalApp;
