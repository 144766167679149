import config from 'config';

// NOTICE: This is the NEW Atlas-v2 API

// URL generators for forms
const getAttachFileUrl = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
) => {
  return `${config.API_URL}/${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}/attachment`;
};

const getGroupOfInterestBulkUploadUrl = (
  organization_slug,
  suborganization_slug,
) => {
  return `${config.API_URL}/${organization_slug}/groups_of_interest/${suborganization_slug}/upload`;
};

const getBulkUploadUrl = (
  organization_slug,
  suborganization_slug,
) => {
  return `${config.API_URL}/${organization_slug}/data_management/${suborganization_slug}/bulk`;
};

const generateKpiExcel = ({
  organization_slug,
  suborganization_slug,
  start_date,
  end_date
}) => {
  let endpoint = `${organization_slug}/data_management/${suborganization_slug}/bulk/kpi_data`;
  if (start_date && end_date) {
    endpoint = `${endpoint}?start_date=${start_date}&end_date=${end_date}`;
  }
  return {
    type: 'API_CALL_REQUEST',
    target: 'kpi_excel_download',
    method: 'PUT',
    endpoint,
    body: {
      // Something?
    },
  };
};

const generateSingleNodeExcel = ({
  organization_slug,
  suborganization_slug,
}) => {
  return {
    type: 'API_CALL_REQUEST',
    target: 'kpi_excel_download',
    method: 'PUT',
    endpoint: `${organization_slug}/data_management/${suborganization_slug}/bulk/template`,
    body: {},
  };
};

const downloadKpiExcelData = (
  endpoint,
) => {
  return {
    type: 'API_CALL_REQUEST',
    target: 'kpi_excel_download',
    method: 'GET',
    endpoint,
  };
};

const getEqualityPlanUploadUrl = (
  organization_slug,
  suborganization_slug,
  planId,
  step,
  type,
) => {
  return `${config.API_URL}/${organization_slug}/equality_plan/${suborganization_slug}/${planId}/document/${step}/${type}`;
};

const getEqualityPlanSalaryUploadUrl = (
  organization_slug,
  suborganization_slug,
  plan_id,
) => {
  return `${config.API_URL}/${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/salary/upload`;
};

// Action creators proper
const requestTaxonomies = (
  organization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies',
  method: 'GET',
  endpoint: `${organization_slug}/taxonomy`,
  body: null,
});

const requestDataCategories = (
  // NOTICE: the default config is not used as such anywhere
  //         you have to change 'target' to 'data_categories_no_periods' (and use the rest as default)
  //             in the OLD data management screen
  //         in 'fill and report' you have to override everything else, but NOT target
  //         hopefully this whole endpoint will disappear soon
  {
    organization_slug,
    suborganization_slug,
    reporting_standard = 'aplanet',
    includeAllPeriods = false,
    dateFilterValues = [],
    target = 'data_categories'
  }
) => {
  const endpoint = `${organization_slug}/data_management/${suborganization_slug}/kpi_category`;
  const baseQuery = { reporting_standard, all_periods: includeAllPeriods ? '1' : '0' };
  const query = !dateFilterValues || dateFilterValues.length === 0 ?
    baseQuery : { ...baseQuery, start: dateFilterValues[0], end: dateFilterValues[1] };

  return {
    type: 'API_CALL_REQUEST',
    target,
    method: 'GET',
    endpoint,
    query,
    body: null,
  };
};

const requestKpiDetail = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  keep,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_detail',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}`,
  body: null,
  keep,
});

const resetKpiDetail = () => ({
  type: 'RESET_KPI_DETAIL',
});

const requestKpiValueDependencies = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  hash,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_dependency',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}/dependency`,
  body: null,
  hash,
});

const createKpi = (
  organization_slug,
  suborganization_slug,
  name_translations,
  parent_slug, // NOTICE: This is a kpi_category slug
  schema,
  type,
  guidelines,
  collection_frequency,
  cycle_date,
  first_reported_at,
  sdgs = [],
  reporting_standards = [],
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_kpi',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi`,
  body: {
    name_translations,
    parent_slug, // NOTICE: This is a kpi_category slug
    schema,
    type,
    guidelines,
    collection_frequency,
    cycle_date,
    first_reported_at,
    sdgs,
    reporting_standards,
  }
});

const updateKpi = ({
  organization_slug,
  suborganization_slug,
  kpi_slug,
  uuid,
  sdgs,
  standard_info,
  name_translations,
  schema,
  schema_empty_existing_values,
  esg_type,
  guidelines_std,
  periodicities,
  code,
  annual_start_date
}) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_kpi_edit',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}`,
  body: {
    uuid,
    sdgs,
    standard_info,
    name_translations,
    schema,
    schema_empty_existing_values,
    esg_type,
    guidelines_std,
    annual_start_date,
    periodicities,
    code
  },
  keep: true,
});

const editKpi = ({
  organization_slug,
  suborganization_slug,
  kpi_slug,
  sdgs,
  name_translations,
  schema,
  schema_empty_existing_values,
  esg_type,
  guidelines_std,
  code,
  periodicities,
  annual_start_date
}) => ({
  type: 'API_CALL_REQUEST',
  target: '4.0_kpi_edit',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/kpi/${kpi_slug}`,
  body: {
    sdgs,
    name_translations,
    schema,
    schema_empty_existing_values,
    esg_type,
    guidelines_std,
    code,
    periodicities,
    annual_start_date
  },
  useNewApiUrl: true
});

const editBulkKpi = ({
  organization_slug,
  suborganization_slug,
  kpi_slugs,
}, values) => ({
  type: 'API_CALL_REQUEST',
  target: '4.0_kpi_edit',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/kpis/meta`,
  body: { kpi_slugs, ...values },
  useNewApiUrl: true
});

const changeKpiCategorySlug = (
  category_slug,
) => ({
  type: 'CHANGE_KPI_CATEGORY_SLUG',
  category_slug
});

const createKpiCategory = ({
  organization_slug,
  suborganization_slug,
  parent_slug = '',
  name_translations = [],
  code = '',
  color = '',
  parents = []
}) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_categories',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi_category`,
  body: {
    name_translations,
    code,
    color,
    parent_slug,
    parents,
  }
});

const addCategories = ({
  organization_slug,
  suborganization_slug,
  parents,
  categories,
  new_categories = []
}) => ({
  type: 'API_CALL_REQUEST',
  target: '4.0_category_add',
  method: 'POST',
  useNewApiUrl: true,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/categories`,
  body: {
    parents,
    categories,
    new_categories
  }
});

const addKpis = ({
  organization_slug,
  suborganization_slug,
  parents,
  kpis,
}) => ({
  type: 'API_CALL_REQUEST',
  target: '4.0_kpi_add',
  method: 'POST',
  useNewApiUrl: true,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/kpis`,
  body: {
    parents,
    kpis,
  }
});

const updateReportingStructure = (
  organization_slug,
  suborganization_slug,
  sections,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'reporting_structure',
  method: 'PUT',
  useNewApiUrl: true,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure`,
  body: sections
});

const requestReportingStructure = (
  {
    organization_slug,
    suborganization_slug,
    ignoreLoading = false
  }
) => ({
  type: 'API_CALL_REQUEST',
  target: 'reporting_structure',
  method: 'GET',
  useNewApiUrl: true,
  ignoreLoading,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure`,
  body: null,
});

const requestReportingStructureWithValues = (
  {
    organization_slug,
    suborganization_slug,
    ignoreLoading = false,
    dateFilterValues = [],
  }
) => {
  const query = !dateFilterValues || dateFilterValues.length === 0 ?
    {} : { start: dateFilterValues[0], end: dateFilterValues[1] };

  return {
    type: 'API_CALL_REQUEST',
    target: 'data_categories',
    method: 'GET',
    useNewApiUrl: true,
    ignoreLoading,
    endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/with_values`,
    query,
    body: null,
  };
};

const requestStandardsCategoriesOrKpis = (
  {
    organization_slug,
    suborganization_slug,
    getKpis,
  }
) => ({
  type: 'API_CALL_REQUEST',
  target: 'standards_categories_kpis',
  method: 'GET',
  useNewApiUrl: true,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/${getKpis ? 'all_standards_kpis' : 'all_standards_sections'}`,
  body: null,
});

const updateKpiCategory = ({
  organization_slug,
  suborganization_slug,
  category_slug,
  name_translations,
  code = '',
  color = '',
}) => ({
  type: 'API_CALL_REQUEST',
  target: '4.0_category_edit',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi_category/${category_slug}`,
  body: {
    name_translations,
    code,
    color,
  }
});

const deleteKpiCategory = (
  organization_slug,
  suborganization_slug,
  category_slug,
  uuid
) => ({
  type: 'API_CALL_REQUEST',
  target: 'category_delete',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/category/${category_slug}${uuid ? ("/" + uuid) : ""}`,
  useNewApiUrl: true,
});

const getDataRequests = ({
  organization_slug,
  suborganization_slug,
  page = 1,
  filters = {},
}) => ({
  useNewApiUrl: true,
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/internal`,
  query: { page, ...filters },
});

const getDataRequestStatusProgress = ({
  organization_slug,
  suborganization_slug,
  filters = {},
}) => ({
  useNewApiUrl: true,
  type: 'API_CALL_REQUEST',
  target: 'data_request_progress',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/status_progress`,
  query: filters,
});

const dataRequestsUseResponse = (
  organization_slug,
  suborganization_slug,
  requests,
  useValue,
  useAttachments,
  value,
) => ({
  useNewApiUrl: true,
  organization_slug,
  suborganization_slug,
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/use_data`,
  body: {
    request_ids: requests,
    use_value: useValue,
    use_attachments: useAttachments,
    value,
  },
});

const dataRequestsUseAggregatedResponse = (
  organization_slug,
  suborganization_slug,
  kpi_value_id,
  organization_kpi_id,
  requests,
  aggregationType = '',
  aggregatedValue = {},
  aggregatedComment = '',
  aggregatedAttachments = [],
) => ({
  useNewApiUrl: true,
  organization_slug,
  suborganization_slug,
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/use_aggregated_data`,
  body: {
    kpi_value_id,
    organization_kpi_id,
    request_ids: requests,
    aggregation_type: aggregationType,
    aggregated_value: aggregatedValue,
    aggregated_comment: aggregatedComment,
    aggregated_attachments: aggregatedAttachments,
  },
});

const sendPuntualReminderDataRequests = (
  organization_slug,
  suborganization_slug,
  data_request_ids = []
) => ({
  useNewApiUrl: true,
  organization_slug,
  suborganization_slug,
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'PUT',
  body: {
    data_request_ids
  },
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/send_puntual_reminder`,
});

const deleteDataRequests = (
  organization_slug,
  suborganization_slug,
  request_ids,
) => ({
  useNewApiUrl: true,
  organization_slug,
  suborganization_slug,
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/${request_ids}`,
});

const createDataRequests = (
  organization_slug,
  suborganization_slug,
  body
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data-request`,
  body,
  organization_slug,
  suborganization_slug,
});

const getDataRequestAttachments = (
  organization_slug,
  suborganization_slug,
  request_id
) => ({
  useNewApiUrl: true,
  type: 'API_CALL_REQUEST',
  target: 'attachments',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/data_request/${request_id}/attachments`,
});

const requestKpiUpdate = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  body,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_kpi',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}`,
  body,
  keep: true,
});

const deleteKpiValue = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_kpi',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}`,
  keep: true,
});

//TODO: refactor backend to express the intention better (add_tags === selection, remove_tags isn't necessary)
const addRemoveTags = (
  organization_slug,
  suborganization_slug,
  kpi_slugs = [],
  add_tags = [],
  remove_tags = [],
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_meta',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/meta`,
  body: {
    add_tags,
    remove_tags,
    kpi_slugs,
  },
  keep: true,
  meta_target: 'self', // NOTICE: addRemoveTags is called with 'self' but affects all orgs
  organization_slug,
  suborganization_slug
});

const getKpisInfo = (
  organization_slug,
  suborganization_slug,
  kpi_slugs = [],
) => ({
  type: 'API_CALL_REQUEST',
  target: 'reporting_structure_kpi',
  method: 'PUT',
  useNewApiUrl: true,
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/getkpis`,
  body: {
    kpi_slugs,
  },
  keep: true,
});

const setApplies = (
  organization_slug,
  suborganization_slug,
  kpi_slugs = [],
  applies,
  not_apply_comment,
  target = 'self',
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_meta',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/meta`,
  body: {
    applies,
    not_apply_comment,
    kpi_slugs,
    target,
  },
  keep: true,
  meta_target: target,
  organization_slug,
  suborganization_slug
});

const updateGuidelines = (
  organization_slug,
  suborganization_slug,
  kpi_slugs = [],
  guidelines = {}, // includes guidelines _std, _org and _member
  target = 'self',
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_meta',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/meta`,
  body: {
    ...guidelines,
    kpi_slugs,
    target,
  },
  keep: true,
  meta_target: target,
});

const updateKpiSettings = (reducer_target = 'data_kpi_settings') => ({
  organization_slug,
  suborganization_slug,
  kpi_slugs = [],
  settings = {}, // includes collection_frequency, cycle_date, first_reported_at, source, formula, aggregation and more...
  affect_old,
  mandatory_data,
  show_guidelines_std,
  target = 'self',
}) => ({
  organization_slug,
  suborganization_slug,
  kpi_slug: reducer_target === 'data_sources_kpi_config' ? kpi_slugs[0] : null,
  type: 'API_CALL_REQUEST',
  target: reducer_target,
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/meta`,
  body: {
    ...settings,
    kpi_slugs,
    affect_old,
    mandatory_data,
    show_guidelines_std,
    target
  },
  keep: true,
  meta_target: target,
});

const copyKpiAttachments = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  attachments,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_attachment',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}/attachment/copy`,
  body: attachments,
});

const recordKpiAttachment = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  attachment,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_attachment',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}/attachment`,
  body: attachment,
  keep: true,
});

const deleteKpiAttachment = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  filename,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_attachment',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/${period}/attachment/${filename}`,
  keep: true,
});
// NOTICE: reminders not used right now in data requets 4.0
const remindSuggestionRequest = (
  organization_slug,
  suborganization_slug,
  slug,
  period,
  email,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/remind`,
  organization_slug,
  suborganization_slug,
  body: {
    slug,
    period,
    email,
  },
});
// NOTICE: reminders not used right now in data requets 4.0
const bulkRemindSuggestionRequest = (
  organization_slug,
  suborganization_slug,
  reminders,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_request',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/remind`,
  organization_slug,
  suborganization_slug,
  body: {
    reminders,
  },
});

const requestHistoryForOrganization = (
  organization_slug,
  suborganization_slug,
  page = 0,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_history',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/history`,
  query: { page },
  body: null,
});

const requestFilesForOrganization = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_files',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/files`,
  body: null,
});

const requestHistoryForKpi = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  page = 0,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_history',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/history`,
  query: { page },
  body: null,
});

const requestFilesForKpi = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_files',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/files`,
  body: null,
});

const requestKpiPermissions = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_permissions',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/permissions`,
  body: null,
});

const changeKpiPermissions = (target = 'kpi_permissions') => (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  member_slug,
  permissions,
  levels = {},
) => ({
  kpi_slug,
  organization_slug: suborganization_slug,
  type: 'API_CALL_REQUEST',
  target,
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/permissions/${member_slug}`,
  body: {
    permissions,
    levels,
  }
});

const changeKpiRestriction = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  restricted = true,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_restriction',
  method: restricted ? 'POST' : 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/restriction`,
});

const requestDataSourcesInformation = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  children,
) => ({
  kpi_slug,
  organization_slug: suborganization_slug,
  children,
  type: 'API_CALL_REQUEST',
  target: 'data_sources',
  method: 'GET',
  endpoint: `${organization_slug}/data_sources/${suborganization_slug}/kpi/${kpi_slug}/?children=${children}`,
});

const requestBulkUploadList = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_bulk_upload',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/bulk`,
  suborganization_slug,
});

const createDashboard = (
  organization_slug,
  suborganization_slug,
  name,
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_list',
    method: 'POST',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}`,
    body: { name },
  };
};

const deleteDashboard = (
  organization_slug,
  suborganization_slug,
  slug,
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_list',
    method: 'DELETE',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${slug}`,
  };
};

const updateDashboard = (
  organization_slug,
  suborganization_slug,
  slug,
  body
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_list',
    method: 'PUT',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${slug}`,
    body
  };
};

const requestDashboard = (
  standard,
  organization_slug,
  suborganization_slug,
  dateFilterValues = [],
) => {
  const endpoint = `${organization_slug}/dashboard/${suborganization_slug}/${standard}`;
  const query = !dateFilterValues || dateFilterValues.length === 0 ? {} : { start: dateFilterValues[0], end: dateFilterValues[1] };

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard',
    method: 'GET',
    endpoint,
    query,
    body: null,
  };
};

const requestDashboardCardKpiValues = (
  organization_slug,
  suborganization_slug,
  dashboard_slug,
  card_slug,
  date_filter_values = [],
) => {
  const endpoint = `${organization_slug}/dashboard/${suborganization_slug}/${dashboard_slug}/card/${card_slug}/values`;
  const query = !date_filter_values || date_filter_values.length === 0 ? {} : { start: date_filter_values[0], end: date_filter_values[1] };

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_values',
    method: 'GET',
    endpoint,
    query,
    body: null,
  };
};

const requestDashboards = (
  organization_slug,
  suborganization_slug,
) => {
  const endpoint = `${organization_slug}/dashboard/${suborganization_slug}`

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_list',
    method: 'GET',
    endpoint,
    body: null,
  };
};

const createDashboardCard = (
  organization_slug,
  suborganization_slug,
  slug,
  body
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard',
    method: 'POST',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${slug}/card`,
    body
  };
};

const requestDashboardCard = (
  organization_slug,
  suborganization_slug,
  dashboard_slug,
  card_slug
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_card',
    method: 'GET',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${dashboard_slug}/card/${card_slug}`
  };
};

const updateDashboardCard = (
  organization_slug,
  suborganization_slug,
  dashboard_slug,
  card_slug,
  body
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard_card',
    method: 'PUT',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${dashboard_slug}/card/${card_slug}`,
    body
  };
};

const deleteDashboardCard = (
  organization_slug,
  suborganization_slug,
  dashboard_slug,
  card_slug
) => {

  return {
    type: 'API_CALL_REQUEST',
    target: 'dashboard',
    method: 'DELETE',
    endpoint: `${organization_slug}/dashboard/${suborganization_slug}/${dashboard_slug}/card/${card_slug}`,
  };
};

const requestReportHistory = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'report_history',
  method: 'GET',
  endpoint: `${organization_slug}/report/${suborganization_slug}`,
  body: null,
});

const generateReport = ({
  organization_slug,
  suborganization_slug,
  title,
  show_comments,
  kpi_slugs = [],
  dates = [],
}) => ({
  type: 'API_CALL_REQUEST',
  target: 'report_history',
  method: 'POST',
  endpoint: `${organization_slug}/report/${suborganization_slug}`,
  body: {
    title,
    show_comments,
    kpi_slugs,
    dates,
  },
});

const exportReportDocument = (
  organization_slug,
  suborganization_slug,
  report_slug,
  format = 'odt',
) => ({
  type: 'API_CALL_REQUEST',
  target: 'report_history',
  method: 'PUT',
  endpoint: `${organization_slug}/report/${suborganization_slug}/${report_slug}/${format}`,
  body: null,
});

const approveKpis = (
  organization_slug,
  suborganization_slug,
  kpis = [],
  comment = '',
  level, // defaults to 'undefined' which means "aprove all the levels I can"
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_approval',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/approval`,
  body: {
    kpis,
    comment,
    level,
  },
});

const rejectKpis = (
  organization_slug,
  suborganization_slug,
  kpis = [],
  comment = '',
  level, // defaults to 'undefined' which means "reject all the levels I can"
) => ({
  type: 'API_CALL_REQUEST',
  target: 'data_approval',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/rejection`,
  body: {
    kpis,
    comment,
    level,
  },
});

//KPI MONITOR API

const getKpiMonitor = ({
  organization_slug,
  suborganization_slug,
  kpi_slug,
  type, // if type not specified, retrieve all monitoring values?
  period
}) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_monitor',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/monitoring`,
  query: {
    kpi_slug: kpi_slug,
    period: period,
    type: type,
    all_periods: period ? false : true
  },

});

const createKpiMonitor = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  forAllPeriodsBool,
  kpi_monitor = {} // kpi monitoring values {type: <target|bounds|etc>, value: Object, id? : <identificator>, period: String (All|202*) }
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_monitor',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/monitoring`,
  body: {
    kpi_slug: kpi_slug,
    period: period,
    kpi_monitor: kpi_monitor,
    all_periods: forAllPeriodsBool
  },
});

const updateKpiMonitor = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  forAllPeriodsBool,
  kpi_monitor = {} // kpi monitoring values {type: <target|bounds|etc>, value: Object , id : <identificator>, period: String (All|202*)}
) => (
  {
    type: 'API_CALL_REQUEST',
    target: 'kpi_monitor',
    method: 'PUT',
    endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/monitoring`,
    body: {
      kpi_slug: kpi_slug,
      period: period,
      kpi_monitor: kpi_monitor,
      all_periods: forAllPeriodsBool
    },
  });

const deleteKpiMonitor = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  period,
  forAllPeriodsBool,
  kpi_monitor = {}, // kpi monitoring values {type: <target|bounds|etc>, value: Object , id : <identificator>, period: String (All|202*)} if type not specified, delete all monitoring values ?
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_monitor',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/monitoring`,
  query: {
    kpi_slug: kpi_slug,
    period: period,
    kpi_monitor: kpi_monitor,
    all_periods: forAllPeriodsBool
  },
});

// Files Download ZIP API

const createArchive = (
  organization_slug,
  suborganization_slug,
  type,
  payload
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_archive',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/file_export/${type}`,
  body: payload
});

const getArchives = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_archive',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/file_export`,
});

const deleteArchive = (
  organization_slug,
  suborganization_slug,
  file_id
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_archive',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/file_export/${file_id}`,
});

const updateArchive = (
  organization_slug,
  suborganization_slug,
  file_id,
  body
) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_archive',
  method: 'PUT',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/file_export/${file_id}`,
  body
})

const getDocumentation = (
  organization_slug,
  pageId
) => ({
  type: 'API_CALL_REQUEST',
  target: 'documentation',
  endpoint: `${organization_slug}/notion/${pageId}`
})

// This API remains from v1 to v2
const requestProfile = organization_slug => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'GET',
  endpoint: `${organization_slug}/profile`,
  body: null
});

const requestOrganizationTree = organization_slug => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_tree',
  method: 'GET',
  endpoint: `${organization_slug}/organization_tree`,
  body: null
});

const updateProfile = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'profile',
  method: 'PUT',
  endpoint: `${organization_slug}/profile`,
  body
});

// NOTICE: This is the OLD Atlas-v1 API
const requestOrganizationDetail = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_detail',
  method: 'GET',
  endpoint: `${organization_slug}/organization_detail/${suborganization_slug}`,
  body: null
});

const requestGroupsOfInterest = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'groups_of_interest',
  method: 'GET',
  endpoint: `${organization_slug}/groups_of_interest/${suborganization_slug}`,
});

const createGroupOfInterest = (organization_slug, suborganization_slug, name, type) => ({
  type: 'API_CALL_REQUEST',
  target: 'groups_of_interest',
  method: 'POST',
  endpoint: `${organization_slug}/groups_of_interest/${suborganization_slug}`,
  body: { name, type }
});

const editGroupOfInterest = (organization_slug, suborganization_slug, id, name, type) => ({
  type: 'API_CALL_REQUEST',
  target: 'groups_of_interest',
  method: 'PUT',
  endpoint: `${organization_slug}/groups_of_interest/${suborganization_slug}/${id}`,
  body: { name, type }
});

const deleteGroupOfInterest = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'groups_of_interest',
  method: 'DELETE',
  endpoint: `${organization_slug}/groups_of_interest/${suborganization_slug}/${id}`,
  body: null,
});

const createGoiMember = (organization_slug, goiId, name, email, description) => ({
  type: 'API_CALL_REQUEST',
  target: 'goi_member',
  method: 'POST',
  endpoint: `${organization_slug}/groups_of_interest/${goiId}/members`,
  body: { name, email, description }
});

const editGoiMember = (organization_slug, goiId, id, name, email, description) => ({
  type: 'API_CALL_REQUEST',
  target: 'goi_member',
  method: 'PUT',
  endpoint: `${organization_slug}/groups_of_interest/${goiId}/members/${id}`,
  body: { name, email, description }
});

const deleteGoiMember = (organization_slug, goiId, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'goi_member',
  method: 'DELETE',
  endpoint: `${organization_slug}/groups_of_interest/${goiId}/members/${id}`,
  body: null
});

const requestAllOrganizationKpiMembers = (
  organization_slug, suborganization_slug
) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_organization_kpi_member',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/assignees`,
});

const requestOrganizationKpiMember = (organization_slug, suborganization_slug, kpiSlug) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_kpi_member',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpiSlug}/assignees`,
});

const syncOrganizationKpiMembers = (organization_slug, suborganization_slug, role, body, isBulk = false) => ({
  type: 'API_CALL_REQUEST',
  target: isBulk ? 'organization_kpi_member_bulk' : 'organization_kpi_member',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/assignees/${role}`,
  body,
});

const syncOrganizationKpiMembersToChildNodes = (organization_slug, suborganization_slug, kpiSlugs) => ({
  type: 'API_CALL_REQUEST',
  target: 'organization_kpi_member_sync_child_nodes',
  method: 'POST',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/assignees/sync`,
  body: { kpiSlugs },
});

// eslint-disable-next-line communist-spelling/communist-spelling
const requestAnalyses = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}`,
});

const requestAnalysisResult = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis_result',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/result`,
});

const updateAnalysisResult = (
  organization_slug, suborganization_slug, id, body
) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis_result',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/result`,
  body,
});

const requestAnalysis = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}`,
});

const createAnalysis = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}`,
  body,
});

const closeAnalysis = (organization_slug, suborganization_slug, analysisId) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/close`,
});

const requestAnalysisSusIssues = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis_sus_issues',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/sustainable_issues`,
});

const requestSusIssues = (organization_slug, suborganization_slug) => {
  let endpoint = `${organization_slug}/sustainable_issues/${suborganization_slug}`;
  return {
    type: 'API_CALL_REQUEST',
    target: 'sus_issues',
    method: 'GET',
    endpoint,
  }
};

const addAnalysisSusIssue = (organization_slug, suborganization_slug, id, issues) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis_sus_issues',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/sustainable_issues`,
  body: issues,
});

const deleteAnalysisSusIssue = (organization_slug, suborganization_slug, id, issueId) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis_sus_issues',
  method: 'DELETE',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/sustainable_issues/${issueId}`,
});

const requestActiveListeningMediums = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'active_listening_medium',
  method: 'GET',
  endpoint: `${organization_slug}/active_listening_mediums/${suborganization_slug}`,
});

const addActiveListeningMedium = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'active_listening_medium',
  method: 'POST',
  endpoint: `${organization_slug}/active_listening_mediums/${suborganization_slug}`,
  body,
});

const requestCompanies = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'company',
  method: 'GET',
  endpoint: `${organization_slug}/benchmark_companies/${suborganization_slug}`,
});

const addCompany = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'company',
  method: 'POST',
  endpoint: `${organization_slug}/benchmark_companies/${suborganization_slug}`,
  body,
});

const startAnalysis = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/start`,
  body,
});

const startActiveListening = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/start_active_listening`,
  body,
});

const uploadBenchmark = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'benchmark',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/benchmark_csv`,
  body,
});

const uploadActiveListeningData = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'active_listening',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}/active_listening_csv`,
  body,
});

const updateAnalysis = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}`,
  body,
});

const deleteAnalysis = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'DELETE',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${id}`,
});

const createSurvey = (organization_slug, suborganization_slug, analysisId, name, type, csvData) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys`,
  body: {
    name,
    type,
    csvData,
  },
});

const requestSurveys = (organization_slug, suborganization_slug, analysisId) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys`,
});

const addSurveyRecipient = (organization_slug, suborganization_slug, analysisId, surveyId, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_recipient',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/recipients`,
  body,
});

const updateSurveyRecipient = (organization_slug, suborganization_slug, analysisId, surveyId, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_recipient',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/recipients/${body.goi_member_id}`,
  body,
});

const deleteSurveyRecipient = (organization_slug, suborganization_slug, analysisId, surveyId, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_recipient',
  method: 'DELETE',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/recipients/${body.goi_member_id}`,
});

const requestSurvey = (organization_slug, suborganization_slug, analysisId, surveyId) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}`,
});

const closeSurveys = (organization_slug, suborganization_slug, analysisId, type) => ({
  type: 'API_CALL_REQUEST',
  target: 'analysis',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/close-surveys/${type}`,
});

const closeSurvey = (organization_slug, suborganization_slug, analysisId, surveyId) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey',
  method: 'PUT',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/close`,
});

const requestAnalysisSubjects = (organization_slug, suborganization_slug, analysisId) => {
  let endpoint = `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/subjects`;
  return {
    type: 'API_CALL_REQUEST',
    target: 'analysis_subjects',
    method: 'GET',
    endpoint
  };
};

const saveSurveySubjects = (organization_slug, suborganization_slug, analysisId, surveyId, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_subjects',
  method: 'POST',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/subjects`,
  body
});

const requestSurveySubjects = (organization_slug, suborganization_slug, analysisId, surveyId) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_subjects',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/subjects`
});

const sendSurveyEmail = (organization_slug, suborganization_slug, analysisId, surveyId, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'send_survey',
  method: 'POST',
  endpoint:
    `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/send`,
  body
});

const sendSurveyRecipientEmail = (organization_slug, suborganization_slug, analysisId, surveyId, recipientId) => ({
  type: 'API_CALL_REQUEST',
  target: 'send_survey',
  method: 'POST',
  endpoint:
    `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/recipients/${recipientId}/send`,
});

const requestSurveyResponses = (organization_slug, suborganization_slug, analysisId, surveyId) => ({
  type: 'API_CALL_REQUEST',
  target: 'survey_responses',
  method: 'GET',
  endpoint: `${organization_slug}/analysis/${suborganization_slug}/${analysisId}/surveys/${surveyId}/responses`
});

const requestEqualityPlans = (organization_slug, suborganization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}`,
});

const createEqualityPlan = (organization_slug, suborganization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}`,
  body
});

const requestEqualityPlan = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${id}`,
});

const updateEqualityPlan = (organization_slug, suborganization_slug, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${id}`,
  body
});

const deleteEqualityPlan = (organization_slug, suborganization_slug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan',
  method: 'DELETE',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${id}`,
});

const requestEqualityPlanDocuments = (organization_slug, suborganization_slug, plan_id, step, type) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_document',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/document/${step}/${type || ''}`,
});

const uploadEqualityPlanDocument = (organization_slug, suborganization_slug, plan_id, step, type, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_document',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/document/${step}/${type}`,
  body
});

const downloadEqualityPlanDocument = (organization_slug, suborganization_slug, plan_id, type, format) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_document_template',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/document/${type}/download/${format}`,
});

const requestEqualityPlanObjectives = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_objective',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/objective`,
});

const createEqualityPlanObjective = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_objective',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/objective`,
  body
});

const updateEqualityPlanObjective = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_objective',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/objective/${id}`,
  body
});

const requestEqualityPlanFactors = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_factor',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/factor`,
});

const createEqualityPlanFactor = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_factor',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/factor`,
  body
});

const updateEqualityPlanFactor = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_factor',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/factor/${id}`,
  body
});

const deleteEqualityPlanFactor = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_factor',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/factor/${id}`,
});

const requestEqualityPlanDepartments = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_department',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/department`,
});

const createEqualityPlanDepartment = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_department',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/department`,
  body
});

const updateEqualityPlanDepartment = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_department',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/department/${id}`,
  body
});

const deleteEqualityPlanDepartment = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_department',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/department/${id}`,
});

const requestEqualityPlanProfessionalGroups = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_professional_group',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/professional_group`,
});

const createEqualityPlanProfessionalGroup = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_professional_group',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/professional_group`,
  body
});

const updateEqualityPlanProfessionalGroup = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_professional_group',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/professional_group/${id}`,
  body
});

const deleteEqualityPlanProfessionalGroup = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_professional_group',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/professional_group/${id}`,
});

const requestEqualityPlanPositions = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_position',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/position`,
});

const createEqualityPlanPosition = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_position',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/position`,
  body
});

const updateEqualityPlanPosition = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_position',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/position/${id}`,
  body
});

const deleteEqualityPlanPosition = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_position',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/position/${id}`,
});

const requestEqualityPlanSalaries = (organization_slug, suborganization_slug, plan_id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_salary',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/salary`,
});

const createEqualityPlanSalary = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_salary',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/salary`,
  body
});

const updateEqualityPlanSalary = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_salary',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/salary/${id}`,
  body
});

const deleteEqualityPlanSalary = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_salary',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/salary/${id}`,
});

const requestEqualityPlanActions = (organization_slug, suborganization_slug, plan_id, type = 'general') => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_action',
  method: 'GET',
  endpoint: `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/action/${type}`,
});

const createEqualityPlanAction = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_action',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/action`,
  body
});

const updateEqualityPlanAction = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_action',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/action/${id}`,
  body
});

const updateEqualityPlanActionIndicator = (organization_slug, suborganization_slug, plan_id, action_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_action_indicator',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/action/${action_id}/indicator/${id}`,
  body
});

const createEqualityPlanMeasure = (organization_slug, suborganization_slug, plan_id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_measure',
  method: 'POST',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/measure`,
  body
});

const updateEqualityPlanMeasure = (organization_slug, suborganization_slug, plan_id, id, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_measure',
  method: 'PUT',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/measure/${id}`,
  body
});

const deleteEqualityPlanMeasure = (organization_slug, suborganization_slug, plan_id, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'equality_plan_measure',
  method: 'DELETE',
  endpoint:
    `${organization_slug}/equality_plan/${suborganization_slug}/${plan_id}/measure/${id}`,
});

const deleteKpi = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
  uuid
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_delete',
  method: 'DELETE',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/reporting_structure/kpi/${kpi_slug}${uuid ? ("/" + uuid) : ""}`,
  keep: true,
  useNewApiUrl: true,
});

const deleteReport = (
  organization_slug,
  suborganization_slug,
  report_slug
) => ({
  type: 'API_CALL_REQUEST',
  target: 'report_history',
  method: 'DELETE',
  endpoint: `${organization_slug}/report/${suborganization_slug}/${report_slug}`,
  body: null,
});

const getKpiVariables = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_variable',
  method: 'GET',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}`,
});

const getKpiVariableStatus = (
  organization_slug,
  suborganization_slug,
  variable_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_variable_status',
  method: 'GET',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}/${variable_slug}/status`,
});

const getNormalizedKpiVariables = (
  organization_slug,
  suborganization_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'taxonomies_kpi_variable',
  method: 'GET',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}/normalized`,
});

const createKpiVariable = (
  organization_slug,
  suborganization_slug,
  body,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_variable',
  method: 'POST',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}`,
  body,
});

const syncKpiVariables = (
  organization_slug,
  suborganization_slug,
  variables,
  options,
  modifiedVariableName,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_variable',
  method: 'PUT',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}`,
  body: { variables, options, modifiedVariableName, },
});

const syncKpiVariablesToChildOrganizations = (
  organization_slug,
  suborganization_slug,
  variable_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_variable_sync',
  method: 'POST',
  endpoint: `${organization_slug}/kpi_variable/${suborganization_slug}/${variable_slug}/sync`,
});

const requestKpiValueStatus = (
  organization_slug,
  suborganization_slug,
  kpi_slug,
) => ({
  type: 'API_CALL_REQUEST',
  target: 'kpi_value_status',
  method: 'GET',
  endpoint: `${organization_slug}/data_management/${suborganization_slug}/kpi/${kpi_slug}/value_status`,
  body: null,
  kpiSlug: kpi_slug,
  keep: true,
});

export {
  requestTaxonomies,
  getAttachFileUrl,
  getBulkUploadUrl,
  requestDataCategories,
  requestReportingStructure,
  requestReportingStructureWithValues,
  requestStandardsCategoriesOrKpis,
  requestKpiDetail,
  resetKpiDetail,
  requestKpiValueDependencies,
  createKpi,
  createKpiCategory,
  updateKpiCategory,
  addCategories,
  deleteKpiCategory,
  getDataRequests,
  getDataRequestStatusProgress,
  deleteDataRequests,
  getDataRequestAttachments,
  requestKpiUpdate,
  deleteKpiValue,
  addRemoveTags,
  setApplies,
  updateGuidelines,
  updateKpiSettings,
  copyKpiAttachments,
  recordKpiAttachment,
  deleteKpiAttachment,
  remindSuggestionRequest,
  bulkRemindSuggestionRequest,
  dataRequestsUseResponse,
  dataRequestsUseAggregatedResponse,
  requestHistoryForOrganization,
  requestFilesForOrganization,
  requestHistoryForKpi,
  requestFilesForKpi,
  requestKpiPermissions,
  changeKpiPermissions,
  changeKpiRestriction,
  requestDataSourcesInformation,
  requestBulkUploadList,
  createDashboard,
  deleteDashboard,
  updateDashboard,
  requestDashboard,
  requestDashboards,
  createDashboardCard,
  updateDashboardCard,
  deleteDashboardCard,
  requestDashboardCard,
  requestDashboardCardKpiValues,
  approveKpis,
  rejectKpis,
  getKpiMonitor,
  createKpiMonitor,
  updateKpiMonitor,
  deleteKpiMonitor,
  createArchive,
  getArchives,
  deleteArchive,
  updateArchive,
  getDocumentation,
  requestReportHistory,
  generateReport,
  exportReportDocument,
  requestProfile,
  requestOrganizationTree,
  updateProfile,
  requestOrganizationDetail,
  requestGroupsOfInterest,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
  // eslint-disable-next-line communist-spelling/communist-spelling
  requestAnalyses,
  requestAnalysis,
  createAnalysis,
  closeAnalysis,
  requestAnalysisSusIssues,
  requestSusIssues,
  addAnalysisSusIssue,
  deleteAnalysisSusIssue,
  requestCompanies,
  addCompany,
  startAnalysis,
  startActiveListening,
  uploadBenchmark,
  updateAnalysis,
  deleteAnalysis,
  createSurvey,
  requestSurveys,
  addSurveyRecipient,
  updateSurveyRecipient,
  deleteSurveyRecipient,
  requestSurvey,
  requestAnalysisSubjects,
  saveSurveySubjects,
  requestSurveySubjects,
  sendSurveyEmail,
  requestActiveListeningMediums,
  addActiveListeningMedium,
  uploadActiveListeningData,
  requestSurveyResponses,
  closeSurvey,
  closeSurveys,
  requestAnalysisResult,
  updateAnalysisResult,
  sendSurveyRecipientEmail,
  requestEqualityPlans,
  createEqualityPlan,
  requestEqualityPlan,
  updateEqualityPlan,
  deleteEqualityPlan,
  requestEqualityPlanDocuments,
  uploadEqualityPlanDocument,
  downloadEqualityPlanDocument,
  requestEqualityPlanObjectives,
  createEqualityPlanObjective,
  updateEqualityPlanObjective,
  requestEqualityPlanFactors,
  createEqualityPlanFactor,
  updateEqualityPlanFactor,
  deleteEqualityPlanFactor,
  requestEqualityPlanDepartments,
  createEqualityPlanDepartment,
  updateEqualityPlanDepartment,
  deleteEqualityPlanDepartment,
  requestEqualityPlanProfessionalGroups,
  createEqualityPlanProfessionalGroup,
  updateEqualityPlanProfessionalGroup,
  deleteEqualityPlanProfessionalGroup,
  requestEqualityPlanPositions,
  createEqualityPlanPosition,
  updateEqualityPlanPosition,
  deleteEqualityPlanPosition,
  requestEqualityPlanActions,
  requestEqualityPlanSalaries,
  createEqualityPlanSalary,
  updateEqualityPlanSalary,
  deleteEqualityPlanSalary,
  createEqualityPlanAction,
  updateEqualityPlanAction,
  updateEqualityPlanActionIndicator,
  getEqualityPlanUploadUrl,
  getEqualityPlanSalaryUploadUrl,
  createEqualityPlanMeasure,
  updateEqualityPlanMeasure,
  deleteEqualityPlanMeasure,
  syncOrganizationKpiMembers,
  syncOrganizationKpiMembersToChildNodes,
  requestOrganizationKpiMember,
  requestAllOrganizationKpiMembers,
  deleteKpi,
  generateKpiExcel,
  generateSingleNodeExcel,
  downloadKpiExcelData,
  updateKpi,
  changeKpiCategorySlug,
  deleteReport,
  getGroupOfInterestBulkUploadUrl,
  getKpiVariables,
  getKpiVariableStatus,
  getNormalizedKpiVariables,
  createKpiVariable,
  syncKpiVariables,
  syncKpiVariablesToChildOrganizations,
  getKpisInfo,
  addKpis,
  updateReportingStructure,
  createDataRequests,
  editKpi,
  editBulkKpi,
  requestKpiValueStatus,
  sendPuntualReminderDataRequests,
};
