import React, {
    useMemo,
    useCallback
  } from 'react';
  import { injectIntl } from 'react-intl';
  import { withRouter } from 'react-router';
  import { useSelector } from 'react-redux';

  import Answer from './Answer';
  import Attachments from './Attachments';
  import ExternalRequests from './ExternalRequests';
  import SubOrg from './SubOrg';
  import Tags from './Tags';
  import Validation from './Validation';
  import Settings from './Settings';
  import PeriodSwitcher from './PeriodSwitcher';
  import Guidelines from './Guidelines';
  import Dependencies from './Dependencies';
  import ReverseDependencies from './ReverseDependencies';

  import { Loading } from 'tsComponents/emptyStates/Loading';

  import useOrganizations from 'utils/useOrganizations';
  import { selectTargetValue } from 'utils/kpiMonitoring';
  import { useFeatureList } from 'components/FeatureSwitch';

  import {
    getKpiStatus,
  } from 'utils/kpi';

  import {
    Row,
    Col,
    Divider,
  } from 'antd';

  const canSchemaBeCalculated = (schema, _formulas = [], userProfile = {}) => {
    // Only quantitative schemas can be calculated
    // TODO: Calculate Array/Tuple type

    return (
      ['quantitative', 'choice'].includes(schema?.type) && (
        //(formulas || []).length >= 1 && // NOTICE: We need to allow inputting formulas from the interface either way
        (userProfile || {}).role === 'system' // NOTICE: For now only system users can change calculated
      )
    );
  };

  const DataApplies = ({
    kpi_slug,
    period,
    data = {},
    changePeriod,
    loading = false,
    error = null,
    onUseValue,
    onUseComment,
    onUseAttachments,
    onUsePeriod,
    onUseAttachment,
    onRemind,
    permissions = {},
    org_mandatory_data = {},
    handleOpenAnswer,
    onShowDataRequests,
    handleUseDataRequests,
    handleUseAggregatedDataRequests,
  }) => {
    const {
      suborganization,
    } = useOrganizations();

    const {
      data: userProfile,
    } = useSelector(state => state.profile);

    const {
      suborganizationFeatures: featureList,
    } = useFeatureList();

    const {
      status: kpiStatus,
    } = useMemo(() => {
      const result = data && getKpiStatus(data);
      if(!result || result.status !== 'restricted') {
        return result;
      }
      return getKpiStatus({
        ...data,
        restricted: false, // NOTICE: In kpi detail we do not consider the 'restricted' status
                           //         If we are here it means we have permissions...
      })
    }, [
      data,
    ]);
    // TODO: Formulas for tables
    const canBeCalculated = canSchemaBeCalculated(data.schema || {}, data.formulas, userProfile);
    const canBeChildren = data.children_kpi_values && data.children_kpi_values.length >= 1;
    const canBeAggregated = data.aggregations && data.aggregations.length >= 1 && canBeChildren;

    const getStandardInfo = useCallback((kpi_standard,standard_info_array) => {
      return kpi_standard.map(standard=>{
        return standard_info_array[standard];
      });
      
      //const { standard_info } = kpi;
      // if(kpi && standard === 'aplanet') {
      //   return kpi.standard_info.filter(info => enabledReportSet.has(info.standard));
      // }
  
      // if(!kpi || !kpi.code) {
      //   return [];
      // }
  
      // return standard_info.filter(info => info.standard === standard);
    }, []);

    if (loading) {
      return <Loading />
    }

    return (
      <>
        <Row type="flex" justify="center">
          <PeriodSwitcher
            changePeriod={changePeriod}
            loading={loading}
            {...data}
          />
        </Row>
        <Row>
          <Col span={24}>
            <Answer
              source={data.value_source}
              source_params={data.value_source_params}
              schema={data.schema}
              schemaLabels={data.schemaLabels}
              value={data.kpi_value}
              childrenValues={data.children_kpi_values}
              previous_value={data.previous_kpi_value}
              comment={data.comment}
              previous_comment={data.previous_kpi_comment}
              attachments={data.attachments}
              previous_attachments={data.previous_attachments}
              lastUpdate={data.last_update}
              kpi_slug={kpi_slug}
              kpi_name={data.name}
              org_name={suborganization.name}
              period={period}
              previous_period={data.previous_period}
              loading={loading}
              error={error}
              onOpenAnswer={handleOpenAnswer}
              onUseValue={onUseValue}
              onUseComment={onUseComment}
              onUseAttachments={onUseAttachments}
              onUsePeriod={onUsePeriod}
              onUseAttachment={onUseAttachment}
              permissions={permissions}
              isValidating={!!data.ready_to_validate_level}
              target={featureList.has('targets') ? selectTargetValue(data.monitoring_value) : null }
              has_target = {data.has_target}
              type={data.type}
              sdgs={data.sdgs}
              standard_info={getStandardInfo(data.standards,data.standard_info)}
              config={data.config}
              dependee_kpis={data.dependee_kpis}
              suborganization_data={data.suborganizations}
            />
          </Col>
        </Row>
        <Row type="flex" gutter={16}>
          <Col xs={24} md={12} xl={14} xxl={17}>
            {
              (!data.attachments || data.attachments.length === 0) && !permissions.can_write_kpi
                ? null
                : (
                  <>
                    <Divider />
                    <Row>
                      <Attachments
                        loading={loading}
                        kpi_slug={kpi_slug}
                        period={period}
                        attachments={data.attachments || []}
                        permissions={permissions}
                        isValidating={!!data.ready_to_validate_level}
                        config={data.config}
                      />
                    </Row>
                  </>
                )
            }
            <Divider />
            {
              !data.dependee_kpis || data.dependee_kpis.length === 0 || data.value_source !== 'calculated'
              ? null
              : (
                <Row>
                  <Col span={24}>
                    <Dependencies
                      source={data.value_source}
                      formula={(data.value_source_params || {}).formula}
                      kpis={data.dependee_kpis}
                    />
                  </Col>
                </Row>
              )
            }
            {
              !data.dependent_kpis || data.dependent_kpis.length === 0
              ? null
              : (
                <Row>
                  <Col span={24}>
                    <ReverseDependencies
                      source={data.value_source}
                      kpis={data.dependent_kpis}
                    />
                  </Col>
                </Row>
              )
            }
            <Row>
              <Col span={24}>
                <Guidelines
                  loading={loading}
                  kpi_slug={kpi_slug}
                  permissions={permissions}
                  {...data}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <SubOrg
                  schema={data.schema}
                  schemaLabels={data.schemaLabels}
                  loading={loading}
                  kpi_slug={kpi_slug}
                  kpi_name={data.name}
                  period={period}
                  cards={data.suborganizations}
                  onOpenAnswer={handleOpenAnswer}
                  onUseComment={onUseComment}
                  onUseAttachment={onUseAttachment}
                  permissions={permissions}
                  canAggregate={!data.ready_to_validate_level && data.value_source === 'manual'}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ExternalRequests
                  schema={data.schema}
                  loading={loading}
                  kpi_slug={kpi_slug}
                  value_source={data.value_source}
                  period={data.period}
                  cards={data.external || []}
                  onOpenAnswer={handleOpenAnswer}
                  handleUseDataRequests={handleUseDataRequests}
                  handleUseAggregatedDataRequests={handleUseAggregatedDataRequests}
                  onRemind={onRemind}
                  permissions={permissions}
                  canAggregate={!data.ready_to_validate_level && data.value_source === 'manual'}
                  onShowDataRequests={onShowDataRequests}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12} xl={10} xxl={7}>
            <Settings
              loading={loading}
              error={error}
              schema={data.schema}
              kpi_slug={kpi_slug}
              org_mandatory_data={org_mandatory_data}
              permissions={permissions}
              canBeChildren={canBeChildren}
              canBeAggregated={canBeAggregated}
              canBeCalculated={canBeCalculated}
              formula={(data.source_params || {}).formula}
              formula_unit={(data.source_params || {}).formula_unit}
              aggregation={(data.source_params || {}).aggregation}
              aggregation_unit={(data.source_params || {}).aggregation_unit}
              {...data}
            />
            <Validation
              loading={loading}
              kpi_slug={kpi_slug}
              period={period}
              permissions={permissions}
              kpiStatus={kpiStatus}
              org_mandatory_data={org_mandatory_data}
              attachments={data.attachments}
              source={data.value_source}
              {...data}
            />
            {
              (!data.tags || data.tags.length === 0) && !permissions.can_configure_kpi
              ? null
              : (
                <Tags
                  loading={loading}
                  kpi={data}
                  permissions={permissions}
                  {...data}
                />
              )
            }
          </Col>
        </Row>
      </>
    )
  }

  export default injectIntl(withRouter(DataApplies));

