import React, { useMemo, useCallback } from 'react'
import {
  Layout,
  Row,
  Col
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';

import Nav from 'components/Nav';
import AppHeader from 'components/AppHeader';
import OrganizationLogo from 'components/OrganizationLogo';

import useElementSize from 'utils/useElementSize';
import useOrganizations from 'utils/useOrganizations';

import { toggleSidebar } from 'actions/app';

import './style.less';

const { Header, Sider } = Layout;

const AppFramework = ({
  children,
  className='',
  disabledOrganizationTreeSelector = false,
  nav = undefined,
  header = undefined,
  currentOrganization = undefined,
  disableOnClickInSiderLogo = false,
  hideOrgLogoinSider = false,
  hideHeader = false,
}) => {
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const org = currentOrganization || suborganization || organization;

  const {
    clientWidth,
    scrollWidth
  } = useElementSize(document.body);

  const dispatch = useDispatch();

  const {
    isSidebarCollapsed,
  } = useSelector(state => state.app);

  const onCollapse = useCallback((collapsed) => {
    dispatch(toggleSidebar());
  }, [
    dispatch,
  ]);

  const hasHorizontalScroll = useMemo(() => scrollWidth > clientWidth, [
    clientWidth,
    scrollWidth,
  ]);

  return (
    <Layout
      hasSider={true}
      className={`
        wrapper-layout
        ${hasHorizontalScroll ? 'hasHorizontalScroll' : ''}
      `}
    >
      <Sider
        theme="light"
        className="sider"
        collapsedWidth={88}
        collapsed={isSidebarCollapsed}
        trigger={null}
        collapsible
      >
        <Row type="flex" justify="center" className="sider__logo-wrapper">
          {hideOrgLogoinSider ? null : (
            <Col>
              {disableOnClickInSiderLogo ? (
                <OrganizationLogo
                  showPoweredBy={org?.config?.show_powered_by}
                  name={org.name}
                  logo={org.logo}
                  logo_small={org.logo_small}
                  showSmallLogo={isSidebarCollapsed}
                  className={isSidebarCollapsed ? "sider__logo-small" : "sider__logo-full"}
                />
              ) : (
                <Link to="/">
                  <OrganizationLogo
                    showPoweredBy={org?.config?.show_powered_by}
                    name={org.name}
                    logo={org.logo}
                    logo_small={org.logo_small}
                    showSmallLogo={isSidebarCollapsed}
                    className={isSidebarCollapsed ? "sider__logo-small" : "sider__logo-full"}
                  />
                </Link>
              )}
            </Col>
          )}
        </Row>
        {nav ? nav : <Nav/>}
        <Row
          type="flex"
          justify={isSidebarCollapsed ? "center" : "end"}
          className={isSidebarCollapsed ? "sider__trigger--collapsed" : "sider__trigger--not-collapsed"}
        >
          <Col>
            {isSidebarCollapsed ? <MenuUnfoldOutlined onClick={onCollapse} /> : <MenuFoldOutlined onClick={onCollapse} />}
          </Col>
        </Row>
      </Sider>
      <Layout
        className={`
          main-layout
          ${isSidebarCollapsed ? 'main-layout--collapsed' : ''}
          ${hasHorizontalScroll ? 'hasHorizontalScroll' : ''}
        `}
      >
        {hideHeader ? null : (
          <Header
            className={`
            main-header
            ${isSidebarCollapsed ? 'main-header--collapsed' : ''}
          `}
          >
            {header ? header : <AppHeader disabledOrganizationTreeSelector={disabledOrganizationTreeSelector}/>}
          </Header>
        )}
        <Layout hasSider={false} className={`content-layout ${className}`}>
          {children}
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AppFramework
