import {
  toDb,
} from 'utils/date';

const bulkUploadSuccess = (
  suborganization_slug,
  filename,
  member_name,
  member_email,
  member_avatar,
) => ({
  type: 'ADD_BULK_UPLOAD',
  suborganization_slug,
  filename,
  member_name,
  member_email,
  member_avatar,
  updated_at: toDb(new Date()),
});

export {
  bulkUploadSuccess,
}
