const translateSlug = (labels = {}) => (slug) => {
  const result = (labels || {})[`${slug}+`] || (labels || {})[slug];
  return result;
}

export const getOptions = (schema, schemaLabels = {}, tableDimensions = {})=> {
  const { options = {} } = schema;
  const by = (options.by || '').replace('+', '');
  const allLabels = (
    (schemaLabels.components || {})[`${by}+`] ||
    (schemaLabels.components || {})[by] ||
    (schemaLabels.innerSchema) ||
    schemaLabels ||
    {}
  )?.options || {};

  switch(options.source) {
    case 'organization':
      return (
        tableDimensions[by] || []
      ).map(slug => ({
        name: translateSlug(allLabels)(slug) || slug,
        slug,
      }));
    case 'standard':
      return (options.standardItems || []).map(({ name, slug }) => ({
        name: translateSlug(allLabels)(slug) || name || slug,
        slug,
      }));
    default:
      return [];
  }
};

