import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { injectIntl } from 'react-intl';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import EditableComment from 'components/EditableComment';
import AnswerFooter from 'components/AnswerFooter';
import { useAvailableUnits } from 'utils/useAvailableUnits';
import { formatNumberWithIntl } from 'utils/formatNumber';

import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';
import {
  Row,
  Col,
  Divider,
  Modal,
} from 'antd';
import DataTargets from 'components/DataTargets';

const DEFAULT_INITIAL_VALUE = {
  value: null,
  unit: 'number',
};

// NOTICE: This must be the same as in src/components/TableAnswer/converters.js
const PERCENTAGE_SLUG = '__percentage';

const DECIMAL_SEPARATOR = {
  'es': ',',
  'es-ES': ',',
  'pt-PT': ',',
  'pt': ',',
  'pt-BR': ',',
  'fr-FR': ',',
  'ca-ES': ',',
  default: '.',
};

const TRUE_DECIMAL_SEPARATOR = '.';
const NOT_NUMBER_RE = new RegExp('\\D', 'g');
const NOT_NUMBER_OR_DECIMAL = new RegExp('[^\\d.]', 'g'); // TODO: Better
const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const printSign = (str) => {
  return `${str}`.startsWith('-') ? '-' : '';
};

const removeAdditionalDots = (str) => {
  let n = 0;
  return (str || '').replace(/\./g, function() { return n++ > 0 ? '' : TRUE_DECIMAL_SEPARATOR; });
};

// For decimals it's slightly complex
const DECIMAL_FORMATTER = intl => value => {
  const separator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;
  return printSign(value) + removeAdditionalDots(
    `${value}`
      .replace(NOT_NUMBER_OR_DECIMAL, '')
  ).replace(TRUE_DECIMAL_SEPARATOR, separator);
};
const DECIMAL_PARSER = intl => value => {
  const separator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;
  return printSign(value) + removeAdditionalDots(
    value
      .replace(separator, TRUE_DECIMAL_SEPARATOR)
      .replace(NOT_NUMBER_OR_DECIMAL, '')
  );
};

// For integers it's simpler
const INTEGER_FORMATTER = intl => (value = '') => printSign(value) + `${value}`.replace(NOT_NUMBER_RE, '');
const INTEGER_PARSER = intl => value => printSign(value) + value.replace(NOT_NUMBER_RE, '');

const useQuantitativeSchemaProps = (
  schema,
  _config,
  intl,
) => {
  const config = _config?.force_decimal_points || {};
  const forceDecimal = config.hasOwnProperty(schema.metricSlug)
    ? config[schema.metricSlug]
    : config['*']

  const decimalPoints = typeof forceDecimal === 'number'
    ? forceDecimal
    : (schema.decimalPoints || 0);

  const step = useMemo(() => {
    return 1 / (10 ** decimalPoints);
  }, [
    decimalPoints,
  ]);

  const min = useMemo(() => {
    if(typeof schema.minimum !== 'undefined' && schema.minimum !== null) {
      return Number(schema.minimum);
    }
    if(typeof schema.allowNegative === 'boolean' && !schema.allowNegative) {
      return 0;
    }
    return undefined;
  }, [
    schema,
  ]);

  const max = useMemo(() => {
    if(typeof schema.maximum !== 'undefined' && schema.maximum !== null) {
      return Number(schema.maximum);
    }
    return undefined;
  }, [
    schema,
  ]);

  const formatter = (decimalPoints > 0 ? DECIMAL_FORMATTER : INTEGER_FORMATTER)(intl);
  const parser = (decimalPoints > 0 ? DECIMAL_PARSER : INTEGER_PARSER)(intl);

  return {
    precision: decimalPoints,
    step,
    min,
    max,
    formatter,
    parser,
  }
};

const Component = injectIntl(({
  intl,
  config,
  schema,
  value: kpiValue,
  onChange,
}) => {
  const t = intl.messages;
  const availableUnits = useAvailableUnits(schema);

  const {
    unit = (availableUnits[0] || {}).slug,
    value = DEFAULT_INITIAL_VALUE.value,
  } = kpiValue || {};

  const handleValueChange = useCallback((value) => {
    onChange({
      ...kpiValue,
      value,
      unit,
    });
  }, [
    kpiValue,
    onChange,
    unit,
  ]);

  const handleUnitChange = useCallback((unit) => {
    onChange({
      ...kpiValue,
      value,
      unit,
    });
  }, [
    kpiValue,
    onChange,
    value,
  ]);

  const schemaProps = useQuantitativeSchemaProps(schema, config, intl);

  return (
    <Row
      type="flex"
      align="middle"
      gutter={15}
      style={{
        width: '100%',
      }}
    >
      <Col>
        <CustomInput.Number
          {...schemaProps}
          value={value}
          onChange={handleValueChange}
          className="KpiDetail__answer-quantitative-input-medium"
        />
      </Col>
      { !!availableUnits.length &&
      <Col>
        {
          availableUnits.length > 1
          ? <CustomSelect
              title={t.createcustomkpi_select_unit}
              selected={unit}
              options={availableUnits}
              onSelect={handleUnitChange}
              className="KpiDetail__answer-quantitative-unit-dropdown"
              renderOption={({ name, symbol }) => (
                symbol
                ? `${name} (${symbol})`
                : name
              )}
            />
          : availableUnits.length === 1 && availableUnits[0].symbol
          ? <span>{ availableUnits[0].symbol }</span>
          : null
        }
      </Col>
      }
    </Row>
  );
});

const TableComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema
  config,
  value: kpiValue,
  onChange,
  showPercentage = false,
}) => {
  const t = intl.messages;
  const availableUnits = useAvailableUnits(schema);

  const {
    unit = (availableUnits[0] || {}).slug,
    value = DEFAULT_INITIAL_VALUE.value,
    [PERCENTAGE_SLUG]: percentage,
    target_value = null
  } = kpiValue || {};

  const handleValueChange = useCallback((value) => {
    onChange({
      ...kpiValue,
      value,
      unit,
    });
  }, [
    kpiValue,
    onChange,
    unit,
  ]);

  const handleUnitChange = useCallback((unit) => {
    onChange({
      ...kpiValue,
      value,
      unit,
    });
  }, [
    kpiValue,
    onChange,
    value,
  ]);

  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  const schemaProps = useQuantitativeSchemaProps(schema, config, intl);
  const ifTargetValue = featureList && featureList.has('targets') && target_value !== null;

  return (
    <Row
      type="flex"
      gutter={[15, 15]}
      justify={ifTargetValue?'space-between':'start'}
    >
      <Col>
        <CustomInput.Number
          {...schemaProps}
          value={value}
          onChange={handleValueChange}
          className="KpiDetail__answer-quantitative-input-medium"
        />
      </Col>
      {
        !!availableUnits.length &&
        <Col>
          {
            availableUnits.length > 1
            ? <CustomSelect
                title={t.createcustomkpi_select_unit}
                selected={unit}
                options={availableUnits}
                onSelect={handleUnitChange}
                className="KpiDetail__answer-quantitative-table-unit-dropdown"
                renderOption={({ name, symbol }) => (
                  symbol
                  ? `${name} (${symbol})`
                  : name
                )}
              />
            : availableUnits.length === 1 && availableUnits[0].symbol
            ? <span>{ availableUnits[0].symbol }</span>
            : null
          }
        </Col>
      }
      {
        !showPercentage || typeof percentage === "undefined"
        ? null
        : (
          <Col
            className="KpiDetail__answer-quantitative-percentage"
          >
            {
              formatNumberWithIntl(intl)(
                percentage,
                {
                  style: 'percent',
                  maximumFractionDigits: 1,
                }
              )
            }
          </Col>
        )
      }
      {
        !ifTargetValue ? null
        : (
          <Col className='DataTarget'>
            <DataTargets.QuantitativeTableShow 
                target={target_value}
                kpi_value={kpiValue}
                schema={schema}
                config={config}
              />
           </Col>
        )
      }
    </Row>
  );
});

const Edit = ({
  intl,
  schema, // TODO: Obtain the metric info from the schema
  value: initialValue,
  comment: initialComment,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  onCancel,
  onSave,
  target,
  onTarget,
  onClear,
  config: kpiConfig
}) => {
  const {
    suborganization,
    permissions,
  } = useOrganizations();

  const { config = {} } = suborganization;
  
  const t = intl.messages;
  const availableUnits = useAvailableUnits(schema);

  const [ value, setValue ] = useState(initialValue || {
    value: DEFAULT_INITIAL_VALUE.value,
    unit: (availableUnits[0] || {}).slug,
  });
  const [ comment, setComment ] = useState(initialComment || '');
  const [ edittingComment, setEdittingComment ] = useState(false);
  const [ dirty, setDirty ] = useState(false);
  const [hasTarget, setHasTarget] = useState(false);

  const handleChange = useCallback((value) => {
    setDirty(true);
    setValue(value);
  }, []);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const isEmpty = useMemo(() => {
    return typeof (value || {}).value === 'undefined' ||
      value.value === null ||
      value.value === '';
  }, [
    value,
  ]);

  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '');
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear,
  ]);

  const handleSave = useCallback(() => {
    // TODO: Check 'dirty'
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      value,
      comment,
    );
  }, [
    dirty,
    value,
    comment,
    onSave,
  ]);
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  useEffect(()=>{
    if( featureList && featureList.has('targets') && target ){
      setHasTarget(true);
    }

  }, [target,featureList]);

  const hasTargetPermissions = useMemo(() => {
    return featureList && featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <Row>
          <Col span={6}>
          <Component
          schema={schema}
          config={config}
          value={value}
          onChange={handleChange}
          target={target}
        />
          </Col>
          <Col span={6}>
              <DataTargets
              schema = {schema}
              config = {config}
              kpi_value={value}
              target={target}
              justify="start"
            />
          </Col>
        </Row>

        <Divider />
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={kpiConfig}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
        onTarget={onTarget}
        hasTarget={hasTarget}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Edit.Component = Component;
Edit.TableComponent = TableComponent;

export default injectIntl(Edit);

