import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Title from './Title';
import A from 'components/A';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import AplanetIcon from 'components/AplanetIcon';
import {
  flattenTree,
  mapOverTreeFromLeafs,
  idField,
  childrenField,
} from 'utils/tree';

import {
  getParentsOfRSCategory,
  getParentsOfRSKpi,
} from 'utils/reporting_structure';
import useOrganizations from 'utils/useOrganizations';

import CustomCheckbox from 'components/CustomCheckbox';

import {
  Tree,
  message,
  Switch
} from 'antd';

import './style.less';
import { sortKpiStructure } from 'utils/kpi';


const ROOT_ID = '__root'; // Needs not to be a valid slug, hence __ 

const NO_FUNC = () => { }

const KpiTree = ({
  intl,
  categories,
  selectedCategory,
  setSelectedCategory = NO_FUNC,
  checkedCategories = [],
  setCheckedCategories = NO_FUNC,
  selectedKpi,
  setSelectedKpi = NO_FUNC,
  checkedKpis = [],
  setCheckedKpis = NO_FUNC,
  showOnlyCategories = false,
  isDirectory = false,
  search = '',
  previousSelectedCategories = [],
  subtreeCategories = [],
  expandedTreeCategories = [],
  onTreeUpdated,
  setShowNotApplies,
  clearSelection,
  reportingStructure,
  areFiltersSet,
  textFilterSet,
  enabled_reports,
  showNotApplyToggle = true,
  setShowCategoryWizard,
  setShowIndicatorWizard,
  isCreateMode=false,
  ...props
}) => {

  const {
    organization,
    isMainOrganization,
    permissions
  } = useOrganizations();

  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    if (search) setExpandedKeys([...expandedKeys, ...expandedTreeCategories])
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )

  const treeData = useMemo(
    () => {
      const treeDataUnsorted = mapOverTreeFromLeafs(categories)((reportingStructureNode, children = []) => {
        if (!!reportingStructureNode.category) {
          return {
            isCategory: true,
            title: reportingStructureNode.category.name,
            name: reportingStructureNode.category.name,
            name_translations: reportingStructureNode.category.name_translations,
            is_custom: reportingStructureNode.category.is_custom,
            slug: reportingStructureNode.category.slug,
            code: reportingStructureNode.category.code,
            standard: reportingStructureNode.category.standard,
            uuid: reportingStructureNode.category.uuid,
            key: reportingStructureNode.category.uuid,
            disabled: previousSelectedCategories.includes(reportingStructureNode.category.uuid) || subtreeCategories.includes(reportingStructureNode.category.uuid),
            checkable: showOnlyCategories || !!reportingStructureNode.kpis?.length || !!children.length,
            children: children.sort(sortKpiStructure(intl.locale)),
            parents: getParentsOfRSCategory(reportingStructure, reportingStructureNode.category.uuid),
          }
        }
        if (showOnlyCategories) return;

        const kpiCodes = (reportingStructureNode.standard_info || [])
                          .filter(({ standard }) => reportingStructureNode.is_custom || enabled_reports.includes(standard))
                          .map(({ code }) => code); 

        return {
          ...reportingStructureNode,
          title: reportingStructureNode.name,
          key: reportingStructureNode.uuid,
          isLeaf: true,
          code: kpiCodes.length ? kpiCodes[0] : undefined, 
          parents: getParentsOfRSKpi(reportingStructure, reportingStructureNode.uuid),
        };
      });

      return treeDataUnsorted.sort(sortKpiStructure(intl.locale));

    },
    [categories, previousSelectedCategories, reportingStructure, showOnlyCategories, subtreeCategories, intl.locale, enabled_reports]
  );

  //temporal patch - store tree state locally until we implement new design and solve performance problems
  const [treeNodes, setTreeNodes] = useState()
  useEffect(() => {
    setTreeNodes(treeData);
  }, [treeData])

  const handleCheck = useCallback(
    (_, { checked, checkedNodes, node }) => {
      let checkedCategoriesData = [...checkedCategories];
      let checkedKpisData = [...checkedKpis];
      checkedNodes.forEach(
        node => {
          if (node.isCategory && !checkedCategoriesData.includes(node.uuid)) {
            checkedCategoriesData.push(node.uuid)
          } else {
            !checkedKpisData.includes(node.uuid) && checkedKpisData.push(node.uuid);
          }
        }
      );
      if (!checked) {
        const checkedUuidsFromCurrentNodeAndParents = [...Object.keys(flattenTree(node, 'uuid')), ...node.parents];
        checkedCategoriesData = checkedCategoriesData.filter(uuid => !checkedUuidsFromCurrentNodeAndParents.includes(uuid))
        checkedKpisData = checkedKpisData.filter(uuid => !checkedUuidsFromCurrentNodeAndParents.includes(uuid))
      }
      setCheckedCategories(checkedCategoriesData);
      setCheckedKpis(checkedKpisData);
    },
    [checkedCategories, checkedKpis, setCheckedCategories, setCheckedKpis]
  );

  const handleSelect = useCallback(
    (_, { node }) => {
      if (node.isCategory) {
        setSelectedKpi(null);
        setSelectedCategory(node);
      }
      else {
        setSelectedCategory(null);
        setSelectedKpi(node.uuid)
      }
    },
    [setSelectedCategory, setSelectedKpi]
  );

  const checkedKeys = useMemo(
    () => showOnlyCategories ? [...checkedKpis, ...checkedCategories] : [...checkedKpis],
    [checkedCategories, checkedKpis, showOnlyCategories]
  );
  const selectedKey = useMemo(
    () => [selectedCategory?.uuid || selectedKpi?.uuid],
    [selectedCategory, selectedKpi]
  );

  const flatTreeData = useMemo(
    () => Object.values(
      flattenTree({
        slug: ROOT_ID,
        [idField]: ROOT_ID,
        [childrenField]: treeNodes || [],
      }, 'uuid')
    ).filter(({ slug }) => slug !== ROOT_ID),
    [treeNodes]
  );

  const allCategories = useMemo(
    () => flatTreeData
      .filter(({ children, disabled }) => !!children & !disabled).map(({ uuid }) => uuid)
    ,
    [flatTreeData]
  );

  const allKpis = useMemo(
    () => flatTreeData
      .filter(({ children }) => !children).map(({ uuid }) => uuid)
    ,
    [flatTreeData]
  );

  const areAllNodesSelected = useMemo(
    () => showOnlyCategories ? allCategories.length === checkedKeys.length : allKpis.length === checkedKeys.length,
    [allCategories.length, allKpis.length, checkedKeys.length, showOnlyCategories]
  );

  const handleOnToggleSelectAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setCheckedCategories(allCategories);
        setCheckedKpis && setCheckedKpis(allKpis);
      } else {
        clearSelection();
      }
    },
    [setCheckedCategories, allCategories, setCheckedKpis, allKpis, clearSelection]
  );

  const onDrop = useCallback(info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    if (info.dropToGap && info.dragNode.isLeaf) {
      return message.error(intl.formatMessage({ id: 'kpi_tree_drag_not_allowed' }))
    }

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...treeNodes];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeNodes(data)
    onTreeUpdated(data)
  }
    , [treeNodes, onTreeUpdated, intl])

  const handleOnClickExpand = useCallback((expandedKeysValues) => {
    setExpandedKeys(expandedKeysValues);
  }, []);

  const hasContent = Boolean(categories.length)
  const emptySearchAndFilter = (areFiltersSet || textFilterSet);
  const hasNewButton =
  (setShowCategoryWizard &&
    setShowIndicatorWizard &&
    (isMainOrganization || permissions.can_configure_kpi)) ||
  (!organization.config.features.restricted_admins &&
    permissions.can_affect_all_kpis);

  const emptyState = emptySearchAndFilter
    ? <Empty {...getEmptyResultsProps(textFilterSet, areFiltersSet)} />
    : <Empty {...emptyPropsMap.get("noTreeCreated")}>
        { hasNewButton && <ButtonGroup>
          <button           
            className="button--main"
            onClick={() => setShowCategoryWizard(true)} 
          >
            <AplanetIcon name='Add' />
            <FormattedMessage id="new" />
          </button>
        </ButtonGroup>}
      </Empty>

  return (
    <div className="KpiTree" data-testid="KpiTree">
      <div className="KpiTree__header">
        <div className="KpiTree__header__select-all">
          <CustomCheckbox
            checked={areAllNodesSelected}
            indeterminate={checkedKeys.length > 0 && !areAllNodesSelected}
            onChange={handleOnToggleSelectAll}
          />
          {areAllNodesSelected && isCreateMode
            ? intl.formatMessage({ id: 'datamanagement_config_all_selected' })
            : intl.formatMessage({ id: 'datamanagement_config_select_all' })
          }
        </div>
        <div className="KpiTree__header__remove-selection">
          {(checkedKeys.length > 0 || selectedCategory || selectedKpi) &&
            <A onClick={clearSelection}>
              {intl.formatMessage({ id: 'datamanagement_config_clear_selection' })}
            </A>
          }
        </div>
        {showNotApplyToggle && (
          <div className="KpiTree__header__apply-switcher">
            <Switch size="small" onChange={setShowNotApplies} />
            <span>{intl.formatMessage({ id: 'not_apply' })}</span>
          </div>
        )}
      </div>
      <div className="KpiTree__list">
        { hasContent 
          ? <Tree
            {...props}
            onDrop={onDrop}
            expandedKeys={expandedKeys}
            onExpand={handleOnClickExpand}
            multiple={showOnlyCategories || isDirectory}
            treeData={treeNodes}
            titleRender={nodeData => <Title nodeData={nodeData} enabled_reports={enabled_reports} searchText={search} expandedKeys={expandedKeys} showDetailedInfo={!selectedCategory && !selectedKpi && checkedKpis.length === 0} />}
            expandAction={false}
            checkable={true}
            selectable={true}
            onSelect={handleSelect}
            showIcon={false}
            onCheck={handleCheck}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKey}
            checkStrictly={showOnlyCategories}
          />
          : emptyState
        }
      </div>
    </div>
  );
}
export default injectIntl(KpiTree);
