import React from 'react';
import { Row } from 'antd';
//import useOrganizations from 'utils/useOrganizations';
import OrganizationArchiveType from 'components/NotificationType/OrganizationArchive';
import DefaultType from 'components/NotificationType/Default';

const COMPONENT_PER_TYPE = {
    org_archive_completed: OrganizationArchiveType,
    org_archive_pending: OrganizationArchiveType,
    org_archive_deleted: OrganizationArchiveType
}

const COMPONENT_DEFAULT = DefaultType

const NO_OP = () => {};

const Popup = ({
    intl,
    title,
    content,
    onNavigation = NO_OP
}) => {
    
    const Component = COMPONENT_PER_TYPE[title] || COMPONENT_DEFAULT;
return(
    <React.Fragment>
        <Row>
            <Component.Popup intl={intl} content={content} onNavigation={onNavigation} />
        </Row>

    </React.Fragment>
)};

const NotificationBody = ({
    intl,
    title,
    content,
    onNavigation = NO_OP
}) => {
    
    const Component = COMPONENT_PER_TYPE[title] || COMPONENT_DEFAULT;
return(
    <React.Fragment>
        <Row>
            <Component intl={intl} content={content} onNavigation={onNavigation} />
        </Row>

    </React.Fragment>
)

};


NotificationBody.Popup = Popup;
export default NotificationBody;
