import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { getDataRequestIdsByKpi, DATA_REQUEST_RECURRENCE } from 'utils/dataRequests';
import useOrganizations from 'utils/useOrganizations';
import { TableDataRequestsCellsProvider } from './context';

import DataRequestsKpiRow from './DataRequestsKpiRow';
import DataRequestsRow from './DataRequestsRow';
import DataRequestsRecurrentRows from './DataRequestsRecurrentRows';

const DataRequestsTableBody = ({
  dataRequests,
  handleCheckDataRequests,
  handleCheckKpi,
  handleCheckRecurrentRequests,
  handleOpenKpi,
  checkedDataRequestIds,
  handleDeleteDataRequests,
  handleSendReminderDataRequests,
  handleCopyDataRequests,
  selectedTableCustomView,
  canCopyData,
  canSendRequest
}) => {

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const isKpiChecked = useCallback((kpiSlug) => {
    const requestsIds = getDataRequestIdsByKpi(kpiSlug, dataRequests);
    return requestsIds.every(id => checkedDataRequestIds.includes(id));
    }, [
      checkedDataRequestIds,
      dataRequests
    ]
  );

  return (
    <tbody>
      { dataRequests.map(({
        kpi_name,
        kpi_name_translations,
        kpi_slug,
        kpi_uuids,
        standard_info,
        categories,
        schema,
        schemaLabels,
        requests,
      }) => (
        <React.Fragment key={kpi_uuids[0]}>
          <DataRequestsKpiRow
            kpiSlug={kpi_slug}
            kpiName={kpi_name || kpi_name_translations[suborganization.language]}
            kpiStandardInfo={standard_info}
            categories={categories}
            handleCheckKpi={handleCheckKpi}
            handleOpenKpi={handleOpenKpi}
            isKpiChecked={isKpiChecked(kpi_slug)}
            selectedTableCustomView={selectedTableCustomView}
          />
          {requests.map((request, index) => {
            const {
              pack_uuid,
              type,
              periodicity,
              requesterName,
              requesterEmail,
              requesterAvatar,
              periods,

              notif_number,
              notif_time,
              notif_relative,
            } = request;
            return (
              <React.Fragment key={`${kpi_uuids[0]}_request_${index}`}>
                <TableDataRequestsCellsProvider
                  value={{
                    organizationSlug: organization.slug,
                    suborganizationSlug: suborganization.slug,
                    suborganizationLogo: suborganization.logo_small,
                    suborganizationName: suborganization.name,
                    suborganizationConfig: suborganization.config || {},
                    type,
                    schema,
                    schemaLabels,
                    kpi_slug,
                    kpi_name: kpi_name || kpi_name_translations[suborganization.language],
                    kpi_uuid: kpi_uuids[0],
                    kpiStandardInfo: standard_info,
                    pack_uuid,
                    periodicity,
                    periods,
                    requesterName,
                    requesterEmail,
                    requesterAvatar,
                    notif_number,
                    notif_time,
                    notif_relative,
                    handleCheckDataRequests,
                    handleCheckRecurrentRequests,
                    checkedDataRequestIds,
                    handleOpenKpi,
                    handleDeleteDataRequests,
                    handleCopyDataRequests,
                    selectedTableCustomView,
                    canCopyData,
                    canSendRequest,
                    handleSendReminderDataRequests
                  }}
                >
                  {type === DATA_REQUEST_RECURRENCE.one_time ? (
                    <DataRequestsRow />
                  ) : null}
                  {type === DATA_REQUEST_RECURRENCE.recurrent ? (
                    <DataRequestsRecurrentRows />
                  ) : null}
                </TableDataRequestsCellsProvider>
              </React.Fragment>
            )
          })}
        </React.Fragment>
      ))}
    </tbody>
  );
};

DataRequestsTableBody.propTypes = {
  dataRequests: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  handleCheckDataRequests: PropTypes.func.isRequired,
  handleCheckKpi: PropTypes.func.isRequired,
  handleCheckRecurrentRequests: PropTypes.func.isRequired,
  handleOpenKpi: PropTypes.func.isRequired,
  checkedDataRequestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleDeleteDataRequests: PropTypes.func.isRequired,
  handleCopyDataRequests: PropTypes.func.isRequired,
  selectedTableCustomView: PropTypes.arrayOf(PropTypes.string).isRequired,
  canCopyData: PropTypes.bool,
  canSendRequest: PropTypes.bool,
};

export default DataRequestsTableBody;