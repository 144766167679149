const TARGET = 'reporting_structure_kpi';
const isTarget = (target) => [
  TARGET,
].includes(target);
const initialState = {
  data: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      return {
        data: action.response.result,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export {
  reducer as reporting_structure_kpi,
};
