import {
  useReducer,
  useMemo,
  useCallback,
} from 'react';

import {
  adaptInitialState as adaptTableState,
  getReducer as getTableReducer,
} from './useTableState';

import {
  adaptInitialState as adaptTupleState,
  getReducer as getTupleReducer,
} from './useTupleState';

const DEFAULT_VALUES = {
  qualitative: { text: '' },
  quantitative: { value: null, unit: 'number' },
  choice: { choice: undefined },
  boolean: { boolean: undefined },
  default: {},
};

const NO_DEPENDEES = [];

const adaptInitialState = (props, initialState) => {
  //const { schema = {}, tableDimensions, slug, organization, organization_parent, period, dependees = [] } = props || {};
  const { schema } = props || {};

  switch(schema?.type) {
    case 'table':
      return adaptTableState(props, initialState)
    case 'tuple':
      return adaptTupleState(props, initialState)
    default:
      return initialState
  }
};

const defaultReducer = (type) => (state, action) => {
  switch(action.type) {
    case 'UPDATE':
      return action.value;
    case 'DELETE':
      return DEFAULT_VALUES[type] || DEFAULT_VALUES.default;
    default:
      return state;
  }
};

const getReducer = ({
  schema,
  tableDimensions,
  slug,
  organization,
  organization_parent,
  period,
  dependees = [],
}) => {
  switch(schema?.type) {
    case 'table':
      return getTableReducer({
        schema,
        tableDimensions,
        slug,
        organization,
        organization_parent,
        period,
        dependees,
      })
    case 'tuple':
      return getTupleReducer({ schema, slug })
    default:
      return defaultReducer(schema?.type);
  }
};

export const useAnswerState = ({
  schema,
  tableDimensions,
  initialState: _initialState,
  slug,
  organization,
  organization_parent,
  period,
  dependees = NO_DEPENDEES,
}) => {
  const initialState = adaptInitialState({
    schema,
    tableDimensions,
    slug,
    organization,
    organization_parent,
    period,
    dependees,
  },_initialState);

  const reducer = useMemo(
    () => getReducer({
      schema,
      tableDimensions,
      slug,
      organization,
      organization_parent,
      period,
      dependees,
    }),
    [
      schema,
      tableDimensions,
      slug,
      organization,
      organization_parent,
      period,
      dependees,
    ]
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const update = useMemo(() => {
    const components = schema?.components || {};
    switch(schema?.type) {
      case 'table':
        return (address, value) => dispatch({ type: 'UPDATE', address, value });
      case 'tuple':
        return (name, ...params) => {
          if(components[name] && components[name].type === 'table') {
            dispatch({
              type: 'UPDATE_TABLE',
              name,
              address: params[0],
              value: params[1],
            });
          } else {
            dispatch({
              type: 'UPDATE',
              name,
              value: params[0],
            });
          }
        };
      default:
        return (value) => dispatch({ type: 'UPDATE', value });
    }
  }, [
    schema,
    dispatch,
  ]);

  const _delete = useCallback(() => {
    const components = schema?.components || {};
    switch(schema?.type) {
      case 'table':
        return (address) => dispatch({ type: 'DELETE', address });
      case 'tuple':
        return (name, ...params) => {
          if(components[name] && components[name].type === 'table') {
            dispatch({
              type: 'DELETE_TABLE',
              name,
              address: params[0],
            });
          } else {
            dispatch({
              type: 'DELETE',
              name,
            });
          }
        };
      default:
        return () => dispatch({ type: 'DELETE' });
    }
  }, [
    schema,
    dispatch,
  ]);

  return {
    update,
    delete: _delete,
    values: state,
  };
};

