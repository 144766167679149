import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { withRouter } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Collapse } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import {
  fetchRequestsStatusProgress,
  fetchSuborgsDataRequests,
  fetchDataRequests,
  saveQueryFilters,
} from 'actions/thirdParty';
import { queryStringToObject } from 'utils/queryParameters';
import {
  DATA_REQUEST_STATUS,
} from 'utils/dataRequests';
import {
  getOrderedSuborganizationPath
} from 'utils/organization';
import {
  FILTER_TYPES,
  getQueryFilters,
  SCREENS
} from 'hooks/useScreenFilters/constants';
import useScreenFilters from 'hooks/useScreenFilters';
import { useEventTracking } from 'hooks/useEventTracking';
import useScreenSize from 'utils/useScreenSize';

import { Loading } from 'tsComponents/emptyStates/Loading';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { Error } from 'tsComponents/emptyStates/Error';
import { emptyPropsMap, getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';
import { getErrorProps } from 'tsComponents/emptyStates/errorProps';
import { goBack, useContactUs} from 'tsComponents/emptyStates/helpers';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import MainLayout from 'components/MainLayout';
import Sidemenu from './Sidemenu';
import Header from './Header';
import Filters from './Filters';
import ProgressBars from 'components/ProgressBars';
import AplanetIcon from 'components/AplanetIcon';
import OrganizationPath from 'components/OrganizationPath';
import KpiRequestsCard from 'components/KpiRequestsCard';
import CustomModalType from 'components/CustomModalType';

import './style.less';

const { Panel } = Collapse;

const FILLED_STATUS = 'filled';

// TODO: System to handle backend errors on the frontend
const ERROR_CODES = {
  forbidden: 403,
  notFound: 404,
  internalServerError: 500
};

const ThirdPartyRequests = ({
  match,
  history,
  location
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();
  const screenSize = useScreenSize();

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [lastLoadedPagesBySuborg, setLastLoadedPagesBySuborg] = useState({});

  const defaultOpenSuborganizations = useMemo(() => {
    const queryStringObject = queryStringToObject(location?.search);
    return queryStringObject?.suborganizations?.split(',') || [];
  }, [
    location?.search
  ]);

  const [openSuborgs, setOpenSuborgs] = useState(defaultOpenSuborganizations);

  const {
    topLevelOrg,
    queryFiltersObj,
    selector_orgs,
    profile,
    filters_config,
    tree,
    orgs_with_dr,
    requests_by_organization,
    status_progress,
    initialLoading,
    statusProgressLoading,
    loading,
    code,
  } = useSelector(state => state.third_party);

  const suborgsFilterDefaultValues = useMemo(
    () => filters_config?.find(({name}) => name === FILTER_TYPES.suborganizations_filter)?.values || [],
    [filters_config]
  );

  const {
    values: [
      [filteredSuborganizations, isSuborganizationFilterSet],
      [filteredPeriodicities, isPeriodicityFilterSet],
      [periodDateFilter, isPeriodDateFilterSet],
      [requestDateFilter, isRequestDateFilterSet],
      [responseDateFilter, isResponseDateFilterSet],
      [filteredRequestStatus, isRequestStatusFilterSet],
      [kpiNameSearch, isKpiNameSearchSet]
    ],
    handleFilter,
    filterState,
    resetAllFilters,
    setAllFilters,
  } = useScreenFilters({
    screen: SCREENS.third_party_requests,
    defaultFiltersSet: {
      [FILTER_TYPES.suborganizations_filter]: {
        defaultValue: suborgsFilterDefaultValues,
        condition: (set) => set.length !== suborgsFilterDefaultValues.length
      },
    }
  });

  const areFiltersSet = useMemo(
    () => isSuborganizationFilterSet || isPeriodicityFilterSet || isPeriodDateFilterSet
    || isRequestDateFilterSet || isResponseDateFilterSet || isRequestStatusFilterSet,
    [
      isSuborganizationFilterSet,
      isPeriodicityFilterSet,
      isPeriodDateFilterSet,
      isRequestDateFilterSet,
      isResponseDateFilterSet,
      isRequestStatusFilterSet,
  ]);

  useEffect(() => {
    const filterValues = {
      [FILTER_TYPES.suborganizations_filter]: filteredSuborganizations,
      [FILTER_TYPES.periodicity_filter]: filteredPeriodicities,
      [FILTER_TYPES.period_date_filter]: periodDateFilter,
      [FILTER_TYPES.request_date_filter]: requestDateFilter,
      [FILTER_TYPES.response_date_filter]: responseDateFilter,
      [FILTER_TYPES.request_status_filter]: filteredRequestStatus.includes(FILLED_STATUS)
      ? [...filteredRequestStatus.filter(s => s !== FILLED_STATUS), DATA_REQUEST_STATUS.done, DATA_REQUEST_STATUS.in_use, DATA_REQUEST_STATUS.partially_in_use]
      : filteredRequestStatus, //NOTICE: 'filled' corresponde a 'done', 'in-use' y 'partially-in-use'
      [FILTER_TYPES.text_filter]: kpiNameSearch?.includes('?') ? kpiNameSearch.replaceAll('?', '') : kpiNameSearch, // NOTICE: Delete question mark from text search because knex does not work properly
    };
    const queryFilters = getQueryFilters(SCREENS.third_party_requests, filterValues);
    dispatch(saveQueryFilters(queryFilters));
  }, [
    dispatch,
    filteredPeriodicities,
    filteredRequestStatus,
    filteredSuborganizations,
    kpiNameSearch,
    periodDateFilter,
    responseDateFilter,
    requestDateFilter
  ]);

  const { token } = match.params;

  useEffect(() => {
    if (queryFiltersObj?.suborganizations?.length && topLevelOrg) {
      dispatch(fetchRequestsStatusProgress(token, topLevelOrg, queryFiltersObj));
    }
  }, [
    intl,
    dispatch,
    queryFiltersObj,
    token,
    topLevelOrg
  ]);

  useEffect(() => {
    const { suborganizations, ...restQueryFilters } = queryFiltersObj;
    if (suborganizations?.length && topLevelOrg) {
      dispatch(fetchSuborgsDataRequests(token, topLevelOrg, suborganizations, restQueryFilters));
      setLastLoadedPagesBySuborg((prevState) => {
        const newState = {};
        suborganizations.forEach(suborg => {
          newState[suborg] = 1; // NOTICE: reset to first page
        });
        return {
          ...prevState,
          ...newState,
        };
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryFiltersObj]);

  const currentTopOrganization = useMemo(
    () => selector_orgs?.find(({slug}) => slug === topLevelOrg),
    [selector_orgs, topLevelOrg]
  );

  const onSelectTopOrganization = useCallback((selectedOrganization) => {
    resetAllFilters(false);
    history.push(`/request/${token}?toplevelorg=${selectedOrganization}`);
    history.go(0)
  }, [resetAllFilters, history, token]);

  const onClickRequestsList = useCallback(() => {
    history.push(`/request/${token}?toplevelorg=${topLevelOrg}`);
  }, [
    history,
    token,
    topLevelOrg
  ]);
  
  const onChangeKpiSearch = useCallback((term) => {
    handleFilter(FILTER_TYPES.text_filter)(term)
  }, [
    handleFilter,
  ]);

  const handleLoadMore = useCallback((suborganization, currentPage, nextPage) => {
    // This is a dirty workaround because react-infinite-scroller sucks...
    if(topLevelOrg && nextPage && (currentPage === lastLoadedPagesBySuborg[suborganization] || !lastLoadedPagesBySuborg[suborganization])) {
      const { suborganizations, ...restQueryFilters } = queryFiltersObj;
      dispatch(
        fetchDataRequests(token, topLevelOrg, suborganization, nextPage, restQueryFilters)
      );
      setLastLoadedPagesBySuborg((prevState) => ({
        ...prevState,
        [suborganization]: nextPage
      }));
    }
  }, [
    dispatch,
    lastLoadedPagesBySuborg,
    queryFiltersObj,
    token,
    topLevelOrg
  ]);

  const filters = useMemo(() => {
    if (filters_config?.length) {
      const suborganizationFilterValues = (orgs_with_dr || []).map((suborganization) => getOrderedSuborganizationPath(tree, suborganization));
      const restFilters = filters_config?.filter(({name}) => name !== FILTER_TYPES.suborganizations_filter);
      return [
        ...restFilters,
        {
          ...filters_config?.find(({name}) => name === FILTER_TYPES.suborganizations_filter),
          values: suborganizationFilterValues,
        }
      ];
    }
  }, [
    filters_config,
    orgs_with_dr,
    tree
  ]);

  const progressBarsConfig = useMemo(() => [
    {
      data: [{
        id: FILLED_STATUS,
        count: queryFiltersObj?.suborganizations?.length && status_progress.responsed ? status_progress.responsed : 0,
        total: queryFiltersObj?.suborganizations?.length && status_progress.total ? status_progress.total : 0,
        label: intl.formatMessage({id: "third_party_requests_status_filled"}),
        iconProps: {
          name: 'Data management',
          faStyle: 'fad'
        },
      }],
      numberFormat: 'countOfTotal',
      span: 24,
    }
  ], [
    intl,
    queryFiltersObj?.suborganizations?.length,
    status_progress.responsed,
    status_progress.total
  ]);

  const hasDataRequestsToShow = useMemo(() => {
    return orgs_with_dr?.length && requests_by_organization
      ? filteredSuborganizations.some(suborgSlug => {
        const kpis = requests_by_organization[suborgSlug]?.data.reduce((acc, el) => [...acc, ...el.kpis], []);
        return kpis?.length;
      })
      : false;
  }, [
    orgs_with_dr?.length,
    filteredSuborganizations,
    requests_by_organization
  ]);

  const currentOpenSuborgs = useMemo(() => {
    if (requests_by_organization) {
      return filteredSuborganizations.filter(suborgSlug => {
        const kpis = requests_by_organization[suborgSlug]?.data.reduce((acc, el) => [...acc, ...el.kpis], []);
        return kpis?.length && openSuborgs.includes(suborgSlug);
      });
    }
    return openSuborgs;
  }, [
    filteredSuborganizations,
    openSuborgs,
    requests_by_organization
  ]);

  const handleOpenRequest = useCallback((suborganization, dataRequestId) => {
    window.open(`/request/${token}/data_request/${dataRequestId}?toplevelorg=${topLevelOrg}&suborganization=${suborganization}`, "_blank");
  }, [token, topLevelOrg]);

  const totalSuborganizations = useMemo(() => {
    const suborganizationFilter = filters?.find(({name})=> name === FILTER_TYPES.suborganizations_filter);
    return suborganizationFilter?.values?.length || 0;
  }, [filters]);

  const onToggleShowProgress = useCallback((showProgress) => {
    eventTracking.capture(
      'dataRequestExternal.progressBarsToggleShow',
      {
        organization_id: currentTopOrganization?.id,
        organization_name: currentTopOrganization?.name,
        organization_slug: currentTopOrganization?.slug,
        suborganization_id: currentTopOrganization?.id,
        suborganization_name: currentTopOrganization?.name,
        suborganization_slug: currentTopOrganization?.slug,
        visibility: showProgress ? 1 : 0,
      }
    );
  }, [
    currentTopOrganization?.id,
    currentTopOrganization?.name,
    currentTopOrganization?.slug,
    eventTracking
  ]);

  const contactUs = useContactUs()

  if(code === ERROR_CODES.forbidden) {
    return <Empty {...emptyPropsMap.get("noAccess")} />
  }

  if(initialLoading) {
    return(
      <Loading />
    );
  }

  if(code === ERROR_CODES.internalServerError) {
    return <Error {...getErrorProps("internalServerError")}>
      <ButtonGroup>
        <button className='button--secondary' onClick={goBack}>
          <AplanetIcon name='Arrow to left' />
          <FormattedMessage id="back" />
        </button>
        <a className='button--primary' href={`mailto:${contactUs}`}>
          <AplanetIcon name='Contact' />
          <FormattedMessage id="nav_contact_us" />
        </a>
      </ButtonGroup>
    </Error>
  }

  return(
    <section className='ThirdPartyRequests'>
      <MainLayout
        className='ThirdPartyRequests__main-layout'
        currentOrganization={currentTopOrganization}
        disableOnClickInSiderLogo
        hideOrgLogoinSider={!selector_orgs?.length}
        nav={
          // NOTICE: For the moment in Sidemenu we only have requests list
          <Sidemenu onClickMenuItem={onClickRequestsList} />
        }
        header={profile ? (
            <Header
              onSelectOrganization={onSelectTopOrganization}
              profile={profile}
              organizations={selector_orgs}
              currentTopOrganization={currentTopOrganization}
            />
          ) : null
        }
        hideHeader={!profile}
      >
      {screenSize === 'sm' || screenSize === 'xs' ? (<Empty {...emptyPropsMap.get("landingMobile")} />) : (
        <section className='ThirdPartyRequests__container'>
          <span className='ThirdPartyRequests__title'>
            {currentTopOrganization?.name
              ? intl.formatMessage({id: 'third_party_requests_title_org'}, {topOrganization: currentTopOrganization?.name})
              : intl.formatMessage({id: 'third_party_requests_title'})
            }
          </span>
          <>
            <Filters
              filters={filters}
              setAllFilterState={setAllFilters}
              filterState={filterState}
              resetAllFilters={resetAllFilters}
              areFiltersSet={areFiltersSet}
              isKpiNameSearchSet={isKpiNameSearchSet}
              kpiNameSearch={kpiNameSearch}
              onChangeKpiSearch={onChangeKpiSearch}
              organization={currentTopOrganization}
              totalSuborganizations={totalSuborganizations}
              disabledFilters={!orgs_with_dr?.length}
            />
            <div className='ThirdPartyRequests__content'>
            {loading || statusProgressLoading ? (<Loading />) : (
              <>
                <ProgressBars
                  config={progressBarsConfig}
                  hideShowButtonPlacement="topRight"
                  onToggleShowProgress={onToggleShowProgress}
                  defaultShowProgressBars
                  hasHideShowButton
                  isPercentFormat={false}
                />
                <section className='ThirdPartyRequests__organizations'>
                  {hasDataRequestsToShow ? (
                    <Collapse
                      className="ThirdPartyRequests__organizations-collapse"
                      expandIcon={({ isActive }) => (
                        <AplanetIcon className="ThirdPartyRequests__expand-icon" name={isActive ? "Chevron down" : "Chevron right"} />
                      )}
                      onChange={(open) => setOpenSuborgs(open)}
                      activeKey={currentOpenSuborgs}
                      bordered={false}
                    >
                      {orgs_with_dr?.filter(({slug}) => filteredSuborganizations.includes(slug)).map(suborg => {
                        const kpis = requests_by_organization[suborg.slug]?.data.reduce((acc, el) => [...acc, ...el.kpis], []) || [];
                        return (
                          <Panel
                            key={suborg.slug}
                            className={`ThirdPartyRequests__organization-info ${kpis.length ? '' : 'is-disabled'}`}
                            header={(
                              <OrganizationPath
                                className={`ThirdPartyRequests__organization-path ${kpis.length ? '' : 'is-disabled'}`}
                                slug={suborg.slug}
                                logo_small={suborg.logo_small}
                                name={suborg.name}
                                sortedSuborganizations={getOrderedSuborganizationPath(tree, suborg)}
                              />
                            )}
                            collapsible={kpis.length ? null : 'disabled'}
                          >
                              {kpis.length ? (
                                <div className="ThirdPartyRequests__kpi-cards">
                                  <InfiniteScroll
                                    pageStart={2} // NOTICE: the first page to load is 2 because in fetchConfigData the page 1 is loaded
                                    loadMore={() => handleLoadMore(suborg.slug, requests_by_organization[suborg.slug].currentPage, requests_by_organization[suborg.slug].nextPage)}
                                    hasMore={!requests_by_organization[suborg.slug].loading && !!requests_by_organization[suborg.slug].nextPage}
                                    useWindow={false}
                                  >
                                    {kpis.map(({
                                      slug,
                                      name,
                                      standard_info,
                                      sdgs,
                                      type,
                                      requests,
                                    }) => (
                                      <div key={slug} className="ThirdPartyRequests__kpi-card">
                                        <KpiRequestsCard
                                          name={name}
                                          standardInfo={standard_info}
                                          sdgs={sdgs}
                                          esgType={type}
                                          requests={requests}
                                          onClickOpenRequest={(requestId, valueSource) => valueSource !== 'manual' ? setShowWarningModal(true) : handleOpenRequest(suborg.slug, requestId)}
                                          hideKpiNoManual={!!filteredRequestStatus.length}
                                        />
                                      </div>
                                    ))}
                                  </InfiniteScroll>
                                </div>
                              ) : null}
                          </Panel>
                      )})}
                    </Collapse>
                  ) : (!orgs_with_dr?.length || code === ERROR_CODES.notFound ? (<Empty {...emptyPropsMap.get("noRequestThird")} />) : (<Empty {...getEmptyResultsProps(isKpiNameSearchSet, areFiltersSet)} />))}
                </section>
                {showWarningModal ? (
                  <CustomModalType
                      showModal={showWarningModal} 
                      type="warning" 
                      iconType="fad"
                      title={intl.formatMessage({id: 'third_party_requests_permit_changed_modal_title'})}
                      icon="Alert"
                      onCancelText= {intl.formatMessage({id: 'third_party_requests_permit_changed_modal_cancel'})}
                      onCancel={() => setShowWarningModal(false)}
                      content={
                        <section className="ThirdPartyRequests__warning-modal">
                          <span className="ThirdPartyRequests__warning-modal-message">{intl.formatMessage({id: 'third_party_requests_permit_changed_modal_message'})}</span>
                          <span className="ThirdPartyRequests__warning-modal-message-bold">{intl.formatMessage({id: 'third_party_requests_permit_changed_modal_bold_message'})}</span>
                        </section>
                      }
                    />
                  ) : null}
                </>
              )}
              </div>
            </>
        </section>
      )}
      </MainLayout>
    </section>
  );
}

export default withRouter(ThirdPartyRequests);
