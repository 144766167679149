import React, { useMemo, useCallback, useState } from "react";
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Collapse, Checkbox } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { formatMessage } from '../../intl';
import ReportingStandardTag from "components/ReportingStandardTag";
import CustomTag from "components/CustomTag";
import Required from "components/Required";
import PeriodicitiesBulkSelector from "../PeriodicitiesBulkSelector";
import CustomSelect from "../CustomSelect";
import CustomDatepicker from "../CustomDatepicker";
import AplanetIcon from "components/AplanetIcon";
import {
  formatDateShort,
} from 'utils/date';

import './style.less';

const { Panel } = Collapse;

const DEFAULT_CYCLE_DATE_OPTIONS = ['01-01'];

const ConfigureTimeSelector = ({
  handleChange,
  organization,
  suborganization,
  intl,
  values,
  kpisAlreadyAdded,
  kpisNotAlreadyAdded
}) => {

  const [year, setYear] = useState()
  const [cycle_date, setCycleDate] = useState()
  const [differentFrequencies, setdDifferentFrequencies] = useState()
  const [differentStartDates, setDifferentStartDates] = useState()

  const cycleDateOptions = useMemo(() => {
    if (suborganization.slug === organization.slug) {
      // Top level org, all options are possible
      return organization?.config?.cycle_date_options || DEFAULT_CYCLE_DATE_OPTIONS;
    }

    // Suborgs only get the one possible cycle date
    // But notice this will never be used because for now you can only create KPIs in a top-level org
    return (
      suborganization?.config?.start_fiscal_year && [suborganization?.config?.start_fiscal_year]
    ) || (
        organization?.config?.cycle_date_options?.length > 0
        && [organization?.config?.cycle_date_options[0]]
      ) || DEFAULT_CYCLE_DATE_OPTIONS;
  }, [
    organization,
    suborganization,
  ]);

  const cycleDateOptionsTranslated = useMemo(() => {
    return (cycleDateOptions || []).map(slug => ({
      slug,
      name: formatDateShort(`1970-${slug} 12:00:00.000000Z`, intl),
    }));
  }, [
    cycleDateOptions,
    intl,
  ]);

  const handleAllPeriodicities = period => handleChange('selected_kpis')(values.selected_kpis.map(kpi => kpisNotAlreadyAdded.some(k => k.uuid === kpi.uuid) ? { ...kpi, periodicities: [period] } : kpi))

  const handleCycleDateChange = useCallback((cycle_date) => {
    const newValue = !cycle_date
      ? null
      : !year
        ? `${(new Date()).getFullYear()}-${cycle_date}`
        : `${year}-${cycle_date}`
    setCycleDate(cycle_date)
    handleChange('selected_kpis')(values.selected_kpis.map(kpi => kpisNotAlreadyAdded.some(k => k.uuid === kpi.uuid) ? { ...kpi, cycle_date, annual_start_date: newValue } : kpi))
  }, [handleChange, kpisNotAlreadyAdded, values.selected_kpis, year]);

  const handleKpiCycleDateChange = useCallback((cycle_date, kpi) => {
    const newValue = !cycle_date
      ? null
      : !kpi.year
        ? `${(new Date()).getFullYear()}-${cycle_date}`
        : `${kpi.year}-${cycle_date}`
    handleChange('selected_kpis')(values.selected_kpis.map(k => k.uuid === kpi.uuid ? { ...k, cycle_date, annual_start_date: newValue } : k))
  }, [handleChange, values.selected_kpis]);

  const handleYearChange = useCallback((_year) => {
    const year = _year && moment(_year).year();
    const newValue = !year || (!cycle_date && !cycleDateOptions[0])
      ? null
      : !cycle_date
        ? `${year}-${cycleDateOptions[0]}`
        : `${year}-${cycle_date}`
    setYear(year)
    handleChange('selected_kpis')(values.selected_kpis.map(kpi => kpisNotAlreadyAdded.some(k => k.uuid === kpi.uuid) ? { ...kpi, year, annual_start_date: newValue } : kpi))
  }, [cycleDateOptions, cycle_date, handleChange, kpisNotAlreadyAdded, values.selected_kpis]);

  const handleKpiYearChange = useCallback((_year, kpi) => {
    const year = _year && moment(_year).year();
    const newValue = !year || (!kpi.cycle_date && !cycleDateOptions[0])
      ? null
      : !kpi.cycle_date
        ? `${year}-${cycleDateOptions[0]}`
        : `${year}-${kpi.cycle_date}`
    handleChange('selected_kpis')(values.selected_kpis.map(k => k.uuid === kpi.uuid ? { ...k, year, annual_start_date: newValue } : k))
  }, [cycleDateOptions, handleChange, values.selected_kpis]);



  return (
    <div
      className="ConfigureTimeSelector"
    >
      <h3 className="ConfigureTimeSelector_EnabledTitle">{formatMessage('configuretimeselector_title')}</h3>
      <CustomTag name={formatMessage('configuretimeselector_selected', { x: values.selected_kpis.length })} colorclass="org" style={{ width: 'fit-content' }} />
      {
        kpisAlreadyAdded.length > 0 && (
          <div ConfigureTimeSelector className="ConfigureTimeSelector_Warning">
            <div><AplanetIcon name="Alert" faStyle="fad" style={{ color: '#1677FF', marginRight: "8px" }} /><b>{formatMessage('configuretimeselector_warning')}</b></div>
            <Collapse defaultActiveKey={['1']} ghost style={{ display: "inline-block" }} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
              <Panel header={formatMessage('configuretimeselector_collapse')} key="1">
                <ul>
                  {kpisAlreadyAdded.map((kpi) => (
                    <li key={kpi.uuid}>{kpi.name}</li>
                  ))}
                </ul>
              </Panel>
            </Collapse>
            <span style={{ paddingLeft: "16px" }}>{formatMessage('configuretimeselector_warning2')}</span>
          </div>
        )
      }
      {
        kpisAlreadyAdded.length !== values.selected_kpis.length && (
          <>
            <div className="ConfigureTimeSelector_AllKpi">
              <span>{formatMessage('configuretimeselector_info')}</span>
              <h3 className={differentFrequencies ? 'ConfigureTimeSelector_DisabledTitle' : 'ConfigureTimeSelector_EnabledTitle'}>{formatMessage('configuretimeselector_title2')}<Required /></h3>
              <PeriodicitiesBulkSelector disabled={differentFrequencies} onChange={handleAllPeriodicities} />
              {kpisNotAlreadyAdded.length > 1 && (
                <div className="ConfigureTimeSelector_CheckBox">
                  <Checkbox checked={differentFrequencies} onChange={e => setdDifferentFrequencies(e.target.checked)} />
                  <span>{formatMessage('configuretimeselector_checkbox')}</span>
                </div>
              )}
              <h3 className={differentStartDates ? 'ConfigureTimeSelector_DisabledTitle' : 'ConfigureTimeSelector_EnabledTitle'} >{formatMessage('configuretimeselector_title3')}<Required /></h3>
              <span>{formatMessage('configuretimeselector_info2')}<Required /></span>
              <div className="ConfigureTimeSelector_CycleDate">
                <CustomSelect
                  disabled={differentStartDates}
                  options={cycleDateOptionsTranslated}
                  onSelect={handleCycleDateChange}
                  style={{ maxWidth: '250px', marginRight: '40px' }}
                />
                <CustomDatepicker
                  disabled={differentStartDates}
                  picker="year"
                  onChange={handleYearChange}
                  allowClear={false}
                />
              </div>
              {kpisNotAlreadyAdded.length > 1 && (
                <div className="ConfigureTimeSelector_CheckBox">
                  <Checkbox checked={differentStartDates} onChange={e => setDifferentStartDates(e.target.checked)} />
                  <span>{formatMessage('configuretimeselector_checkbox2')}</span>
                </div>
              )}
            </div>
            {differentFrequencies && kpisNotAlreadyAdded && (
              <div className="ConfigureTimeSelector_DifferentFrequency">
                <h3 className="ConfigureTimeSelector_EnabledTitle">{formatMessage('configuretimeselector_title4')}<Required /></h3>
                {kpisNotAlreadyAdded.map(kpi => (
                  <div key={kpi.uuid} className="ConfigureTimeSelector_Kpi">
                    <div className="ConfigureTimeSelector_Kpi_Title">
                      {kpi.standard && kpi.code && <ReportingStandardTag standard={kpi.standard} code={kpi.code} />}
                      <h4>{kpi.name}</h4>
                    </div>
                    <PeriodicitiesBulkSelector
                      value={kpi.periodicities && kpi.periodicities[0]}
                      onChange={period => handleChange('selected_kpis')(values.selected_kpis.map(k => k.uuid === kpi.uuid ? { ...k, periodicities: [period] } : k))}
                    />
                  </div>
                ))}
              </div>
            )}
            {differentStartDates && kpisNotAlreadyAdded && (
              <div className="ConfigureTimeSelector_DifferentDate">
                <h3 className="ConfigureTimeSelector_EnabledTitle">{formatMessage('configuretimeselector_title5')}<Required /></h3>
                {kpisNotAlreadyAdded.map(kpi => (
                  <div key={kpi.uuid} className="ConfigureTimeSelector_Kpi">
                    <div className="ConfigureTimeSelector_Kpi_Title">
                      {kpi.standard && kpi.code && <ReportingStandardTag standard={kpi.standard} code={kpi.code} />}
                      <h4>{kpi.name}</h4>
                    </div>
                    <div className="ConfigureTimeSelector_CycleDate">
                      <CustomSelect
                        options={cycleDateOptionsTranslated}
                        onSelect={(cycle_date) => handleKpiCycleDateChange(cycle_date, kpi)}
                        style={{ maxWidth: '250px', marginRight: '40px' }}
                        selected={kpi.cycle_date}
                      />
                      <CustomDatepicker
                        picker="year"
                        onChange={(year) => handleKpiYearChange(year, kpi)}
                        allowClear={false}
                        value={kpi.year ? moment(kpi.year, "YYYY") : null}
                      />
                    </div>

                  </div>
                ))}
              </div>
            )}
          </>
        )
      }

    </div>
  )
}
export default injectIntl(ConfigureTimeSelector);