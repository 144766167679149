import React, {
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import useCustomRequest from 'utils/useCustomRequest';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import './style.less';

import {
  Upload,
  Spin,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//const NO_OP = () => {};
const NO_OP = (...a) => { console.log('UploadButton', a); };

// NOTICE: This component hides the (ugly) logic of uploading via a multipart
//         form in our app
const UploadButton = ({
  intl,
  children = null,
  actionUrl,
  data = {},
  onSuccess = NO_OP,
  onError = NO_OP,
  authenticated = false,
  accept,
  className = '',
  component,
  section = 'datamanagement',
}) => {
  const t = intl.messages;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(() => dispatch(_refreshAccessToken()), [ dispatch ]);

  const customRequest = useCustomRequest({
    api_requests,
    refreshAccessToken,
    onSuccess,
    onError,
  }, [
    api_requests,
    refreshAccessToken,
    onSuccess,
    onError,
  ]);

  const handleChange = useCallback(({ file }) => {
    switch((file || {}).status) {
      case 'error':
        setError(true);
        setLoading(false);
        break;
      case 'done':
        setError(false);
        setLoading(false);
        break;
      case 'uploading':
        setError(false);
        setLoading(true);
        break;
      default:
    }
  }, []);

  return (
    <React.Fragment>
      <Upload
        showUploadList={false}
        action={actionUrl}
        disabled={loading}
        className={`UploadButton ${className}`}
        { ...( accept && { accept } ) }
        {
          ...(
          !authenticated ? {} :
          {
            customRequest,
            headers: {
              'Authorization': `Bearer ${api_requests.access_token}`,
            },
          }
          )
        }
        data={data}
        customRequest={customRequest}
        onChange={handleChange}
      >
        { component ||
          <>
            <div className="UploadButton__content">
              {
                loading
                ? <Spin size="large" />
                : <FontAwesomeIcon icon="file-upload" size="2x" color="white" />
              }
              <p className="UploadButton__upload-text">
                { t[`${section}_upload_button`] }
              </p>
            </div>
            <p className="UploadButton__upload-subtext">
              {
                loading
                  ? t[`${section}_upload_loading`]
                  : error
                  ? t[`${section}_upload_error`]
                  : children
              }
            </p>
          </>
        }
      </Upload>
    </React.Fragment>
  );
};

export default injectIntl(UploadButton);
