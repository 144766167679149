import React, { useContext, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DashboardCardFormContext } from 'containers/DashboardCardForm';
import useOrganizations from 'utils/useOrganizations';
import AplanetIcon from 'components/AplanetIcon';
import CustomModalType from 'components/CustomModalType';
import './style.less';

const CardFormSummary = ({ intl }) => {

    const { values, currentStep } = useContext(DashboardCardFormContext);
    const { suborganization } = useOrganizations();
    const [showModal, setShowModal] = useState(false);

    const nodesByLevel = useMemo(() => ( values.selectedNodeLevel === 2 ) 
                                            ?  suborganization.children.map( subOrg => subOrg.id ) 
                                            : [ suborganization.id]
    , [suborganization.children, suborganization.id, values.selectedNodeLevel]);
    
    if ((values?.selectedRowKeys || []).length === 0){
        return null;
    }

    return (
        <div className="DashboardCardForm__summary">
            {
                (currentStep >= 0) && 
                <>
                    { intl.formatMessage(
                        {id: 'dashboard_card_form_summary_step_1'},
                        {value: values?.selectedRowKeys?.length || 0}
                    )}
                </>
            }

            {
                (currentStep >= 2) && 
                <>, { intl.formatMessage(
                    {id: 'dashboard_card_form_summary_step_2'},
                    {value: nodesByLevel.length || 0}
                )}</>
            }

            {
                (currentStep >= 3) && 
                (values.selectedRows[0].schema.type ==='table') && 
                <>, { intl.formatMessage(
                    {id: 'dashboard_card_form_summary_step_3'},
                    {value: values?.graphAddress?.length || 0}
                )}</>
            }

            {
                (currentStep >= 4) && 
                <>, { intl.formatMessage(
                    {id: 'dashboard_card_form_summary_step_4'},
                    {value: values?.periodsNumber || 0}
                )}</>
            }

            <button type='button' onClick={ () => setShowModal(true) }>
                <AplanetIcon name="circle-info" size="18px" />
            </button>

            <CustomModalType 
                showModal={showModal}
                type='main'
                onCancel={ () => setShowModal(false) }
                onCancelText={ intl.formatMessage({id: 'dashboard_card_form_summary_modal_cancel' }) }
                title={ intl.formatMessage({id: 'dashboard_card_form_summary_modal_title' }) }
                content={
                    <dl className='DashboardCardForm__summary__modal_content'>
                        <dt>{ intl.formatMessage({id: 'dashboard_card_form_summary_modal_content_indicators' }) }</dt>
                        {
                            values?.selectedRows?.map( kpi => (
                                <dd key={ kpi.uuid }>
                                    <AplanetIcon size="15px" name="file" faStyle="fal" />
                                    {' '}
                                    <Link to={`/kpi/${kpi.slug}/last/data`} target="_blank">{ kpi.name }</Link> 
                                </dd>
                            ))
                        }
                    </dl>
                }
                width={ 880 }
            />

        </div>
    )
}

export default injectIntl(CardFormSummary);