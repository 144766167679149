import React, {  Fragment, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';
import T from 'components/T';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import Filter from 'components/SustainableIssues/Filter';

import {
  Row,
  Col,
  Button,
  Modal,
  Popconfirm,
} from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const shortStandard = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'Electric Utilities Sector Supplement',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
};

const SustainableIssues = ({
  mainOrgSlug,
  enabledReports,
  intl,
  analysisSusIssues,
  fetchingAnalysisSusIssues,
  susIssues,
  fetchingSusIssues,
  requestSusIssues,
  addAnalysisSusIssue,
  deleteAnalysisSusIssue,
  closeStep,
  onNext,
  analysis,
  ...props
}) => {
  const t = intl.messages;
  const [showAddModal, setShowAddModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const searchIssues = useCallback(
    (issues, text) => {
      return issues
        .filter(
          issue => {
            return (issue.issue || "").toLowerCase().includes(text)
              || (issue.subject || "").toLowerCase().includes(text)
              || searchIssues(issue.children, text).length > 0;
          }
        )
        .map(issue => {
          return {
            ...issue,
            children: searchIssues(issue.children, text),
          };
        });
    },
    []
  );

  const filterIssues = useCallback(
    (issues, key, values) => {
      return issues.filter(issue => {
        const isCustomMatch = (
          key === 'standards' &&
          values.includes('custom') &&
          (issue.has_custom || issue.is_custom));
        return (
          isCustomMatch ||
          issue[key].some(item => values.includes(item)) ||
          filterIssues(issue.children, key, values).length > 0
        );
      }).map(issue => ({
        ...issue,
        children: filterIssues(issue.children, key, values),
      }));
    },
    []
  );

  const filteredSusIssues = useMemo(
    () => {
      if (!susIssues || _.isEmpty(filters)) {
        return susIssues;
      }

      let issues = [...susIssues];

      if (filters.search) {
        issues = searchIssues(issues, filters.search.toLowerCase());
      }

      if (filters.standards && filters.standards.length > 0) {
        issues = filterIssues(issues, 'standards', filters.standards);
      }

      if (filters.sdgs && filters.sdgs.length > 0) {
        issues = filterIssues(issues, 'sdgs', filters.sdgs);
      }

      if (filters.esg_types && filters.esg_types.length > 0) {
        issues = filterIssues(issues, 'esg_types', filters.esg_types);
      }
      return issues;
    },
    [susIssues, filters, searchIssues, filterIssues]
  );
  const clusterSDGs = useSDGsClustering();

  const columns = useMemo(
    () => {
      let cols = [{
        title: showAddModal ? <T selected_x_subjects={{x: selectedRowKeys.length}} />  : t.subject,
        dataIndex: 'subject',
      }, {
        title: t.issue,
        dataIndex: 'issue',
        render: (text) => text || '-',
      }, {
        title: t.standard,
        dataIndex: 'standards',
        render: (standards) => `${(standards?.filter(standard => standard !== 'custom') || []).map(standard => shortStandard[standard]).join(', ')}`,
      }, {
        title: t.sdg,
        dataIndex: 'sdgs',
        render: (sdgs) => clusterSDGs(sdgs || []).map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg} />)
      }, {
        title: t.type,
        dataIndex: 'esg_types',
        render: (types) => (types || []).map(type => <EsgLogo key={type} type={type} />)
      }];

      if (!showAddModal) {
        cols.push({
          render: (record) => {
            if (analysis.stage === 'issues') {
              return (
                <Popconfirm
                  title={t.analysis_delete_topic_confirm}
                  onConfirm={() => deleteAnalysisSusIssue(record.id)}
                  okText={t.yes}
                  cancelText={t.no}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    type="danger"
                    shape="round"
                  >
                    { t.delete }
                  </Button>
                </Popconfirm>
              )
            }
          },
        });
      }
      return cols;
    },
    [selectedRowKeys, showAddModal, deleteAnalysisSusIssue, analysis, t, clusterSDGs]
  );

  const getAllChildrenIds = useCallback(
    (category) => {
      let childrenIds = [];
      category.children.forEach(
        child => {
          childrenIds.push(child.id);
          if (child.children && child.children.length > 0) {
            childrenIds.push(...getAllChildrenIds(child));
          }
        }
      );
      return childrenIds;
    },
    []
  );

  const addIssueRowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record, selected) => {
        let rowKeys = [];
        if (selected) {
          rowKeys = [
            ...selectedRowKeys,
            record.id,
          ]
          if (record.children.length > 0) {
            setExpandedRowKeys([...expandedRowKeys, record.id]);
          }
        } else {
          rowKeys = _.difference(selectedRowKeys, [record.id]);
        }
        setSelectedRowKeys(rowKeys);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        let rowKeys = [];
        if (selected) {
          rowKeys = selectedRows.map(row => row.id);
        } else {
          rowKeys = _.difference(
            selectedRowKeys,
            changeRows.map(row => row.id)
          );
        }
        setSelectedRowKeys(rowKeys);
      }
    }),
    [selectedRowKeys, setExpandedRowKeys, expandedRowKeys]
  );

  const openAddModal = useCallback(
    () => {
      requestSusIssues();
      setShowAddModal(true)
    },
    [requestSusIssues]
  );

  const onAddIssue = useCallback(
    () => {
      addAnalysisSusIssue({ kpi_category_ids: selectedRowKeys });
      setSelectedRowKeys([]);
      setShowAddModal(false);
    },
    [addAnalysisSusIssue, selectedRowKeys]
  );

  const onBulkDelete = useCallback(
    () => {
      deleteAnalysisSusIssue(selectedRowKeys);
      setSelectedRowKeys([]);
    },
    [deleteAnalysisSusIssue, selectedRowKeys]
  );

  return (
    <Fragment>
      <FormattedHTMLMessage
        id="sustainable_issues_desc"
        defaultMessage={ t.sustainable_issues_desc }
        tagName="p"
      />
      <Row type="flex" justify="end" className='sustainable-issues-actions'>
        {analysis.stage === 'issues' ? (
          <CustomButton
            type="primary"
            onClick={closeStep}
          >
            { t.sustainable_issues_done }
          </CustomButton>
        ) : (
          <Link to={analysis.method === 'internal_external' ? 'internal_financial_environment_social_impact' : 'benchmark'} className="CustomButton ant-btn-primary">{t.next}</Link>
        )}
      </Row>
      <CustomTable
        columns={columns}
        dataSource={analysisSusIssues}
        childrenColumnName='not-children'
        rowSelection={addIssueRowSelection}
        rowKey='id'
        loading={fetchingAnalysisSusIssues}
      />

      <Row gutter={[10, 10]}>
        <Col>
          <CustomButton
            type="primary"
            onClick={openAddModal} disabled={analysis.stage !== 'issues'}
          >
            { t.add_other }
          </CustomButton>
        </Col>
        <Col>
          <Popconfirm
            title={t.analysis_delete_topics_confirm}
            onConfirm={onBulkDelete}
            okText={t.yes}
            cancelText={t.no}
          >
            <CustomButton
              type="danger"
              disabled={!selectedRowKeys.length || analysis.stage !== 'issues'}
              icon={<DeleteOutlined />}
            >
              { t.analysis_delete_selected_topics }
            </CustomButton>
          </Popconfirm>
        </Col>
      </Row>

      <Modal
        title={t.add_subject_issue}
        visible={showAddModal}
        width="90%"
        okText={t.add}
        onOk={onAddIssue}
        onCancel={() => setShowAddModal(false)}
        okButtonProps={{
          shape: 'round',
        }}
        cancelButtonProps={{
          shape: 'round',
        }}
      >
        <Filter
          filterIssues={setFilters}
          enabledReports={enabledReports}
        />
        <CustomTable
          columns={columns}
          dataSource={filteredSusIssues}
          rowSelection={addIssueRowSelection}
          rowKey='id'
          rowClassName={
            record => (record.children && record.children.length > 0
              ? ``
              : `hidden-expand-icon`
            )
          }
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange={setExpandedRowKeys}
          loading={fetchingSusIssues}
        />
      </Modal>
    </Fragment>
  );
}


export default injectIntl(SustainableIssues);
