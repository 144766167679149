import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { flatten, find, intersection } from 'lodash';
import {
  message,
  Row,
  Col,
} from 'antd';
import KpiTree from 'components/KpiTree';
import KpiTreeActionsSection from 'components/KpiTreeActionsSection';
import VariablesConfiguration from 'components/VariablesConfiguration';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import { requestStandardsCategoriesOrKpis, requestReportingStructure, updateReportingStructure } from 'actions/api';
import useScreenFilters from 'hooks/useScreenFilters';
import { SCREENS, FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import {
  reportingStructureToTree,
  searchInReportingStructureTree,
  filterReportingStructureTree,
  appliesReportingStructureTree,
  treeToReportingStructure,
} from 'utils/reporting_structure';

import CreateOrAddKpi from 'components/CreateOrAddKpi';
import CreateOrEditCategory from 'components/CreateOrAddCategory';
import ConfigureTopActions from 'components/ConfigureTopActions';

function Configure({
  intl,
}) {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [checkedKpis, setCheckedKpis] = useState([]);
  const [showCategoryWizard, setShowCategoryWizard] = useState(false);
  const [showIndicatorWizard, setShowIndicatorWizard] = useState(false);
  const [showVariablesConfiguration, setShowVariabledConfiguration] = useState(false);
  const [expandedTreeNodes, setExpandedTreeNodes] = useState([]);
  const [showNotApplies, setShowNotApplies] = useState(false);
  const {
    values: [
      [filteredSdgs, sdgFilterSet],
      [filteredTypes, typeFilterSet],
      [filteredTags, tagFilterSet],
      [filteredStatuses], // eslint-disable-line no-unused-vars
      [filteredStandards, standardFilterSet],
      [textFilter, textFilterSet],
    ],
    handleFilter,
    filterState,
    isFilterSet, // eslint-disable-line no-unused-vars
    resetAllFilters,
    setAllFilters
  } = useScreenFilters({
    screen: SCREENS.manage,
  });

  const {
    organization,
    suborganization,
    organizationTree,
  } = useOrganizations();

  const { enabled_reports = [] } = suborganization.config

  const clearSelection = useCallback(
    () => {
      setSelectedCategory(null);
      setSelectedKpi(null);
      setCheckedKpis([]);
      setCheckedCategories([]);
    },
    [setSelectedCategory, setSelectedKpi, setCheckedKpis, setCheckedCategories]
  );

  const areFiltersSet = useMemo(
    () => sdgFilterSet || typeFilterSet || tagFilterSet || standardFilterSet,
    [
      sdgFilterSet,
      standardFilterSet,
      tagFilterSet,
      typeFilterSet,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clearSelection
    , [suborganization.slug]);

  const {
    data: reporting_structure,
    loading: loadingReportingStructure,
    error: errorReportingStructure,
    saving: savingReportingStructure,
  } = useSelector((state) => state.reporting_structure);

  useEffect(
    () => {
      if (savingReportingStructure === true) {
        message.loading({ content: intl.formatMessage({ id: 'reporting_structure_saving_message' }), key: 'reporting_structure_save_message', duration: 0 });
      } else if (savingReportingStructure === false) {
        message.success({ content: intl.formatMessage({ id: 'reporting_structure_saved_message' }), key: 'reporting_structure_save_message', duration: 2 });
      }
    },
    [savingReportingStructure, intl],
  );

  const reporting_structure_kpis = useMemo(() => flatten(reporting_structure?.map((cat) => cat.kpis)), [reporting_structure]);

  const doesCategoryApplies = category => {
    return category.children.length === 0 || category.children.filter(c => !c.isCategory).some(kpi => kpi.applies)
      || category.children.filter(c => c.isCategory && doesCategoryApplies(c)).length > 0;
  }

  useEffect(() => {
    if (!showNotApplies) {
      const appliesKpis = reporting_structure_kpis.filter(({ applies }) => applies).map(({ uuid }) => uuid)
      const cleanChekedKpis = intersection(appliesKpis, checkedKpis)
      const cleanSelectedKpi = appliesKpis.includes(selectedKpi) ? selectedKpi : null
      const cleanSelectedCategory = selectedCategory && doesCategoryApplies(selectedCategory) ? selectedCategory : null
      setCheckedKpis(cleanChekedKpis)
      setSelectedKpi(cleanSelectedKpi)
      setSelectedCategory(cleanSelectedCategory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reporting_structure_kpis, showNotApplies])

  const checkedKpisData = useMemo(
    () => reporting_structure_kpis.filter((kpi) => checkedKpis.includes(kpi.uuid)),
    [checkedKpis, reporting_structure_kpis],
  );
  const checkedCategoriesData = useMemo(
    () => reporting_structure?.filter((category) => checkedCategories.includes(category.uuid)),
    [checkedCategories, reporting_structure],
  );
  const selectedKpiObject = find(reporting_structure_kpis, ['uuid', selectedKpi]);

  const {
    data: standards_categories_kpis,
    loading: standards_categories_kpis_loading
  } = useSelector((state) => state.standards_categories_kpis);

  const reportingStructureTree = useMemo(
    () => reportingStructureToTree(reporting_structure || []),
    [reporting_structure],
  );
  const treeData = useMemo(
    () => {
      let treeData = reportingStructureTree;
      treeData = appliesReportingStructureTree(treeData, showNotApplies);

      if (!areFiltersSet && !textFilterSet) {
        return treeData;
      }
      const expandedNodes = [];
      if (textFilterSet) {
        treeData = searchInReportingStructureTree(treeData, textFilter.toLowerCase(), expandedNodes);
      }
      setExpandedTreeNodes(expandedNodes);


      if (sdgFilterSet) {
        treeData = filterReportingStructureTree(treeData, 'sdgs', filteredSdgs);
      }

      if (typeFilterSet) {
        treeData = filterReportingStructureTree(treeData, 'esgs', filteredTypes);
      }

      if (tagFilterSet) {
        treeData = filterReportingStructureTree(treeData, 'tags', filteredTags);
      }

      if (standardFilterSet) {
        treeData = filterReportingStructureTree(treeData, 'standard_info', filteredStandards);
      }

      return treeData;
    },
    [reportingStructureTree, showNotApplies, areFiltersSet, textFilterSet, sdgFilterSet, typeFilterSet, tagFilterSet, standardFilterSet, textFilter, filteredSdgs, filteredTypes, filteredTags, filteredStandards],
  );
  const onChangeSearch = useCallback((term) => {
    handleFilter(FILTER_TYPES.text_filter)(term);
  }, [handleFilter]);

  const onClearSelecteds = () => {
    setSelectedKpi('');
    setSelectedCategory(null);
  };

  // Request new data from API
  useEffect(() => {
    if (
      !organization
      || !suborganization
    ) return;

    dispatch(
      requestReportingStructure({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
      }),
    );
  }, [
    organization,
    suborganization,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !organization
      || !suborganization
      || (!showCategoryWizard
        && !showIndicatorWizard)
    ) return;

    dispatch(
      requestStandardsCategoriesOrKpis({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        getKpis: showIndicatorWizard,
      }),
    );
  }, [
    organization,
    suborganization,
    showCategoryWizard,
    dispatch,
    showIndicatorWizard,
  ]);

  // Hack to avoid unnecessary refreshing
  const previousSelectedCategories = useMemo(() => [], []);
  const subtreeCategories = useMemo(() => [], []);

  const hasContent = Boolean(treeData.length)

  if (loadingReportingStructure) {
    return <Loading />;
  }

  return (
    <Row
      className="DataManagement__config"
      type="flex"
    >
      <Col span={24}>
        <ConfigureTopActions
          setAllFilterState={setAllFilters}
          filterState={filterState}
          resetAllFilters={resetAllFilters}
          areFiltersSet={areFiltersSet}
          isKpiNameSearchSet={textFilterSet}
          kpiNameSearch={textFilter}
          onChangeKpiSearch={onChangeSearch}
          setShowCategoryWizard={setShowCategoryWizard}
          setShowIndicatorWizard={setShowIndicatorWizard}
          setShowVariabledConfiguration={setShowVariabledConfiguration}
          hasContent={hasContent}
        />
        <Row
          gutter={[10, 10]}
          style={{ justifyContent: 'center' }}
        >
          <Col span={selectedCategory || selectedKpi || checkedKpis.length > 0 ? 14 : 24}>
            <Row gutter={[10, 10]}>
              <Col span={24} className="DataManagement__config__KpiTree-container">
                <KpiTree
                  categories={treeData}
                  search={textFilter}
                  expandedTreeCategories={expandedTreeNodes}
                  draggable={false}
                  previousSelectedCategories={previousSelectedCategories}
                  subtreeCategories={subtreeCategories}
                  onTreeUpdated={(tree) => dispatch(updateReportingStructure(organization.slug, suborganization.slug, treeToReportingStructure(tree)))}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  checkedCategories={checkedCategories}
                  setCheckedCategories={setCheckedCategories}
                  selectedKpi={selectedKpiObject}
                  setSelectedKpi={setSelectedKpi}
                  checkedKpis={checkedKpis}
                  setCheckedKpis={setCheckedKpis}
                  textFilterSet={textFilterSet}
                  areFiltersSet={areFiltersSet}
                  setShowNotApplies={setShowNotApplies}
                  clearSelection={clearSelection}
                  // search={debouncedSearchTerm}
                  isDirectory
                  reportingStructure={reporting_structure}
                  enabled_reports={enabled_reports}
                  setShowCategoryWizard={setShowCategoryWizard}
                  setShowIndicatorWizard={setShowIndicatorWizard}
                />
              </Col>
            </Row>
          </Col>
          <Col span={selectedCategory || selectedKpi || checkedKpis.length > 0 ? 10 : 0}>
            <KpiTreeActionsSection
              checkedKpis={checkedKpisData}
              selectedKpi={selectedKpiObject}
              checkedCategories={checkedCategoriesData}
              selectedCategory={selectedCategory}
              organization={organization}
              suborganization={suborganization}
              organizationTree={organizationTree}
              onClearSelecteds={onClearSelecteds}
              reporting_structure={reporting_structure}
              savingReportingStructure={savingReportingStructure}
              enabled_reports={enabled_reports}
            />
          </Col>
        </Row>
      </Col>
      <CreateOrEditCategory
        visible={showCategoryWizard}
        standardsCategories={standards_categories_kpis}
        reportingStructure={reporting_structure}
        loading={loadingReportingStructure || standards_categories_kpis_loading}
        errorReportingStructure={errorReportingStructure}
        onCreate={() => setShowCategoryWizard(false)}
        onCancel={() => setShowCategoryWizard(false)}
      />
      <CreateOrAddKpi
        visible={showIndicatorWizard}
        standardsKpis={standards_categories_kpis}
        reportingStructure={reporting_structure}
        reporting_structure_kpis={reporting_structure_kpis}
        loading={loadingReportingStructure || standards_categories_kpis_loading}
        errorReportingStructure={errorReportingStructure}
        onCreate={() => setShowIndicatorWizard(false)}
        onCancel={() => setShowIndicatorWizard(false)}
      />
      {showVariablesConfiguration
        && (
          <VariablesConfiguration
            visible={showVariablesConfiguration}
            onClose={() => setShowVariabledConfiguration(false)}
          />
        )}
    </Row>
  );
}

export default withRouter(injectIntl(Configure));