import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import { useEventTracking } from 'hooks/useEventTracking';
import A from 'components/A';
import FiltersPanel from 'components/FiltersPanel';
import SearchFilter from 'components/SearchFilter';
import AplanetIcon from 'components/AplanetIcon';
import OrganizationPath from 'components/OrganizationPath';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';

const FILLED_STATUS = 'filled';

const Filters = ({
  filters,
  setAllFilterState,
  filterState,
  resetAllFilters,
  areFiltersSet,
  isKpiNameSearchSet,
  kpiNameSearch,
  onChangeKpiSearch,
  organization,
  totalSuborganizations = 0,
  disabledFilters = false,
}) => {
  const intl = useIntl();
  const eventTracking = useEventTracking();

  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  // Filters
  const [suborganizationFilterValues, setSuborganizationFilterValues] = useState(filterState.suborganizations_filter);
  const [periodicityFilterValues, setPeriodicityFilterValues] = useState(filterState.periodicity_filter);
  const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);
  const [requestDateFilterValues, setRequestDateFilterValues] = useState(filterState.request_date_filter);
  const [responseDateFilterValues, setResponseDateFilterValues] = useState(filterState.response_date_filter);
  const [requestStatusFilterValues, setRequestStatusFilterValues] = useState(filterState.request_status_filter);

  const filtersConfig = useMemo(() => {
    if (disabledFilters) {
      return [];
    }
    const suborganizationFilter = filters.find(({name})=> name === FILTER_TYPES.suborganizations_filter);
    const periodicityFilter = filters.find(({name})=> name === FILTER_TYPES.periodicity_filter);
    const periodDateFilter = filters.find(({name})=> name === FILTER_TYPES.period_date_filter);
    const requestDateFilter = filters.find(({name})=> name === FILTER_TYPES.request_date_filter);
    const responseDateFilter = filters.find(({name})=> name === FILTER_TYPES.response_date_filter);
    const requestStatusFilter = filters.find(({name})=> name === FILTER_TYPES.request_status_filter);
    return [
      {
        columnTitle: intl.formatMessage({id: `third_party_requests_filter_column_suborganizations`}),
        colSpan: { xs: 10, sm: 10, md: 10, lg: 10 },
        filters: [
          {
            filterType: suborganizationFilter.type,
            hasSelectAll: true,
            listOptions: suborganizationFilter.values.map((suborganizations) => {
              const targetOrganization = suborganizations.find(({isTargetOrg}) => isTargetOrg);
              return ({
                value: targetOrganization.slug,
                disabledTitle: true,
                label: (
                  <OrganizationPath
                    slug={targetOrganization.slug}
                    logo_small={targetOrganization.logo_small}
                    name={targetOrganization.name}
                    sortedSuborganizations={suborganizations}
                    hasEllipsis
                  />
                )
              });
            }),
            setSelectedOptions: setSuborganizationFilterValues,
            selectedOptions: suborganizationFilterValues,
          }
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `third_party_requests_filter_column_dates`}),
        colSpan: { xs: 6, sm: 6, md: 6, lg: 6 },
        filters: [
          {
            filterType: periodicityFilter.type,
            filterTitle: intl.formatMessage({id: `third_party_requests_filter_title_periodicity`}),
            listOptions: periodicityFilter.values.map(option => ({
              value: option,
              title: intl.formatMessage({id: `third_party_requests_filter_${option}`}),
              label: (<span className={`DataRequests__periodicity ${option}`}>{intl.formatMessage({id: `third_party_requests_filter_${option}`})}</span>)
            })),
            setSelectedOptions: setPeriodicityFilterValues,
            selectedOptions: periodicityFilterValues,
          },
          {
            filterType: periodDateFilter.type,
            filterTitle: intl.formatMessage({id: `third_party_requests_filter_title_period_date`}),
            picker: periodDateFilter.picker,
            format: periodDateFilter.format,
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: periodDateFilterValues,
            onChange: setPeriodDateFilterValues,
          },
          {
            filterType: requestDateFilter.type,
            filterTitle: intl.formatMessage({id: `third_party_requests_filter_title_request_date`}),
            picker: requestDateFilter.picker,
            format: requestDateFilter.format,
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: requestDateFilterValues,
            onChange: setRequestDateFilterValues,
          },
          {
            filterType: responseDateFilter.type,
            filterTitle: intl.formatMessage({id: `third_party_requests_filter_title_response_date`}),
            picker: responseDateFilter.picker,
            format: responseDateFilter.format,
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: responseDateFilterValues,
            onChange: setResponseDateFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `third_party_requests_filter_column_fill_status`}),
        colSpan: { xs: 4, sm: 4, md: 4, lg: 4 },
        filters: [
          {
            filterType: requestStatusFilter.type,
            filterTitle: intl.formatMessage({id: `third_party_requests_filter_title_fill_status`}),
            listOptions: requestStatusFilter.values.map(option => ({
              value: option,
              label: intl.formatMessage({id: `third_party_requests_filter_${option}`})
            })),
            setSelectedOptions: setRequestStatusFilterValues,
            selectedOptions: requestStatusFilterValues,
          }
        ]
      }
    ];
  }, [
    intl,
    filters,
    suborganizationFilterValues,
    periodicityFilterValues,
    periodDateFilterValues,
    requestDateFilterValues,
    requestStatusFilterValues,
    responseDateFilterValues,
    disabledFilters,
  ]);

  const onSetFilters = useCallback(() => {
    setAllFilterState({
      [FILTER_TYPES.suborganizations_filter]: suborganizationFilterValues,
      [FILTER_TYPES.periodicity_filter]: periodicityFilterValues,
      [FILTER_TYPES.period_date_filter]: periodDateFilterValues,
      [FILTER_TYPES.request_date_filter]: requestDateFilterValues,
      [FILTER_TYPES.response_date_filter]: responseDateFilterValues,
      [FILTER_TYPES.request_status_filter]: requestStatusFilterValues,
      [FILTER_TYPES.text_filter]: kpiNameSearch,
    });

    eventTracking.capture(
      'dataRequestExternal.filterSelection',
      {
        organization_id: organization?.id,
        organization_name: organization?.name,
        organization_slug: organization?.slug,
        suborganization_id: organization?.id,
        suborganization_name: organization?.name,
        suborganization_slug: organization?.slug,
        suborganization_filter_select_all: totalSuborganizations === suborganizationFilterValues.length ? 1 : 0,
        suborganization_filter_total: totalSuborganizations,
        suborganization_filter_suborganization_selected: suborganizationFilterValues.length,
        periodicity: periodicityFilterValues,
        period: periodDateFilterValues.length ? 1 : 0,
        request_date: requestDateFilterValues.length ? 1 : 0,
        response_date: responseDateFilterValues.length ? 1 : 0,
        fill_status_pending: requestStatusFilterValues.includes(DATA_REQUEST_STATUS.pending) ? 1 : 0,
        fill_status_filled: requestStatusFilterValues.includes(FILLED_STATUS) ? 1 : 0,
      }
    );
  }, [
    eventTracking,
    kpiNameSearch,
    organization?.id,
    organization?.name,
    organization?.slug,
    periodDateFilterValues,
    periodicityFilterValues,
    requestDateFilterValues,
    requestStatusFilterValues,
    setAllFilterState,
    suborganizationFilterValues,
    totalSuborganizations,
    responseDateFilterValues,
  ]);

  const onCancelFilter = useCallback(() => {
    setSuborganizationFilterValues(filterState.suborganizations_filter);
    setPeriodicityFilterValues(filterState.periodicity_filter);
    setPeriodDateFilterValues(filterState.period_date_filter);
    setRequestDateFilterValues(filterState.request_date_filter);
    setResponseDateFilterValues(filterState.response_date_filter);
    setRequestStatusFilterValues(filterState.request_status_filter);
  }, [
    filterState.period_date_filter,
    filterState.periodicity_filter,
    filterState.request_date_filter,
    filterState.request_status_filter,
    filterState.response_date_filter,
    filterState.suborganizations_filter,
  ]);

  const onClearSearchAndFilters = useCallback(() => {
    const suborganizationFilter = filters.find(({name})=> name === FILTER_TYPES.suborganizations_filter);
    const defaultOrgs = suborganizationFilter.values.map((suborgs) => suborgs.find(({isTargetOrg}) => isTargetOrg).slug);
    setSuborganizationFilterValues(defaultOrgs);
    setPeriodicityFilterValues([]);
    setPeriodDateFilterValues([]);
    setRequestDateFilterValues([]);
    setResponseDateFilterValues([]);
    setRequestStatusFilterValues([]);
    setHasToClearKpiSearch(true);
    resetAllFilters();
  }, [
    filters,
    resetAllFilters
  ]);

  return(
    <section className="ThirdPartyRequestsFilters">
      <FiltersPanel
        areFiltersSet={areFiltersSet}
        onCancelFilter={onCancelFilter}
        onSetFilters={onSetFilters}
        filtersConfig={filtersConfig}
        disabled={disabledFilters}
      />
      <SearchFilter
        textFilter={kpiNameSearch}
        onSetSearch={onChangeKpiSearch}
        hasToClearSearch={hasToClearKpiSearch}
        setHasToClearKpiSearch={setHasToClearKpiSearch}
        defaultOpen={isKpiNameSearchSet}
        placeholder={intl.formatMessage({id: `third_party_requests_search_kpi_placeholder`})}
        disabled={disabledFilters}
        autoFocus
      />
      <div className='ThirdPartyRequestsFilters__remove-filters-wrapper'>
        <A
          className='ThirdPartyRequestsFilters__remove-filters'
          onClick={onClearSearchAndFilters}
          disabled={(!areFiltersSet && !isKpiNameSearchSet) || disabledFilters}
        >
          {intl.formatMessage({id: 'third_party_requests_filters_delete_all'})}
        </A>
      </div>
    </section>
  );
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    values: PropTypes.array,
    format: PropTypes.string,
    picker: PropTypes.string,
  })),
  setAllFilterState: PropTypes.func.isRequired,
  filterState: PropTypes.object.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  areFiltersSet: PropTypes.bool,
  isKpiNameSearchSet: PropTypes.bool,
  kpiNameSearch: PropTypes.string,
  onChangeKpiSearch: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo_small: PropTypes.string,
    logo: PropTypes.string,
  }),
  totalSuborganizations: PropTypes.number,
  disabledFilters: PropTypes.bool,
};

export default Filters;