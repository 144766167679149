import React, {
  useCallback,
} from 'react'
import './style.less'

const CustomTag = (props) => {
  const {
    name,
    //selected,
    colorclass,
    onDelete = null,
    className = '',
    onClick,
    ...rest
  } = props

  /*const colorclass = useMemo(() => {
    return COLORS[stringToHash(name, COLORS.length)];
  }, [
    name,
  ]);*/

  const handleClose = useCallback(() => onDelete(name),
    [
      onDelete,
      name,
    ]
  );

  return (
    <span
      {...rest}
      className={`
        CustomTag
        ${className || ''}
        ${!onDelete ? '' : 'CustomTag__deletable'}
        CustomTag__color_${colorclass}
      `}
      onClick={onClick}
    >
      {name}

      {
        !onDelete ? null : (
          <button
            className="CustomTag__deletebutton"
            onClick={handleClose}
          >
            &times;
          </button>
        )
      }
    </span>
  );
}

export default CustomTag
