import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Loading } from 'tsComponents/emptyStates/Loading';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';

import InfiniteScroll from 'react-infinite-scroller';

import useOrganizations from 'utils/useOrganizations';

import {
  requestHistoryForKpi,
} from 'actions/api';

import {
  resetKpiHistory,
} from 'actions/resetData';

import HistoryCard from 'components/HistoryCard';

import { Timeline } from 'antd';

const History = ({
  intl,
  kpi_slug,
  onOpenAnswer,
  location,
}) => {
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    count,
    data,
    loading, // TODO: Handle loading states
    //error,
  } = useSelector(state => state.kpi_history);

  useEffect(() => {
    dispatch(resetKpiHistory());
    dispatch(
      requestHistoryForKpi(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        0,
      )
    )
    setLoadedPage(0);
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
  ]);

  const [loadedPage, setLoadedPage] = useState(0);

  const handleLoadMore = useCallback((_page) => {
    let page = _page;
    if (page === 0) {
      console.log('WARNING: loading more with no data');
      dispatch(resetKpiHistory());
    }

    if (page <= loadedPage) {
      // This is a dirty workaround because react-infinite-scroller sucks...
      console.log('WARNING: attempting to reload a page', page);
      page = loadedPage + 1;
    }

    dispatch(
      requestHistoryForKpi(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        page,
      )
    )

    setLoadedPage(page);
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    loadedPage,
  ]);

  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      ...props,
      org_name: suborganization.name,
    })
  }, [
    suborganization,
    onOpenAnswer,
  ]);

  if (loading && loadedPage === 0) {
    return <Loading />
  }


  if (!data.length) {
    return <Empty {...emptyPropsMap.get("noHistoryKPI")} />
  }

  return (
    <div className="KpiDetail__history">
      <Timeline
        className="KpiDetail__timeline"
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={!loading && count > data.length}
        >
          {
            (data || []).map((entry, index) => (
              <Timeline.Item
                key={entry.type + entry.created_at + index}
                color="gray"
                className={`KpiDetail__timeline_entry KpiDetail__timeline_entry_${entry.type}`}
              >
                <HistoryCard
                  showKpi={false}
                  onOpenAnswer={handleOpenAnswer}
                  {...entry}
                />
              </Timeline.Item>
            ))
          }
        </InfiniteScroll>
      </Timeline>
    </div>
  )
}

export default injectIntl(withRouter(History));
