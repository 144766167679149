import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {injectIntl} from "react-intl";

import useForm from "../../utils/useForm";
import useOrganizations from "../../utils/useOrganizations";

import {addCategories} from "../../actions/api";

import {message} from 'antd';
import ModalWizard from "../ModalWizard";
import useSteps from "./useSteps";
import "./style.less"
import {v4 as uuidv4} from 'uuid'


const CreateOrAddCategory = (
  {
    intl,
    loading,
    errorReportingStructure,
    visible = false,
    onCreate,
    onCancel,
    reportingStructure,
    standardsCategories
  }
) => {

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const dispatch = useDispatch();

  const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(false)
  const [isSavingCategories, setIsSavingCategories] = useState(false)

  const loadedCategories = useMemo(() => {
    const customCategories = (reportingStructure || [])
    .filter(({category}) => category && !(standardsCategories || []).some(({slug}) => category.slug === slug))
    .map(({category, parent}) => ({
      parent: parent?.uuid,
      slug: category.slug,
      name: category.name,
      uuid: category.uuid
    }))
    return [...customCategories, ...(standardsCategories || [])];
  }, [
    reportingStructure,
    standardsCategories
  ]);

  const saveCategory = ((values) => {
    dispatch(addCategories({
      organization_slug: organization.slug,
      suborganization_slug: suborganization.slug,
      parents: values.parents,
      categories: values.selected_categories
        .filter(c => !values?.new_categories.some(nc => nc.uuid === c.uuid))
        .map(c => ({slug: c.slug, addHierarchy: !!values.useSubtree.find(u => u === c.uuid), uuid: c.uuid})),
      new_categories: values?.new_categories.filter(nc => values.selected_categories.some(sc => sc.uuid === nc.uuid))
    }))
    setIsSavingCategories(true)
    resetForm()
  })

  const defaultValues = useMemo(() => ({
    name_translations: (organization?.config?.preferred_languages || []).map(locale => ({locale, name: ''})),
    selected_categories: [],
    useSubtree: [],
    new_categories: [],
    subtree_categories: []
  }), [organization?.config?.preferred_languages]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: saveCategory,
    validate: useCallback(() => ({}), []),
    defaultValues,
    validationDefaults: {locale: suborganization.language}
  });

  const categories = useMemo(() => {
    return [...values?.new_categories, ...loadedCategories];
  }, [
    values?.new_categories,
    loadedCategories
  ]);

  const onClose = useCallback(() => {
    resetForm()
    setIsCreatingNewCategory(false)
    onCancel()
  }, [resetForm, onCancel, setIsCreatingNewCategory])

  useEffect(() => {
    if (visible && !loading && !errorReportingStructure && reportingStructure?.length > 0 && isSavingCategories) {
      setIsCreatingNewCategory(false)
      setIsSavingCategories(false)
      resetForm()
      onCreate()
    }
  }, [
    reportingStructure,
    errorReportingStructure,
    intl,
    loading,
    onCreate,
    onClose,
    resetForm,
    isSavingCategories,
    visible
  ])

  useEffect(() => {
    if (visible && errorReportingStructure && isSavingCategories) {
      message.error(intl.formatMessage({id: "create_or_edit_category_error_message"}))
      setIsSavingCategories(false)
    }
  }, [
    errorReportingStructure,
    intl,
    isSavingCategories,
    visible
  ])

  const onFinish = useCallback(() => {
    handleSubmit()
  }, [handleSubmit])

  const handleNewCategory = useCallback((name) => {
    const orgLocale = suborganization.language;
    const nt = values?.name_translations.map(value => value?.locale === orgLocale ? ({locale: orgLocale, name}) : value);
    handleChange('name_translations')(nt)
    setIsCreatingNewCategory(true)
  }, [handleChange, suborganization, values?.name_translations])

  const submitNewCategory = useCallback((save = false) => {
    if(save) {
      const name = values?.name_translations.find(({locale}) => locale === suborganization.language).name;
      const newCategory = {
        name,
        slug: uuidv4(),
        uuid: uuidv4(),
        isNew: true,
        name_translations: values?.name_translations
      };
      handleChange('selected_categories')([...values?.selected_categories, {slug: newCategory.slug, uuid: newCategory.uuid}])
      handleChange('new_categories')([newCategory, ...values?.new_categories])
      handleChange('name_translations')((organization?.config?.preferred_languages || []).map(locale => ({locale, name: ''})))
    }
    setIsCreatingNewCategory(false);
  }, [setIsCreatingNewCategory, values, handleChange, suborganization.language, organization]);

  const steps = useSteps({
    intl,
    values,
    reportingStructure,
    errors,
    isDirty,
    isCreatingNewCategory,
    handleChange,
    handleNewCategory,
    submitNewCategory,
    categories,
    suborganization,
    organizationName: organization.name
  })

  return (<ModalWizard
    className="CreateOrEditCategory-modal"
    title={intl.formatMessage({id: 'create_or_edit_category_title'})}
    visible={visible}
    steps={steps}
    onCancel={onClose}
    onFinish={onFinish}
    afterClose={onClose}
    isCreating={isCreatingNewCategory}
    saveAction={(value) => submitNewCategory(value)}
    loading={loading}
  />)
}

export default injectIntl(CreateOrAddCategory);
