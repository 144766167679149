import React from 'react';
import PropTypes from 'prop-types';
import {formatMessage} from '../../../intl'
import { useIntl } from 'react-intl';

const BooleanCard = React.forwardRef(({ parameters, data }, ref) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
  } = parameters;
  const hasData = typeof data === 'boolean';

  return (
    <div className="DashboardComponent__card_content">
      {
        !hasData
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <div className="DashboardComponent__booleancard">
            <output>{ data ? formatMessage('yes') : formatMessage('no') }</output>
          </div>
      }
    </div>
  );
});

BooleanCard.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.bool,
};

export default BooleanCard;

