import React, { useCallback, useEffect } from 'react';

import Answer from 'components/Answer';

import { useAnswerState } from 'utils/useAnswerState';

const NO_DEPENDEES = [];
const NO_OP = () => {};

const DataAnswer = ({
  editting = false,
  config,
  target,
  isTarget,
  schemaLabels,
  previous_value,
  onOpenAnswer = NO_OP,
  onUpdateValue = NO_OP,
  disabled,
  schema,
  tableDimensions,
  kpi_value,
  kpi_slug,
  organization,
  organization_parent,
  period,
  dependees = NO_DEPENDEES,
}) => {
  const handleOpenAnswer = useCallback(() => {
    onOpenAnswer({
      period,
      value: kpi_value,
      attachments: [],
    });
  }, [
    onOpenAnswer,
    kpi_value,
    period,
  ])

  const {
    values: answerValues,
    update: updateAnswer,
  } = useAnswerState({
    schema,
    tableDimensions,
    initialState: kpi_value,
    slug: kpi_slug,
    organization,
    organization_parent,
    period,
    dependees,
  });

  // KLUDGE: We want the value generated HERE because useAnswerState needs to happen when remounting,
  // BUT we need to save from the parent component, so we will launch onUpdateValue
  useEffect(() => {
    onUpdateValue(answerValues);
  }, [
    onUpdateValue,
    answerValues,
  ]);

  return editting
    ? (
      <Answer.Edit
        compact
        schema={schema}
        schemaLabels={schemaLabels}
        kpi_slug={kpi_slug}
        organization={organization}
        organization_parent={organization_parent}
        period={period}
        dependees={dependees}
        config={config}
        value={answerValues}
        onChange={updateAnswer}
        disabled={disabled}
        columnsNoScroll={3}
        target={target}
        isTarget={isTarget}
      />
    ) : (
      <Answer.Show
        compact
        schema={schema}
        schemaLabels={schemaLabels}
        kpi_slug={kpi_slug}
        organization={organization}
        organization_parent={organization_parent}
        period={period}
        dependees={dependees}
        config={config}
        value={answerValues}
        previous_value={previous_value}
        onClick={handleOpenAnswer}
        columnsNoScroll={3}
        target={target}
        isTarget={isTarget}
      />
    );
};

export default DataAnswer;

