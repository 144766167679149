import React from "react";
import styled from "@emotion/styled";
import { Tooltip } from "antd";

import CustomTagSimple from "components/CustomTagSimple";

export interface TagListProps {
  /**
   * Array of tag string
   */
  tags: String[];
  /**
   * Number of tags to be displayed directly
   */
  showNTags?: number;
}

export const StyledTagList = styled.div`
  .CustomTagSimple:last-child {
    margin-right: 0;
  }
`;

const StyledTooltipTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 250px;
  .CustomTagSimple {
    margin-bottom: 4px;
    margin-top: 4px;
  }
`;

/**
 * Empty component with a message and children components for actions
 */
export const TagList = ({ tags, showNTags = 3 }: TagListProps) => {
  const tagNodes = tags.map((tag, i) => (
    <CustomTagSimple key={`${tag}-${i}`} name={tag} />
  ));
  return (
    <StyledTagList>
      {tagNodes.slice(0, showNTags)}
      {tags.length > showNTags && (
        <Tooltip title={<StyledTooltipTag>{tagNodes}</StyledTooltipTag>}>
          <CustomTagSimple name="..." />
        </Tooltip>
      )}
    </StyledTagList>
  );
};
