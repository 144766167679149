import { loop, Cmd } from 'redux-loop';
import { requestReportingStructure, requestTaxonomies } from 'actions/api';

const TARGET = 'reporting_structure';
const SECONDARY_TARGET = ['kpi_delete', 'category_delete', '4.0_kpi_edit', '4.0_category_edit', '4.0_kpi_add', '4.0_category_add', 'kpi_value_status'];

const isTarget = (target) => [
  TARGET,
].includes(target);

const isSecondaryTarget = (target) => SECONDARY_TARGET.includes(target);

const initialState = {
  data: null,
  loading: false,
  error: null,
  saving: null,
  loadingKpiValueStatus: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target === 'kpi_value_status') {
        return {
          ...state,
          loadingKpiValueStatus: true,
        };
      }
      if(isSecondaryTarget(action.target)) return {
        data: state.data,
        loading: state.loading,
        error: null,
        saving: true
      }
      if(!isTarget(action.target)) return state;
      return {
        data: state.data,
        loading: action.ignoreLoading ? false : true,
        error: null,
        saving: state.saving
      };
    case 'API_CALL_COMPLETE':
      if (action.response.target === 'kpi_value_status') {
        let data = state.data && [...state.data];
        return {
          ...state,
          data: data && data.map(
            item => ({
              ...item,
              kpis: item.kpis.map(kpi => {
                if (kpi.slug === action.response.kpiSlug) {
                  return {
                    ...kpi,
                    kpi_value_status_for_whole_tree: action.response.result.status,
                  };
                }
                return kpi;
              })
            })
          ),
          loadingKpiValueStatus: false,
        };
      }
      //TODO: skipped put so far, pending handling errors
      if(isSecondaryTarget(action.response.target)) {
        const urlParams = action.response.endpoint.split('/')
        const organization_slug = urlParams[0]
        const suborganization_slug = urlParams[2]
        return loop(
          state,
          Cmd.list([
            Cmd.action(
              requestReportingStructure({
                organization_slug: organization_slug,
                suborganization_slug: suborganization_slug,
                ignoreLoading: true
              })
            ),
            Cmd.action(
              requestTaxonomies(organization_slug)
            )
          ])
        );
      }
      if(!action.response || !isTarget(action.response.target) || action.response.method === 'PUT') return state;
      return {
        data: action.response.result,
        loading: false,
        error: null,
        saving: state.saving === true ? false : null
      };
    case 'API_CALL_FAILED':
      if(!action.request || (action.request.target !== 'kpi_value_status')) return state;
      return {
        ...state,
        loadingKpiValueStatus: false,
      };
    default:
      return state;
  }
};

export {
  reducer as reporting_structure,
};
