import React, {
  useCallback,
  useMemo,
} from 'react';
import { Row, Col } from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import useOrganizations from 'utils/useOrganizations';
import {
  switchCurrentOrganization,
} from 'actions/organizationTree';
import OrganizationSelector from 'components/OrganizationSelector';

import AvatarDropdown from './AvatarDropdown';
import ProductSelector from './ProductSelector';
import LanguagesDropdown from './LanguagesDropdown';
import {updateQueryParam} from 'utils/queryParameters';
import { useFeatureList } from 'components/FeatureSwitch';
import NotificationCenter from 'components/NotificationCenter';
import './style.less';

const AppHeader = ({
  location,
  history,
  disabledOrganizationTreeSelector = false,
}) => {
  const { search, pathname } = location;
  const dispatch = useDispatch();

  const {
    organization: mainOrganization,
    memberships,
    suborganization,
    organizationTree,
    organization
  } = useOrganizations();

  const {
    data: profile
  } = useSelector(state => state.profile);

  const {
    avatar,
    name,
    user_language
  } = profile || {};

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const langQueryValue = useMemo(() => urlParams.get('lang'), [urlParams]);
  const defaultValue = useMemo(() => langQueryValue || suborganization.language, [langQueryValue, suborganization.language]);

  const orgHasUserLang = useMemo(() => organization.languages.includes(user_language), [
    organization.languages, user_language
  ]);

  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);


  const onOrganizationSelection = useCallback(
    (org) => dispatch(switchCurrentOrganization(org)),
    [ dispatch ]
  );

  const onLanguageChange = useCallback((lang) => {
    window.location.assign(`${pathname}?${updateQueryParam('lang', lang)(urlParams)}`);
  }, [pathname, urlParams]);

  const onNotificationNavigation = useCallback((route) => {
    history.push(route);
  }, 
  //eslint-disable-next-line
  []);

  return (
    <section className='AppHeader'>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
      >
        <Col
          className="AppHeader__left"
        >
          <ProductSelector
            organization={suborganization}
          />
        </Col>
        <Col
          className="AppHeader__right"
        >
          <Row
            type='flex'
            justify='end'
            align='middle'
            gutter={8}
          >
          { !isRestrictedAdmin &&  
            <Col span={1} className="NotificationCenter__container">
              <NotificationCenter
                onNavigation={(link) => onNotificationNavigation(link)}
              />
            </Col>
          }
           
            <Col style={{position: 'initial'}}>
              <OrganizationSelector
                tree={organizationTree}
                current={suborganization}
                onSelect={onOrganizationSelection}
                disabled={disabledOrganizationTreeSelector}
              />
            </Col>
            <Col>
              <AvatarDropdown
                dispatch={dispatch}
                history={history}
                avatar={avatar}
                name={name}
                memberships={memberships}
                organization={suborganization}
                mainOrganization={mainOrganization}
              />
            </Col>
            {
              !orgHasUserLang &&
              <Col span={4}>
                <LanguagesDropdown
                  organization={organization}
                  onChange = {onLanguageChange}
                  defaultValue = {defaultValue}
                />
              </Col>
            }

          </Row>
        </Col>
      </Row>

    </section>
);
}

export default injectIntl(
  withRouter(
    AppHeader
  )
);
