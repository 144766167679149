import React, {
  useMemo,
  useCallback,
} from 'react';
import { withRouter } from "react-router";
import { injectIntl } from 'react-intl';

import CustomTabs from 'components/CustomTabs';
import { useFeatureList } from 'components/FeatureSwitch';
import useOrganizations from 'utils/useOrganizations';

import FilesTab from './FilesTabs/Files';
import FilesDownloadTab from './FilesTabs/FilesDownload';

const TABS = ['files', 'downloads'];
const DEFAULT_TAB = 'files';


const Files = ({
  history,
  match,
  intl,
}) => {
  //const t = intl.messages;

  const {
    suborganization,
  } = useOrganizations();

  const activeTab = match.params.category || DEFAULT_TAB;

  const setActiveTab = useCallback((tab) => {
    history.push(`/data/files/${tab}`);
  }, [
    history,
  ]);

  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);
  const canExportKpis = useMemo(() => (
    ( !featureList.has('restricted_admins') || suborganization?.permissions?.can_affect_all_kpis ) && suborganization?.permissions?.can_export_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const tabs = useMemo(() => {
    let tabs = TABS.filter(tab => ({
        files: !isRestrictedAdmin,
        downloads: canExportKpis,
      }[tab] !== false
    ));

    return tabs;
  }, [
    isRestrictedAdmin,
    canExportKpis
  ]);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, [
    setActiveTab,
  ]);

  const handleDownload = useCallback(() => {
      setActiveTab('downloads');
  }, [
    setActiveTab,
  ]);

  return (
    <div className="DataManagement__filesRoot">

    <div className="DataManagement__filesTabs">
      <CustomTabs
        intl={intl}
        // title={intl.formatMessage({ id: 'filemanagement' })}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        namePrefix="filemanagement"
      />
    </div>  

    {
      activeTab === 'files' &&
      <FilesTab onDownload={handleDownload}/>
    }

    {
      activeTab === 'downloads' &&
      <FilesDownloadTab />
    }


    </div>
  );
}

export default injectIntl(
  withRouter(
    Files
  )
)
