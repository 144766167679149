import React, {
  useMemo,
} from 'react';
import { Tooltip } from 'antd';
import './style.less';

const codeSplitRegExp = new RegExp('[-.]');

const COLORS = {
  equality: () => ['#f1a0ff', '#5a0e62'],
  gri: (code) => {
    switch(code && code[0]) {
      case '1':
        return ['#fff', '#003C72'];
      case '2':
        return ['#fff', '#732281'];
      case '3':
        return ['#fff', '#00956D'];
      case '4':
        return ['#fff', '#FF6B01'];
      default:
        return ['#fff', '#003C72'];
    }
  },
  'gri-2021': (code) => {
    if(code) {
      const firstCode = code.split(codeSplitRegExp).filter(Boolean)[0];
      if(firstCode && firstCode.length <= 2) {
        return ['#fff', '#003C72'];
      }
    }
    switch(code && code[0]) {
      case '1':
        return ['#fff', '#003C72'];
      case '2':
        return ['#fff', '#732281'];
      case '3':
        return ['#fff', '#00956D'];
      case '4':
        return ['#fff', '#FF6B01'];
      default:
        return ['#fff', '#003C72'];
    }
  },
  sdg: () => ['#fff', '#444'], // TODO: SDG colors
  sdgc: () => ['#fff', '#444'], // TODO: SDG colors
  bcorp: () => ['#fff', '#444'], // TODO: BCorp colors
  einf: () => ['#fff', '#444'], // TODO: EINF colors
  euss: () => ['#fff', '#444'], // TODO: EUSS colors
  ungc: () => ['#fff', '#444'], // TODO: UNGC colors
  tefce: () => ['#fff', '#444'], // TODO: TEFCE colors
  prme: () => ['#fff', '#444'], // TODO: PRME colors
  'sasb-hc-dy': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-hc-di': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-hc-dr': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-hc-mc': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-hc-ms': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-hc-bp': () => ['#fff', '#444'], // TODO: SASB colors
  'sasb-fn-ac': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-cb': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-cf': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-in': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-ib': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-mf': () => ['#fff', '#444'], // TODO: colors
  'sasb-fn-ex': () => ['#fff', '#444'], // TODO: colors
  'sasb-cg-aa': () => ['#fff', '#444'], // TODO: colors
  'sasb-cg-mr': () => ['#fff', '#444'], // TODO: colors
  'tcfd': () => ['#fff', '#444'], // TODO: colors
  'sasb-fb-fr': () => ['#fff', '#444'], // TODO: colors
  'sasb-if-re': () => ['#fff', '#444'], // TODO: colors
  'sfdr': () => ['#fff', '#444'], // TODO: colors
  'shift': () => ['#fff', '#444'], // TODO: colors
  'scority': () => ['#fff', '#444'], // TODO: colors
  'ghg': () => ['#fff', '#444'], // TODO: colors
  disabled: () => ['#fff', '#999'],
  default: () => ['#fff', '#444'],
};

const useColors = (standard, code) => {
  return useMemo(() => {
    return (COLORS[standard] || COLORS.default)(code);
  }, [
    standard,
    code,
  ]);
};

const RealTag = ({
  code,
  className,
  color,
  backgroundColor,
}) => (
  <span
    className={`ReportingStandardTag ${className || ''}`}
    style={{
      color,
      backgroundColor,
    }}
  >
    { code }
  </span>
);

const ReportingStandardTag = ({
  standard,
  name,
  code = '',
  className = '',
  disabled = false,
  showTooltip = false,
}) => {
  const [color, backgroundColor] = useColors(disabled ? 'disabled' : standard, code);

  return showTooltip
    ? (
      <Tooltip
        title={name}
        placement="bottom"
      >
        <RealTag
          code={code}
          color={color}
          backgroundColor={backgroundColor}
          className={className}
        />
      </Tooltip>
    ) : (
      <RealTag
        code={code || standard.toUpperCase()}
        color={color}
        backgroundColor={backgroundColor}
        className={className}
      />
    );
}

export default ReportingStandardTag;
