import React, { FunctionComponent, ReactElement, SVGProps } from "react";
import styled from "@emotion/styled";

import type { FormattedMessage } from "react-intl";

import { ReactComponent as EmptyDefaultAnimation } from "assets/animations/searchNoResult.svg";

export interface EmptyProps {
  /**
   * Animation component
   */
  svgAnimation?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /**
   * Intro message
   */
  message: string | ReactElement<FormattedMessage>;
  /**
   * Explanation/reason message
   */
  explanation?: string | ReactElement<FormattedMessage>;
  /**
   * Child components
   */
  children?: React.ReactNode;
}

const defaultPrimaryColor = "#147F74";

export const StyledEmpty = styled.div<EmptyProps>`
  color: #23302e;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.15;
  padding: 80px 0;
  text-align: center;
  .animated {
    margin: 0 auto;
    display: block;
    max-width: 300px;
    margin-bottom: 24px;
  }
  .link,
  &:visited {
    color: ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    text-decoration: none;
    font-weight: 500;
    &:hover {
      color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
  }
  .text--bold {
    line-height: 1.5;
    margin-bottom: 16px;
    font-weight: 500;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text--regular {
    line-height: 1.5;
  }
  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

/**
 * Empty component with a message and children components for actions
 */
export const Empty = ({
  svgAnimation = EmptyDefaultAnimation,
  message,
  explanation,
  children,
  ...props
}: EmptyProps) => {
  const AnimationComponent = svgAnimation;

  return (
    <StyledEmpty message={message} {...props}>
      <>
        <AnimationComponent />
        <p className="text--bold">{message}</p>
        {explanation && <p className="text--regular">{explanation}</p>}
        {children}
      </>
    </StyledEmpty>
  );
};

/**
 * Empty component to render inside a card
 */
export const EmptyCard = styled(Empty)<EmptyProps>`
  font-size: 12px;
  padding: 16px 0;
  .animated {
    max-width: 100px;
    margin-bottom: 16px;
  }
  .text--bold {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
