import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { uniqBy } from 'lodash';
import { Row, Col, Tooltip, Modal } from 'antd';
import EsgLogo from 'components/EsgLogo';
import HeaderStatusLabel from 'components/HeaderStatusLabel';
import ReportingStandardTag from 'components/ReportingStandardTag';
import CustomButton from 'components/CustomButton';
import ModalEditKPI from 'components/ModalEditKPI';
import { SDGLogoList } from '../../components/SDGList';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import {
  useDispatch,
} from 'react-redux';

import {
  deleteKpi,
} from 'actions/api';


const Header = ({
  intl,
  id,
  name,
  name_translations,
  slug,
  collection_frequency,
  type,
  sdgs = [],
  standard_info = {},
  last_updated_at,
  last_collection_at,
  is_custom,
  schema,
  schemaLabels,
  permissions = {},
  loading,
  guidelines_std,
  organization_slug,
  suborganization_slug,
  enabled_reports = [],
  periodicities = [],
  first_reported_at,
  kpi_value_status_for_whole_tree,
  isMainOrganization
}) => {
  const dispatch = useDispatch();
  const [showEditKPIModal, setShowEditKPIModal] = useState(false);

  const handleOnDelete = useCallback(
    () => {
      Modal.confirm({
        title: intl.formatMessage({ id: "modaleditkpi_delete_modal_title"}),
        content: intl.formatMessage({ id: "modaleditkpi_delete_modal_description"}),
        okText: intl.formatMessage({ id: "modaleditkpi_delete_modal_ok"}),
        cancelText: intl.formatMessage({ id: "modaleditkpi_delete_modal_cancel"}),
        onOk() {
          dispatch(
            deleteKpi(
              organization_slug,
              suborganization_slug,
              slug,
            )
          );
        },
      })
    },
    [intl, dispatch, organization_slug, suborganization_slug, slug]
  );

  const enabledReportSet = useMemo(() => {
    // NOTICE: all the organizations should support 'aplanet' codes
    return new Set((enabled_reports || []).concat(organization_slug));
  }, [
    enabled_reports,
    organization_slug,
  ]);

  return (
    <React.Fragment>
      <section className="KpiDetail__hero" >
        <Row type="flex" className="KpiDetail__first-row KpiDetail__flex-sw KpiDetail__flex-center" gutter={{ xs: 8, sm: 16, md: 24}}>
          <Col>
            <HeaderStatusLabel
              last_updated_at={last_updated_at}
              last_collection_at={last_collection_at}
            />
          </Col>
          <Col>
            <EsgLogo type={type} />
          </Col>
        </Row>
        <Row type="flex" className='KpiDetail__second-row' gutter={{ xs: 8, sm: 16, md: 24}}>
          <Col style={{ flex: 1 }}>
            <span className="KpiDetail__name">
              { name }
            </span>
          </Col>
        </Row>
        <Row type="flex" className="KpiDetail__third-row KpiDetail__flex-sw" gutter={{ xs: 8, sm: 16, md: 24}}>
          <Col className='KpiDetail__flex-end'>
            <span className='KpiDetail__collection_frequency'>
              { intl.formatMessage({ id: "collection_frequency"}) }: { intl.formatMessage({id: `collection_frequency_${collection_frequency}` })}
            </span>
          </Col>
          <Col className="KpiDetail__flex">
            <section className='KpiDetail__sdgs'>
              <span>{ intl.formatMessage({ id: "sdg"}) }</span>
              <section>
                <SDGLogoList
                  sdgs={sdgs}
                  className='KpiDetail__sdg'
                  square={true}
                />
              </section>
            </section>
            {
              // NOTICE: We only show standards that have a code, and only if you org has it
              Object.keys(standard_info)
                .filter(standard => enabledReportSet.has(standard))
                .sort((a,b) => a < b)
                .map(standard => {
                  const values = (standard_info[standard] || []).filter(({code}) => !!code);
                  return ( values.length > 0 &&
                    <section
                      key={standard}
                      className='KpiDetail__standards'
                    >
                      <span className='KpiDetail__standards-title'>
                        { standard !== organization_slug ? intl.formatMessage({ id: `reporting_standard_${standard}` }) : ""}
                      </span>
                      {
                        uniqBy(values, 'code').map(value => (
                          <ReportingStandardTag
                            standard={standard}
                            showTooltip
                            {...value}
                          />
                        ))
                      }
                    </section>
                  );
                })
            }
            {
              !is_custom || !permissions.can_configure_kpi || !isMainOrganization ? null :
              <section
                className='KpiDetail__standards__button'
              >
                <CustomButton
                  type="dark"
                  onClick={() => setShowEditKPIModal(true)}
                >
                  { intl.formatMessage({ id: "kpidetail_edit_button"}) }
                </CustomButton>
                &nbsp;
                <Tooltip title={ intl.formatMessage({ id: "modaleditkpi_delete"}) }>
                  <CustomButton
                    type="dark"
                    icon={<DeleteOutlined />}
                    onClick={handleOnDelete}
                  />
                </Tooltip>
              </section>
            }
          </Col>
        </Row>
      </section>
      {is_custom && showEditKPIModal &&
        <ModalEditKPI
          key={id}
          onClose={() => setShowEditKPIModal(false)}
          name={name}
          name_translations={name_translations}
          kpi_slug={slug}
          sdgs={sdgs}
          standard_info={standard_info}
          schema={schema}
          schemaLabels={schemaLabels}
          loading={loading}
          esg_type={type}
          guidelines_std={guidelines_std}
          periodicities={periodicities}
          first_reported_at={first_reported_at}
          kpi_value_status_for_whole_tree={kpi_value_status_for_whole_tree}
        />
      }
    </React.Fragment>
  );
};

export default injectIntl(Header);
