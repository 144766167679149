import React, { useCallback, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { ESG_TYPES } from 'utils/kpi'; 
import EsgLogo from 'components/EsgLogo';
import CustomInput from 'components/CustomInput';
import CustomMultiSelect from 'components/CustomMultiSelect';

import {
  Row,
  Col,
} from 'antd';
import SDGFilter from "../SDGFilter";

const REPORTING_STANDARDS = [
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'custom',
];

const SustainableIssuesFilter = ({
  intl,
  filterIssues,
  enabledReports,
}) => {
  const t = intl.messages;
  const [filters, setFilters] = useState({});

  const reportingStandards = useMemo(() => {
    return REPORTING_STANDARDS
      .filter(slug => enabledReports.includes(slug))
      .map(slug => ({
        slug,
        name: t[`reporting_standard_${slug}`],
      }));
  }, [
    t,
    enabledReports,
  ]);

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      slug,
      name: t[`esg_type_${slug}`],
    }));
  }, [
    t,
  ]);

  const onFilter = useCallback(
    (key, value) => {
      const newFilters = {
        ...filters,
        [ key ]: value,
      };
      setFilters(newFilters);
      filterIssues(newFilters);
    },
    [filters, filterIssues]
  );
  const onSearch = useCallback(
    value => {
      const newFilters = { ...filters, search: value };
      setFilters(newFilters);
      filterIssues(newFilters);
    },
    [filters, filterIssues]
  );

  return (
    <Row className="filters" gutter={10}>
      <Col span={9}>
        <CustomInput
          placeholder={t.search}
          onChange={e => onSearch(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <SDGFilter
          selected={filters.sdgs}
          onSelect={val => onFilter('sdgs', val)}
          wideMenu={true}
          inModal={true}
        />
      </Col>
      <Col span={5}>
        <CustomMultiSelect
          title={t.item_manager_standard}
          options={reportingStandards}
          selected={filters.standards}
          onSelect={val => onFilter('standards', val)}
          inModal={true}
        />
      </Col>
      <Col span={5}>
        <CustomMultiSelect
          title={t.item_manager_type}
          options={esgTypes}
          selected={filters.esg_types}
          onSelect={val => onFilter('esg_types', val)}
          renderOptionPrefix={slug => <EsgLogo key={slug} type={slug}/>}
          inModal={true}
        />
      </Col>
    </Row>
  );
};

export default injectIntl(SustainableIssuesFilter);
