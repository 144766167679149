import React, {
  useEffect,
  useMemo,
  //useCallback,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';
import Loading from 'containers/Loading';
import NotFound from 'containers/NotFound';
import AccountsRedirect from 'containers/AccountsRedirect';
import KpiDetail from 'containers/KpiDetail';
import DashboardRoutes from 'containers/Dashboard/routes';
import { useFeatureList } from 'components/FeatureSwitch';
import GroupsOfInterest from 'containers/GroupsOfInterest';
import Materiality from 'containers/Materiality';
import EqualityPlans from 'containers/EqualityPlans';
import EqualityPlan from 'containers/EqualityPlan';
import Analysis from 'containers/Analysis';
import SurveyBuilder from 'containers/SurveyBuilder';
import Survey from 'containers/Survey';
import NotSubscribed from 'containers/NotSubscribed';
import DataManagement from 'containers/DataManagement'
import Reports from 'containers/Reports';
import NeutralityDisabledRedirect from 'containers/NeutralityDisabledRedirect';
import useOrganizations from 'utils/useOrganizations';
import { getNormalizedKpiVariables } from 'actions/api';

const ProtectedRoute = ({
  isLoggedIn,
  hasOrganizationRequirement,
  ...props
}) => (
  isLoggedIn
    ? (
      hasOrganizationRequirement
      ? <Route {...props} />
      : <Redirect to="/organizations" />
    ) :
      <Redirect to="/signin" />
);

const Routes = ({
  auth,
  organization,
  getNormalizedKpiVariables,
}) => {

  const {
    features: featureList,
    upselling,
    setFeatures,
  } = useFeatureList();

  const [ varsFor, setVarsFor ] = useState(null);

  const {
    suborganization,
  } = useOrganizations();

  useEffect(
    () => {
      // TODO: Workaround, please fix
      if (
        organization?.data?.slug && suborganization?.slug
        && varsFor !== `${organization.data.slug} // ${suborganization.slug}`
        && auth.logged_in
      ) {
        setVarsFor(`${organization.data.slug} // ${suborganization.slug}`);
        getNormalizedKpiVariables(
          organization.data.slug, suborganization.slug
        );
      }
    },
    [
      organization,
      suborganization,
      getNormalizedKpiVariables,
      varsFor,
      auth.logged_in,
    ]
  );

  useEffect(
    () => {
      if (suborganization?.slug) {
        setFeatures({
          features: featureList,
          upselling,
          suborganizationFeatures: new Set(
            Object.keys(suborganization.product_config?.atlas?.features || [])
              .filter(feature => suborganization.product_config?.atlas?.features?.[feature])
          ),
        })
      }
    },
    [
      suborganization,
      featureList,
      upselling,
      setFeatures,
    ]
  );

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);

  return (
    <Switch>
      <Route exact path="/" component={() => {
        if(window.location.pathname  !== '/') {
          window.location = `${window.location.protocol}//${window.location.host}/#${window.location.pathname}`;
          return null;
        }
        const defaultRoute = auth.logged_in ? (
          organization.data
            ? '/data'
            : '/organizations'
        ) : '/signin';
        return <Redirect to={defaultRoute} />
      }} />
      <Route path="/loading" component={Loading.Big} />
      <Route path="/signin" component={AccountsRedirect} />
      <Route path="/recover_password/:token" component={AccountsRedirect} />
      <Route path="/signup/:token" component={AccountsRedirect} />
      <Route path="/recover_password" component={AccountsRedirect} />
      <Route path="/survey/:mainOrgSlug/:subOrgSlug/:uuid" component={Survey} />
      <Route path="/logout" component={AccountsRedirect} />
      <Route path="/not_subscribed" component={NotSubscribed} />

      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={true}
        path="/organizations"
        component={AccountsRedirect}
      />

      { !featureList.has('dashboard') ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/dashboard/:activeTab?"
          render={({ match, history }) => (
            <DashboardRoutes history={history} match={match} />
          )}
        />
      }
      
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/kpi/:slug/:period/:action?"
        render={({ match, history }) => (
          <KpiDetail urlBase="kpi" history={history} match={match} />
        )}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/data/fill/:standard/:slug/:period/:action?"
        render={({ match, history }) => (
          <KpiDetail
            urlBase={`data/fill/${match.params.standard}`}
            history={history}
            match={match}
          />

        )}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/data/:tab(files|history|pending|requests)/:slug/:period/:action?"
        render={({ match, history }) => (
          <KpiDetail
            urlBase={`data/${match.params.tab}`}
            history={history}
            match={match}
          />

        )}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/profile"
        component={AccountsRedirect}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/organization"
        component={AccountsRedirect}
      />
      { !featureList.has('groups_of_interest') || isRestrictedAdmin ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/groups-of-interest"
          component={GroupsOfInterest}
        />
      }
      { featureList.has('neutrality') || isRestrictedAdmin ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/neutrality-disabled"
          component={NeutralityDisabledRedirect}
        />
      }
      { !featureList.has('materiality') || isRestrictedAdmin ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/materiality"
          component={Materiality}
        />
      }
      { !featureList.has('equality') || isRestrictedAdmin? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/equality-plan/:id/data-collection/:slug/:period/:action?"
          render={({ match, history }) => (
            <KpiDetail
              urlBase={`equality-plan/${match.params.id}/data-collection`}
              history={history}
              match={match}
            />
          )}
        />
      }
      { !featureList.has('equality') || isRestrictedAdmin ? null :
        <ProtectedRoute
          exact
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/equality-plan"
          component={EqualityPlans}
        />
      }
      { !featureList.has('equality') || isRestrictedAdmin ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/equality-plan/:id/:stage?"
          component={EqualityPlan}
        />
      }
      { (isRestrictedAdmin || !suborganization?.permissions?.can_report_kpi) ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          exact
          path="/report/:activeTab?"
          component={Reports}
        />
      }
      { isRestrictedAdmin ? null :
        <ProtectedRoute
          isLoggedIn={auth.logged_in}
          hasOrganizationRequirement={!!organization.data}
          path="/report/:standard/:slug/:period/:action?"
          render={({ match, history }) => (
            <KpiDetail
              urlBase={`report/${match.params.standard}`}
              history={history}
              match={match}
            />
          )}
        />
      }
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        exact
        path="/analysis/:id(\d+)/:stage?"
        component={Analysis}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/analysis/:analysisId(\d+)/survey/:surveyId(\d+)/:stage?"
        component={SurveyBuilder}
      />
      <ProtectedRoute
        isLoggedIn={auth.logged_in}
        hasOrganizationRequirement={!!organization.data}
        path="/data/:activeTab?/:category?"
        render={({ match, history }) => (
          <DataManagement
            history={history}
            match={match}
          />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = ({
  auth,
  organization,
}) => ({
  auth,
  organization,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getNormalizedKpiVariables,
    }
  )(Routes)
);
