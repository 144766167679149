import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'tsComponents/emptyStates/Loading';
import Variables from 'components/VariablesConfiguration/Variables';
import useOrganizations from 'utils/useOrganizations';
import { getKpiVariables } from 'actions/api';
import CustomModal from 'components/CustomModal';

import {
  Tabs,
  Modal,
} from 'antd';

import './style.less';
import { groupBy } from 'lodash';


const { TabPane } = Tabs;

const VariablesConfiguration = ({
  intl,
  visible,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [editingVariables, setEditingVariables] = useState([]);

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();

  useEffect(
    () => {
      if (organization?.slug && suborganization?.slug) {
        dispatch(
          getKpiVariables(organization.slug, suborganization.slug)
        );
      }
    },
    [
      dispatch,
      organization,
      suborganization,
    ]
  );

  const getGroupedVariables = useCallback(
    (items) => {
      let grouped = {};

      items.forEach(
        variable => {
          grouped = {
            ...grouped,
            [variable.locale]: {
              ...(grouped[variable.locale] || {}),
              [variable.organization_id ? 'custom' : 'default']: variable,
            },
          };
        }
      );
      return grouped;
    },
    []
  );

  const getGroupedOptions = useCallback(
    (items) => {
      let grouped = {};

      items.forEach(
        option => {
          grouped = {
            ...grouped,
            [option.option_slug]: {
              ...(grouped[option.option_slug] || {}),
              [option.locale]: {
                ...(grouped[option.option_slug]?.[option.locale] || {}),
                [option.organization_id ? 'custom' : 'default']: option,
              }
            },
          };
        }
      );
      return grouped;
    },
    []
  );

  const {
    fetching,
    data,
  } = useSelector(state => state.kpi_variable);

  const preOwnedVariables = useMemo(
    () => {
      if (!data) {
        return [];
      }

      let variables = [];
      const preOwned = groupBy(
        data.filter(item => !item.organization_id && !item.option_slug),
        'slug'
      );

      Object.keys(preOwned).forEach(slug => {
        const variable = getGroupedVariables(
          data.filter(item => item.slug === slug && !item.option_slug)
        );
        const options = getGroupedOptions(
          data.filter(item => item.slug === slug && item.option_slug)
        );

        variables.push({
          slug,
          variable,
          options,
        });
      });
      return variables;
    },
    [
      data,
      getGroupedVariables,
      getGroupedOptions,
    ]
  );

  const ownVariables = useMemo(
    () => {
      if (!data) {
        return [];
      }

      let variables = [];
      const owned = groupBy(
        data.filter(
          item => item.organization_id
            && !item.option_slug
            && !item.default_variable_id
        ),
        'slug'
      );

      Object.keys(owned).forEach(slug => {
        const variable = getGroupedVariables(
          data.filter(item => item.slug === slug && !item.option_slug)
        );
        const options = getGroupedOptions(
          data.filter(item => item.slug === slug && item.option_slug)
        );

        variables.push({
          slug,
          variable,
          options,
          deprecated: !!data
            .filter(item => item.slug === slug && !item.option_slug)
            .find(({status}) => status === 'deprecated'),
        });
      });
      return variables;
    },
    [
      data,
      getGroupedVariables,
      getGroupedOptions,
    ]
  );

  const handleOnClose = useCallback(
    () => {
      if (editingVariables.length === 0) {
        onClose();
        return;
      }
      Modal.confirm({
        title: intl.formatMessage({ id: 'variables_configuration_close_without_save' }),
        okText: intl.formatMessage({ id: 'variables_configuration_close_without_save_yes' }),
        cancelText: intl.formatMessage({ id: 'variables_configuration_close_without_save_no' }),
        onOk: onClose,
      });
    },
    [
      intl,
      editingVariables,
      onClose,
    ]
  );

  const allVariables = useMemo(
    () => [...preOwnedVariables, ...ownVariables],
    [
      preOwnedVariables,
      ownVariables,
    ]
  );

  return (
    <CustomModal
      className="VariablesConfiguration"
      shown={visible}
      onCancel={handleOnClose}
      title={intl.formatMessage({ id: 'variables_configuration' })}
      footer={null}
      width={'90%'}
    >
      { 
        (fetching || !data)
        ? <Loading />
        : <Tabs defaultActiveKey="preOwned">
            <TabPane
              tab={intl.formatMessage({ id: 'variables_configuration_preowned' })}
              key="preOwned"
            >
              <Variables
                allVariables={allVariables}
                data={preOwnedVariables}
                editingVariables={editingVariables}
                setEditingVariables={setEditingVariables}
                canAdd={false}
                canManage={permissions.can_manage_variables_configuration}
              />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'variables_configuration_own' })}
              key="own"
            >
              <Variables
                allVariables={allVariables}
                data={ownVariables}
                canAdd={permissions.can_manage_variables_configuration}
                canManage={permissions.can_manage_variables_configuration}
                editingVariables={editingVariables}
                setEditingVariables={setEditingVariables}
              />
            </TabPane>
          </Tabs>
      }
    </CustomModal>
  );
}

export default injectIntl(VariablesConfiguration);
