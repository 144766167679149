import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

// This sucks, but antd DatePicker
import moment from 'moment';

import {
  useDispatch,
} from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import {
  useCollectionFrequencyOptions,
} from 'utils/collectionFrequency';

import {
  formatDateShortMedium,
} from 'utils/date';

import {
  updateKpiSettings,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import CustomRadio from 'components/CustomRadio';
import CustomModal from 'components/CustomModal';
import CustomSelect from 'components/CustomSelect';

import {
  Switch,
  Row,
  Col,
} from 'antd';

import {
  findTree,
} from 'utils/tree';

import { ORGANIZATION_TARGET_OPTIONS } from 'components/CustomSelect/targets';

import './style.less';

const DEFAULT_CYCLE_DATE_OPTIONS = ['01-01'];

const ModalKpiSettings = ({
  intl,
  visible,
  onClose,
  loading = false,
  error,
  kpi_slugs = [],
  all_periods = [],
  collection_frequency: defaultCollectionFrequency = 'year',
  cycle_date: defaultCycleDate = '01-01',
  first_reported_at,
  valid_collection_frequencies = [],
  can_override = false,
  mandatory_data,
  org_mandatory_data
}) => {
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
    organizationTree
  } = useOrganizations();

  const hasChildren = useMemo(() => {
    const treeNode = findTree(
      [organizationTree],
      ({ slug }) => slug === suborganization.slug,
    );

    // TODO: MAYBE consider 'restricted' children as non-existing??
    return treeNode && treeNode.children && treeNode.children.length > 0;
  }, [
    suborganization,
    organizationTree,
  ]);

  const cycleDateOptions = useMemo(() => {
    if (suborganization.slug === organization.slug) {
      // Top level org, all options are possible
      return organization?.config?.cycle_date_options || DEFAULT_CYCLE_DATE_OPTIONS;
    }

    // Suborgs only get the one possible cycle date
    // But notice this will never be used because for now you can only create KPIs in a top-level org
    return (
      suborganization?.config?.start_fiscal_year && [suborganization?.config?.start_fiscal_year]
    ) || (
        organization?.config?.cycle_date_options?.length > 0
        && [organization?.config?.cycle_date_options[0]]
      ) || DEFAULT_CYCLE_DATE_OPTIONS;
  }, [
    organization,
    suborganization,
  ]);

  const cycleDateOptionsTranslated = useMemo(() => {
    return (cycleDateOptions || []).map(slug => ({
      slug,
      name: formatDateShortMedium(`1970-${slug} 12:00:00.000000Z`, intl),
    }));
  }, [
    cycleDateOptions,
    intl,
  ]);

  const [submitting, setSubmitting] = useState(false);
  const collectionFrequencyOptions = useCollectionFrequencyOptions(intl);

  const [collectionFrequency, setCollectionFrequency] = useState(defaultCollectionFrequency);
  const [cycleDate, setCycleDate] = useState(defaultCycleDate);
  const [affectOldPeriods, setAffectOldPeriods] = useState(false);
  const [mandatoryData, setMandatoryData] = useState(mandatory_data);
  const [target, setTarget] = useState('self');

  const year = useMemo(() => {
    const year = moment(first_reported_at).year();
    return year;
  }, [
    first_reported_at,
  ]);

  const handleCycleDateChange = useCallback((cycle_date) => {
    setCycleDate(
      cycle_date || moment(first_reported_at).format('MM-DD')
    );
  }, [
    first_reported_at,
  ]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    dispatch(
      updateKpiSettings()({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        kpi_slugs,
        settings: {
          collection_frequency: collectionFrequency,
          cycle_date: cycleDate,
        },
        affect_old: affectOldPeriods,
        mandatory_data: mandatoryData,
        target: target
      })
    )
  }, [dispatch, organization.slug, suborganization.slug, kpi_slugs, collectionFrequency, cycleDate, affectOldPeriods, mandatoryData, target]);

  const handleAffectOldPeriods = useCallback((affect) => {
    setAffectOldPeriods(affect);
    if (!affect && cycleDate !== defaultCycleDate) {
      setCycleDate(defaultCycleDate);
    }
  }, [
    cycleDate,
    defaultCycleDate,
  ]);

  const handleTargetChange = useCallback((target) => {
    setTarget(target)
  }, [])

  useEffect(() => {
    // Close the modal
    if (submitting && !loading && !error) {
      setSubmitting(false);
      onClose();
    }
  }, [
    onClose,
    submitting,
    loading,
    error,
  ]);

  useEffect(() => {
    setMandatoryData(mandatory_data);
  }, [mandatory_data]);

  useEffect(() => {
    if (!visible && (
      collectionFrequency !== defaultCollectionFrequency ||
      cycleDate !== defaultCycleDate
    )) {
      setCollectionFrequency(defaultCollectionFrequency);
      setCycleDate(defaultCycleDate);
    }
  }, [
    visible,
    collectionFrequency,
    cycleDate,
    defaultCollectionFrequency,
    defaultCycleDate,
  ]);

  const handleMandatoryDataOnChange = useCallback((key, value) => {
    setMandatoryData({ ...mandatoryData, [key]: value })
  }, [mandatoryData]);

  const noneMandatory = useMemo(() => {
    return Object.keys(mandatoryData || {}).every(key => !mandatoryData[key]);
  }, [mandatoryData]);

  return (
    <CustomModal
      className="ModalKpiSettings"
      shown={visible}
      title={intl.formatMessage({ id: "modalkpisettings_title" })}
      onOk={onSubmit}
      onCancel={onClose}
      footer={
        <div className="ModalKpiSettings__footer">
          <CustomButton
            className="ModalKpiSettings__btn-footer"
            key="back"
            onClick={onClose}
          >
            {intl.formatMessage({ id: "modalkpisettings_cancel" })}
          </CustomButton>
          <CustomButton
            className="ModalKpiSettings__btn-footer"
            key="submit"
            type="primary"
            disabled={noneMandatory}
            onClick={onSubmit}
          >
            {intl.formatMessage({ id: "modalkpisettings_save" })}
          </CustomButton>
        </div>
      }
    >
      {
        !!Object.keys(org_mandatory_data || {}).length &&
        <section className="ModalKpiSettings__firstsection">
          <div className="ModalKpiSettings__title">{intl.formatMessage({ id: "mandatory_settings_information" })}</div>
          {
            Object.keys(org_mandatory_data || {}).map(key => {
              return org_mandatory_data[key] &&
                <Row
                  className="ModalKpiSettings__list"
                  key={key}
                  type="flex"
                  gutter={8}
                >
                  <Col>
                    <Switch
                      checked={mandatoryData[key]}
                      onChange={(value) => handleMandatoryDataOnChange(key, value)}
                      disabled={loading}
                      checkedChildren={intl.formatMessage({ id: "yes" })}
                      unCheckedChildren={intl.formatMessage({ id: "no" })}
                    />
                  </Col>
                  <Col
                    style={{ flex: 1 }}
                  >
                    {
                      intl.formatMessage({ id: `mandatory_${key}` })
                    }
                  </Col>
                </Row>
            })
          }
          {
            noneMandatory && <div style={{ color: 'red' }}>{intl.formatMessage({ id: "mandatory_for_validation" })}</div>
          }
        </section>
      }
      <section className={!Object.keys(org_mandatory_data || {}).length ? 'ModalKpiSettings__firstsection' : ''}>
        <div className="ModalKpiSettings__title">{intl.formatMessage({ id: "collection_frequency" })}</div>
        <CustomRadio.Group
          value={collectionFrequency}
          onChange={(e) => setCollectionFrequency(e.target.value)}
          buttonStyle="solid"
          disabled
        >
          {
            collectionFrequencyOptions.map(frequency => (
              <CustomRadio.Button
                key={frequency.slug}
                value={frequency.slug}
                disabled={!valid_collection_frequencies.includes(frequency.slug)}
              >
                {frequency.name}
              </CustomRadio.Button>
            ))
          }
        </CustomRadio.Group>
      </section>
      <section>
        <div className="ModalKpiSettings__title">{intl.formatMessage({ id: "modalkpisettings_cycle_date" })}</div>
        <div className="ModalKpiSettings__dateSelector">
          {
            affectOldPeriods
              ? (
                <CustomSelect
                  options={cycleDateOptionsTranslated}
                  selected={cycleDate}
                  onSelect={handleCycleDateChange}
                />
              ) : (
                <strong>
                  {formatDateShortMedium(`${year}-${cycleDate} 12:00:00.000000Z`, intl)}
                </strong>
              )
          }
          <strong>{year}</strong>
        </div>
      </section>
      <section>
        <div className="ModalKpiSettings__title">{intl.formatMessage({ id: "modalkpisettings_affect_old" })}</div>
        {
          can_override
            ? (
              <Row
                type="flex"
                gutter={8}
              >
                <Col>
                  <Switch
                    checked={affectOldPeriods}
                    onChange={handleAffectOldPeriods}
                    disabled={loading}
                    checkedChildren={intl.formatMessage({ id: "yes" })}
                    unCheckedChildren={intl.formatMessage({ id: "no" })}
                  />
                </Col>
                <Col
                  style={{ flex: 1 }}
                >
                  {
                    intl.formatMessage({ id: "modalkpisettings_affect_old_question" })
                  }
                </Col>
              </Row>
            ) : (
              <strong>
                {
                  affectOldPeriods
                    ? intl.formatMessage({ id: "modalkpisettings_affect_old_true" })
                    : intl.formatMessage({ id: "modalkpisettings_affect_old_false" })
                }
              </strong>
            )
        }
      </section>
      {hasChildren ?
        (<section>
          <div className="ModalKpiSettings__title">{intl.formatMessage({ id: "applies_not_applies_direction" })}</div>
          <CustomSelect
            title={intl.formatMessage({ id: "applies_not_applies_direction" })}
            selected={target}
            options={ORGANIZATION_TARGET_OPTIONS.map(slug => ({
              name: intl.formatMessage({ id: `applies_not_applies_direction_${slug}` }),
              slug,
            }))}
            onSelect={handleTargetChange}
          />
        </section>)
        : null
      }

    </CustomModal>
  )
};

export default injectIntl(ModalKpiSettings);
