import React, {
  useCallback,
} from 'react'
import { injectIntl } from 'react-intl'
import './style.less'
import { withRouter } from 'react-router';

const Navigator = ({
  intl,
  current,
  history,
  to,
  error,
}) => {
  const onBack = useCallback(() => {
    if(to) {
      history.push(to);
    } else {
      history.goBack();
    }
  }, [
    history,
    to,
  ]);

  return (
    <section className='Navigator'>
      <section
        className='Navigator-goBack'
        onClick={onBack}
      >
        <img
          src='/images/assets/chevron-left.svg'
          alt=''
        />
        <span className='Navigator-back'>
          { intl.formatMessage({ id: "navigator_back"}) }
        </span>
        {!error && <figure className='Navigator-divider' />}
      </section>
      {!error && <span className='Navigator-current'>
        { current }
      </span>}
    </section>
  );
}

export default injectIntl(
  withRouter(
    Navigator
  )
);
