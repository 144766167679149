import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from '@emotion/react'
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import config from 'config';
import { Routes } from 'containers/Routes';
import { FeatureProvider } from 'components/FeatureSwitch';
import { useEventTracking } from 'hooks/useEventTracking';
import updateTheme from 'utils/updateTheme';

const Root = ({
  intl
}) => {

  const { organization } = useSelector(state => state);

  const eventTracking = useEventTracking();
  const [theme, setTheme] = useState({}); // TODO - configure default theme
  const [features, setFeatures] = useState({
    features: new Set(
      (organization.data || {}).features ||
      config.DEFAULT_FEATURES
    ),
    upselling: new Set(
      (organization.data || {}).features_upselling ||
      config.DEFAULT_FEATURES_UPSELLING
    ),
  });

  // Request the profile upon login / org changes
  useEffect(() => {
    const theme = organization.data?.config?.theme;
    updateTheme(theme);
    if(theme) {
      setTheme(theme);
    }
  }, [
    organization.data,
  ]);

  useEffect(() => {
    eventTracking.init();
  }, [eventTracking]);

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
        buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
        descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
        customErrorImage="/images/error_image.png"
      />
    }>
      <ThemeProvider theme={theme}>
        <FeatureProvider
          value={{
            ...features,
            setFeatures,
          }}
        >
          <ConfigProvider locale={intl.formats.antd}>
            <Routes />
          </ConfigProvider>
        </FeatureProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default injectIntl(Root);
