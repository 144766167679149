import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import {
  Modal,
  Button,
  Checkbox
} from 'antd';

import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const MODAL_TYPES = {
  default: 'main',
  main: 'main',
  success: 'success',
  warning: 'warning',
  error: 'error',
}

const CustomModalType = ({
  intl,
  className,
  width = 700,
  showModal = false,
  type = MODAL_TYPES.default,
  title,
  content,
  footer,
  disabledOk,
  onOk,
  onCancel,
  onOkText,
  onCancelText,
  icon,
  iconType,
  hasNotShowAgainCheckbox = false,
  hasCloseButton = false,
}) => {
  const [notShowAgain, setNotShowAgain] = useState(false);

  return (
    <Modal
      visible={showModal}
      width={width}
      className={`CustomModalType ${className ? className : ''} ${type}`}
      title={(
        <div className={`CustomModalType__title ${type}`}>
          {icon ? (
            <AplanetIcon
              className={`CustomModalType__icon ${type}`}
              faStyle={iconType}
              name={icon}
            />
          ) : null}
          {title}
        </div>
      )}
      footer={footer 
        ? footer 
        :(
          <div className="CustomModalType__footer">
            <div className={`CustomModalType__buttons ${type}`}>
              {onCancelText ? (
                <Button onClick={(e) => onCancel(e, notShowAgain)}>
                  {onCancelText}
                </Button>
              ) : null}
              {onOk ? (
                <Button type="primary" onClick={(e) => onOk(e, notShowAgain)} disabled={disabledOk}>
                  {onOkText}
                </Button>
              ) : null}
            </div>
            {hasNotShowAgainCheckbox ? (
              <Checkbox
                className={`CustomModalType__checkbox ${type}`}
                onChange={() => setNotShowAgain(!notShowAgain)} 
                checked={notShowAgain}
              >
                <span className="CustomModalType__checkboxText">
                    {intl.formatMessage({id: 'custom_modal_type_not_show_again'})}
                </span>
              </Checkbox>
            ) : null}
          </div>
        )
      }
      onCancel={onCancel}
      closable={hasCloseButton}
      closeIcon={<AplanetIcon name="Cancel" />}
      centered
    >
      <div className="CustomModalType__content" >{content}</div>
    </Modal>
  )
};

CustomModalType.propTypes = {
  onOkText: PropTypes.string,
  onCancelText: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    MODAL_TYPES.main, 
    MODAL_TYPES.warning,
    MODAL_TYPES.success,
    MODAL_TYPES.error, 
  ]),
  icon: PropTypes.string,
  iconType: PropTypes.string,
  content: PropTypes.element,
  footer: PropTypes.element,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  showModal: PropTypes.bool,
  hasNotShowAgainCheckbox: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  width: PropTypes.number,
};

export default injectIntl(CustomModalType);
