import React, {
    useMemo
} from 'react'
import { injectIntl } from 'react-intl';
import chroma from "chroma-js";
import PropTypes from 'prop-types'
import { isEmpty, isEqual } from 'lodash';
import { defaultColors } from '../useColors.js';

import { cardMap, getParametersFromData, convertData } from './types';
import { propTypes } from '../propTypes';

const DEFAULTS = {
  parameters: {},
  data: {},
  dashboardTheme: defaultColors,
  showDataLabels: false,
  showDataTarget: false
};

const CardChart = ({
  intl, 
  id,
  type,
  parameters = DEFAULTS.parameters,
  data = DEFAULTS.data,
  dashboardTheme = DEFAULTS.dashboardTheme,
  showDataLabels = DEFAULTS.showDataLabels,
  showDataTarget = DEFAULTS.showDataTarget,
  contentRef,
}) => {
  
  const filledDashboardTheme = useMemo(() => {
    return !isEmpty(data) ? data.series.map((_, i) => dashboardTheme[i] || chroma.random().hex()) : []
  }, [data, dashboardTheme])

  const adaptedData = useMemo(() => {
    return convertData(intl, type, data, parameters, filledDashboardTheme, showDataTarget);
  }, [intl, type, data, parameters, filledDashboardTheme, showDataTarget]);

  const adaptedParameters = useMemo(() => {
    return {
      ...(getParametersFromData(data)),
      ...parameters,
      showDataLabels,
      showDataTarget
    }
  }, [ data, parameters, showDataLabels, showDataTarget ]);
  
  const Content = cardMap[type] || cardMap.default;
  
  const {
    periods = DEFAULTS.periods
  } = data || {};

  return (
    <Content
      ref={contentRef}
      slug={periods.length > 0 ? `${id}_${periods[0]}` : id}
      parameters={adaptedParameters}
      data={adaptedData}
      dashboardTheme={dashboardTheme}
    />
  );
};

CardChart.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  data: propTypes.data,
  dashboardTheme: PropTypes.arrayOf(PropTypes.string),
  contentRef: PropTypes.object.isRequired,
  showDataLabels: PropTypes.bool,
  showDataTarget: PropTypes.bool,
};

function cardPropsAreEqual(prevCard, nextCard){
  return isEqual(prevCard.data, nextCard.data);
}

export default React.memo(injectIntl(CardChart), cardPropsAreEqual);