import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import './style.less';
import Answer from 'components/Answer';

import { useAvailableUnits } from 'utils/useAvailableUnits';
import { convertToUnit } from 'utils/units';
import DataTargetIcon from './DataTargetIcon';
import {
  Row,
  Col,
  Checkbox
} from 'antd';
import { formatNumberWithIntl } from 'utils/formatNumber';

const NO_VALUE = '-';
const getFormatOptions = (schema, _config) => {
  const showTrailingZeros = _config?.show_trailing_zeros || false;
  const config = _config?.force_decimal_points || {};

  const forceDecimal = config.hasOwnProperty(schema.metricSlug)
    ? config[schema.metricSlug]
    : config['*']

  const doForce = typeof forceDecimal === 'number';

  const digits = doForce
    ? forceDecimal
    : (schema.decimalPoints || 0);

  return {
    minimumFractionDigits: showTrailingZeros ? digits : 0,
    maximumFractionDigits: digits,
  }
};


const BooleanComponent = injectIntl(({
    intl,
    target,
    justify = "end"
  }) => {
    const t = intl.messages;
    if(target && Object.keys(target).length > 0 ){
          return (
              <Col className="DataTarget">
                      <Row justify={justify}>
                        <Row align="middle" justify="center">
                        {/* <FontAwesomeIcon
                          className='icon-large icon-black' 
                          icon={'bullseye'}
                        /> */}
                        <DataTargetIcon />
                        </Row>
                        
                        <Row align="middle" justify="center">
                          {/* Right/Wrong <section className={`DataTargetLabel ${ target.boolean ? 'positive' : 'negative'} `}> */}
                        {/* Neutral color */}
                          <section className={`DataTargetLabel neutral`}>
                            <span>{ target.boolean ? t.yes : t.no }</span>
                          </section>
                        </Row>
                      </Row>
            </Col>
          );
    } else{
      return (
        <Col span={12}></Col>
      );
    }
  });

const QuantitativeComponent = injectIntl(({
intl,
schema,
schemaLabels,
target,
kpi_value,
config,
justify = "end"
}) => {
  const availableUnits = useAvailableUnits(schema);
  const targetUnit = target.unit ? target.unit : '';

  const unitText = useMemo(() => {
    const unitObject = availableUnits.find(({ slug }) => slug === targetUnit)
    return unitObject ? unitObject.symbol : '';
  }, [
    targetUnit,
    availableUnits,
  ]);
  const convertToTargetBase = targetUnit && convertToUnit(targetUnit, availableUnits);

//  && (typeof target.value === Object && Object.keys(target.value).length > 0)
if(target && target.value !== null && (typeof target === 'object' && Object.keys(target).length > 0) ){
        return (
            <Col className="DataTarget">
            {

            <Row justify={justify} style={{alignItems : 'center'}}>
                <div style={{marginRight: '5px'}}>
                {/* <FontAwesomeIcon
                    className='icon-large icon-black' 
                    icon={'bullseye'}
                /> */}
                <DataTargetIcon />
                </div>

                <Answer.Show
                  compact
                  schema={schema}
                  schemaLabels={schemaLabels}
                  config={config}
                  value={target}
                  columnsNoScroll={1}
                />                  


            </Row>
            }
            { kpi_value && kpi_value.value !==null && kpi_value.value !== undefined &&
                    <Row justify={justify}>
                    {/* Right/Wrong: <section className={`DataTargetLabel ${kpi_value.value - target.value >= 0 ? 'positive' : 'negative'} `}> */}
                    
                    {/* Grey neutral color */}
                    <section className={`DataTargetLabel neutral `}>
                        <span>{convertToTargetBase(kpi_value).value - target.value > 0 ? '+': ''} 
                        { 
                              formatNumberWithIntl(intl)(
                                convertToTargetBase(kpi_value).value - target.value,
                                getFormatOptions(schema, config),
                              ) 
                            }
                            {
                              unitText
                              ? ` ${unitText}`
                              : ''
                            }
                        </span>
                    </section>
                    </Row>
                    
                    
            }
        </Col>
        );
} else{
    return (
    <Col span={12}></Col>
    );
}
});

const BooleanTableComponent = injectIntl(({
    intl,
    target,
  }) => {
    // const t = intl.messages;
    if(target){
        return (
                // <div>
                //     <FontAwesomeIcon
                //           className='icon-large icon-black' 
                //           icon={'bullseye'}
                //     />
                    <Checkbox
                    disabled
                    checked={target.boolean}
                    className={`target_checkbox${target.boolean?'-true':''}`}
                />
                // </div>

        );



          // return (
          //     <Col className="DataTarget">
          //             <Row justify="end">
          //               <div>
          //               {/* <FontAwesomeIcon
          //                 className='icon-large icon-black' 
          //                 icon={'bullseye'}
          //               /> */}
          //               <DataTargetIcon />
          //               </div>
                        
          //               <Row>
          //                 <section className={`DataTargetLabel ${ target.boolean ? 'positive' : 'negative'} `}>
          //                   <span>{ target.boolean ? t.yes : t.no }</span>
          //                 </section>
          //               </Row>
          //             </Row>
          //   </Col>
          // );
    } else{
      return (
        <Col span={12}></Col>
      );
    }
  });

const QuantitativeTableComponent = injectIntl(({
    intl,
    schema,
    schemaLabels,
    target,
    kpi_value,
    config
  }) => {

    const availableUnits = useAvailableUnits(schema);
    const targetUnit = target.unit ? target.unit : '';

    const unitText = useMemo(() => {
      const unitObject = availableUnits.find(({ slug }) => slug === targetUnit)
      return unitObject ? unitObject.symbol : '';
    }, [
      targetUnit,
      availableUnits,
    ]);
    const convertToTargetBase = targetUnit && convertToUnit(targetUnit, availableUnits);

       if(target && kpi_value && (target.value !== undefined && target.value !== null) ){


        return (
            <Row justify='end' >
              <Col span={24} style={{textAlign: 'end'}}>
              {
                typeof target.value === 'undefined' || target.value === null
                ? NO_VALUE
                : formatNumberWithIntl(intl)(
                  target.value,
                  getFormatOptions(schema, config),
                )
              }
              {
              unitText
              ? ` ${unitText}`
              : ''
            }
            </Col>
            { kpi_value && kpi_value.value !=null && kpi_value.value !== undefined &&
              <Row justify='end'>
              <section className={`DataTargetLabel neutral table-inner`}>
                        <span>{convertToTargetBase(kpi_value).value - target.value > 0 ? '+': ''} 
                            { 
                              formatNumberWithIntl(intl)(
                                convertToTargetBase(kpi_value).value - target.value,
                                getFormatOptions(schema, config),
                              ) 
                            }
                            {
                              unitText
                              ? ` ${unitText}`
                              : ''
                            }
                        </span>
            </section>
            </Row>
            }
          </Row>
          );
    }else if ( (target !== undefined && target !== null) && typeof target !== 'object' ){
      return (
        <Row justify='end' >
          <Col span={24} style={{textAlign: 'end'}}>
            {
              typeof target === 'undefined' || target === null
              ? NO_VALUE
              : formatNumberWithIntl(intl)(
                target,
                getFormatOptions(schema, config),
              )
            }
            {/* {
              unitText
              ? ` ${unitText}`
              : ''
            } */}
          </Col>
          { kpi_value && kpi_value.value !=null && kpi_value.value !== undefined &&
            <Row justify='end'>
              <section className={`DataTargetLabel neutral table-inner `}>
                        <span>{kpi_value.value - target > 0 ? '+': ''} { 
                          formatNumberWithIntl(intl)(
                              kpi_value.value - target,
                                getFormatOptions(schema, config),
                              ) 
                               }</span>
            </section>
            </Row>
          }
          </Row>
      );
    }else{
      return (
        <Col span={12}></Col>
      );
    }
  });

const TableComponent = ({
    schema,
    schemaLabels,
    target,
    kpi_value,
    config
    }) => {
  
    switch (schema.type) {
        case 'quantitive':
            return (
                <QuantitativeTableComponent
                    schema = {schema}
                    schemaLabels={schemaLabels}
                    target = {target}
                    kpi_value={kpi_value}
                    config={config}
                />
            );
        case 'boolean':
            return (
                <BooleanTableComponent
                    target={target}
                />
            );
    
        default:
            return (
                <Col span={12}></Col>
              );
    }
};

const SummaryComponent = injectIntl(({
  intl,
  target,
  kpi_value,
  justify= "end"
}) => {
  return (
      <Col>
            {/* <FontAwesomeIcon
            className='icon-large icon-black' 
            icon={'bullseye'}
        /> */}
        <DataTargetIcon />
        <Row justify={justify}>
        {/* Right/Wrong <section className={`DataTargetLabel ${kpi_value.value - target >= 0 ? 'positive' : 'negative'} `}> */}
        {/* Neutral color */}
        <section className={`DataTargetLabel neutral `}>
            <span>{ kpi_value.value - target }</span>
        </section>
        </Row>
    </Col>
  );
});

const Show = ({
  schema,
  schemaLabels,
  target,
  kpi_value,
  config,
  justify = "end",
}) => {

  switch (schema.type) {
    case 'quantitative':
        return (
                <QuantitativeComponent
                schema = {schema}
                schemaLabels={schemaLabels}
                target = {target}
                kpi_value={kpi_value}
                config={config}
                justify={justify}
            />
            
        );
    case 'boolean':
        return (
                <BooleanComponent
                target={target}
                justify={justify}
            />
            
        );

    default:
        return (
            <Col span={12}></Col>
          );
}
};


Show.TableComponent = TableComponent;
Show.QuantitativeComponent = QuantitativeComponent;
Show.BooleanComponent = BooleanComponent;
Show.BooleanTableComponent = BooleanTableComponent;
Show.QuantitativeTableComponent = QuantitativeTableComponent;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

