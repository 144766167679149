import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';
import { changeLocale } from '../intl';  // NOTICE: 'intl' is a node package
import { isEmpty } from 'lodash';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';
import { objectToQueryParameters } from 'utils/queryParameters';
import { getQueryFiltersNames, SCREENS } from 'hooks/useScreenFilters/constants';
import { setTaxonomies } from 'actions/thirdParty';
import {v4 as uuidv4} from 'uuid';

// NEW:

const getFiltersConfig = (filters) => {
  const queryFiltersNames = getQueryFiltersNames(SCREENS.third_party_requests);
  return Object.entries(queryFiltersNames).map(([filterName, queryFilterName]) => {
    const filter = filters.find(({name}) => queryFilterName === name);
    return {
      ...filter,
      name: filterName
    };
  });
};

const requestConfigData = async (token, topLevelOrg) => {
  // NOTICE: This is the only endpoint from external data request that allows no top_level_org param
  const baseUrl = `${config.API_URL_NEW}/external/${token}/data_request`;
  const config_data_response = await fetch(
    topLevelOrg ? `${baseUrl}?top_level_org=${topLevelOrg}` : baseUrl,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
  const config_data_result = await parseResponse(config_data_response);
  return config_data_result;
};

const requestConfigDataSuccess = (token, topLevelOrg) => (result) => ({
  type: 'THIRD_PARTY_CONFIG_DATA_REQUEST_COMPLETE',
  result,
  token,
  topLevelOrg,
});

const requestConfigDataFail = ({ code, text }) => ({
  type: 'THIRD_PARTY_CONFIG_DATA_REQUEST_FAILED',
  code,
  text,
});

const requestStatusProgress = async (token, topLevelOrg, filters) => {
  const filtersQueryParams = objectToQueryParameters(filters);
  const baseUrl = `${config.API_URL_NEW}/external/${token}/data_request/progress_bar?top_level_org=${topLevelOrg}`;
  const response = await fetch(
  isEmpty(filtersQueryParams) ? baseUrl : `${baseUrl}&${filtersQueryParams}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  const result = await parseResponse(response);
  return result;
};

const requestStatusProgressSuccess = result => ({
  type: 'THIRD_PARTY_STATUS_PROGRESS_REQUEST_COMPLETE',
  result,
});

const requestStatusProgressFail = ({ code, text }) => ({
  type: 'THIRD_PARTY_STATUS_PROGRESS_REQUEST_FAILED',
  code,
  text,
});

const requestDataRequests = async (token, topLevelOrg, suborganization, page = 1, filters = {}) => {
  const filtersQueryParams = objectToQueryParameters(filters);
  const baseUrl = `${config.API_URL_NEW}/external/${token}/data_request/${suborganization}?top_level_org=${topLevelOrg}&page=${page}&limit=${5}`;
  const response = await fetch(
    isEmpty(filtersQueryParams) ? baseUrl : `${baseUrl}&${filtersQueryParams}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  const result = await parseResponse(response);
  return result;
};

const requestDataRequestsSuccess = result => ({
  type: 'THIRD_PARTY_DATA_REQUESTS_REQUEST_COMPLETE',
  result,
});

const requestDataRequestsFail = (suborganization) => ({ code, text }) => ({
  type: 'THIRD_PARTY_DATA_REQUESTS_REQUEST_FAILED',
  code,
  text,
  suborganization,
});

const requestSuborgsDataRequests = async (token, topLevelOrg, suborganizations, filters = {}) => {
  const  requests_by_organization_result = await Promise.all(suborganizations.map(async suborganization => {
    const result = await requestDataRequests(token, topLevelOrg, suborganization, 1, filters)
    return result;
  }));
  return requests_by_organization_result;
};

const requestSuborgsDataRequestsSuccess = (request_uuid) => result => ({
  type: 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST_COMPLETE',
  result,
  request_uuid,
});

const requestSuborgsDataRequestsFail = (request_uuid) => ({ code, text }) => ({
  type: 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST_FAILED',
  code,
  text,
  request_uuid,
});

const requestRequestDetail = async (token, topLevelOrg, suborganization, requestId) => {
  const response = await fetch(
    `${config.API_URL_NEW}/external/${token}/data_request/${suborganization}/detail/${requestId}?top_level_org=${topLevelOrg}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  const result = await parseResponse(response);
  return result;
};

const requestRequestDetailSuccess = result => ({
  type: 'THIRD_PARTY_REQUEST_DETAIL_REQUEST_COMPLETE',
  result,
});

const requestRequestDetailFail = ({ code, text }) => ({
  type: 'THIRD_PARTY_REQUEST_DETAIL_REQUEST_FAILED',
  code,
  text,
});

// OLD:

const requestSubmit = async (
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  data_request_id,
  value,
  comment,
) => {
  const response = await fetch(
    `${config.API_URL}/external/${token}/data_request/${data_request_id}?top_level_org=${topLevelOrg}`,
    {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        value,
        comment,
        suborganization_slug,
        kpi_slug
      })
    }
  );

  const result = await parseResponse(response);
  return result;
};

const submitSuccess = (data_request_id, attachments) => (response) => ({
  type: 'SUBMIT_THIRD_PARTY_COMPLETE',
  response,
  data_request_id,
  attachments
});

const submitFail = ({ code, text }) => ({
  type: 'SUBMIT_THIRD_PARTY_FAILED',
  code,
  text,
});

const requestDeleteKpiAttachment = async (
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  period,
  filename,
) => {
  const response = await fetch(
    `${config.API_URL}/external/${token}/${kpi_slug}/${period}/attachment/${filename}?top_level_org=${topLevelOrg}&organization_slug=${suborganization_slug}`,
    {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      },
    }
  );

  const result = await parseResponse(response);
  return result;
};

const deleteKpiAttachmentSuccess = (data_request_id) => (response) => ({
  type: 'DELETE_THIRD_PARTY_KPI_ATTACHMENT_COMPLETE',
  response,
  data_request_id
});

const deleteKpiAttachmentFail = ({ code, text }) => ({
  type: 'DELETE_THIRD_PARTY_KPI_ATTACHMENT_FAILED',
  code,
  text,
});

const initialState = {
  topLevelOrg: null,
  queryFiltersObj: {},
  selector_orgs: null,
  profile: null,
  filters_config: null,
  tree: null,
  orgs_with_dr: null,
  requests_by_organization: null,
  status_progress: {},
  kpi_request_detail: null,
  uploadingAttachments: false,
  attachments: [],
  initialLoading: false,
  statusProgressLoading: false,
  loading: false,
  error: null,
  code: null,
  suborgs_request_uuid: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THIRD_PARTY_SAVE_QUERY_FILTERS':
      return {
        ...state,
        queryFiltersObj: action.queryFilters || {},
      };
    case 'THIRD_PARTY_CONFIG_DATA_REQUEST':
      return loop(
        {
          ...state,
          initialLoading: true
        },
        Cmd.run(requestConfigData, {
          successActionCreator: requestConfigDataSuccess(action.token, action.topLevelOrg),
          failActionCreator: requestConfigDataFail,
          args: [ action.token, action.topLevelOrg ]
        })
      );
    case 'THIRD_PARTY_CONFIG_DATA_REQUEST_COMPLETE':
      const filters_config = getFiltersConfig(action.result.filters);
      const state_data = {
        ...state,
        token: action.token,
        topLevelOrg: action.topLevelOrg || action.result.top_level_org,
        selector_orgs: action.result.selector_orgs,
        profile: action.result.profile,
        filters_config,
        tree: action.result.tree,
        orgs_with_dr: action.result.orgs_with_dr,
        loading: false,
        initialLoading: false,
        error: null,
        code: null,
      };
      if(action.result.profile?.language && action.result.profile?.language !== state.profile?.language) {
        return loop(
          state_data,
          Cmd.list([
            Cmd.action(changeLocale(action.result.profile?.language)),
            Cmd.action(setTaxonomies({sdgs: action.result.sdgs}))
          ])
        );
      }
      return loop(state_data, Cmd.action(setTaxonomies({sdgs: action.result.sdgs})));
    case 'THIRD_PARTY_CONFIG_DATA_REQUEST_FAILED':
      return {
        ...state,
        loading: false,
        initialLoading: false,
        language: null,
        error: action.text,
        code: action.code
      };
    case 'THIRD_PARTY_STATUS_PROGRESS_REQUEST':
      return loop(
        {
          ...state,
          statusProgressLoading: true
        },
        Cmd.run(requestStatusProgress, {
          successActionCreator: requestStatusProgressSuccess,
          failActionCreator: requestStatusProgressFail,
          args: [ action.token, action.topLevelOrg, action.filters ]
        })
      );
    case 'THIRD_PARTY_STATUS_PROGRESS_REQUEST_COMPLETE':
      return {
        ...state,
        status_progress: action.result,
        statusProgressLoading: false,
        error: null,
        code: null,
      };
    case 'THIRD_PARTY_STATUS_PROGRESS_REQUEST_FAILED':
      return {
        ...state,
        status_progress: {},
        statusProgressLoading: false,
        error: action.text,
        code: action.code
      };
    case 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST':
      // NOTICE: create request_uuid to avoid race condition if user filters while is loading slower request
      const request_uuid = uuidv4();
      return loop(
        {
          ...state,
          loading: true,
          suborgs_request_uuid: request_uuid,
        },
        Cmd.run(requestSuborgsDataRequests, {
          successActionCreator: requestSuborgsDataRequestsSuccess(request_uuid),
          failActionCreator: requestSuborgsDataRequestsFail(request_uuid),
          args: [ action.token, action.topLevelOrg, action.suborganizations, action.filters ]
        })
      );
    case 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST_COMPLETE':
      if (state.suborgs_request_uuid !== action.request_uuid) {
        return state;
      }
      const requests_by_organization_result = {};
      action.result.forEach(({data, ...paginationInfo}) => {
        const { data_request_ids, kpis, organization_slug } = data;
        // NOTICE: This is the first request of all organizations data therefore dataPage is always 1
        requests_by_organization_result[organization_slug] = {
          data: [{dataPage: paginationInfo.currentPage, data_request_ids, kpis, ...paginationInfo}],
          ...paginationInfo,
          loading: false,
        };
      });
      return {
        ...state,
        requests_by_organization: requests_by_organization_result,
        loading: false,
        error: null,
        code: null,
      };
    case 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST_FAILED':
      if (state.suborgs_request_uuid !== action.request_uuid) {
        return state;
      }
      return {
        ...state,
        loading: false,
        error: action.text,
        code: action.code
      };
    case 'THIRD_PARTY_DATA_REQUESTS_REQUEST':
      return loop(
        {
          ...state,
          requests_by_organization: {
            ...state.requests_by_organization,
            [action.suborganization] : {
              ...state.requests_by_organization[action.suborganization],
              loading: true,
            }
          }
        },
        Cmd.run(requestDataRequests, {
          successActionCreator: requestDataRequestsSuccess,
          failActionCreator: requestDataRequestsFail(action.suborganization),
          args: [ action.token, action.topLevelOrg, action.suborganization, action.page, action.filters ]
        })
      );
    case 'THIRD_PARTY_DATA_REQUESTS_REQUEST_COMPLETE':
      const {data: resultData, ...paginationInfo} = action.result;
      const { data_request_ids, kpis, organization_slug } = resultData;
      const { data: dataInState } = state.requests_by_organization[organization_slug];
      let newData = [];
      if (action.result?.currentPage === 1 || !dataInState) {
        newData = [{dataPage: paginationInfo.currentPage, data_request_ids, kpis, ...paginationInfo}];
      } else {
        const indexToReplace = dataInState.findIndex(({dataPage}) => dataPage === action.result?.currentPage);
        const lastElementIndex = dataInState.length - 1;
        // NOTICE: If new data is not yet in state but it's the next page, then concat, if it's already in, then replace
        newData = indexToReplace < 0 && dataInState[lastElementIndex].dataPage === action.result?.previousPage
          ? dataInState.concat({dataPage: paginationInfo.currentPage, data_request_ids, kpis, ...paginationInfo})
          : (indexToReplace < 0
              ? []
              : [
                ...dataInState.slice(0, indexToReplace),
                {dataPage: paginationInfo.currentPage, data_request_ids, kpis, ...paginationInfo},
                ...dataInState.slice(indexToReplace + 1),
              ]);
      }
      return {
        ...state,
        requests_by_organization: {
          ...state.requests_by_organization,
          [organization_slug] : {
            data: newData,
            limit: action.result?.limit,
            previousPage: action.result?.previousPage,
            currentPage: action.result?.currentPage,
            nextPage: action.result?.nextPage,
            total: action.result?.total,
            loading: false,
          }
        },
        error: null,
        code: null,
      };
    case 'THIRD_PARTY_DATA_REQUESTS_REQUEST_FAILED':
      return {
        ...state,
        requests_by_organization: {
          ...state.requests_by_organization,
          [action.suborganization] : {
            ...state.requests_by_organization[action.suborganization],
            loading: false,
          }
        },
        error: action.text,
        code: action.code
      };
    case 'THIRD_PARTY_REQUEST_DETAIL_REQUEST':
      return loop(
        {
          ...state,
          loading: true
        },
        Cmd.run(requestRequestDetail, {
          successActionCreator: requestRequestDetailSuccess,
          failActionCreator: requestRequestDetailFail,
          args: [ action.token, action.topLevelOrg, action.suborganization, action.requestId ]
        })
      );
    case 'THIRD_PARTY_REQUEST_DETAIL_REQUEST_COMPLETE':
      const { metrics, variables, ...kpiRequestDetail } = action.result;
      return loop({
          ...state,
          kpi_request_detail: kpiRequestDetail,
          loading: false,
          error: null,
          code: null,
        }, Cmd.action(setTaxonomies({metrics, kpi_variables: variables}))
      );
    case 'THIRD_PARTY_REQUEST_DETAIL_REQUEST_FAILED':
      return {
        ...state,
        loading: false,
        error: action.text,
        code: action.code
      };
    case 'DATA_REQUEST:RESPONSED':
      // NOTICE: This is from WebSockets when a data request change its status
      const {
        suborganization_slug,
        data_request_id,
      } = action.data;

      // NOTICE: Update state only if exists
      if (isEmpty(state?.status_progress) || !state?.requests_by_organization || !state.token || !state.topLevelOrg) {
        return state;
      }

      let CmdList = [
        Cmd.run(requestStatusProgress, {
          successActionCreator: requestStatusProgressSuccess,
          failActionCreator: requestStatusProgressFail,
          args: [ state.token, state.topLevelOrg, state.queryFiltersObj ]
        })
      ];

      if (state.queryFiltersObj?.suborganizations?.includes(suborganization_slug) && state.requests_by_organization[suborganization_slug]) {
        // NOTICE: Only fetch again to get data request with new status if stored in reducer
        const dataFromPageWithDataRequest = state.requests_by_organization[suborganization_slug].data?.find(({data_request_ids}) => data_request_ids.includes(parseInt(data_request_id)));
        CmdList = dataFromPageWithDataRequest
          ? [
              ...CmdList,
              Cmd.run(requestDataRequests, {
                successActionCreator: requestDataRequestsSuccess,
                failActionCreator: requestDataRequestsFail(suborganization_slug),
                args: [ state.token, state.topLevelOrg, suborganization_slug, dataFromPageWithDataRequest.dataPage, state.queryFiltersObj ]
              })
            ]
          : CmdList;
      }

      return loop(
        {
          ...state,
          loading: false,
          error: null,
          code: null,
        },
        Cmd.list(CmdList)
      );
    case 'SUBMIT_THIRD_PARTY':
      return loop(
        {
          ...state,
          loading: true,
          error: null,
        },
        Cmd.run(requestSubmit, {
          successActionCreator: submitSuccess(action.data_request_id),
          failActionCreator: submitFail,
          args: [
            action.token,
            action.topLevelOrg,
            action.suborganization_slug,
            action.kpi_slug,
            action.data_request_id,
            action.value,
            action.comment,
          ]
        })
      );
    case 'SUBMIT_THIRD_PARTY_COMPLETE':
      if(!state?.kpi_request_detail || state?.kpi_request_detail.data_request_id !== action.data_request_id) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        kpi_request_detail: {
          ...state.kpi_request_detail,
          suggestion: {
            ...state.kpi_request_detail.suggestion,
            value: action.response.value || state.kpi_request_detail.suggestion.value,
            comment: action.response.comment || state.kpi_request_detail.suggestion.comment,
            attachment_ids: state.kpi_request_detail.attachments.map(({id}) => id)
          },
          suggested_at: new Date().toISOString(),
          request_status: DATA_REQUEST_STATUS.done,
          attachments: state.kpi_request_detail.attachments
        },
        loading: false,
        uploadingAttachments: false,
        attachments: state.attachments.filter(({status}) => status === 'failed'),
      };
    case 'SUBMIT_THIRD_PARTY_FAILED':
      return {
        ...state,
        loading: false,
        error: action.text,
        code: action.code
      };
    case 'ADD_THIRD_PARTY_KPI_ATTACHMENT':
      if(!state?.kpi_request_detail || state?.kpi_request_detail.data_request_id !== action.data_request_id) {
        return state;
      }
      return {
        ...state,
        kpi_request_detail: {
          ...state.kpi_request_detail,
          attachments: [
            ...(state.kpi_request_detail.attachments || []),
            action.attachment,
          ]
        }
      };
    case 'DELETE_THIRD_PARTY_KPI_ATTACHMENT':
      return loop(
        {
          ...state,
          loading: true,
          error: null,
        },
        Cmd.run(requestDeleteKpiAttachment, {
          successActionCreator: deleteKpiAttachmentSuccess(action.data_request_id),
          failActionCreator: deleteKpiAttachmentFail,
          args: [ action.token, action.topLevelOrg, action.suborganization_slug, action.kpi_slug, action.period, action.filename ]
        })
      );
    case 'DELETE_THIRD_PARTY_KPI_ATTACHMENT_COMPLETE': {
      if(!state?.kpi_request_detail || state?.kpi_request_detail.data_request_id !== action.data_request_id) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        kpi_request_detail: {
          ...state.kpi_request_detail,
          attachments: (state.kpi_request_detail.attachments || []).filter(({filename}) => filename !== action.response.filename)
        },
        loading: false,
      };
    }
    case 'DELETE_THIRD_PARTY_KPI_ATTACHMENT_FAILED':
      return {
        ...state,
        loading: false,
        error: action.text,
        code: action.code
      };
    case 'THIRD_PARTY_UPLOAD_ATTACHMENTS':
      return {
        ...state,
        uploadingAttachments: false,
        attachments: action.attachmentIds.map(
          (id) => ({ id, status: 'uploading' })
        ),
      };
    case 'THIRD_PARTY_UPLOAD_ATTACHMENTS_START':
      return {
        ...state,
        uploadingAttachments: true,
      };
    case 'THIRD_PARTY_UPLOAD_ATTACHMENT_UPLOADED':
    case 'THIRD_PARTY_UPLOAD_ATTACHMENT_UPLOAD_FAILED':
      const index = state.attachments.map(
        attachment => attachment.id
      ).indexOf(action.attachmentId);
      return {
        ...state,
        attachments: [
          ...state.attachments.slice(0, index),
          {
            id: action.attachmentId,
            status: action.type === 'THIRD_PARTY_UPLOAD_ATTACHMENT_UPLOADED'
              ? 'success'
              : 'failed',
          },
          ...state.attachments.slice(index + 1)
        ],
      };
    default:
      return state;
  }
};

export { reducer as third_party };
