import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import { Loading } from 'tsComponents/emptyStates/Loading';

const LoaderModal = ({
  visible,
  title,
  message,
}) => {
  return <Modal
    visible={visible}
    closable={false}
    footer={null}
    title={null}>
      <Loading title={title} message={message} />
  </Modal>;
};

export default injectIntl(LoaderModal);