import React from 'react';
import { injectIntl } from 'react-intl';

import Show from './Show';
import Edit from './Edit';

const NO_OP = () => {};

const QualitativeAnswer = ({
  intl,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  source,
  source_params,
  schema,
  edit,
  loading,
  onEdit = NO_OP,
  onSave = NO_OP,
  onClear = NO_OP,
  onCancel = NO_OP,
  onShowLastPeriod = NO_OP,
  onUseLastValue = NO_OP,
  onUseLastWholeData = NO_OP,
  onUseLastAttachments = NO_OP,
  onUseLastPeriod = NO_OP,
  canWrite = false,
  expanded = false,
  suborganization_data,
  config,
}) => {
  const t = intl.messages;

  return (
    <React.Fragment>
      <section className='KpiDetail__answer'>
        <div className="KpiDetail__title">{ t.answer }</div>
        {
          edit && canWrite
          ? <Edit
              source={source}
              source_params={source_params}
              schema={schema}
              value={value}
              comment={comment}
              expanded={expanded}
              onSave={onSave}
              onClear={onClear}
              onCancel={onCancel}
              previous_value={previous_value}
              previous_comment={previous_comment}
              previous_attachments={previous_attachments}
              onShowLastPeriod={onShowLastPeriod}
              onUseLastValue={onUseLastValue}
              onUseLastWholeData={onUseLastWholeData}
              onUseLastAttachments={onUseLastAttachments}
              onUseLastPeriod={onUseLastPeriod}
              config={config}
            />
          : <Show
              source={source}
              source_params={source_params}
              schema={schema}
              value={value}
              comment={comment}
              expanded={expanded}
              previous_value={previous_value}
              previous_comment={previous_comment}
              previous_attachments={previous_attachments}
              onEdit={onEdit}
              onShowLastPeriod={onShowLastPeriod}
              onUseLastValue={onUseLastValue}
              onUseLastWholeData={onUseLastWholeData}
              onUseLastAttachments={onUseLastAttachments}
              onUseLastPeriod={onUseLastPeriod}
              canWrite={canWrite}
              suborganization_data={suborganization_data}
              config={config}
            />
        }
      </section>
    </React.Fragment>
  );
}

QualitativeAnswer.Show = Show.Component;
QualitativeAnswer.Edit = Edit.Component;
QualitativeAnswer.TableShow = Show.TableComponent;
QualitativeAnswer.TableEdit = Edit.TableComponent;
QualitativeAnswer.Summary = Show.SummaryComponent;

export default injectIntl(QualitativeAnswer);
