import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import { editKpi } from 'actions/api';

import { formatMessage } from '../../intl';

import useForm from 'utils/useForm';
import validation from './validation';

import CustomButton from 'components/CustomButton';
import DeleteModal from './DeleteModal'
import CreateEditCustomKPI from 'components/CreateEditCustomKPI';
import CustomCollapse from 'components/CustomCollapse';
import AplanetIcon from 'components/AplanetIcon'

import { Col, Modal, Row, Tooltip } from 'antd';

import './style.less';

//TODO: Probably this is something we should get from the back
//const DEFAULT_REPORTING_STANDARD = 'aplanet';
// NOTICE: Order is important here (see below!)
const REPORTING_STANDARDS = [
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
];

const CollapseEditKPI = ({
  intl,
  loading = false,
  error,
  name,
  name_translations,
  esgs: esg_type,
  schema,
  schemaLabels,
  slug,
  uuid,
  is_custom,
  sdgs: defaultSdgs = [],
  standard_info = {},
  guidelines_std,
  periodicities,
  annual_start_date,
  kpi_value_status_for_whole_tree,
  handleKpiDeleteFromFolder,
  handleKpiDeleteFromPlatform,
  reporting_structure,
}) => {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const componentValues = useMemo(() => {
    const VALUES_PER_SCHEMA = {
      quantitative: schema,
      default: {},
    };
    return VALUES_PER_SCHEMA[schema.type] || VALUES_PER_SCHEMA.default;
  }, [schema]);

  const {
    organization,
    suborganization,
    isMainOrganization,
    permissions
  } = useOrganizations();

  const {
    data: userProfile,
  } = useSelector(state => state.profile);

  const enabledReports = useMemo(() => {
    return (organization.config || {}).enabled_reports || [];
  }, [
    organization,
  ]);

  const standardInfo = useMemo(() => {
    return enabledReports.reduce((acc, curr) => {
      acc[curr] = standard_info[curr] ?? [{}];
      return acc;
    }, {});
  }, [enabledReports, standard_info]);

  const [submitting, setSubmitting] = useState(false);
  const [schemaDirty, setSchemaDirty] = useState(false);


  const submitForm = () => {
    setSubmitting(true);
    const {
      name_translations: newNames,
      sdgs = [],
      esg_type,
      guidelines,
      enabled_standards,
      schemaLabels,
      schema_empty_existing_values = false,
      code,
      periodicities,
      annual_start_date,
      ...rest
    } = values;
    const schema = Object.fromEntries(
      Object.keys(rest)
        .filter(key => !REPORTING_STANDARDS.includes(key) && !(REPORTING_STANDARDS.map(std => `${std}_enabled`)).includes(key))
        .map(key => [key, rest[key]])
    );

    const newKpiNames = newNames.length === name_translations.length &&
      name_translations.every(({ locale, name }) => {
        const newName = newNames.find((nn => nn.locale === locale));
        return newName?.name === name;
      }) ? undefined : newNames.filter(({ name }) => name !== '');

    dispatch(
      editKpi({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        kpi_slug: slug,
        uuid,
        sdgs,
        code,
        name_translations: newKpiNames,
        esg_type,
        guidelines_std: guidelines,
        schema: schema.type ? schema : undefined, // type will be undefined if schema hasn't changed,
        schema_empty_existing_values,
        periodicities,
        annual_start_date
      })
    );
  };

  const defaultValues = useMemo(() => {
    let result = {
      name,
      name_translations: name_translations.filter(({ locale }) => (organization?.config?.preferred_languages || []).includes(locale)),
      esg_type: esg_type[0],
      guidelines: guidelines_std,
      code: standard_info[0].code,
      periodicities,
      annual_start_date,
      //...labeledSchema, Don't know why we were using this, but it overrides some values like annual_start_date
      schemaLabels,
      ...componentValues,
    };
    enabledReports.forEach(er => result[`${er}_enabled`] = !!standardInfo[er].length && standardInfo[er].every(si => !!Object.keys(si).length));

    return result;
  }, [name, name_translations, esg_type, guidelines_std, standard_info, periodicities, annual_start_date, schemaLabels, componentValues, enabledReports, organization?.config?.preferred_languages, standardInfo]);

  const defaultSetValues = useMemo(() => {
    return {
      sdgs: defaultSdgs,
      enabled_standards: Object.keys(standard_info || {}),
      ...standardInfo
    };
  }, [defaultSdgs, standardInfo, standard_info]);

  const validateForm = useMemo(() => validation(intl), [intl]);
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    isDirty,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
    defaultSetValues,
    validationDefaults: { locale: suborganization.language },
    setNames: ['sdgs', 'enabled_standards', ...enabledReports],
    continousValidation: true,
  });

  const handleOnSubmit = useCallback(
    () => {
      if (
        (schema.type === 'table' || schema.type === 'quantitative')
        &&
        schemaDirty
      ) {
        Modal.confirm({
          title: formatMessage("modaleditkpi_update_schema_title"),
          content: formatMessage("modaleditkpi_update_schema_description"),
          okText: formatMessage("modaleditkpi_update_schema_ok"),
          cancelText: userProfile?.role === 'system'
            ? formatMessage("modaleditkpi_update_schema_ok_without_delete")
            : formatMessage("modaleditkpi_update_schema_cancel"),
          onOk() {
            handleChange('schema_empty_existing_values')(true);
            handleSubmit();
          },
          onCancel() {
            if (userProfile?.role === 'system') {
              handleChange('schema_empty_existing_values')(false);
              handleSubmit();
            }
          }
        });
      } else {
        // TODO: Do we need to reset 'decimalPoints', 'metricSlug' and 'allowedUnitSlugs'?
        if (!isDirty('dimensions') && !isDirty('innerSchema')) {
          handleChange('dimensions')(undefined);
          handleChange('innerSchema')(undefined);
          handleChange('type')(undefined); // Set type to `undefined` as if we have not edited the schema
        }
        handleChange('schema_empty_existing_values')(false);
        handleSubmit();
      }
    },
    [isDirty, schema, schemaDirty, handleSubmit, handleChange, userProfile]
  );

  const handleClose = useCallback(
    () => {
      setSchemaDirty(false);
      resetForm();
      setShowForm(false)
    },
    [
      resetForm,
      setSchemaDirty,
    ]
  );

  useEffect(() => {
    // Close the modal
    if (submitting && !loading && !error) {
      setSubmitting(false);
      handleClose();
    }
  }, [
    handleClose,
    submitting,
    loading,
    error,
  ]);

  const PanelHeader = useCallback(() => (
    <div className="KpiTreeActionsSection__actions__title">
      <span>
        {formatMessage("kpi_tree_action_section_actions_indicator")}
      </span>
      <span>
        {name}
      </span>
    </div>
  ),
    [name]
  )

  return (
    <>
      <CustomCollapse
        className="KpiTreeActionsSection__actions__indicator"
        header={<PanelHeader />}
        tooltip={{ title: formatMessage("modaleditkpi_tooltip"), placement: 'topRight' }}
      >
        {showForm
          ? (
            <>
              <CreateEditCustomKPI
                intl={intl}
                kpiSlug={slug}
                values={values}
                errors={errors}
                handleChange={handleChange}
                schemaDirty={schemaDirty}
                setSchemaDirty={setSchemaDirty}
                organization={organization}
                suborganization={suborganization}
                kpiValueStatus={kpi_value_status_for_whole_tree}
              />
              <Row type="flex" justify="end">
                <Col className="ModalEditKPI__footer">
                  <CustomButton
                    className="ModalEditKPI__btn-footer"
                    key="back"
                    icon={<AplanetIcon name="Cancel" />}
                    danger
                    onClick={handleClose}
                  >
                    {formatMessage("modaleditkpi_cancel")}
                  </CustomButton>
                  <CustomButton
                    className="ModalEditKPI__btn-footer"
                    key="submit"
                    type="primary"
                    icon={<AplanetIcon name="Save" faStyle="fas" />}
                    onClick={handleOnSubmit}
                  >
                    {formatMessage("modaleditkpi_save")}
                  </CustomButton>
                </Col>
              </Row>
            </>
          )
          : (
            <div className="ButtonsContainer">
              <Tooltip title={!isMainOrganization ? intl.formatMessage({ id: 'only_available_at_parent' }) : ""}>
                <div style={{ cursor: !isMainOrganization ? 'not-allowed' : 'pointer' }}>
                  <CustomButton className="DeleteButton" icon={<AplanetIcon name="Bin" />} disabled={!isMainOrganization || !permissions.can_configure_kpi} danger onClick={() => setShowModal(true)} style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto'}}>
                    {formatMessage("delete")}
                  </CustomButton>
                </div>
              </Tooltip>
              <Tooltip title={!isMainOrganization ? intl.formatMessage({ id: 'only_available_at_parent' }) : ""}>
                <div style={{ cursor: !isMainOrganization ? 'not-allowed' : 'pointer' }}>
                  <CustomButton className="EditButton" icon={<AplanetIcon name="Edit" />} disabled={!isMainOrganization || !is_custom  || !permissions.can_configure_kpi} onClick={() => setShowForm(true)} style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto'}}>
                    {formatMessage("edit")}
                  </CustomButton>
                </div>
              </Tooltip>
            </div>
          )
        }
      </CustomCollapse>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        slug={slug}
        name={name}
        handleKpiDeleteFromFolder={handleKpiDeleteFromFolder}
        handleKpiDeleteFromPlatform={handleKpiDeleteFromPlatform}
        reporting_structure={reporting_structure}
      />
    </>
  )
};

export default injectIntl(CollapseEditKPI);
