import React, {
  useMemo,
} from 'react'
import { Tabs } from 'antd';
import './style.less'

const CustomTabs = ({
  intl,
  title,
  tabs: tabSlugs,
  tabObjects,
  activeTab,
  defaultActiveTab,
  setActiveTab,
  namePrefix = 'datamanagement',
}) => {
  const t = intl.messages;

  const tabs = useMemo(() => {
    return tabObjects || (tabSlugs || []).map(slug => ({
        key: slug,
        label: t[`${namePrefix}_${slug}`],
        children: (<></>)
      }))
  }, [
    namePrefix,
    t,
    tabObjects,
    tabSlugs,
  ]);

  return (
    <React.Fragment>
      <section className='CustomTabs'>
        <span className='CustomTabs-title'>{title}</span>
        { tabs.length < 1 ? null :
          <>
            <figure className='CustomTabs-divider'/>
            <section className='CustomTabs-tabs'>
              <Tabs activeKey={activeTab} defaultActiveKey={defaultActiveTab} onChange={setActiveTab} items={tabs} />
            </section>
          </>
        }
      </section>
    </React.Fragment>
  );
}

export default CustomTabs
