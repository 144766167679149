import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import CustomTable from 'components/CustomTable';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const shortStandard = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'Electric Utilities Sector Supplement',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
};

const ResultTable = ({
  intl,
  subjects,
  showComments,
}) => {
  const t = intl.messages;
  const clusterSDGs = useSDGsClustering();

  const columns = useMemo(
    () => {
      let _columns =  [{
        title: t.subject,
        dataIndex: 'subject',
      }, {
        title: t.issue,
        dataIndex: 'issue',
      }, {
        title: t.standard,
        dataIndex: 'standards',
        render: (standards) => `${(standards || []).map(standard => shortStandard[standard]).join(', ')}`,
      }, {
        title: t.sdg,
        dataIndex: 'sdgs',
        render: (sdgs) => clusterSDGs(sdgs).map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg} />)
      }, {
        title: t.type,
        dataIndex: 'esg_types',
        render: (types) => types?.map(type => <EsgLogo key={type} type={type} />)
      }, {
        title: t.weight,
        dataIndex: 'weight',
      }];
      if (showComments) {
        _columns.push({
          title: t.comment,
          dataIndex: 'comment'
        });
      }
      return _columns;
    },
    [t, showComments, clusterSDGs]
  );

  return <CustomTable
    columns={columns}
    dataSource={subjects}
    childrenColumnName='not-children'
    rowKey='id' />
}

export default injectIntl(ResultTable);
