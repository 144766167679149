import React, {
  useCallback,
  useMemo,
  useContext
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Checkbox,
} from 'antd';
import { getPeriodsFormat, toBetweenDotsDateFormat } from 'utils/date';
import DataRequestsInfoCells from './DataRequestsInfoCells';
import { TableDataRequestsCellsContext } from './context';
import { DATA_REQUEST_RECURRENCE, DATA_REQUEST_STATUS } from 'utils/dataRequests';

const DataRequestsRow = ({
  intl,
  isRequestToOneUser = false,
}) => {
  const {
    kpi_slug,
    kpi_uuid,
    pack_uuid,
    type,
    periodicity,
    periods,
    handleCheckDataRequests,
    checkedDataRequestIds,
    handleOpenKpi,
  } = useContext(TableDataRequestsCellsContext);

  const allRequestIds = useMemo(() => {
    return type === DATA_REQUEST_RECURRENCE.recurrent ? periods.reduce((acc, period) => [...acc, ...period.requests_params.map(({request_id}) => request_id)], []) : [];   
  }, [
    periods,
    type
  ]);

  const onCheckRequests = useCallback((requests_params, period, value_source) => {
    const checkedIds = requests_params.map(({request_id, params, status}) => ({
      id: request_id,
      email: params.request.email,
      status,
      period,
      kpi_slug,
      value_source,
      type,
      groupedRecurrentRequests: type === DATA_REQUEST_RECURRENCE.recurrent ? allRequestIds : null,
    }));
    handleCheckDataRequests(checkedIds);
  }, [
    allRequestIds,
    handleCheckDataRequests,
    kpi_slug,
    type
  ]);

  return (
    periods.map(({
      start_period_date,
      end_period_date,
      kpi_value,
      period,
      last_update,
      requests_params,
      value_source,
    }) => {
      const [ firstRequestParams, ...restRequestsParams ] = requests_params;
      return (
        <React.Fragment key={`${kpi_uuid}_${pack_uuid}_${start_period_date}_${end_period_date}`}>
          <tr>
            <td className={`DataRequests__request-checkbox-cell ${type === DATA_REQUEST_RECURRENCE.recurrent ? 'is-empty' : ''}`}>
              {type === DATA_REQUEST_RECURRENCE.recurrent ? null : (
                  <Checkbox
                    className="DataRequests__request-checkbox"
                    onChange={() => onCheckRequests(requests_params, period, value_source)} 
                    checked={requests_params.every(({request_id}) => checkedDataRequestIds.includes(request_id))}
                  />
              )}
            </td>
            <td className='DataRequests__period'>
              {type === DATA_REQUEST_RECURRENCE.recurrent ? (
                <>
                  <Checkbox
                    className="DataRequests__request-checkbox"
                    onChange={() => onCheckRequests(requests_params, period, value_source)} 
                    checked={requests_params.every(({request_id}) => checkedDataRequestIds.includes(request_id))}
                  />
                  <span className='DataRequests__period-date'>{getPeriodsFormat(start_period_date, end_period_date, intl)}</span>
                </>
              ) : (
                <>
                  <span className='DataRequests__period-date'>{getPeriodsFormat(start_period_date, end_period_date, intl)}</span>
                  <div className='DataRequests__type-periodicity-wrapper'>
                    <span className={`DataRequests__type ${type}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_type_${type}`})}</span>
                    <span className={`DataRequests__periodicity ${periodicity}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_periodicity_${periodicity}`})}</span>
                  </div>
                </>
              )}
            </td>
            <DataRequestsInfoCells
              valueSource={value_source}
              kpiValue={kpi_value}
              lastKpiUpdate={last_update}
              startPeriod={start_period_date}
              endPeriod={end_period_date}
              responseValue={firstRequestParams.params.response?.value}
              responseComment={firstRequestParams.params.response?.comment}
              totalAttachments={firstRequestParams.params.response?.attachment_ids?.length || 0}
              requestedToEmail={firstRequestParams.params.request?.email}
              requestedToName={firstRequestParams.params.request?.name}
              requestedToAvatar={firstRequestParams.params.request?.avatar}
              requesterComment={firstRequestParams.params.request?.comment}
              notificationDate={toBetweenDotsDateFormat(firstRequestParams.request_date, intl)}
              requestStatus={firstRequestParams.status}
              responseDate={firstRequestParams.params.response?.date}
              requestId={firstRequestParams.request_id}
              userType={firstRequestParams.type}
              // NOTICE: For the moment we only have use action so lastActionDate will only show up when data request status is in-use or partially-in-use
              lastActionDate={
                (firstRequestParams.status === DATA_REQUEST_STATUS.in_use || firstRequestParams.status === DATA_REQUEST_STATUS.partially_in_use)
                ? (firstRequestParams.params.response?.response_used_at || firstRequestParams.params.response?.date) // NOTICE: defensive programming use date if response_used_at does not exist
                : null
              }
              lastReminderSentDate = {
                firstRequestParams.status === DATA_REQUEST_STATUS.pending
                ? firstRequestParams.params.request?.reminder_sent_at
                : null
              }
              handleOpenKpi={() => handleOpenKpi(kpi_slug, period)}
              dataInUse={firstRequestParams.params.response?.data_in_use}
              hideUsersInfo={isRequestToOneUser}
            />
          </tr>
          {restRequestsParams.map((requestParams, index) => (
            <tr key={`${kpi_uuid}_${pack_uuid}_${start_period_date}_${end_period_date}_${index}`}>
              <td className="DataRequests__request-checkbox-cell is-empty">
              </td>
              <td className='DataRequests__period is-empty'>
              </td>
              <DataRequestsInfoCells
                valueSource={value_source}
                kpiValue={kpi_value}
                lastKpiUpdate={last_update}
                startPeriod={start_period_date}
                endPeriod={end_period_date}
                responseValue={requestParams.params.response?.value}
                responseComment={requestParams.params.response?.comment}
                totalAttachments={requestParams.params.response?.attachment_ids?.length || 0}
                requestedToEmail={requestParams.params.request?.email}
                requestedToName={requestParams.params.request?.name}
                requestedToAvatar={requestParams.params.request?.avatar}
                requesterComment={requestParams.params.request?.comment}
                notificationDate={toBetweenDotsDateFormat(requestParams.request_date, intl)}
                requestStatus={requestParams.status}
                responseDate={requestParams.params.response?.date}
                requestId={requestParams.request_id}
                userType={requestParams.type}
                // NOTICE: For the moment we only have use action so lastActionDate will only show up when data request status is in-use or partially-in-use
                lastActionDate={
                  (requestParams.status === DATA_REQUEST_STATUS.in_use || requestParams.status === DATA_REQUEST_STATUS.partially_in_use)
                  ? (requestParams.params.response?.response_used_at || requestParams.params.response?.date) // NOTICE: defensive programming use date if response_used_at does not exist
                  : null
                }
                lastReminderSentDate = {
                  requestParams.status === DATA_REQUEST_STATUS.pending
                  ? requestParams.params.request?.reminder_sent_at
                  : null
                }
                handleOpenKpi={() => handleOpenKpi(kpi_slug, period)}
                dataInUse={requestParams.params.response?.data_in_use}
                hideUsersInfo={isRequestToOneUser}
              />
            </tr>
          ))}
        </React.Fragment>
      );
    })
  );
}

DataRequestsRow.propTypes = {
  isRequestToOneUser: PropTypes.bool,
};

export default injectIntl(DataRequestsRow);