import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';

import {
  createEditor,
} from 'slate';

import { Slate, Editable, withReact } from 'slate-react';

import {
  serialize,
  deserialize,
} from './serializer';

import {
  Toolbar,
  MarkButton,
  BlockButton,
  LinkButton,
  InsertImageButton,
  Element,
  Leaf,
  withLinks,
  withImage,
} from './components';

import {
  Divider,
} from 'antd';

import {
  BoldOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  LinkOutlined,
  PictureOutlined,
} from '@ant-design/icons';

import './style.less'

const CustomTextArea = ({
  intl,
  className,
  value, // NOTICE: changing value externally after the first render does nothing really
  placeholder,
  onChange,
  fullWidth = true,
  supportImages = false,
  imageUploadUrl,
  api_requests,
  refreshAccessToken,
  readOnly = false,
}) => {
  const editor = useMemo(
    () => withImage(withLinks(withReact(createEditor()))),
  []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  // Literally the initial value of 'value'
  const initialValue = useMemo(() => value || '', []); // eslint-disable-line react-hooks/exhaustive-deps

  const [editorValue, setEditorValue] = useState(
    deserialize(initialValue)
    //tempValue
  );

  const notEmptyLink = (node => node.type !== 'link' || node.children[0].text !== "")
  const cleanEmptyNodesChildren = (node) => {
    if (node.type) {
      return node;
    }
    return {...node, children: node.children.map(child => ((child.italic || child.bold) && child.text === "") ? {...child, italic: false, bold: false} : child)}
  }
  const handleChange = useCallback((newEditorValue) => {
    setEditorValue(newEditorValue);
    if(onChange) {
      const newValue = newEditorValue.filter(notEmptyLink).map(node => ({...node, children: node.children.filter(notEmptyLink)})).map(cleanEmptyNodesChildren).map(serialize).join('\n')
      onChange(newValue);
    }
  }, [
    onChange,
  ]);

  return (
    <section
      className={`CustomTextArea ${fullWidth ? 'CustomTextArea__wide' : ''} ${className || ''}`}
    >
      <Slate
        editor={editor}
        value={editorValue}
        onChange={handleChange}
      >
        {!readOnly && <Toolbar>
          <MarkButton format="bold" icon={<BoldOutlined />} />
          <MarkButton format="italic" icon={<ItalicOutlined />} />
          <Divider type="vertical" />
          <BlockButton format="numbered-list" icon={<OrderedListOutlined />} />
          <BlockButton format="bulleted-list" icon={<UnorderedListOutlined />} />
          <Divider type="vertical" />
          <LinkButton icon={<LinkOutlined />} />
          { supportImages &&
            <InsertImageButton
              icon={<PictureOutlined />}
              uploadUrl={imageUploadUrl}
              api_requests={api_requests}
              refreshAccessToken={refreshAccessToken}
              intl={intl}
            />
          }
        </Toolbar>}
        <Editable
          readOnly={readOnly}
          className={readOnly ? '' : `CustomTextArea__editor`}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          data-gramm={false}
          data-gramm_editor={false}
          data-enable-grammarly={false}
          translate="no"
        />
      </Slate>
    </section>
  );
}

export default CustomTextArea;
