import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {EMAIL_REGEXP} from 'utils/validation';
import {v4 as uuidv4} from 'uuid'

import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';

import {
  Select,
} from 'antd';

import {
  PlusOutlined,
} from '@ant-design/icons';
import InfoTooltip from 'components/InfoTooltip';

const { Option } = Select;

const AddExternalUser = ({
  intl,
  languages,
  onAddExternalUser,
  allUserEmails,
  disabled = false,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [languageSelected, setLanguageSelected] = useState(null);
  const [commentInput, setCommentInput] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleAddExternalUser = useCallback(() => {
    if (!EMAIL_REGEXP.test(emailInput)) {
      setEmailError(intl.formatMessage({id: 'step_3_email_not_valid'}));
    } else if (allUserEmails.includes(emailInput.toLowerCase())) {
      setEmailError(intl.formatMessage({id: 'step_3_email_exists'}));
    } else {
      const externalUser = {
        slug: uuidv4(),
        name: nameInput,
        email: emailInput.toLowerCase(),
        avatar: null,
        language: languageSelected,
        comment: commentInput || '',
        internal: false
      };
      onAddExternalUser(externalUser);
      setNameInput('');
      setEmailInput('');
      setLanguageSelected(null);
      setCommentInput('');
      setEmailError('');
    }
  }, 
  [
    commentInput,
    allUserEmails,
    emailInput,
    intl,
    languageSelected,
    nameInput,
    onAddExternalUser,
  ]);

  return (
    <div className="AddExternalUser">
      {showForm ? (
        <div className="AddExternalUser__forms">
          <div className="AddExternalUser__form">
            <label for="Name" className="AddExternalUser__label">{intl.formatMessage({id: 'step_3_add_name_label'})}</label>
            <CustomInput
              id="Name"
              className="AddExternalUser__input"
              placeholder={intl.formatMessage({id: 'step_3_add_name_placeholder'})}
              value={nameInput}
              onChange={e => setNameInput(e.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="AddExternalUser__formWrapper">
            <div className="AddExternalUser__form">
              <label for="Email" className="AddExternalUser__label">{intl.formatMessage({id: 'step_3_add_email_label'})}</label>
              <CustomInput
                id="Email"
                className={`AddExternalUser__input ${emailError ? 'has-error' : ''}`}
                placeholder={intl.formatMessage({id: 'step_3_add_email_placeholder'})}
                value={emailInput}
                onChange={e =>  setEmailInput(e.target.value)}
                disabled={disabled}
              />
            </div>
            {emailError ? (
              <span className="AddExternalUser__emailError">{emailError}</span>
            ) : null}            
          </div>
          <div className="AddExternalUser__form">
            <label for="Language" className="AddExternalUser__label">{intl.formatMessage({id: 'step_3_add_language_label'})}</label>
            <Select
              className="AddExternalUser__selector"
              placeholder={intl.formatMessage({id: 'step_3_add_language_placeholder'})}
              value={languageSelected}
              onChange={(selection) =>  setLanguageSelected(selection)}
              disabled={disabled}
            >
              {languages.map(language => (
                <Option
                  key={language}
                >
                  {intl.formatMessage({id: language})}
                </Option>
              ))}
            </Select>
          </div>
          <div className="AddExternalUser__form">
            <label for="Comment" className="AddExternalUser__label">{intl.formatMessage({id: 'step_3_add_comment_label'})}</label>
            <CustomInput.TextArea
              id="Comment"
              className="AddExternalUser__textArea"
              placeholder={intl.formatMessage({id: 'step_3_add_comment_placeholder'})}
              rows={1}
              value={commentInput}
              onChange={e => setCommentInput(e.target.value)}
              disabled={disabled}
            />
          </div>
          <CustomButton
            className="AddExternalUser__addButton"
            type="primary" 
            onClick={handleAddExternalUser}
            disabled={!nameInput || !emailInput || !languageSelected || disabled}
          >
             <PlusOutlined /> {intl.formatMessage({id: 'step_3_add_button'})}
          </CustomButton> 
        </div>
      ) : null}
      <div className="AddExternalUser__wrapper">
        <button
          className="AddExternalUser__showFormButton"
          onClick={() => setShowForm(true)}
          disabled={showForm || disabled}
        >
          <PlusOutlined /> {intl.formatMessage({id: 'step_3_add_external_user'})}
        </button>
        <InfoTooltip
          type='warning'
          content={
            <span className="Step3__warningTooltipContent">
                {intl.formatMessage(
                    {id: `step_3_warning_tooltip_limit_users`},
                    {boldValue: <b>{intl.formatMessage({id: `step_3_warning_tooltip_limit_users_bold`})}</b>}
                )}
            </span>
          }
        />
      </div>
    </div>
  );
};

AddExternalUser.propTypes = {
  disabled: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  onAddExternalUser: PropTypes.func.isRequired,
  currentUserSlugs: PropTypes.arrayOf(PropTypes.string),
  allUserEmails: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(AddExternalUser);
