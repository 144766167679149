import React from 'react'
import {Modal} from 'antd';
import './style.less'

const NO_OP = () => {};

const CustomModal = ({
  shown,
  setShown = NO_OP,
  title,
  onOk,
  children,
  okText,
  cancelText,
  footer,
  width,
  onCancel = NO_OP,
  className = '',
  destroyOnClose = true,
  afterClose = NO_OP
}) => (
  <Modal
    className={`CustomModal ${className}`}
    title={title}
    visible={shown}
    onOk={() => onOk()}
    onCancel={() => {
      onCancel()
      setShown(false)
    }}
    closeIcon={<img src='/images/assets/cross.svg' alt=''/>}
    okText={okText}
    cancelText={cancelText}
    footer={footer}
    width={width}
    centered={true}
    destroyOnClose={destroyOnClose}
    afterClose={afterClose}
  >
    {children}
  </Modal>
)

export default CustomModal
