import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';
import config from 'config';
import useOrganizations from 'utils/useOrganizations';
import { isExternalPath } from 'utils/validation';

const eventNames = {
    'dataFiles.downloadGlobalFile': 'data files: download whole org',
    'dataFiles.downloadNodeTemplate': 'data files: download current node',
    'dataFiles.uploadNodeTemplate': 'data files: upload template',
    'attachments.downloadAll': 'attachments: download zip',
    'fillReport.generateReport': 'report: generate',
    'report.download': 'report: download',
    'report.delete': 'report: delete',
    'nav.documentation': 'documentation: open',
    'dataRequestTab.customViewSelection': 'custom view: selection',
    'dataRequestTab.progressBarsToggleShow': 'progress: show',
    'dataRequestTab.useRequestsBulk': 'data: use',
    'dataRequestTab.deleteRequestsBulk': 'data: delete',
    'dataRequestTab.sendReminderRequestsBulk': 'data: send reminder',
    'dataRequestTab.scrollToTop': 'top scroll: back',
    'dashboards.tabAccess': 'dashboard: access',
    'dataManagement.tabAccess': 'data tab: access',
    'dataOwners.assign': 'extended owners: assign',
    'dataOwners.remove': 'extended owners: remove',
    'dataOwners.sync': 'extended owners: sync to all children',
    'dataOwners.add': 'extended owners: add',
    'fillReport.filters': 'fill and report: filter selection',
    'dataRequestExternal.progressBarsToggleShow': 'external request progress: show',
    'dataRequestExternal.filterSelection': 'external request filter: selection',
    'dataRequestExternal.responseAttachmentDelete': 'external request response attachment: delete',
};

export const useEventTracking = () => { 

    const [isInitialized, setIsInitialized] = useState(false);
    const { suborganization, organizationTree } = useOrganizations();
    const { data: userProfile } = useSelector(state => state.profile);
    const { profile: externalUserProfile } = useSelector(state => state.third_party);

    const isExternalUser = useMemo(() => {
        return isExternalPath(window.location.pathname) && externalUserProfile;
    }, [externalUserProfile]);

    const init = useCallback(() => { 

        if ((userProfile || isExternalUser) && config.POSTHOG_KEY && (!isInitialized)){
            const profile = userProfile ? userProfile : externalUserProfile;
            const aplanet_id = profile.organization ? (profile.organization.aplanet_id ? profile.organization.aplanet_id : 0) : 0; 
            setIsInitialized(true);
            
            // Init PostHog
            posthog.init(config.POSTHOG_KEY, {
                api_host: config.POSTHOG_URL,
                loaded: function(posthog) { 
                    posthog.identify(profile.id, {
                        user_role: profile.role, 
                        user_language: profile.language,
                        user_organization_id: profile.organization_id,
                        user_email: profile.email,
                        aplanet_id: aplanet_id
                    });
                },
                autocapture: false, 
                capture_pageview: false
            });
        }

    }, [externalUserProfile, isExternalUser, isInitialized, userProfile]);

    const capture = (eventKey, params) => {

        if (config.POSTHOG_KEY){

            const eventName = eventKey in eventNames ? eventNames[eventKey] : null;
            
            if (eventName){

                const eventParams = isExternalUser ? params : {
                    organization_id: organizationTree.id,
                    organization_name: organizationTree.name,
                    organization_slug: organizationTree.slug,
                    suborganization_id: suborganization.id,
                    suborganization_name: suborganization.name,
                    suborganization_slug: suborganization.slug,
                    organization_aplanet_id: organizationTree.aplanet_id,
                    ...params,
                };

                // console.log(eventName, eventParams);
                posthog.capture(eventName, eventParams);

            } else {

                console.warn('[useEventTracking] - Invalid event key: ' + eventKey);
                
            }
        
        }

    }

    return {
        init,
        capture
    };

};
