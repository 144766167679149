const COMBINING_CHARACTERS_REGEX = new RegExp('[\u0300-\u036f]', 'g');
const WHITESPACE_REGEX = new RegExp('[ ]+', 'g');

export const removeAccents = (str) => (str || '').normalize('NFD').replace(COMBINING_CHARACTERS_REGEX, '');

export const makeSearchString = (str) => removeAccents(str).toLowerCase().trim();

export const makeSearchStrings = (str) => (str || '').split(WHITESPACE_REGEX).map(makeSearchString);


export const kebabToPascal = (str = '') => {
  const arr = str.split('-');
  const capital = arr.map(
    (item, index) => {
      return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    }
  );
  return capital.join('');
};

export const replaceTextURLByHTMLLink = (text) => {
  if (!text) {
    return text;
  }

  // URLs starting with http://, https://, or ftp://
  let replacedText = text.replace(
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
    '<a href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacedText = replacedText.replace(
    // eslint-disable-next-line no-useless-escape
    /(^|[^\/])(www\.[\S]+(\b|$))/gim,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  return replacedText;
};

// https://stackoverflow.com/questions/67665886/display-a-string-in-react
export const truncateString = (words, length) => {
  words = words.trim(); //you need to decided whether you do this or not
  length -= 3; // because ' (...)'.length === 6
  if (length >= words.length) return words;

  let oldResult = /\s/.test(words.charAt(length));
  for (let i = length - 1; i > -1; i--) {
    const currentRusult = /\s/.test(words.charAt(i))

    //check if oldresult is white space and current is not.
    //which means current character is end of word
    if (oldResult && !currentRusult) {
      return `${words.substr(0, i + 1)}...`;
    }
    oldResult = currentRusult;
  }
  // you need to decide whether you will just return truncated or original
  // in case you want original just return word
  return '...';
}

export const convertStringToArrayWordSegments = (str, numChars) => {

  if (str.length <= numChars) {
    return [str];
  }

  const arrStr = (`${ str }`).split(' ');
  let arrResult = [];
  let strLine = '';
  
  while(arrStr.length){
    const firstWord = arrStr.shift();
    if (`${ strLine } ${ firstWord }`.length > numChars){
      arrResult.push(strLine);
      strLine = `${ firstWord }`;
    } else {
      strLine = `${ strLine } ${ firstWord }`;
    }
  }

  arrResult.push(strLine);
  return arrResult;

}