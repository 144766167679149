import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Collapse,
} from 'antd';
import './style.less';

const DEFAULT_KEY='1';

const CollapsableCard = ({
  intl,
  onClick,
  title,
  icon,
  children,
  status,
  className = '',
  footer = false,
  action = false,
  defaultExpanded = false,
}) => {
  return (
    <Collapse
      className={`CollapsableCard__card ${footer ? 'with-footer' : ''} ${className}`}
      expandIconPosition='right'
      bordered={true}
      expandIcon={({ isActive }) => (
        <section className='CollapsableCard__icon'>
          {!action ? null : action}
          <img src='/images/assets/chevron-down.svg' alt='' style={{transform: `rotate(${isActive ? 180 : 0}deg)`}} />
        </section>
      )}
      defaultActiveKey={defaultExpanded ? [DEFAULT_KEY] : []}
    >
      <Collapse.Panel
        key={DEFAULT_KEY}
        header={<div className='CollapsableCard__extra-icon'>{icon}<span>{title}</span></div>}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}

export default injectIntl(CollapsableCard);
