import {
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

const getBaseUnit = (schema, metrics) => {

    const metric = metrics.find(({ slug }) => slug === schema.metricSlug);

    if(!metric || !metric.units) {
        return null;
    }

    return metric.units.find( unit => unit.is_base );

}

const getTupleBaseUnit = (schema, metrics) => {

    const components = schema.components || {};
    return Object.keys(components)
        .filter(key => components[key].type === 'quantitative')
        .map(key => {
            return [key, getBaseUnit(components[key], metrics)];
        }).reduce((obj, [key, units]) => {
            obj[key] = units;
            return obj;
        }, {});

}

const useMetricUnits = (schema) => {

    const taxonomies = useSelector(state => state.taxonomies);

    const metrics = useMemo(() => {
        return (
            taxonomies.metrics && taxonomies.metrics.length > 0
                ? taxonomies.metrics
                : []
        ).map(({ metrics }) => metrics || []).reduce((acc, arr) => acc.concat(arr), []);
    }, [
        taxonomies,
    ]);

    const baseUnits = useMemo(() => { 

        switch(schema.type) {
            case 'quantitative':
                return getBaseUnit(schema, metrics);
            case 'tuple': {
                return getTupleBaseUnit(schema, metrics);
            }
            case 'table': {
                if(schema.innerSchema.type === 'tuple') {
                    return getTupleBaseUnit(schema.innerSchema, metrics);
                }
                return getBaseUnit(schema.innerSchema, metrics);
            }
            default:
                return null;
        }
    }, [
        schema,
        metrics
    ]);

    return { 
        baseUnits
    };

}

export {
    useMetricUnits
};