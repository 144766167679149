import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import Filter from './SelectKpiFilter';
import { Loading } from 'tsComponents/emptyStates/Loading';
import CardFormStepWrapper from '../CardFormStepWrapper';
import { DashboardCardFormContext } from '../..';

import { 
  FORM_KEY_CARD_NAME,
  FORM_KEY_GRAPH_ADDRESS, 
  FORM_KEY_PERIODICITY, 
  FORM_KEY_PERIODSNUMBER, 
  FORM_KEY_SELECTED_ROWS, 
  FORM_KEY_SELECTED_ROW_KEYS, 
  FORM_KEY_SELECTED_UNIT} from 'containers/DashboardCardForm/constants';

import { useDashboardCardForm } from 'containers/DashboardCardForm/useDashboardCardForm';
import KpiTreeSelector from 'components/KpiTreeSelector';
import useOrganizations from 'utils/useOrganizations';
import { requestReportingStructure } from 'actions/api';

import '../style.less'

const SelectKpi = ({
  intl,
  organization
}) => {

  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const { getTableDataByKpis } = useDashboardCardForm();
  const { values, handleChange } = useContext(DashboardCardFormContext);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const displayCheckboxList = document.getElementsByName('disabled')
    displayCheckboxList.forEach((item) => {
      item.parentNode.style.display = 'none'
    })
  })

  const enabledReports = useMemo(() => [
      ...organization?.data?.config?.enabled_reports || [],
      'custom',
    ]
  , [
    organization,
  ]);

  const setSelectedKpis = useCallback((selectedRows) => {

    if (values.id && !isInitialized){
      handleChange(FORM_KEY_SELECTED_ROWS)(selectedRows);

      if (selectedRows.length > 0 && ['boolean', 'qualitative'].includes(selectedRows[0]?.schema?.type)){
        handleChange(FORM_KEY_PERIODSNUMBER)(1);
      }

      setIsInitialized(true);
      return;
    }

    const selectedRowKeys = selectedRows.map(row => ({ 
      slug: row.slug,
      uuid: row?.uuid || ''
    }));

    handleChange(FORM_KEY_SELECTED_ROW_KEYS)(selectedRowKeys);
    handleChange(FORM_KEY_SELECTED_ROWS)(selectedRows);
  
    const tableDataByKpis = getTableDataByKpis(selectedRows);
    handleChange(FORM_KEY_GRAPH_ADDRESS)([...tableDataByKpis.dimensions, ...tableDataByKpis.columns]);

    //Check common periodicities
    if (!selectedRows.every( kpi => kpi.periodicities.includes(values.periodicity) )){
      handleChange(FORM_KEY_PERIODICITY)(null);
      handleChange(FORM_KEY_PERIODSNUMBER)(1);
    }

    let selectedUnit = '';
    if (selectedRows.length > 0){
      const finalSchema = selectedRows[0].schema.type === 'table' ? selectedRows[0].schema.innerSchema : selectedRows[0].schema;
      if (finalSchema.type === 'quantitative' && finalSchema.metricSlug && finalSchema.allowedUnitSlugs.length === 1){
        selectedUnit = finalSchema.allowedUnitSlugs[0];
      }
    }
    handleChange(FORM_KEY_SELECTED_UNIT)(selectedUnit);

    if (!values?.id && selectedRows.length > 0){
      handleChange(FORM_KEY_CARD_NAME)( selectedRowKeys.length > 1 
                                              ? intl.formatMessage({ id: 'dashboard_card_form_select_graph_default_name' }) 
                                              : selectedRows[0].name.substring(0, 40));
    }
    
    if (selectedRows.length > 0 && ['boolean', 'qualitative'].includes(selectedRows[0]?.schema?.type)){
      handleChange(FORM_KEY_PERIODSNUMBER)(1);
    }
  
  }, [isInitialized, getTableDataByKpis, handleChange, values.periodicity, intl, values?.id]);

  const kpiHasValidPeriodicities = useCallback(( kpi ) => {

    return kpi.periodicities.some( periodicity => { 
      return values.selectedRows.every( kpiSelected => kpiSelected.periodicities.includes(periodicity) )
    })

  }, [values.selectedRows]);

  const kpiHasValidMetric = useCallback(( kpi ) => {

    if (!(kpi.schema.type === 'quantitative')) return true;

    return kpi.schema.metricSlug 
            && values.selectedRows.every( kpiSelected => kpiSelected.schema.type === 'quantitative' && kpiSelected.schema.metricSlug === kpi.schema.metricSlug )

  }, [values.selectedRows]);

  const {
    suborganization
  } = useOrganizations();

  useEffect(() => {
    if (
        !organization?.slug ||
        !suborganization?.slug
    ) return
    
    dispatch(
        requestReportingStructure({
            organization_slug: organization.slug,
            suborganization_slug: suborganization.slug
        })
    );

  }, [
      organization?.slug,
      suborganization?.slug,
      dispatch,
  ]);

  const {
    data: reporting_structure,
  } = useSelector(state => state.reporting_structure);

  const isNodeDisabled = useCallback((record, selectedKpis) => {

    return !(selectedKpis.length && selectedKpis.some(e => e.slug === record.slug && e.uuid === record.uuid)) && // If we have selected any row, we dont disable that row's checkbox
        (record.isCategory // We disable the checkbox if row is a category and not a kpi 
        || selectedKpis.some(e => e.slug === record.slug && e.uuid !== record.uuid)
        || selectedKpis.some(e => ['table', 'boolean', 'qualitative'].includes(e.schema?.type)) // We disable all kpis if we have selected one which is a table / boolean / qualitative
        || (selectedKpis.length && record.schema?.type === 'table') //If we have selected any kpi, we disable all the ones which are tables
        || (!kpiHasValidPeriodicities(record)) //If we have selected any kpi, we disable all the ones that don't have periodicity matches
        || (!kpiHasValidMetric(record))
        );

  }, [kpiHasValidPeriodicities, kpiHasValidMetric]);

  if (!reporting_structure) return <Loading />;
  
  return (
      <>
        <Filter
          setFilters={setFilters}
          filters={filters} 
          enabledReports={enabledReports}
        />
        <CardFormStepWrapper className="DashboardCardForm__kpiSelection">
          <div className="SelectKpiTable">
            <KpiTreeSelector 
              reportingStructure={reporting_structure || []}
              onSelectKpis={setSelectedKpis}
              selectedKpis={ values.selectedRowKeys }
              extraColumns={['periodicity','standard', 'esg', 'sdg', 'link']}
              isCategoryCheckable={false} 
              filters={filters} 
              isNodeDisabled={ isNodeDisabled }
              enabledReports={ enabledReports }
            />
          </div>
        </CardFormStepWrapper>
      </>
  )
};

export default injectIntl(SelectKpi);
