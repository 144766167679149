import React, {
    useCallback,
} from 'react';
import { Menu, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import {
  resetOrganization,
} from 'actions/organizationTree';

import config from 'config';

import Avatar from 'components/Avatar';

import styled from "@emotion/styled";

import { formatMessage } from '../../../intl';

const MenuItem = styled(Menu.Item)`
  a {
    display: block;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const AvatarDropdown = ({
  intl,
  dispatch,
  avatar,
  name,
  memberships = [],
  organization,
  mainOrganization,
}) => {
    const handleResetOrg = useCallback(() => dispatch(resetOrganization()), [ dispatch ]);
  
    return (
        <Dropdown
            overlay={
                <Menu>
                  { memberships.length <= 1 ? null
                      :
                          <MenuItem
                          onClick={handleResetOrg}
                          >
                          { formatMessage('avatardropdown_change_organization') }
                          </MenuItem>
                  }
                  <MenuItem>
                      <a
                      href={`${config.ACCOUNTS_URL}/profile/${mainOrganization.slug}?org=${organization.slug}`}>
                      { formatMessage('avatardropdown_profile') }
                      </a>
                  </MenuItem>
                  <MenuItem>
                      <a
                      href={`${config.ACCOUNTS_URL}/organization/${mainOrganization.slug}?org=${organization.slug}`}>
                      { formatMessage('avatardropdown_organization') }
                      </a>
                  </MenuItem>
                  <MenuItem>
                      <Link to="/logout">
                      {formatMessage('avatardropdown_logout')}
                      </Link>
                  </MenuItem>
                </Menu>
            }
            className="AppHeader-avatarMenu"
            overlayClassName="AppHeader-avatarDropdown"
            trigger={['click']}
            >
            <section>
                <Avatar
                  shape="circle"
                  src={avatar || undefined}
                  name={name || undefined}
                  icon={<UserOutlined />}
                  className='AppHeader-avatar'
                />
                <div className='AppHeader-profileName'>{name}</div>
                <img
                  className='AppHeader-chevron'
                  src='/images/assets/chevron-down.svg'
                  alt='chevron-down'
                />
            </section>
        </Dropdown>
    );
};

export default injectIntl(
    withRouter(
      AvatarDropdown
    )
);
