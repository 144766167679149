export const convertToUnit = (
  targetUnit,
  availableUnits = []
) => ({
  value,
  unit: sourceUnit,
}) => {
  const targetUnitObj = availableUnits.find(({ slug }) => slug === targetUnit);
  const sourceUnitObj = availableUnits.find(({ slug }) => slug === sourceUnit);

  if(!targetUnitObj || !sourceUnitObj) {
    console.log(`WARNING: I dont know how to convert ${sourceUnit} to ${targetUnit}`);
    return {
      value,
      unit: sourceUnit,
    }
  }

  return {
    value: ((value - sourceUnitObj.offset) * sourceUnitObj.ratio / targetUnitObj.ratio) + targetUnitObj.offset,
    unit: targetUnit,
  }
};

