
import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

import {
  requestDataCategories,
  getKpiMonitor,
  requestKpiDetail,
} from 'actions/api';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const TARGET = 'kpi_detail';
const KPI_TARGET = 'data_kpi';
const META_TARGET = 'data_meta';
const KPI_SETTINGS_TARGET = 'data_kpi_settings';
const KPI_EDIT_TARGET = 'data_kpi_edit';
const APPROVAL_TARGET = 'data_approval';
const REQUEST_TARGET = 'data_request';
const ATTACHMENT_TARGET = 'kpi_attachment';
const RESTRICTION_TARGET = 'kpi_restriction';
const KPI_DELETE_TARGET = 'kpi_delete';
const KPI_MONITORING = 'kpi_monitor';
const KPI_VALUE_STATUS_TARGET = 'kpi_value_status';

const isTarget = (target) => [
  TARGET,
  KPI_TARGET,
  META_TARGET,
  KPI_SETTINGS_TARGET,
  KPI_EDIT_TARGET,
  APPROVAL_TARGET,
  REQUEST_TARGET,
  ATTACHMENT_TARGET,
  RESTRICTION_TARGET,
  KPI_DELETE_TARGET,
  KPI_MONITORING,
  KPI_VALUE_STATUS_TARGET,
].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' && !action.keep ? null : state.data,
        loading: action.target !== KPI_VALUE_STATUS_TARGET ? true : state.loading,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      if(isGeneralTarget(action.response.target)) {
        return {
          data: action.response.result,
          loading: false,
          error: null,
        };
      }

      if(
        action.response.target === KPI_TARGET &&
        action.response.method === 'POST'
      ) {
        return loop(
          {
            data: {...state.data, ...action.response.result},
            loading: false,
            error: null,
          },
          Cmd.action(requestDataCategories(
            {
              organization_slug: action.response.result.organization.slug,
              suborganization_slug: action.response.result.suborganization.slug,
              includeAllPeriods: false
            }
          ))
        );
      }

      if(
        action.response.target === KPI_TARGET &&
        (action.response.method === 'PUT' || action.response.method === 'DELETE')
      ) {
        return {
          data: {...state.data, ...action.response.result},
          loading: false,
          error: null,
        };
      }

      if(
        action.response.target === KPI_MONITORING &&
        action.response.method === 'GET'
      ) {
        return {
          data: {...state.data, ...action.response.result},
          loading: false,
          error: null,
        };
      }

      if(
        action.response.target === KPI_MONITORING &&
        action.response.method === 'POST'
      ) {
        delete action.response.result.period;
        return {
          data: {...state.data, ...action.response.result},
          loading: false,
          error: null,
        };

        // return loop(
        //   {
        //     data: {...state.data, ...action.response.result},
        //     loading: false,
        //     error: null,
        //   },
        //   Cmd.action(requestDataCategories(
        //     {
        //       organization_slug: action.response.result.organization_slug,
        //       suborganization_slug: action.response.result.suborganization_slug,
        //       includeAllPeriods: true
        //     }
        //   ))
        // );
      }

      if(
        action.response.target === KPI_MONITORING &&
        action.response.method === 'PUT'
      ) {
        delete action.response.result.period;
        return {
          data: {...state.data, ...action.response.result},
          loading: false,
          error: null,
        };
      }

      if(
        action.response.target === KPI_MONITORING &&
        action.response.method === 'DELETE'
      ) {
        delete action.response.result.period;
        return {
          data: {...state.data, ...action.response.result},
          loading: false,
          error: null,
        };
      }

      if(
        action.response.target === META_TARGET &&
        state.data &&
        state.data.slug &&
        action.response.result &&
        (action.response.result.kpi_slugs || []).includes(state.data.slug)
        // NOTICE: Prevent from reacting to META_TARGET response if this org was not affected
      ) {
        if(action.response.result.add_tags) {
          return {
            ...state,
            loading: false,
            error: null,
            data: {
              ...(state.data),
              tags: action.response.result.add_tags
            }
          }
        }
        if (typeof action.response.result.applies !== 'undefined') {
          const applies_target = action.response.meta_target;
          let data = state.data;
          let suborganizations = state.data.suborganizations;
          const {
            kpi_slugs,
            target,
            ...update
          } = action.response.result;
          if (applies_target === 'self' || applies_target === 'all') {
            data = {...data, ...update};
          }
          if (applies_target === 'all' || applies_target === 'descendants') {
            suborganizations = suborganizations.map(s => ({...s, ...update}));
          }
          return {
            ...state,
            loading: false,
            error: null,
            data: {
              ...data,
              suborganizations
            }
          }
        }
        if(
          action.response.meta_target !== 'descendants' &&
          (typeof action.response.result.guidelines_std !== 'undefined' ||
          typeof action.response.result.guidelines_org !== 'undefined' ||
          typeof action.response.result.guidelines_member !== 'undefined')
        ) {
          const {
            kpi_slugs,
            ...update
          } = action.response.result;

          return {
            ...state,
            loading: false,
            error: null,
            data: {
              ...(state.data),
              ...update,
            }
          }
        }
      }

      if(
        action.response.target === KPI_SETTINGS_TARGET &&
        state.data &&
        state.data.slug &&
        action.response.body &&
        (action.response.body.kpi_slugs || []).includes(state.data.slug)
      ) {

        const {
          kpi_slugs,
          ...update
        } = action.response.result;

        const {
          organization_slug,
          suborganization_slug,
        } = action.response;

        return loop({
          ...state,
          loading: false,
          error: null,
          data: {
            ...(state.data),
            ...update,
            config: {
              ...(state.data.config),
              ...update
            }
          }
        },
        Cmd.action(
          requestKpiDetail(
            organization_slug,
            suborganization_slug,
            state.data.slug,
            state.data.period.label,
          )
        ));
      }

      if(
        action.response.target === KPI_EDIT_TARGET &&
        state.data &&
        state.data.slug &&
        action.response.body &&
        (action.response.result.kpi_slugs || []).includes(state.data.slug)
      ) {
        const {
          sdgs,
          standard_info,
          name,
          name_translations,
          slug,
          schema,
          schemaLabels,
          esg_type,
          guidelines_std,
          cycle_date,
        } = action.response.result;

        const nextState = {
          ...state,
          loading: false,
          error: null,
          data: {
            ...(state.data),
            name: name || state.data.name,
            name_translations: name_translations || state.data.name_translations,
            type: esg_type || state.data.type,
            guidelines_std: guidelines_std || state.data.guidelines_std,
            slug: slug || state.data.slug,
            sdgs,
            standard_info,
            schema: schema || state.data.schema,
            schemaLabels: schemaLabels || state.data.schemaLabels,
            kpi_value: action.response.body.schema_empty_existing_values === false
              ? state.data.kpi_value
              : null,
            all_periods: schema
              ? [
                  ...state.data.all_periods.map(
                    period => ({...period, kpi_value: null})
                  )
                ]
              : state.data.all_periods,
          }
        };

        if (
          slug
          && (
            slug !== state.data.slug
            || (
              cycle_date
              && cycle_date !== state.data.cycle_date
            )
          )
        ) {
          return loop(
            nextState,
            Cmd.run(
              requestPush,
              { args: [ `/kpi/${slug}/last/` ] },
            )
          );
        }

        if( schema ) {
          //return nextState;
          return loop(
              nextState,
                  Cmd.action(getKpiMonitor(
                    {
                       organization_slug: nextState.data.organization_slug,
                       suborganization_slug: nextState.data.suborganization_slug,
                       kpi_slug:  nextState.data.slug,
                       type: 'target',
                       period: true // retrieve targets for all periods
                    }
                  ))
          );
        } else {
          return nextState;
        }

      }

      if(
        action.response.target === APPROVAL_TARGET &&
        state.data &&
        state.data.slug &&
        action.response.result &&
        action.response.result.length > 0
      ) {
        const result = action.response.result.find(
          ({ slug }) => slug === state.data.slug
        )
        if(result) {
          return {
            ...state,
            loading: false,
            error: null,
            data: result,
          }
        }
      }

      if(
        action.response.target === REQUEST_TARGET &&
        state.data &&
        state.data.slug
      ) {
        switch(action.response.method) {
          case 'POST':
          case 'PUT':
            const {
              organization_slug,
              suborganization_slug,
            } = action.response;
            if(organization_slug && suborganization_slug) {
              return loop(
                {
                  ...state,
                  loading: false,
                  error: null,
                },
                Cmd.action(
                  requestKpiDetail(
                    organization_slug,
                    suborganization_slug,
                    state.data.slug,
                    state.data.period.label,
                  )
                )
              );
            }
            return {
              ...state,
              loading: false,
              error: null,
            }
          case 'DELETE':
            return {
              ...state,
              loading: false,
              error: null,
              data: {
                ...(state.data),
                external: (state.data.external || []).filter(
                  request => request.email !== action.response.result.email
                ),
              }
            };
          default:
            return {
              ...state,
              loading: false,
              error: null,
            };
        }
      }
      if(
        action.response.target === ATTACHMENT_TARGET &&
        state.data
      ) {
        const result = action.response.result;
        switch(action.response.method) {
          case 'DELETE':
            return {
              ...state,
              loading: false,
              error: null,
              data: {
                ...(state.data),
                attachments: [
                  ...(state.data.attachments || []).filter(
                    ({ filename, folder }) => !(
                      filename === result.filename &&
                      folder === result.folder
                    )
                  )
                ],
              }
            };
          case 'POST':
            return {
              ...state,
              loading: false,
              error: null,
              data: {
                ...(state.data),
                attachments: [...(state.data.attachments || []), ...result]
              }
            };
          default:
            const existing = (state.data.attachments || [])
              .find(({ filename, folder }) => (
                filename === result.filename &&
                folder === result.folder
              ));
            return {
              ...state,
              loading: false,
              error: null,
              data: {
                ...(state.data),
                attachments: [
                  ...(state.data.attachments || []),
                  ...(!existing ? [result] : []),
                ],
              }
            };
        }
      }
      if(
        action.response.target === RESTRICTION_TARGET &&
        state.data
      ) {
        return {
          ...state,
          loading: false,
          error: null,
          data: {
            ...(state.data),
            restricted: action.response.method === 'POST',
            permissions: {
              ...(state.data.permissions || {}),
              can_manage_permissions: true, // If you are changing permissions, you can change permissions
            },
          }
        }
      }
      if(
        action.response.target === KPI_DELETE_TARGET &&
        state.data
      ) {
        const category_slug = state.data?.category_slug;
        const pathToPush = category_slug ? `/data/manage/${category_slug}` :  '/data';
        return loop(
          initialState,
          Cmd.run(
            requestPush,
            { args: [ pathToPush ] },
          )
        );
      }

      if(
        (action.response.target === KPI_VALUE_STATUS_TARGET) &&
        state.data &&
        (state.data.slug === action.response.kpiSlug)
      ) {
        return {
          ...state,
          data: {
            ...state.data,
            kpi_value_status_for_whole_tree: action.response.result.status,
          },
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        data: isGeneralTarget(action.request.target) ? null : state.data,
        loading: false,
        error: action.code,
      };
    case 'ADD_KPI_ATTACHMENT':
      if(
        !state.data ||
        state.data.slug !== action.kpi_slug ||
        (state.data.period || {}).label !== action.period
      ) {
        return state;
      }

      // NOTICE: Here we assume action.organization_slug is correct. If it is not bad thing will happen
      return {
        ...state,
        data: {
          ...state.data,
          attachments: [
            ...(state.data.attachments || []),
            action.attachment,
          ],
        }
      };
    case 'SET_OWNERS_UPTODATE':
      return {
        ...state,
        data: {
          ...state.data,
          owners_uptodate: action.owners_uptodate,
        }
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
    case 'RESET_KPI_DETAIL':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as kpi_detail,
};
