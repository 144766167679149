import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as Sentry from '@sentry/react';

import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import Header from './Header';
import Data from './screens/Data';
import History from './screens/History';
//import Validation from './screens/Validation';
import Files from './screens/Files';
import MainLayout from 'components/MainLayout';
import Navigator from 'components/Navigator'
import { Loading } from 'tsComponents/emptyStates/Loading';
import ModalDataAnswer from 'components/ModalDataAnswer';
import Assignees from './screens/Assignees';
import DataRequestsWizardScreen from 'containers/DataRequests';

import { Tabs } from 'antd';
import Error from 'components/Error';

import {
  copyKpiAttachments,
  requestKpiDetail,
  resetKpiDetail,
  requestKpiUpdate,
  recordKpiAttachment,
  remindSuggestionRequest,
  dataRequestsUseResponse,
  dataRequestsUseAggregatedResponse,
} from 'actions/api';

import './style.less';

const URL_BASE = 'kpi';
const DEFAULT_ACTION = 'data';


const KpiDetail = ({
  urlBase = URL_BASE,
  intl,
  history,
  match,
  location,
}) => {
  const [ modalAnswer, setModalAnswer ] = useState(null);
  const [showDataRequests, setShowDataRequests] = useState(false);

  // Store and actions: Get organization and kpi_detail state
  const dispatch = useDispatch();
  const {
    data,
    loading,
    error,
  } = useSelector(state => state.kpi_detail);

  const {
    organization,
    suborganization,
    permissions: suborganizationPermissions,
    isMainOrganization,
  } = useOrganizations();

  const { new_atlas_enabled = {} } = suborganization?.product_config?.atlas;
  const {
    data_request = false,
  } = new_atlas_enabled;

  const permissions = useMemo(() => {
    if(data && data.restricted) {
      return data.permissions;
    }
    return suborganizationPermissions;
  }, [
    suborganizationPermissions,
    data,
  ]);

  const config = suborganization?.product_config?.atlas ?? {};

  const {
    features: featureList,
  } = useFeatureList();

  // Active tab (from URL)
  const activeKey = useMemo(() => {
    return match.params.action || DEFAULT_ACTION;
  }, [ match.params.action ]);

  useEffect(() => {
    const {
      period,
      slug,
      action,
    } = match.params;

    let lastPeriod;

    if (data && data.period && (period === 'last')){
      lastPeriod = data.period;
    } else if (
        data &&
        data.all_periods &&
        data.all_periods.length > 0 &&
        (!data.all_periods.find(({ label }) => label === period))
    ){
      lastPeriod = data.all_periods.slice(-1)[0];
    }

    if (lastPeriod){
      return history.replace(`/${urlBase}/${slug}/${lastPeriod.label}/${action || ''}${location.search}`);
    }
    
  }, [
    urlBase,
    match.params,
    data,
    history,
    location,
  ]);

  // Change tab
  const changeTab = useCallback((action) => {
    const slug = match.params.slug;
    const period = match.params.period;
    if(action === DEFAULT_ACTION) {
      return history.replace(`/${urlBase}/${slug}/${period}${location.search}`);
    }
    return history.replace(`/${urlBase}/${slug}/${period}/${action}${location.search}`);
  }, [
    urlBase,
    match.params.slug,
    match.params.period,
    history,
    location,
  ]);

  // Change period
  const changePeriod = useCallback((period) => {
    const { slug, action, period: oldPeriod } = match.params || {};

    if(period === oldPeriod) {
      return;
    }
    if(!action || action === DEFAULT_ACTION) {
      return history.replace(`/${urlBase}/${slug}/${period}${location.search}`);
    }
    return history.replace(`/${urlBase}/${slug}/${period}/${action}${location.search}`);
  }, [
    urlBase,
    match,
    history,
    location,
  ]);

  // Request new data from API
  const hasData = !!data;
  useEffect(() => {
    // Get slug and period from URL
    const slug = match.params.slug;
    const period = match.params.period;

    if(!slug || !period || !organization) return

    // Dispach action to request data detail
    dispatch(
      requestKpiDetail(
        organization.slug,
        suborganization.slug,
        slug,
        period,
        hasData,
      )
    )
  }, [
    match.params.slug,
    match.params.period,
    organization,
    suborganization,
    dispatch,
    hasData,
  ])

  //Reset the kpi data at the store when the component is unmounted
  useEffect(() => () => dispatch(resetKpiDetail()), [dispatch])

  const {
    slug: kpi_slug,
    period,
  } = match.params || {};

  const handleModalAnswerOpen = useCallback((answer) => {
    setModalAnswer(answer);
  }, []);
  const handleModalAnswerClose = useCallback(() => setModalAnswer(null), []);

  const handleUseValue = useCallback(({ value }) => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        {
          value,
          comment: (data || {}).comment || '',
        },
      )
    );
  }, [
    data,
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
  ]);

  const handleUseValueAndComment = useCallback(({value, comment}) => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        {
          value,
          comment
        },
      )
    );
    handleModalAnswerClose();
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
    handleModalAnswerClose,
  ]);

  const handleUseLastAttachments = useCallback(({attachments, value}) => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        {
          value,
          comment: (data || {}).comment || '',
        },
      )
    );
    dispatch(
      copyKpiAttachments(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        attachments,
      )
    );
  }, [data, dispatch, organization, suborganization, kpi_slug, period]);

  const handleUseLastPeriod = useCallback(({value, comment, attachments}) => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        {
          value,
          comment
        },
      )
    );
    dispatch(
      copyKpiAttachments(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        attachments,
      )
    );
  }, [dispatch, organization, suborganization, kpi_slug, period]);

  const handleUseAttachment = useCallback((attachment) => {
    dispatch(
      recordKpiAttachment(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        attachment,
      )
    );
    handleModalAnswerClose();
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
    handleModalAnswerClose,
  ]);

  const handleRemindSuggestionRequest = useCallback((email) => {
    dispatch(
      remindSuggestionRequest(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        email,
      )
    );
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
  ]);

  const [backUrl, backName] = useMemo(() => {
    if(urlBase.startsWith('report')) {
      return [
        `/${urlBase}`,
        intl.formatMessage({ id:`reports_${match.params.standard}`}),
      ];
    }

    if(urlBase.startsWith('dashboard')) {
      return [
        `/${urlBase}`,
        intl.formatMessage({id:`dashboard_title`}),
      ];
    }

    if(
      urlBase.startsWith('data/fill')
    ) {
      return [
        (
          location.search
          ? `/${urlBase}${location.search}`
          : `/${urlBase}`
        ),
        intl.formatMessage({id: `datamanagement_${match.params.tab || 'fill'}` }),
      ];
    }

    if(
      urlBase === 'data/pending' ||
      urlBase === 'data/history' ||
      urlBase === 'data/files' ||
      urlBase === 'data/requests'
    ) {
      return [
        `/${urlBase}`,
        intl.formatMessage({id: `datamanagement_${match.params.tab || 'fill'}` }),
      ];
    }

    if(
      urlBase.startsWith('equality-plan')
    ) {
      return [
        `/${urlBase}`,
        intl.formatMessage({ id: "plan_of_equality"}),
      ];
    }

    if(!data) {
      if(error) {
        // TODO: Back state of error
        return [
          undefined,
          intl.formatMessage({ id: "loading"}),
        ];
      }

      return [
        undefined,
        intl.formatMessage({ id: "loading"}),
      ];
    }

    return [
      `/data/manage`,
      intl.formatMessage({id: `datamanagement_manage` }),
    ];
  }, [intl, data, urlBase, match, error, location]);

  const onCloseDataRequests = useCallback(() => {
    setShowDataRequests(false);
  }, []);

  const checkedKpisUuids = useMemo(() => {
    return data && data.uuids && data.uuids.length > 0 ? [data.uuids[0]] : [];
  }, [data]);

  const handleUseDataRequests = useCallback((
    requestIds,
    useAnswerData,
    useAnswerAttachments,
    answerData,
  ) => {
    dispatch(
      dataRequestsUseResponse(
        organization.slug,
        suborganization.slug,
        requestIds,
        useAnswerData,
        useAnswerAttachments,
        answerData,
      )
    );
    setModalAnswer(null);
  },
  [
    dispatch,
    organization.slug,
    suborganization.slug,
  ]);

  const handleUseAggregatedDataRequests = useCallback((
    requestIds,
    aggregationType,
    value,
    comment,
    attachments,
  ) => {
    dispatch(
      dataRequestsUseAggregatedResponse(
        organization.slug,
        suborganization.slug,
        data.kpi_value_id,
        data.organization_kpi_id,
        requestIds,
        aggregationType,
        value,
        comment,
        attachments,
      )
    );
    setModalAnswer(null);
  },
  [
    dispatch,
    data,
    organization.slug,
    suborganization.slug,
  ]);

  return (
    <MainLayout
      className="KpiDetail"
      disabledOrganizationTreeSelector={data_request && showDataRequests}
    >
      <Sentry.ErrorBoundary
        fallback={
        <ErrorBoundaryFallback
          titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
          buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
          descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_section_message' })}
          customErrorImage="/images/error_image.png"
        />
      }>
      { data_request && showDataRequests ? (
        <DataRequestsWizardScreen
          onCloseDataRequests={onCloseDataRequests}
          checkedKpisUuids={checkedKpisUuids}
        />
      ) : (
        <React.Fragment>
          <Navigator
            to={backUrl}
            current={backName}
            error={error}
          />
          {
            !loading && error
            ? (
              error === 422
              ? <Error code={422} type='info' />
              : <Error code={error} />
            ) : !data
            ? <Loading />
            : <React.Fragment>
                <Header
                  {...data}
                  loading={loading}
                  permissions={permissions}
                  organization_slug={organization.slug}
                  suborganization_slug={suborganization.slug}
                  enabled_reports={config?.enabled_reports}
                  isMainOrganization={isMainOrganization}
                />
                <Tabs
                  className='dataTabs'
                  destroyInactiveTabPane
                  onChange={changeTab}
                  activeKey={activeKey}
                >
                  <Tabs.TabPane
                    tab={ intl.formatMessage({ id: "kpidetail_data"}) }
                    key="data"
                  >
                    <Data
                      className="KpiDetail__data"
                      kpi_slug={kpi_slug}
                      org_mandatory_data={config?.mandatory_data_in_kpis}
                      period={period}
                      data={data}
                      loading={loading}
                      error={error}
                      changePeriod={changePeriod}
                      onOpenAnswer={handleModalAnswerOpen}
                      onUseValue={handleUseValue}
                      onUseComment={handleUseValueAndComment}
                      onUseAttachments={handleUseLastAttachments}
                      onUsePeriod={handleUseLastPeriod}
                      onUseAttachment={handleUseAttachment}
                      onRemind={handleRemindSuggestionRequest}
                      permissions={permissions}
                      onShowDataRequests={() => setShowDataRequests(true)}
                      handleUseDataRequests={handleUseDataRequests}
                      handleUseAggregatedDataRequests={handleUseAggregatedDataRequests}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={ intl.formatMessage({ id: "kpidetail_history"}) }
                    key="history"
                  >
                    <History
                      kpi_slug={kpi_slug}
                      onOpenAnswer={handleModalAnswerOpen}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={ intl.formatMessage({ id: "kpidetail_files"}) }
                    key="files"
                  >
                    <Files
                      kpi_slug={kpi_slug}
                    />
                  </Tabs.TabPane>
                  {
                    permissions.can_configure_kpi && featureList.has('data_owners') && data.applies &&
                    <Tabs.TabPane
                      tab={ intl.formatMessage({ id: "kpidetail_kpi_data_assignees"}) }
                      key="assignees"
                    >
                      <Assignees
                        kpiSlug={kpi_slug}
                        organizationKpiId={data.organization_kpi_id}
                        owners_uptodate={data.owners_uptodate}
                      />
                    </Tabs.TabPane>
                  }
                  {/*
                    TABS FOR NEXT ITERATION
                    <Tabs.TabPane tab={ intl.formatMessage({ id: "validation"}) } key="validation">
                      <Validation />
                    </Tabs.TabPane>
                  */}
                </Tabs>
                <ModalDataAnswer
                  shown={!!modalAnswer}
                  onClose={handleModalAnswerClose}
                  showUseButtons={permissions.can_write_kpi && modalAnswer?.can_use}
                  loading={loading}
                  error={error}
                  onUseValue={handleUseValueAndComment}
                  onUseAttachment={handleUseAttachment}
                  handleUseDataRequests={handleUseDataRequests}
                  config={config}
                  {...modalAnswer || {}}
                />
              </React.Fragment>
          }
        </React.Fragment>
      )}
      </Sentry.ErrorBoundary>
    </MainLayout>
  );
}

export default injectIntl(
  withRouter(
    KpiDetail
  )
);
