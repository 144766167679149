import React, { useMemo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import CustomTable from 'components/CustomTable';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import Filter from 'components/SustainableIssues/Filter';
import CustomButton from 'components/CustomButton';

import {
  Row,
  Col,
} from 'antd';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const shortStandard = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'Electric Utilities Sector Supplement',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
};

const getDefaultSelectedSubjects = (subjects, surveySubjects) => {
  return _.filter(
    subjects,
    (subject) => _.find(
      surveySubjects,
      (surveySubject) => surveySubject.kpi_category_id === subject.id
    )
  );
};

const SurveySelectSubject = ({
  intl,
  subjects,
  saveSurveySubjects,
  surveySubjects,
  enabledReports,
}) => {
  const t = intl.messages;
  const [selectedSubjects, setSelectedSubjects] = useState(
    getDefaultSelectedSubjects(subjects, surveySubjects)
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    _.map(selectedSubjects, 'id')
  );
  const [subjectsToAdd, setSubjectsToAdd] = useState([]);
  const [subjectsToRemove, setSubjectsToRemove] = useState([]);
  const [filters, setFilters] = useState({});
  const clusterSDGs = useSDGsClustering();

  const columns = useMemo(
    () => [{
      title: t.subject,
      dataIndex: 'subject',
    }, {
      title: t.issue,
      dataIndex: 'issue',
    }, {
      title: t.standard,
      dataIndex: 'standards',
      render: (standards) => `${standards.map(standard => shortStandard[standard]).join(', ')}`,
    }, {
      title: t.sdg,
      dataIndex: 'sdgs',
      render: (sdgs) => clusterSDGs(sdgs).map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg} />)
    }, {
      title: t.type,
      dataIndex: 'esg_types',
      render: (types) => types.map(type => <EsgLogo key={type} type={type} />)
    }],
    [t, clusterSDGs]
  );

  const getSubjectSelectionUpdates = useCallback(
    (selectedRowKeys) => {
      const toAdd = _.difference(
        selectedRowKeys,
        _.map(surveySubjects, 'kpi_category_id')
      );
      const toRemove = _.difference(
        _.map(surveySubjects, 'kpi_category_id'),
        selectedRowKeys
      );
      return {toAdd, toRemove};
    },
    [surveySubjects]
  );

  const addSubjectRowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        const {toAdd, toRemove} = getSubjectSelectionUpdates(selectedRowKeys);
        setSelectedSubjects(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSubjectsToAdd(toAdd);
        setSubjectsToRemove(toRemove);
      },
    }),
    [selectedRowKeys, getSubjectSelectionUpdates]
  );

  const searchIssues = useCallback(
    (issues, text) => {
      return issues
        .filter(
          issue => {
            return (issue.issue || "").toLowerCase().includes(text)
              || (issue.subject || "").toLowerCase().includes(text);
          }
        );
    },
    []
  );

  const filterIssues = useCallback(
    (issues, key, values) => {
      return issues
        .filter(
          issue => {
            return issue[key].some(item => values.includes(item));
          }
        );
    },
    []
  );

  const filteredSubjects = useMemo(
    () => {
      if (!subjects || _.isEmpty(filters)) {
        return subjects;
      }

      let issues = [...subjects];

      if (filters.search) {
        issues = searchIssues(issues, filters.search.toLowerCase());
      }

      if (filters.standards && filters.standards.length > 0) {
        issues = filterIssues(issues, 'standards', filters.standards);
      }

      if (filters.sdgs && filters.sdgs.length > 0) {
        issues = filterIssues(issues, 'sdgs', filters.sdgs);
      }

      if (filters.esg_types && filters.esg_types.length > 0) {
        issues = filterIssues(issues, 'esg_types', filters.esg_types);
      }

      const selectedSubjects = getDefaultSelectedSubjects(
        subjects, surveySubjects
      );
      setSelectedSubjects(selectedSubjects);
      setSelectedRowKeys(selectedSubjects.map(subject => subject.id));
      setSubjectsToAdd([]);
      setSubjectsToRemove([]);
      return issues;
    },
    [subjects, surveySubjects, filters, searchIssues, filterIssues]
  );

  const onClickNext = useCallback(
    (e) => {
      e.preventDefault();
      saveSurveySubjects(subjectsToAdd, subjectsToRemove);
      setSubjectsToAdd([]);
      setSubjectsToRemove([]);
    },
    [saveSurveySubjects, subjectsToAdd, subjectsToRemove]
  );

  return (
    <div className="SurveySelectSubject">
      <Filter
        filterIssues={setFilters}
        enabledReports={enabledReports}
      />
      <Row type="flex" justify="center">
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={filteredSubjects}
            rowSelection={addSubjectRowSelection}
            rowKey='id'
            childrenColumnName='not-children'
          />
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={e => onClickNext(e)}
        >
          { t.next }
        </CustomButton>
      </Row>
    </div>
  );
}


export default injectIntl(SurveySelectSubject);
