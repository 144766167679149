import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
} from 'react-redux';

import EsgLogo from 'components/EsgLogo'
import CustomTag from 'components/CustomTag'
import A from 'components/A';
import AplanetIcon from 'components/AplanetIcon';
import SearchFilter from 'components/SearchFilter';
import FiltersPanel from 'components/FiltersPanel';
import SdgMiniLogo from 'components/SdgMiniLogo';

import { useSDGsClustering } from 'hooks/sdg';

import useOrganizations from 'utils/useOrganizations';
import { KPI_STATUS_OPTIONS } from 'utils/kpi';
import { ESG_TYPES } from 'utils/kpi';
import { FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import { useEventTracking } from 'hooks/useEventTracking';

import {
  Col,
  Row,
} from 'antd';

import './style.less';


// NOTICE: We "fake" the 'restricted' status both in 'pending' and 'uptodate'
const DEFAULT_STATUS_FILTER_STATE = [
  'pending',
  'uptodate',
  //'restricted',
];

const REPORTING_STANDARDS = [
  'aplanet',
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'custom',
];

const PERIODICITY_OPTIONS = ['year', 'semester', 'quarter', 'month'];

const FILTERS_CATALOG_TYPES = {
  list: 'list',
  multiSelect: 'multiSelect',
  date: 'date',
  heading: 'heading',
  select: 'select',
};

const ListFilters = ({
  intl,
  filterState,
  reportActions = null,
  showDataOwnersFilters = true,
  resetAllFilters,
  areFiltersSet,
  setAllFilters,
  isKpiNameSearchSet,
  kpiNameSearch,
  onChangeKpiSearch,
}) => {
  const t = intl.messages;
  const eventTracking = useEventTracking();

  // Filters

  // Standards
  const [standardFilterValues, setStandardFilterValues] = useState(filterState.standard_array_filter);
  const [esgTypeFilterValues, setEsgTypeFilterValues] = useState(filterState.type_filter);
  const [sdgFilterValues, setSdgFilterValues] = useState(filterState.sdg_filter);
  const [tagsFilterValues, setTagsFilterValues] = useState(filterState.tag_filter);

  // Periods
  const [periodicityFilterValues, setPeriodicityFilterValues] = useState(filterState.periodicity_filter);
  const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);

  // Fill Status
  const [statusFilterValues, setStatusFilterValues] = useState(
    filterState.status_filter.filter(slug => slug !== 'restricted')
  );

  // Assignees
  const [assigneeFilterValue, setAssigneeFilterValue] = useState(filterState.assignee_filter);
  const [dataManagerFilterValues, setDataManagerFilterValues] = useState(filterState.data_manager_filter);
  const [dataEditorFilterValues, setDataEditorFilterValues] = useState(filterState.data_editor_filter);
  const [dataValidatorFilterValues, setDataValidatorFilterValues] = useState(filterState.data_validator_filter);

  // Validation Status
  const [validationStatusFilterValues, setValidationStatusFilterValues] = useState(filterState.validation_status_filter);

  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  const clusterSDGs = useSDGsClustering();

  const {
    tags = [],
    assignees = [],
    sdgs = [],
  } = useSelector(state => state.taxonomies);

  const {
    organization,
  } = useOrganizations();

  const patchedTags = useMemo(() => {
    return tags.map(tag => ({
      slug: tag,
      name: tag,
    }));
  }, [
    tags,
  ]);

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      value: slug,
      title: intl.formatMessage({ id: `esg_type_${slug}` }),
      label: (
        <React.Fragment key={slug}>
          <EsgLogo key={slug} type={slug} />
          <span>{intl.formatMessage({ id: `esg_type_${slug}` })}</span>
        </React.Fragment>)
    }));
  }, [
    intl,
  ]);

  const kpiStatuses = useMemo(() => {
    return [
      ...KPI_STATUS_OPTIONS.filter(
        slug => slug !== 'restricted'
      ),
      'no_data',
      'all_data',
    ].map(slug => ({
      value: slug,
      label: t[`kpi_status_${slug}`],
    }));
  }, [
    t,
  ]);

  const reportingStandards = useMemo(() => {
    const enabled = new Set([
      ...((organization.config || {}).enabled_reports || []),
      'custom',
    ]);
    return REPORTING_STANDARDS
      .filter(slug => enabled.has(slug))
      .map(slug => ({
        slug,
        name: t[`reporting_standard_${slug}`],
        key: slug
      }));
  }, [
    organization,
    t,
  ]);

  const validationStatusOptions = useMemo(
    () => [
      'ready_to_validate',
      'pending',
      'validated',
      'rejected',
    ].map(slug => ({
      value: slug,
      label: t[`item_manager_validation_status_${slug}`],
    })),
    [t]
  );

  const handleStatusFilter = useCallback((opts) => {
    return setStatusFilterValues(
      (opts || []).includes('pending') || (opts || []).includes('uptodate')
        ? [...opts, 'restricted']
        : opts
    )
  }, []);

  const handleOnChangeValidationStatus = useCallback(
    (values) => {
      if (values.includes('with_data') && statusFilterValues.includes('pending')) {
        setStatusFilterValues(
          statusFilterValues.filter(status => status !== 'pending')
        );
      } else if (
        validationStatusFilterValues.includes('with_data')
        && !values.includes('with_data')
        && !statusFilterValues.includes('pending')
      ) {
        setStatusFilterValues([
          ...statusFilterValues,
          'pending',
        ]);
      }
      setValidationStatusFilterValues(values);
    },
    [
      statusFilterValues,
      validationStatusFilterValues,
    ]
  );

  const assigneeOptions = useMemo(
    () => ['me', 'all'].map(
      slug => ({
        slug,
        name: intl.formatMessage({id: `list_filter_${slug}`}),
      })
    ),
    [intl]
  );

  const [
    dataManagerOptions,
    dataEditorOptions,
    dataValidatorOptions,
  ] = useMemo(
    () => ['manager', 'editor', 'validator'].map(role => {
      const dataManagers = assignees.filter(
        ({assignments}) => !!assignments
          .find(({roles}) => roles.includes(role))
      );

      return dataManagers.map(({id, name, email}) => ({
        slug: id,
        name: `${name} (${email})`,
      }));
    }),
    [assignees]
  );

  const patchedSdgs = useMemo(() => {
    return sdgs.map(({ slug, code }) => ({
      slug,
      code,
      parent_slug: slug,
      title: intl.formatMessage({ id: `sdg_${slug}` }),
      name: (
        <React.Fragment key={slug}>
          <SdgMiniLogo
            sdg={{
              slug,
              code,
              parent_slug: slug,
            }}
          />
          <span>{intl.formatMessage({ id: `sdg_${slug}` })}</span>
        </React.Fragment>
      )
    }));
  }, [sdgs, intl]);

  const onSDGFilterSelect = useCallback((values) => {
    const selection = sdgs
      .filter((s) => values.includes(s.slug))
      .map((s) => s.targets)
      .flat()
      .map((s) => s.slug);

    setSdgFilterValues(selection);
  }, [
    setSdgFilterValues,
    sdgs,
  ]);

  const clusteredSDGSelection = useMemo(
    () => clusterSDGs(sdgFilterValues).map((s) => s.slug),
    [
      sdgFilterValues,
      clusterSDGs,
    ]
  );

  const filtersConfig = useMemo(() => (
    [
      {
        columnTitle: intl.formatMessage({id: `filter_list_column_fill_status`}),
        filters: [
          {
            filterType: FILTERS_CATALOG_TYPES.list,
            filterTitle: intl.formatMessage({ id: `filter_list_kpi_status` }),
            listType: 'checkboxList',
            listOptions: kpiStatuses,
            setSelectedOptions: handleStatusFilter,
            selectedOptions: statusFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_CATALOG_TYPES.heading,
            columnTitle: intl.formatMessage({id: `filter_list_column_periods`}),
          },
          {
            filterType: FILTERS_CATALOG_TYPES.date,
            filterTitle: intl.formatMessage({id: `filter_list_periods`}),
            picker: 'month',
            format: 'YYYY-MM',
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: periodDateFilterValues,
            onChange: setPeriodDateFilterValues,
          },
          {
            filterType: FILTERS_CATALOG_TYPES.list,
            filterTitle: intl.formatMessage({ id: `filter_list_periodicity` }),
            listOptions: PERIODICITY_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `filter_list_periodicity_option_${option}`}),
              label: <CustomTag name={intl.formatMessage({id: `filter_list_periodicity_option_${option}`})} colorclass={option} />
            })),
            setSelectedOptions: setPeriodicityFilterValues,
            selectedOptions: periodicityFilterValues,
          },
        ],
      },
      {
        columnTitle: intl.formatMessage({id: `filter_list_column_standard`}),
        filters: [
          {
            filterType: FILTERS_CATALOG_TYPES.multiSelect,
            filterTitle: intl.formatMessage({ id: `filter_list_standard` }),
            title: intl.formatMessage({id: `filter_list_standard_placeholder`}),
            options: reportingStandards,
            onSelect: setStandardFilterValues,
            selected: standardFilterValues,
          },
          {
            filterType: FILTERS_CATALOG_TYPES.multiSelect,
            filterTitle: intl.formatMessage({ id: `filter_list_sdg` }),
            title: intl.formatMessage({id: `filter_list_sdg_placeholder`}),
            options: patchedSdgs,
            onSelect: onSDGFilterSelect,
            selected: clusteredSDGSelection,
          },
          {
            filterType: FILTERS_CATALOG_TYPES.list,
            filterTitle: intl.formatMessage({ id: `filter_list_esgType` }),
            listOptions: esgTypes,
            setSelectedOptions: setEsgTypeFilterValues,
            selectedOptions: esgTypeFilterValues,
          },
          {
            filterType: FILTERS_CATALOG_TYPES.multiSelect,
            filterTitle: intl.formatMessage({ id: `filter_list_tags` }),
            title: intl.formatMessage({id: `filter_list_tags_placeholder`}),
            options: patchedTags,
            onSelect: setTagsFilterValues,
            selected: tagsFilterValues,
          },
        ]
      },
      ...(
        showDataOwnersFilters
          ? [{
              columnTitle: intl.formatMessage({id: `filter_list_column_assignees`}), // filter_list_column_periods
              filters: [
                {
                  filterType: FILTERS_CATALOG_TYPES.select,
                  filterTitle: intl.formatMessage({ id: `filter_list_mine_or_all` }),
                  title: intl.formatMessage({id: `filter_list_mine_or_all_placeholder`}),
                  options: assigneeOptions,
                  onSelect: setAssigneeFilterValue,
                  selected: assigneeFilterValue,
                },
                {
                  filterType: FILTERS_CATALOG_TYPES.multiSelect,
                  filterTitle: intl.formatMessage({ id: `filter_list_data_manager` }),
                  title: intl.formatMessage({id: `filter_list_data_manager_placeholder`}),
                  options: dataManagerOptions,
                  onSelect: setDataManagerFilterValues,
                  selected: dataManagerFilterValues,
                },
                {
                  filterType: FILTERS_CATALOG_TYPES.multiSelect,
                  filterTitle: intl.formatMessage({ id: `filter_list_data_editor` }),
                  title: intl.formatMessage({id: `filter_list_data_editor_placeholder`}),
                  options: dataEditorOptions,
                  onSelect: setDataEditorFilterValues,
                  selected: dataEditorFilterValues,
                },
                {
                  filterType: FILTERS_CATALOG_TYPES.multiSelect,
                  filterTitle: intl.formatMessage({ id: `filter_list_data_validator` }),
                  title: intl.formatMessage({id: `filter_list_data_validator_placeholder`}),
                  options: dataValidatorOptions,
                  onSelect: setDataValidatorFilterValues,
                  selected: dataValidatorFilterValues,
                },
              ],
            }]
          : []
      ),
      {
        columnTitle: intl.formatMessage({id: `filter_list_column_validation_status`}),
        filters: [
          {
            filterType: FILTERS_CATALOG_TYPES.list,
            filterTitle: intl.formatMessage({ id: `filter_list_validation_status` }),
            listType: 'checkboxList',
            listOptions: validationStatusOptions,
            setSelectedOptions: handleOnChangeValidationStatus,
            selectedOptions: validationStatusFilterValues,
          },
        ],
      },
    ]
  ), [
    esgTypes,
    intl,
    patchedSdgs,
    patchedTags,
    reportingStandards,
    kpiStatuses,
    validationStatusOptions,
    assigneeOptions,
    dataManagerOptions,
    dataEditorOptions,
    dataValidatorOptions,
    standardFilterValues,
    esgTypeFilterValues,
    tagsFilterValues,
    periodicityFilterValues,
    periodDateFilterValues,
    statusFilterValues,
    assigneeFilterValue,
    dataManagerFilterValues,
    dataEditorFilterValues,
    validationStatusFilterValues,
    dataValidatorFilterValues,
    showDataOwnersFilters,
    handleStatusFilter,
    clusteredSDGSelection,
    onSDGFilterSelect,
    handleOnChangeValidationStatus,
  ]);

  const onSetFilters = useCallback(() => {
    eventTracking.capture('fillReport.filters', {
      mine_all: assigneeFilterValue ? 1 : 0,
      manager: dataManagerFilterValues?.length ? 1 : 0,
      editor: dataEditorFilterValues?.length ? 1 : 0,
      validator: dataValidatorFilterValues?.length ? 1 : 0,
      standards: standardFilterValues?.length ? 1 : 0,
      esg: esgTypeFilterValues?.length ? 1 : 0,
      sdg: sdgFilterValues?.length ? 1 : 0,
      tags: tagsFilterValues?.length ? 1 : 0,
      periods: (periodicityFilterValues || []).map(
        slug => intl.formatMessage({id: `filter_list_periodicity_option_${slug}`})
      ),
      data_range: periodDateFilterValues?.length ? 1 : 0,
      fill_status: (statusFilterValues || []).map(
        slug => intl.formatMessage({id: `kpi_status_${slug}`})
      ),
      validation_status: (validationStatusFilterValues || []).map(
        slug => intl.formatMessage({id: `item_manager_validation_status_${slug}`})
      ),
    });
    setAllFilters({
      [FILTER_TYPES.standard_array_filter]: standardFilterValues,
      [FILTER_TYPES.type_filter]: esgTypeFilterValues,
      [FILTER_TYPES.sdg_filter]: sdgFilterValues,
      [FILTER_TYPES.tag_filter]: tagsFilterValues,
      [FILTER_TYPES.periodicity_filter]: periodicityFilterValues,
      [FILTER_TYPES.period_date_filter]: periodDateFilterValues,
      [FILTER_TYPES.status_filter]: statusFilterValues,
      [FILTER_TYPES.assignee_filter]: assigneeFilterValue,
      [FILTER_TYPES.data_manager_filter]: dataManagerFilterValues,
      [FILTER_TYPES.data_editor_filter]: dataEditorFilterValues,
      [FILTER_TYPES.validation_status_filter]: validationStatusFilterValues,
      [FILTER_TYPES.data_validator_filter]: dataValidatorFilterValues,
    });
  }, [
    intl,
    setAllFilters,
    standardFilterValues,
    esgTypeFilterValues,
    sdgFilterValues,
    tagsFilterValues,
    periodicityFilterValues,
    periodDateFilterValues,
    statusFilterValues,
    validationStatusFilterValues,
    assigneeFilterValue,
    dataManagerFilterValues,
    dataEditorFilterValues,
    dataValidatorFilterValues,
    eventTracking,
  ]);

  const onCancelFilter = useCallback(() => {
    setStandardFilterValues(filterState.standard_array_filter);
    setEsgTypeFilterValues(filterState.type_filter);
    setSdgFilterValues(filterState.sdg_filter);
    setTagsFilterValues(filterState.tag_filter);
    setPeriodicityFilterValues(filterState.periodicity_filter);
    setPeriodDateFilterValues(filterState.period_date_filter);
    setStatusFilterValues(filterState.status_filter);
    setAssigneeFilterValue(filterState.assignee_filter);
    setDataManagerFilterValues(filterState.data_manager_filter);
    setDataEditorFilterValues(filterState.data_editor_filter);
    setValidationStatusFilterValues(filterState.validation_status_filter);
    setDataValidatorFilterValues(filterState.data_validator_filter);
  }, [
    filterState.standard_array_filter,
    filterState.sdg_filter,
    filterState.tag_filter,
    filterState.type_filter,
    filterState.periodicity_filter,
    filterState.period_date_filter,
    filterState.status_filter,
    filterState.validation_status_filter,
    filterState.assignee_filter,
    filterState.data_manager_filter,
    filterState.data_editor_filter,
    filterState.data_validator_filter,
  ]);

  const onClearSearchAndFilters = useCallback(() => {
    setStandardFilterValues([]);
    setEsgTypeFilterValues([]);
    setSdgFilterValues([]);
    setTagsFilterValues([]);
    setPeriodicityFilterValues([]);
    if (filterState.period_date_filter?.length) {
      setPeriodDateFilterValues([]);
    }
    setStatusFilterValues(DEFAULT_STATUS_FILTER_STATE);
    setAssigneeFilterValue();
    setDataManagerFilterValues([]);
    setDataEditorFilterValues([]);
    setValidationStatusFilterValues([]);
    setDataValidatorFilterValues([]);
    setHasToClearKpiSearch(true);
    resetAllFilters();
  }, [
    resetAllFilters,
    filterState.period_date_filter,
  ]);

  return (
    <Row
      className="ListFilters"
      type="flex"
      justify='space-between'
      align='middle'
      gutter={[10, 10]}
    >
      <Col>
        <Row
          gutter={16}
          type="flex"
          align="middle"
        >
          <Col>
            <FiltersPanel
              areFiltersSet={areFiltersSet}
              onCancelFilter={onCancelFilter}
              onSetFilters={onSetFilters}
              filtersConfig={filtersConfig}
            />
          </Col>
          <Col>
            <SearchFilter
              textFilter={kpiNameSearch}
              onSetSearch={onChangeKpiSearch}
              hasToClearSearch={hasToClearKpiSearch}
              setHasToClearKpiSearch={setHasToClearKpiSearch}
              defaultOpen={isKpiNameSearchSet}
              placeholder={intl.formatMessage({id: `filter_list_search_placeholder`})}
              autoFocus
            />
          </Col>
          <Col>
            <A
              className="ListFilters__clear_filters_button"
              onClick={onClearSearchAndFilters}
              disabled={!areFiltersSet && !isKpiNameSearchSet}
            >
              {intl.formatMessage({ id: 'filter_list_clear_filters' })}
            </A>
          </Col>
        </Row>
      </Col>
      {
        !reportActions ? null : (
          <Col>
            <Row
              gutter={10}
              type="flex"
              align="middle"
            >
              {reportActions}
            </Row>
          </Col>
        )
      }
    </Row>
  );
}

export default injectIntl(ListFilters)
