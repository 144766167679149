import { reverse } from "lodash";

export const getOrderedSuborganizationPath = (suborganizationsTree, suborganization, path = []) => {
  const suborganizationTreeInfo = suborganizationsTree.find(({slug}) => suborganization.slug === slug);
  const suborganizationParent = suborganizationsTree.find(({slug}) => suborganizationTreeInfo.parent_slug === slug);
  path = [
    ...path,
    {
      ...suborganization,
      isTargetOrg: path.length === 0
    }
  ];
  return suborganizationParent
    ? getOrderedSuborganizationPath(suborganizationsTree, suborganizationParent, path)
    : reverse(path);
};