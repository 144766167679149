import React, { 
  useCallback,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'antd';

import DataRequestsRequestedByRequestedTo from './DataRequestsRequestedByRequestedTo';
import DataRequestsActionsCell from './DataRequestsActionsCell';
import DataRequestsValueCell from './DataRequestsValueCell';
import DataRequestsAttachmentsCell from './DataRequestsAttachmentsCell';
import DataRequestsCommentCell from './DataRequestsCommentCell';
import CustomModalType from 'components/CustomModalType';
import { getPeriodsFormat } from 'utils/date';
import {
  DATA_REQUEST_STATUS,
  DATA_REQUEST_RECURRENCE,
  DATA_REQUEST_USER_TYPE
} from 'utils/dataRequests';
import AplanetIcon from 'components/AplanetIcon';
import { TABLE_CUSTOM_VIEW_OPTIONS } from 'hooks/useTableCustomView/constants';
import { TableDataRequestsCellsContext } from './context';
import { DELETED_USER_SLUG } from 'utils/user';

const ICON_PER_MODAL_TYPE = {
  delete: 'Bin',
  copy: 'Copy',
  send_reminder: 'Alarm'
};

const ACTION_TYPES = {
  delete: 'delete',
  copy: 'copy',
  send_reminder: 'send_reminder'
};

const MODAL_BY_ACTION = {
  copy: 'main',
  delete: 'warning',
  send_reminder: 'warning'
};

const ModalContent = ({
  intl,
  actionType,
  kpiName,
  requestedToName,
  requestedToEmail,
  periodDates,
  useAnswerData,
  setUseAnswerData,
  setUseAnswerAttachments,
  useAnswerAttachments,
  dataInUse,
  totalAttachments,
  responseValue,
  responseComment,
  type,
  periodicity
}) => (
  <div className="DataRequests__modal-content">
    <div className="DataRequests__modal-content-wrapper">
      <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_message_${actionType}`})}</span>
      {actionType === ACTION_TYPES.send_reminder ? (
        <div className='DataRequests__type-periodicity-wrapper'>
          <span className={`DataRequests__type ${type}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_type_${type}`})}</span>
          <span className={`DataRequests__periodicity ${periodicity}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_periodicity_${periodicity}`})}</span>
        </div>
      ) : null}
      <div className="DataRequests__modal-content-info">
        <div className="DataRequests__modal-content-kpi">
          <AplanetIcon name="KPI" faStyle="fas" size="18px" />
          <span>{kpiName}</span>
        </div>
        <div className="DataRequests__modal-content-user">
          <AplanetIcon name="User" faStyle="fad" size="18px" />
          <div className="DataRequests__modal-content-user-info">
            <span>{requestedToName === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName}</span>
            {requestedToEmail === DELETED_USER_SLUG ? null : <span>{requestedToEmail}</span>}
          </div>
        </div>
        <div className="DataRequests__modal-content-period">
          <AplanetIcon name="Calendar" size="18px" />
          <span>{periodDates}</span>
        </div>
      </div>
      {actionType === ACTION_TYPES.copy ? (
        <div className="DataRequests__modal-content-copy-wrapper">
          <span className="DataRequests__modal-content-copy">{intl.formatMessage({id: `admin_data_requests_table_modal_copy_answer`})}</span>
          <Checkbox
            className="DataRequests__modal-content-copy-checkbox"
            onChange={() => setUseAnswerData(!useAnswerData)} 
            checked={dataInUse?.value || useAnswerData}
            disabled={dataInUse?.value || (!responseValue && !responseComment)}
          >
            {intl.formatMessage({id: `admin_data_requests_table_modal_copy_data`})}
          </Checkbox>
          <Checkbox
            className="DataRequests__modal-content-copy-checkbox"
            onChange={() => setUseAnswerAttachments(!useAnswerAttachments)} 
            checked={dataInUse?.attachments || useAnswerAttachments}
            disabled={dataInUse?.attachments || totalAttachments === 0}
          >
            {intl.formatMessage({id: `admin_data_requests_table_modal_copy_attachments`})}
          </Checkbox>
        </div>
      ) : null}
      <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_action_message_${actionType}`})}</span>
    </div>
    <div className="DataRequests__modal-content-question">{intl.formatMessage({id: `admin_data_requests_table_modal_question_${actionType}`})}</div>
  </div>
);

const DataRequestsInfoCells = ({
  intl,
  valueSource,
  kpiValue,
  lastKpiUpdate,
  startPeriod,
  endPeriod,
  responseValue,
  responseComment,
  totalAttachments,
  requestedToEmail,
  requestedToName,
  requestedToAvatar,
  requesterComment,
  notificationDate,
  requestStatus,
  responseDate,
  requestId,
  hideUsersInfo,
  userType,
  handleOpenKpi,
  lastActionDate,
  lastReminderSentDate,
  dataInUse,
}) => {
  const {
    kpi_name,
    type: requestType,
    handleDeleteDataRequests,
    handleCopyDataRequests,
    handleSendReminderDataRequests,
    selectedTableCustomView,
    canCopyData,
    canSendRequest,
    periodicity
  } = useContext(TableDataRequestsCellsContext);

  const [showModalType, setShowModalType] = useState('');
  const [useAnswerData, setUseAnswerData] = useState(false);
  const [useAnswerAttachments, setUseAnswerAttachments] = useState(false);

  const {
    data: profile
  } = useSelector(state => state.profile);

  const onCancelModal = useCallback(() => {
    setUseAnswerData(false);
    setUseAnswerAttachments(false);
    setShowModalType('');
  }, []);

  const onOkModal = useCallback(() => {
    if (showModalType === ACTION_TYPES.delete) {
      handleDeleteDataRequests([requestId]);
    }
    if (showModalType === ACTION_TYPES.copy) {
      handleCopyDataRequests([requestId], useAnswerData, useAnswerAttachments);
    }
    if (showModalType === ACTION_TYPES.send_reminder) {
      handleSendReminderDataRequests([requestId]);
    }
    onCancelModal();
  }, [
    showModalType,
    onCancelModal,
    handleDeleteDataRequests,
    requestId,
    handleCopyDataRequests,
    useAnswerData,
    useAnswerAttachments,
    handleSendReminderDataRequests
  ]);

  return (
    <>
      {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.requested_by_to_column) ? (
        <td className='DataRequests__requested-by-to' >
          <DataRequestsRequestedByRequestedTo
            requestedToEmail={requestedToEmail}
            requestedToName={requestedToName}
            requestedToAvatar={requestedToAvatar}
            requesterComment={requesterComment}
            notificationDate={notificationDate}
            requestStatus={requestStatus}
            responseDate={responseDate}
            hideUsersInfo={hideUsersInfo}
          />
        </td>
      ) : null}
      {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.value_column) ? (
        responseValue || (kpiValue && userType === DATA_REQUEST_USER_TYPE.internal) ? (
          <DataRequestsValueCell
            requestedToName={requestedToName}
            value={responseValue || kpiValue}
            isResponse={responseValue}
            lastKpiUpdate={lastKpiUpdate}
            valueSource={valueSource}
          />
        ) : (
          <td className='DataRequests__response-value'>
          </td>
        )
      ) : null}
      {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.comment_column) ? (
        <DataRequestsCommentCell
          requestedToName={requestedToName}
          responseComment={responseComment}
        />
      ) : null}
      {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.attachment_column) ? (
        <DataRequestsAttachmentsCell
          requestId={requestId}
          requestedToName={requestedToName}
          totalAttachments={totalAttachments}
          requestStatus={requestStatus}
        />
      ) : null}
      {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.actions_column) ? (
        <>
          <DataRequestsActionsCell
            showDelete={canSendRequest && requestType === DATA_REQUEST_RECURRENCE.one_time && (requestStatus === DATA_REQUEST_STATUS.pending || requestStatus === DATA_REQUEST_STATUS.not_notified)}
            showCopyData={canSendRequest && canCopyData && (requestStatus === DATA_REQUEST_STATUS.done || requestStatus === DATA_REQUEST_STATUS.in_use || requestStatus === DATA_REQUEST_STATUS.partially_in_use)}
            disabledCopyData={valueSource !== "manual" || requestStatus === DATA_REQUEST_STATUS.in_use}
            showOpenKPI={requestedToEmail === profile.email && requestStatus === DATA_REQUEST_STATUS.pending}
            onDelete={() => setShowModalType(ACTION_TYPES.delete)}
            onCopyData={() => setShowModalType(ACTION_TYPES.copy)}
            onOpenKPI={handleOpenKpi}
            valueSource={valueSource}
            lastActionDate={lastActionDate}
            lastReminderSentDate={lastReminderSentDate}
            showSendReminder = {canSendRequest && requestStatus === DATA_REQUEST_STATUS.pending}
            onSendReminder={() => setShowModalType(ACTION_TYPES.send_reminder)}
          />
          <CustomModalType
            showModal={!!showModalType}
            type={MODAL_BY_ACTION[showModalType]}
            title={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_title_${showModalType}`}) : null}
            icon={ICON_PER_MODAL_TYPE[showModalType]}
            iconType='fad'
            onOk={onOkModal}
            disabledOk={showModalType === ACTION_TYPES.copy && !useAnswerData && !useAnswerAttachments}
            onOkText={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_ok_${showModalType}`}) : null}
            onCancel={onCancelModal}
            onCancelText={intl.formatMessage({id: 'admin_data_requests_table_modal_cancel'})}
            content={!!showModalType ? (
              <ModalContent
                intl={intl}
                actionType={showModalType}
                kpiName={kpi_name}
                requestedToName={requestedToName}
                requestedToEmail={requestedToEmail}
                periodDates={getPeriodsFormat(startPeriod, endPeriod, intl)}
                useAnswerData={useAnswerData}
                setUseAnswerData={setUseAnswerData}
                useAnswerAttachments={useAnswerAttachments}
                setUseAnswerAttachments={setUseAnswerAttachments}
                dataInUse={dataInUse}
                totalAttachments={totalAttachments}
                responseValue={responseValue}
                responseComment={responseComment}
                periodicity = {periodicity}
                type = {requestType}
              />
            ) : null}
          />
        </>
      ) : null}
    </>
  );
}

DataRequestsInfoCells.propTypes = {
  valueSource: PropTypes.oneOf(['manual', 'calculated', 'aggregated', 'children']),
  kpiValue: PropTypes.object,
  lastKpiUpdate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    updated_at:PropTypes.string.isRequired,
  }),
  startPeriod: PropTypes.string.isRequired,
  endPeriod: PropTypes.string.isRequired,
  responseValue: PropTypes.object,
  responseComment: PropTypes.string,
  totalAttachments: PropTypes.number.isRequired,
  requestedToEmail: PropTypes.string.isRequired,
  requestedToName: PropTypes.string.isRequired,
  requestedToAvatar: PropTypes.string,
  requesterComment: PropTypes.string,
  notificationDate: PropTypes.string,
  type: PropTypes.string,
  periodicity: PropTypes.string,
  requestStatus: PropTypes.oneOf([
    DATA_REQUEST_STATUS.done,
    DATA_REQUEST_STATUS.pending,
    DATA_REQUEST_STATUS.not_notified,
    DATA_REQUEST_STATUS.partially_in_use,
    DATA_REQUEST_STATUS.in_use,
  ]).isRequired,
  responseDate: PropTypes.string,
  requestId: PropTypes.number.isRequired,
  hideUsersInfo: PropTypes.bool,
  userType: PropTypes.oneOf([
    DATA_REQUEST_USER_TYPE.external,
    DATA_REQUEST_USER_TYPE.internal,
  ]).isRequired,
  handleOpenKpi: PropTypes.func.isRequired,
  lastActionDate: PropTypes.string,
  lastReminderSentDate: PropTypes.string || null,
  dataInUse: PropTypes.shape({
    value: PropTypes.bool,
    attachments: PropTypes.bool,
  }),
};

export default injectIntl(DataRequestsInfoCells);