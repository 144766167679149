import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  formatDate,
  addMonths,
  fromDb,
  toDb,
} from 'utils/date';

import T from 'components/T';

const PERIODS = {
  year: 1,
  semester: 2,
  quarter: 4,
  month: 12,
  default: 1,
};

const CycleDateString = ({
  intl,
  collection_frequency,
  cycle_date,
}) => {
  const periods = useMemo(() => {
    const numPeriods =  PERIODS[collection_frequency] || PERIODS.default;
    const periodLength = 12 / numPeriods;
    return (
      Array(numPeriods).fill()
    ).map((_, index) => {
      const displacedDate = addMonths(
        fromDb(`2020-${cycle_date}T00:00:00.000Z`),
        index*periodLength,
      )

      return formatDate(
        toDb(displacedDate),
        `D MMMM`,
        intl,
      );
    })
  }, [
    collection_frequency,
    cycle_date,
    intl,
  ]);

  const dateFace = useMemo(() => {
    return formatDate(
      `2020-${cycle_date}T00:00:00.000Z`,
      `D`,
      intl,
    );
  }, [
    cycle_date,
    intl,
  ]);

  if(collection_frequency === 'month') {
    // return special format
    return <T
      cycle_date_monthly={{
        date: dateFace,
      }}
    />
  }
    
  return (
    <React.Fragment>
      {
        intl.formatMessage({ id: "cycle_date_other"})
      }
      { ' ' }
      {
        periods.join(', ')
      }
    </React.Fragment>
  );
}

export default injectIntl(CycleDateString);

