import {
  useMemo,
  useCallback,
} from 'react';

// This sucks, but antd
import moment from 'moment';

import {
  isAfter,
  addYears,
  subDays,
  addDays,
  addMonths,
} from 'date-fns';

const COLLECTION_FREQUENCIES = [
  'year',
  'semester',
  'quarter',
  'month',
  //'week',
  //'day',
  // ^^ TODO: not implemented yet
];

export const useCycleDateDisabledDate = (collection_frequency) => {
  return useCallback((date) => {
    if(moment(date).isAfter(moment())) {
      // Disable dates after now
      return true;
    }
    
    switch(collection_frequency) {
      case 'year':
        // Dates that exist every year
        return moment(date).date() === 29 && moment(date).month() === 1;
      case 'semester':
        // TODO: Dates that exist every semester, for now fallback to month
        // eslint-disable-line no-fallthrough
      case 'quarter':
        // TODO: Dates that exist every quarter, for now fallback to month
        // eslint-disable-line no-fallthrough
      case 'month':
        // Dates that exist every month
        return moment(date).date() >= 29;
      default:
        // Enable all dates
        return false;
    }
  }, [
    collection_frequency,
  ]);
};

export const useCollectionFrequencyOptions = (intl) => useMemo(() => {
  return COLLECTION_FREQUENCIES.map(slug => ({
    slug,
    name: intl.formatMessage({ id: `collection_frequency_${slug}`}),
  }));
}, [
  intl,
]);

const isMoreThanTwoPeriodsFromNow = (startDateNextPeriod, type) => {
  const currentDate = new Date();
  let plusTwoPeriodsFromToday;
  switch(type) {
    case 'semester':
      plusTwoPeriodsFromToday = addYears(currentDate, 1);
      break;
    case 'quarter':
      plusTwoPeriodsFromToday = addMonths(currentDate, 6);
      break;
    case 'year':
      plusTwoPeriodsFromToday = addYears(currentDate, 2);
      break;
    case 'month':
      plusTwoPeriodsFromToday = addMonths(currentDate, 2);
      break;
    default:
      break;
  }
  return isAfter(startDateNextPeriod, plusTwoPeriodsFromToday);
};

const calculatePeriodDate = (yearDate, monthDate, dayDate, type) => {
  let startPeriodDate;
  let endPeriodDate;
  switch(type) {
    case 'semester':
      startPeriodDate = new Date(yearDate, (monthDate - 1), dayDate, 17, 17, 17);
      endPeriodDate = addMonths(startPeriodDate, 6);
      break;
    case 'quarter':
      startPeriodDate = new Date(yearDate, (monthDate - 1), dayDate, 17, 17, 17);
      endPeriodDate = addMonths(startPeriodDate, 3);
      break;
    case 'year':
      startPeriodDate = new Date(yearDate, (monthDate - 1), dayDate, 17, 17, 17);
      endPeriodDate = addYears(startPeriodDate, 1);
      break;
    case 'month':
      startPeriodDate = new Date(yearDate, (monthDate - 1), dayDate, 17, 17, 17);
      endPeriodDate = addMonths(startPeriodDate, 1);
      break;
    default:
      break;
  }
  return {
    startPeriodDate,
    endPeriodDate: subDays(endPeriodDate, 1),
  };
};

export const getAllPeriodsUntilTwoFromNow = ({startPeriodDate, periodicity, initialValues = []}) => {
  if (isMoreThanTwoPeriodsFromNow(startPeriodDate, periodicity)) {
    return initialValues;
  }
  const startPeriodDateSplit = startPeriodDate.split('-').map(v => parseInt(v));
  const periodDates = calculatePeriodDate(startPeriodDateSplit[0], startPeriodDateSplit[1], startPeriodDateSplit[2], periodicity);

  return getAllPeriodsUntilTwoFromNow({
    startPeriodDate: addDays(periodDates.endPeriodDate, 1).toISOString().substring(0, 10),
    periodicity,
    initialValues: [...initialValues, {...periodDates}],
  });
};
