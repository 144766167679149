import React, {
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Typography, message } from 'antd';

import config from 'config';

import A from 'components/A';
import Button from 'components/CustomButton'
import AplanetIcon from 'components/AplanetIcon';
import { formatMessage } from '../../../intl';

const { Text } = Typography;

const ProductSelector = ({
  organization,
  history
}) => {
  const adminBaseUrl = useMemo(() => {
    return organization?.config?.community?.custom_redirect_urls?.admin || config.ADMIN_APP_URL;
  }, [
    organization,
  ]);

  const neutralityEnabled = () => {
    message.info({
      content: formatMessage('neutrality_activated_mesage'),
      className: 'AppHeader-neutralityMessage',
      icon: <AplanetIcon name='Smoke' size="16px" faStyle="fad" />,
    });
    message.config({
      maxCount: 1,
    });
  };

  const handleNeutralityButton = () => {
    if (organization?.config?.features?.neutrality) neutralityEnabled()
    else history.push("/neutrality-disabled");
  }

  return (
    <Row type='flex' align='middle'>
      {organization?.config?.features?.show_neutrality &&
        <Col>
          <Button className={`AppHeader-neutrality ${organization?.config?.features?.neutrality ? 'on' : 'off'}`} onClick={handleNeutralityButton}>
            <AplanetIcon name='Smoke' size="16px" faStyle="fad" />
          </Button>
        </Col>
      }
      <Col>
        <Text className='AppHeader-appTitle'>
          {formatMessage('product_atlas')}
        </Text>
      </Col>
      {organization?.products?.includes('community') &&
        <Col>
          <A href={adminBaseUrl} className='AppHeader-linkToCommunity'>
            {formatMessage('product_community')}
          </A>
        </Col>
      }
    </Row>
  );
};

export default withRouter(ProductSelector);
