import React, {
    useMemo,
    useCallback
  } from 'react'
  import { injectIntl } from 'react-intl'

  import CustomInput from 'components/CustomInput';
  import LocaleFlag from 'components/LocaleFlag';
  import useOrganizations from 'utils/useOrganizations';

  import {
    Form,
    Collapse,
    Row,
    Col
  } from 'antd';
  
  import './style.less';

  const { Panel } = Collapse;
  
  const CustomLanguageInput = ({
    intl,
    values,
    errors,
    subtitle = '',
    handleChange,
    placeholder,
    maxLength = 600,
    collapsed = true
  }) => {

    const maybeNameTranslations = useMemo(() => values?.name_translations, [values]);
    const nameTranslations = useMemo(() => maybeNameTranslations || [], [maybeNameTranslations]);
    const {
      suborganization
    } = useOrganizations();

    const orgLocale = useMemo(
      () => suborganization.language,
      [suborganization.language]
    );

    const addTranslation = useCallback(({locale, name}) => {
      const lang = nameTranslations.map(value => value?.locale === locale ? ({locale, name}) : value);
      handleChange('name_translations')(lang);
    }, [handleChange, nameTranslations]);
    return (
        <React.Fragment>
          <section className='CustomLanguageInput-section'>
            {
              nameTranslations.filter(({locale}) => locale === orgLocale).map(({locale, name}) => {
                return (
                <Form.Item
                  colon={false}
                  hasFeedback
                  validateStatus={!!errors ? 'error' : ''}
                  label={<div><LocaleFlag intl={intl} locale={locale}/>{intl.formatMessage({id: locale})}</div>}
                  required
                  key={locale}
                >
                  <CustomInput
                    maxLength={maxLength}
                    placeholder={placeholder}
                    name="name"
                    aria-label="name"
                    value={name}
                    key={locale}
                    onChange={(e) => {
                      addTranslation({locale, [e.target.name]: e.target.value});
                    }}
                  />
                </Form.Item>)
              })
            }
            {
              errors && <div className="CustomLanguageInput__error">{ errors }</div>
            }
            <Collapse defaultActiveKey={collapsed ? ['1'] : null}>
              <Panel header={intl.formatMessage({id: "custom_language_add"})} key="1">
                <>
                  {
                    nameTranslations.filter(({locale}) => locale !== orgLocale)
                    .map(({locale, name}) => {
                      return (
                        <Form.Item
                          colon={false}
                          hasFeedback
                          label={<div><LocaleFlag intl={intl} locale={locale}/>{intl.formatMessage({id: locale})}</div>}
                          key={locale}
                        >
                          <Row style={{width: '100%'}} type="flex" justify="space-between" align="middle">
                            <Col style={{width:'100%'}}>
                              <CustomInput
                                maxLength={maxLength}
                                placeholder={placeholder}
                                name="name"
                                value={name}
                                key={locale}
                                onChange={(e) => {
                                  addTranslation({locale, [e.target.name]: e.target.value});
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Item>
                      )
                    })
                  }
                </>
              </Panel>
            </Collapse>
          </section>
          <div style={{marginTop: '10px'}}>{ subtitle }</div>
        </React.Fragment>
    );
  };

  export default injectIntl(
    CustomLanguageInput
  );
