import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Tooltip,
} from 'antd';

import AplanetIcon from 'components/AplanetIcon';
import ReportingStandardTag from 'components/ReportingStandardTag';
import CustomModalType from 'components/CustomModalType';
import { getOrderedCategoriesStructure } from 'utils/kpi';

const DataRequestsKpiRow = ({
  intl,
  kpiSlug,
  kpiName,
  kpiStandardInfo,
  categories,
  isKpiChecked,
  handleCheckKpi,
  handleOpenKpi,
  selectedTableCustomView,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <tr className="DataRequests__kpi-row">
        <td className="DataRequests__kpi-checkbox-cell" colSpan={1}>
          <Checkbox
           className="DataRequests__kpi-checkbox"
           onChange={() => handleCheckKpi(kpiSlug)}
           checked={isKpiChecked}
          />
        </td>
        <td
          className="DataRequests__kpi-info"
          colSpan={selectedTableCustomView.length}
        >
          <div className="DataRequests__kpi-categories-wrapper">
            <div className="DataRequests__kpi-categories-structure">
              {getOrderedCategoriesStructure(categories[0]).map(category => (
                <div key={`${kpiSlug}_first_category_${category.name}`} className="DataRequests__kpi-categories">
                  <AplanetIcon name="Folder open" size="16px" className="DataRequests__category-icon" />
                  <span className="DataRequests__kpi-category">{category.name}</span>
                  <AplanetIcon name="Chevron right" size="18px" className="DataRequests__arrow-right-icon" />
                </div>
              ))}
            </div>
            {categories.length > 1 ? (
              <>
                <Tooltip
                  overlayClassName="DataRequests__more-categories-tooltip"
                  title={intl.formatMessage({id: 'admin_data_requests_table_see_categories'})}
                >
                  <span
                    className="DataRequests__more-categories"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(true);
                    }}
                  >
                    {intl.formatMessage({id: 'admin_data_requests_table_more_categories'}, { value: categories.length - 1 })}
                  </span>
                </Tooltip>
                <CustomModalType
                  showModal={showModal}
                  width={900}
                  onCancel={(e) => {
                    e.stopPropagation();
                    setShowModal(false);
                  }}
                  onCancelText={intl.formatMessage({id: 'admin_data_requests_table_modal_sync_categories_close'})}
                  title={intl.formatMessage({id: 'admin_data_requests_table_modal_sync_categories_title'})}
                  content={showModal ? (
                    <div className="DataRequests__categories-modal-wrapper">
                      <span>{intl.formatMessage({id: 'admin_data_requests_table_modal_sync_categories_message'})}</span>
                      <hr/>
                      {categories.slice(1).map((categoriesStructure, i) => (
                        <React.Fragment key={`${kpiSlug}_categoriesStructure_${i}`}>
                          <div className="DataRequests__kpi-categories-structure-modal">
                            {getOrderedCategoriesStructure(categoriesStructure).map(category => (
                              <div key={`${kpiSlug}_category_${category.name}`} className="DataRequests__kpi-categories-modal">
                                <AplanetIcon name="Folder open" size="16px" className="DataRequests__category-icon" />
                                <span className="DataRequests__kpi-category">{category.name}</span>
                                <AplanetIcon name="Chevron right" size="18px" className="DataRequests__arrow-right-icon" />
                              </div>
                            ))}
                          </div>
                          <hr/>
                        </React.Fragment>
                      ))}
                    </div>
                  ) : null}
                />
              </>
            ) : null}
          </div>
          <div className="DataRequests__kpi-name-wrapper" onClick={(e) => handleOpenKpi(kpiSlug)}>
            <AplanetIcon name="KPI" faStyle="fas" size="16px" />
            {kpiStandardInfo.map(({standard, code}) => (
              <React.Fragment
                key={`${standard}_${code}`}
              >
                <ReportingStandardTag
                  className="DataRequests__kpi-standard-info"
                  standard={standard}
                  name={code}
                  code={code}
                />
              </React.Fragment>
            ))}
            <Tooltip title={kpiName} overlayClassName="DataRequests__kpi-name-tooltip">
              <span className="DataRequests__kpi-name">{kpiName}</span>
            </Tooltip>
          </div>
        </td>
      </tr>
    </>
  );
};

DataRequestsKpiRow.propTypes = {
  kpiSlug: PropTypes.string.isRequired,
  kpiName: PropTypes.string.isRequired,
  kpiStandardInfo: PropTypes.arrayOf(PropTypes.shape({
    standard: PropTypes.string.isRequired,
    code:PropTypes.string.isRequired,
  })),
  categories: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,   
    parent_id: PropTypes.number,
    name: PropTypes.string.isRequired
  }))),
  isKpiChecked: PropTypes.bool,
  handleCheckKpi: PropTypes.func.isRequired,
  handleOpenKpi: PropTypes.func.isRequired,
  selectedTableCustomView: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default injectIntl(DataRequestsKpiRow);