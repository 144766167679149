import {isEmpty} from 'lodash';

const MAX_USERS_SELECTED = 10;

export const validateStep3 = (intl, values) => {
  const {
    users,
  } = values;
  
  let errors = {};

  if (isEmpty(users)) {
      errors.users = intl.formatMessage({id: 'step_3_error_users_empty'});
  }

  if (users.length > MAX_USERS_SELECTED) {
    errors.periods_limit = intl.formatMessage({id: 'step_3_error_users_limit'});
  }

  return errors;
}; 