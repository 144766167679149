import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import Highlighter from 'react-highlight-words';
import { chain, flatten } from "lodash"

import { formatMessage } from '../../intl';

import AplanetIcon from 'components/AplanetIcon';
import EsgLogo from 'components/EsgLogo';
import SdgMiniLogo from 'components/SdgMiniLogo';
import CustomTagSimple from 'components/CustomTagSimple';
import ReportingStandardTag from 'components/ReportingStandardTag';

import { useSDGsClustering } from "../../hooks/sdg";

// NOTICE: Some memoized components to avoid repeating the same over and over again...
const MemoEsg = React.memo(({
  esg,
  uncolored = false,
}) => {
  return (
    <EsgLogo
      type={esg}
      faStyle={uncolored ? "fal" : "fad"}
      uncolored={uncolored}
    />
  );
});

// NOTICE: String paramters (and not Object) to avoid memoizantion failures
const MemoSdg = React.memo(({
  slug,
  parent_slug,
  code,
  uncolored = false,
}) => {
  const sdg = {
    slug,
    parent_slug,
    code,
  };
  return (
    <SdgMiniLogo
      sdg={sdg}
      unColored={uncolored}
    />
  );
});

const MemoTag = React.memo(({
  tag,
  colorclass,
}) => {
  return (
    <CustomTagSimple
      name={tag}
      colorclass={colorclass}
    />
  );
});



const KpiTreeTitle = ({
  nodeData,
  searchText,
  expandedKeys,
  showDetailedInfo,
  history,
  enabled_reports
}) => {
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useCallback((sdgs) => {
    return clusterSDGs(sdgs).sort((a, b) => a.code - b.code);
  }, [clusterSDGs]);

  const getRecursiveChildren = useCallback((category) => flatten(category?.children?.map(child => {
    if (child.isCategory) return getRecursiveChildren(child)
    else return child
  })), [])

  const childrenEsgs = useMemo(() => {
    return chain(getRecursiveChildren(nodeData)?.map(child => child.esgs)).flatten().uniq().compact().value()
  },
    [getRecursiveChildren, nodeData])

  const childrenSdgs = useMemo(() => {
    const sdgs = chain(getRecursiveChildren(nodeData)?.map(child => clusteredSDGs(child.sdgs))).flatten().uniqBy('code').value()
    return sdgs.map((sdg) => {
      return {
        sdg,
        uncolored: !nodeData.children?.every(child => clusteredSDGs(child.sdgs).some(e => e.code === sdg.code)),
      };
    });
  },
    [clusteredSDGs, getRecursiveChildren, nodeData])

  const childrenTags = useMemo(() => {
    const tags = chain(getRecursiveChildren(nodeData)?.map(child => child.tags)).flatten().uniq().compact().value()
    return tags.map((tag) => {
      return {
        tag,
        colorclass: (nodeData.children?.every(child => child.tags?.some(e => e === tag)) ? "green" : "grey"),
      };
    });
  },
    [getRecursiveChildren, nodeData])

  //Comment this in case we want to put codes on categories later on
  /*const childrenCodes = useMemo(() => {
    const codes = chain(getRecursiveChildren(nodeData)?.map(child => child.standard_info.filter(({ standard }) => child.is_custom ? true : standard !== 'aplanet'))).flatten().uniqBy('code').value()
    return codes
  },
    [getRecursiveChildren, nodeData])*/

  if (!nodeData.isLeaf) {
    // This is a category
    return (
      <div className={`KpiTree__title KpiTree__title__category ${showDetailedInfo ? "KpiTree__title__category__detailed" : ""}`}>
        {
          expandedKeys.includes(nodeData.key)
            ? <AplanetIcon name="Folder open" className="KpiTree__title__icon" />
            : <AplanetIcon name="Folder close" className="KpiTree__title__icon" />
        }

        <span className="KpiTree__title__text">
          {nodeData.code
            ? (
              <ReportingStandardTag
                className="KpiTree__standard_tag"
                standard={nodeData.standard}
                name={nodeData.code}
                code={nodeData.code}
                key={nodeData.standard + nodeData.code}
              />
            )
            : null}
          {!searchText
            ? nodeData.title
            : <Highlighter
              highlightClassName="KpiTree__highlighter"
              searchWords={[searchText]}
              textToHighlight={nodeData.title}
              autoEscape
            />
          }
        </span>
        {
          showDetailedInfo && (
            <>
              <div>
                {
                  (childrenEsgs || []).map(esg =>
                    <MemoEsg
                      key={esg}
                      esg={esg}
                      uncolored
                    />
                  )
                }
              </div>
              <div>
                {
                  (childrenSdgs || []).map(({ sdg, uncolored }, i, arr) => {
                    if (arr.length > 4 && i === 4) return <AplanetIcon key={sdg.slug} name="More" />
                    if (arr.length > 4 && i > 4) return <React.Fragment key={sdg.slug}></React.Fragment>
                    return (
                      <MemoSdg
                        key={sdg.slug}
                        slug={sdg.slug}
                        parent_slug={sdg.parent_slug}
                        code={sdg.code}
                        uncolored={uncolored}
                      />
                    );
                  })
                }
              </div>
              <div>
                {
                  childrenTags.map(({ tag, colorclass }, i, arr) => {
                    if (arr.length > 4 && i === 4) return <AplanetIcon key={tag} name="More" />
                    if (arr.length > 4 && i > 4) return <React.Fragment key={tag}></React.Fragment>
                    return (
                      <MemoTag
                        key={tag}
                        tag={tag}
                        colorclass={colorclass}
                      />
                    )
                  })
                }
              </div>
            </>
          )
        }
      </div>
    );
  }
  return (
    <div className={`KpiTree__title KpiTree__title__kpi ${showDetailedInfo ? "KpiTree__title__kpi__detailed" : ""} ${!nodeData.applies ? "KpiTree__title__kpi__not_applies" : ""}`} key={nodeData.key}>

      <AplanetIcon name="KPI" faStyle="fas" className={`KpiTree__title__icon ${!nodeData.applies ? 'KpiTree__title__icon__not_applies' : ''}`} />

      {!nodeData.applies && (
        <AplanetIcon name="Eye close" faStyle="fal" className="KpiTree__title__icon KpiTree__title__icon__not_applies" />
      )}

      <span className="KpiTree__title__text">
        {(nodeData.standard_info || []).filter(({ standard }) => nodeData.is_custom ? true : enabled_reports.includes(standard)).map(({ standard, code }) => (
          code
            ? (
              <ReportingStandardTag
                className="KpiTree__standard_tag"
                standard={standard}
                name={code}
                code={code}
                key={standard + code}
              />
            )
            : null
        ))}
        {!searchText
          ? nodeData.title
          : <Highlighter
            highlightClassName="KpiTree__highlighter"
            searchWords={[searchText]}
            textToHighlight={nodeData.title}
            autoEscape
          />
        }
      </span>
      {
        showDetailedInfo && (
          <>
            <div>
              {
                (nodeData.esgs || []).map(esg =>
                  <MemoEsg
                    key={esg}
                    esg={esg}
                    uncolored={false}
                  />
                )
              }
            </div>
            <div>
              {
                (clusteredSDGs(nodeData.sdgs) || []).map((sdg, i, arr) => {
                  if (arr.length > 4 && i === 4) return <AplanetIcon key={sdg.slug} name="More" />
                  if (arr.length > 4 && i > 4) return <React.Fragment key={sdg.slug}></React.Fragment>
                  return (
                    <MemoSdg
                      key={sdg.slug}
                      slug={sdg.slug}
                      parent_slug={sdg.parent_slug}
                      code={sdg.code}
                    />
                  )
                })
              }
            </div>
            <div>
              {
                nodeData.tags?.map((tag, i, arr) => {
                  if (arr.length > 4 && i === 4) return <AplanetIcon key={tag} name="More" />
                  if (arr.length > 4 && i > 4) return <React.Fragment key={tag}></React.Fragment>
                  return (
                    <MemoTag
                      key={tag}
                      tag={tag}
                      colorclass="green"
                    />
                  )
                })
              }
            </div>
          </>
        )
      }
      <div>
        <div className="go_to_kpi_icon" onClick={() => history.push(`/kpi/${nodeData.slug}/last/data`)}>
          <span>{formatMessage('kpi_title_go_to_indicator')}</span>
          <AplanetIcon
            name="Arrow to right"
            faStyle="fal"
            className="KpiTree__title__icon"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(KpiTreeTitle);
