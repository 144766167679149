import React, {
  useMemo,
  useCallback,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import CustomInputSearch from 'components/CustomInputSearch';
import {
  Tree,
} from 'antd';

import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';
import KpiTreeTitle from './KpiTreeTitle';

import {
  searchInReportingStructureTree,
  getFlattenKpis,
  reportingStructureToTree,
  getParentsOfRSCategory,
  getParentsOfRSKpi,
} from 'utils/reporting_structure';
import {
  flattenTree,
  mapOverTreeFromLeafs,
} from 'utils/tree';
import {
  isDisabledCategoryInReportingStructureTree,
  isDisabledKpiInReportingStructureTree,
} from 'utils/dataRequests';

import { sortKpiStructure } from 'utils/kpi';

import './style.less';

const KpiTreeSelector = ({
  intl,
  reportingStructure,
  onSelectKpis,
  selectedKpis,
  enabledReports
}) => {
  const [indicatorsSearch, setIndicatorsSearch] = useState('');
  const [expandedTreeNodes, setExpandedTreeNodes] = useState([]);
  const [checkedKpis, setCheckedKpis] = useState(selectedKpis);

  const reportingStructureTree = useMemo(
      () => reportingStructureToTree(reportingStructure || []),
      [reportingStructure]
    );

  const filteredReportingStructureTree = useMemo(
    () => {
      if (!indicatorsSearch) {
        setExpandedTreeNodes([]);
        return reportingStructureTree;
      }
      const expandedNodes = [];
      const filteredTreeData = searchInReportingStructureTree(reportingStructureTree, indicatorsSearch.toLowerCase(), expandedNodes);
      setExpandedTreeNodes(expandedNodes);
      return filteredTreeData;
    },
    [
      indicatorsSearch,
      reportingStructureTree,
    ]
  );

  useEffect(() => {
    let expandedNodes = [...expandedTreeNodes];
    mapOverTreeFromLeafs(filteredReportingStructureTree)((reportingStructureNode) => {
      if (!!reportingStructureNode.category) {
        const kpisInside = getFlattenKpis([reportingStructureNode])() || [];
        if (kpisInside.some(kpi => (checkedKpis.map(({uuid}) => uuid)).includes(kpi.uuid)) && !expandedTreeNodes.includes(reportingStructureNode.category.uuid)) {
          expandedNodes = [...expandedNodes, reportingStructureNode.category.uuid];
        }
      }
    });
    setExpandedTreeNodes(expandedNodes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const treeData = useMemo(
    () => {
      const treeDataUnsorted = mapOverTreeFromLeafs(filteredReportingStructureTree)((reportingStructureNode, children = []) => {
        if (!!reportingStructureNode.category) {
          const kpisInside = getFlattenKpis([reportingStructureNode])() || [];
          return {
            uuid: reportingStructureNode.category.uuid,
            checkable: kpisInside.length > 0,
            isCategory: true,
            title: reportingStructureNode.category.name,
            name: reportingStructureNode.category.name,
            slug: reportingStructureNode.category.slug,
            code: reportingStructureNode.category.code,
            standard: reportingStructureNode.category.standard,
            key: reportingStructureNode.category.uuid,
            children: children.sort(sortKpiStructure(intl.locale)),
            disabled: isDisabledCategoryInReportingStructureTree(checkedKpis, kpisInside),
            parents: getParentsOfRSCategory(reportingStructure, reportingStructureNode.category.uuid),
          }
        }

        const kpiCodes = (reportingStructureNode.standard_info || [])
                          .filter(({ standard }) => reportingStructureNode.is_custom || enabledReports.includes(standard))
                          .map(({ code }) => code); 
                          
        return {
          ...reportingStructureNode,
          uuid: reportingStructureNode.uuid,
          title: reportingStructureNode.name,
          key: reportingStructureNode.uuid,
          isLeaf: true,
          disabled: isDisabledKpiInReportingStructureTree(checkedKpis, reportingStructureNode),
          code: kpiCodes.length ? kpiCodes[0] : undefined, 
          parents: getParentsOfRSKpi(reportingStructure, reportingStructureNode.uuid),
        };
      });

      return treeDataUnsorted.sort(sortKpiStructure(intl.locale));
    },
    [
      checkedKpis,
      filteredReportingStructureTree,
      reportingStructure,
      enabledReports,
      intl.locale
    ]
  );

  const handleCheck = useCallback(
    (_, {checked, checkedNodes, node}) => {
      let checkedKpisData = [...checkedKpis];
      let checkedKpisDataUUids = checkedKpisData.map(({uuid}) => uuid);

      checkedNodes.forEach(nodeData => {
          if (!nodeData.isCategory && !checkedKpisDataUUids.includes(nodeData.uuid)) {
            checkedKpisData.push(nodeData);
          }
        }
      );

      if(!checked) {
        const checkedUuidsFromCurrentNodeAndParents = [...Object.keys(flattenTree(node, 'uuid')), ...node.parents];
        checkedKpisData = checkedKpisData.filter(k => !checkedUuidsFromCurrentNodeAndParents.includes(k.uuid))
      }

      setCheckedKpis(checkedKpisData);
      onSelectKpis(checkedKpisData);
    },
    [checkedKpis, onSelectKpis]
  );

  const checkedKeys = useMemo(
    () => checkedKpis.map(({uuid}) => uuid),
    [checkedKpis]
  );

  const hasSearch = Boolean(indicatorsSearch)

  return (
    <div className="DataRequestKpiTreeSelector">
      <div className="DataRequestKpiTreeSelector__search">
        <CustomInputSearch
          value={indicatorsSearch}
          onChange={setIndicatorsSearch}
          placeholder={intl.formatMessage({id: 'step_1_indicators_search_placeholder'})}
          allowClear
        />
      </div>
      <div className="DataRequestKpiTreeSelector__tree">
        {treeData?.length === 0 && indicatorsSearch 
          ? <Empty {...getEmptyResultsProps(hasSearch, false)} />
          : (
          <Tree 
            expandedKeys={expandedTreeNodes}
            onExpand={setExpandedTreeNodes}
            treeData={treeData}
            titleRender={nodeData => <KpiTreeTitle nodeData={nodeData} searchText={indicatorsSearch} enabledReports={enabledReports} />}
            checkedKeys={checkedKeys}
            onCheck={handleCheck}
            showIcon={false}
            expandAction={false}
            selectable={false}
            multiple
            checkable
          />
        )}
      </div>
    </div>
  );
}

KpiTreeSelector.propTypes = {
  reportingStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedKpis: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  onSelectKpis: PropTypes.func.isRequired,
  enabledReports: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(KpiTreeSelector);
